import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { DialogComponent } from '../../../dialog/components/dialog/dialog.component';
import { MatDialogConfig } from '@angular/material/dialog';
import { ResourcesService } from '../../../../services/resources.service';
import { ChatApiService } from '../../../../services/chat.api.service';
import { Router } from '@angular/router';
import { RouteHelper } from '../../../../helpers/route.helper';
import { ConfirmDestructionDialogComponent } from '../confirm-destruction-dialog/confirm-destruction-dialog.component';
import { PDFSource } from 'ng2-pdf-viewer';
import { AuthService } from '../../../../services/auth.service';
import { Constants } from '../../../../classes/constants';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'tpt-contract-sign-dialog',
  templateUrl: './contract-sign-dialog.component.html',
  styleUrls: ['./contract-sign-dialog.component.scss']
})
export class ContractSignDialogComponent implements OnInit {

  @ViewChild(DialogComponent)
  public dialog: DialogComponent;

  @ViewChild(ConfirmDestructionDialogComponent)
  public changeContract: ConfirmDestructionDialogComponent;

  @Output() signDocumentEvent: EventEmitter<any> = new EventEmitter<any>();

  public src: PDFSource;

  public docType: 'contract' | 'wcc' = 'contract';

  public showActions = false;

  public changeContractTitle = 'Изменение условий договора';

  public changeContractText = 'Для изменения договора обратитесь в службу поддержки';

  private config: MatDialogConfig = {
    width: '720px',
    height: '100vh',
  };

  private className = 'tpt-contract-sign-dialog';

  constructor(private resource: ResourcesService,
              private chatService: ChatApiService,
              private authService: AuthService,
              private routeHelper: RouteHelper,
              private router: Router) { }

  ngOnInit(): void {
  }

  public async open(fileId: string, showActions: boolean, docType: 'contract' | 'wcc'): Promise<void> {
    const expiresAt = +localStorage.getItem(Constants.EXPIRES_KEY);
    const now = Date.now();

    if (now > expiresAt) {
      const refreshToken = localStorage.getItem(Constants.REFRESHTOKEN_KEY);
      if (refreshToken) {

        try {
          const data = await lastValueFrom(this.authService.refreshToken(refreshToken));
          this.authService.updateToken(data);
        } catch (err) {
        }
      }
    }

    const token = localStorage.getItem(Constants.TOKEN_KEY);
    this.src = {
      url: this.resource.getUrl(fileId),
      httpHeaders: {Authorization: `Bearer ${token}`},
      withCredentials: true,
    };

    this.docType = docType;
    this.showActions = showActions;
    this.dialog.config = this.config;
    this.dialog.open(this.className);
  }

  public close(): void {
    this.dialog.close();
  }

  public signDocument(): void {
    this.signDocumentEvent.emit(this.docType);
    this.close();
  }

  public changeContractOpen(): void {
    this.changeContract.open('CHANGE_CONTRACT',
      this.changeContractTitle, this.changeContractText, 'primary', 'В чат поддержки');
  }

  public change(): void {
    this.chatService.createSupportChat().subscribe((chat) => {
      const url = this.router.serializeUrl(
        this.router.createUrlTree(this.routeHelper.contractsChatsUrl(chat.id))
      );

      window.open(url, '_blank');
    }, () => {});
  }

}
