import { Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { each } from 'lodash/fp';
import { CoSvgIconsEnum, SvgIconsEnum } from '../types/svg-icons.enum';

@Injectable({
  providedIn: 'root'
})
export class IconsService {

  static svgIcons = [
    SvgIconsEnum.SidebarLogo,
    SvgIconsEnum.SidebarHome,
    SvgIconsEnum.SidebarFinances,
    SvgIconsEnum.SidebarMessages,
    SvgIconsEnum.SidebarSettings,
    SvgIconsEnum.SidebarFns,
    SvgIconsEnum.SidebarProjects,
    SvgIconsEnum.SidebarTeam,
    SvgIconsEnum.SidebarHelp,
    SvgIconsEnum.SidebarExit,
    SvgIconsEnum.Eye,
    SvgIconsEnum.TriangleDown,
    SvgIconsEnum.DotsMoreHorizontal,
    SvgIconsEnum.Clock,
    SvgIconsEnum.Trash,
    SvgIconsEnum.Trash2,
    SvgIconsEnum.AddContactIcon,
    SvgIconsEnum.CircleCheckmark,
    SvgIconsEnum.Info,
    SvgIconsEnum.Notes,
    SvgIconsEnum.SquareWithPencil,
    SvgIconsEnum.Cross,
    SvgIconsEnum.Pin,
    SvgIconsEnum.Unpin,
    SvgIconsEnum.Attachment,
    SvgIconsEnum.Comment,
    SvgIconsEnum.AssigneePlaceholder,
    SvgIconsEnum.AttachedFile,
    SvgIconsEnum.ExpandedLogo,
    SvgIconsEnum.Bell,
    SvgIconsEnum.ArrowLeft,
    SvgIconsEnum.ArrowRight,
    SvgIconsEnum.LoginCardIcon,
    SvgIconsEnum.RestoreCardIcon,
    SvgIconsEnum.RegisterCardIcon,
    SvgIconsEnum.SidebarSearch,
    SvgIconsEnum.StripePurple,
    SvgIconsEnum.DialogSuccessIcon,
    SvgIconsEnum.DialogFailureIcon,
    SvgIconsEnum.MastercardGrey,
    SvgIconsEnum.StripeGrey,
    SvgIconsEnum.VisaGrey,
    SvgIconsEnum.PayoneerGrey,
    SvgIconsEnum.PayoneerWithColor,
    SvgIconsEnum.Location,
    SvgIconsEnum.DatepickerCalendar,
    SvgIconsEnum.Check,
    SvgIconsEnum.TaskNotes,
    SvgIconsEnum.Notification,
    SvgIconsEnum.Language,
    SvgIconsEnum.ArrowDown,
    SvgIconsEnum.CreateProject,
    SvgIconsEnum.FillProfile,
    SvgIconsEnum.FindProject,
    SvgIconsEnum.HireFreel,
    SvgIconsEnum.Results,
    SvgIconsEnum.Secure,
    SvgIconsEnum.Track,
    SvgIconsEnum.SidebarArrow,
    SvgIconsEnum.SuccessMessage,
    SvgIconsEnum.ErrorMessage,
    SvgIconsEnum.SingleProject,
    SvgIconsEnum.MultiProject,
    SvgIconsEnum.Budget,
    SvgIconsEnum.Candidate,
    SvgIconsEnum.TaskList,
    SvgIconsEnum.ArrowBack,
    SvgIconsEnum.Edit,
    SvgIconsEnum.Delete,
    SvgIconsEnum.InfoIcon,
    SvgIconsEnum.Plus,
    SvgIconsEnum.Assignees,
    SvgIconsEnum.PlusIcon,
    SvgIconsEnum.Details,
    SvgIconsEnum.Calendar,
    SvgIconsEnum.Expand,
    SvgIconsEnum.Feedback,
    SvgIconsEnum.Success,
    SvgIconsEnum.Search,
    SvgIconsEnum.HeaderLogo,
    SvgIconsEnum.ArrowForward,
    SvgIconsEnum.FooterLogo,
    SvgIconsEnum.FlagEN,
    SvgIconsEnum.FlagRU,
    SvgIconsEnum.Instagram,
    SvgIconsEnum.Facebook,
    SvgIconsEnum.Linkedin,
    SvgIconsEnum.Telegram,
    SvgIconsEnum.Medium,
    SvgIconsEnum.Message,
    SvgIconsEnum.Close,
    SvgIconsEnum.PageUp,
    SvgIconsEnum.ConfirmSuccess,
    SvgIconsEnum.Mail,
    SvgIconsEnum.HighPrio,
    SvgIconsEnum.MediumPrio,
    SvgIconsEnum.LowPrio,
    SvgIconsEnum.NoPrio,
    SvgIconsEnum.ExpandMore,
    SvgIconsEnum.Stripe,
    SvgIconsEnum.Payoneer,
    SvgIconsEnum.TransactionFail,
    SvgIconsEnum.Fee,
    SvgIconsEnum.Arbitrage,
    SvgIconsEnum.SmallArrowDown,
    SvgIconsEnum.Fivver,
    SvgIconsEnum.Freelancer_Ru,
    SvgIconsEnum.Upwork,
    SvgIconsEnum.Camera,
    SvgIconsEnum.FilterList,
    SvgIconsEnum.Bubble,
    SvgIconsEnum.Mustache,
    SvgIconsEnum.Zzz,
    SvgIconsEnum.Cancel,
    SvgIconsEnum.BubbleMessage,
    SvgIconsEnum.Flag,
    SvgIconsEnum.CreditCard,
    SvgIconsEnum.Keyboard,
    SvgIconsEnum.AmazonMT,
    SvgIconsEnum.Design99,
    SvgIconsEnum.Kwork,
    SvgIconsEnum.PeoplePH,
    SvgIconsEnum.SimplyHired,
    SvgIconsEnum.Toptal,
    SvgIconsEnum.YaToloka,
    SvgIconsEnum.HelpOutline,
    SvgIconsEnum.ExpandLess,
    SvgIconsEnum.Individual,
    SvgIconsEnum.IndividualEntrepreneur,
    SvgIconsEnum.LegalEntity,
    SvgIconsEnum.Hourglass,
    SvgIconsEnum.Download,
    SvgIconsEnum.MoreHoriz,
    SvgIconsEnum.Link,
    SvgIconsEnum.Sticky,
    SvgIconsEnum.Archived,
    SvgIconsEnum.DocUpload,
    SvgIconsEnum.PurpleCheck,
    SvgIconsEnum.Bank,
    SvgIconsEnum.CheckSmall,
    SvgIconsEnum.Refresh,
    SvgIconsEnum.OnBoarding,
    SvgIconsEnum.LightBulb,
    SvgIconsEnum.CaptionsBubble,
    SvgIconsEnum.LegalInfo,
    SvgIconsEnum.Contact,
    SvgIconsEnum.CheckSuccess,
    SvgIconsEnum.InfoWarn,
    SvgIconsEnum.CopyLink,
    SvgIconsEnum.Email,
    SvgIconsEnum.Phone,
    SvgIconsEnum.Web,
    SvgIconsEnum.Skype,
    SvgIconsEnum.Google,
    SvgIconsEnum.Dollar,
    SvgIconsEnum.ThinCheck,
    SvgIconsEnum.Staple,
    SvgIconsEnum.Replay,
    SvgIconsEnum.EyeOpen,
    SvgIconsEnum.EyeClose,
    SvgIconsEnum.Deregistered,
    SvgIconsEnum.SupportAvatar,
    SvgIconsEnum.Processing,
    SvgIconsEnum.XMark,
    SvgIconsEnum.CheckCircle,
    SvgIconsEnum.Time,
    SvgIconsEnum.Clipboard,
    SvgIconsEnum.Sbp,
    SvgIconsEnum.WarnIcon,
    SvgIconsEnum.ErrIcon,
    SvgIconsEnum.WarningState,
    SvgIconsEnum.ErrorState,
    SvgIconsEnum.SuccessState,
    SvgIconsEnum.CheckThin,
    SvgIconsEnum.WhiteBigLogo,
    SvgIconsEnum.WhiteSmallLogo,
    SvgIconsEnum.ArrowRefresh,
    SvgIconsEnum.Media,
    SvgIconsEnum.BurgerMenu,
    SvgIconsEnum.Grid,
    SvgIconsEnum.CoInfo,
    SvgIconsEnum.SidebarContracts,
    SvgIconsEnum.WarningSmall,
    SvgIconsEnum.EmptyList,
    SvgIconsEnum.UserIcon,
    SvgIconsEnum.UserIconGray,

    // Creative objects icons
    CoSvgIconsEnum.Image,
    CoSvgIconsEnum.Books,
    CoSvgIconsEnum.Quotes,
    CoSvgIconsEnum.Headphones,
    CoSvgIconsEnum.Photo,
    CoSvgIconsEnum.Planet,
    CoSvgIconsEnum.Puzzle,
    CoSvgIconsEnum.Crop,
    CoSvgIconsEnum.Text,
    CoSvgIconsEnum.Play,
    CoSvgIconsEnum.Code,
    CoSvgIconsEnum.Comics,
    CoSvgIconsEnum.Seller,
    CoSvgIconsEnum.Buyer,
    CoSvgIconsEnum.FullLogo,
    CoSvgIconsEnum.SmallLogo,
    CoSvgIconsEnum.Wallet,
    CoSvgIconsEnum.Box,
    CoSvgIconsEnum.Folder,
    CoSvgIconsEnum.Study,
    CoSvgIconsEnum.PO,
    CoSvgIconsEnum.Design,
    CoSvgIconsEnum.Book,
    CoSvgIconsEnum.Audio,
    CoSvgIconsEnum.Music,
    CoSvgIconsEnum.Buy,
    CoSvgIconsEnum.CoSwitcher,
    CoSvgIconsEnum.TcSwitcher,
    CoSvgIconsEnum.Cart,
    CoSvgIconsEnum.TopUp,
    CoSvgIconsEnum.PriceTag,
    CoSvgIconsEnum.Withdraw,
    CoSvgIconsEnum.Exit,
  ];

  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
  ) { }

  public registerIcons(): void {
    each(this.addSvgIcon, IconsService.svgIcons);
  }

  private addSvgIcon = (image: string): void => {
    this.matIconRegistry.addSvgIcon(
      image,
      this.domSanitizer.bypassSecurityTrustResourceUrl(`/assets/icons/${image}.svg`),
    );
  }

}
