<div class="forgot-password-root">
  <div class="form-wrapper">
    <mat-icon [svgIcon]="svgIconsEnum.FooterLogo" class="logo-full"></mat-icon>
    <div class="form-column">
      <h2 class="login__title">
        {{ cardTitle | translate}}
      </h2>

      <div *ngIf="state == ForgotPasswordStates.EMAIL_INPUT">
        <div class="info-text">
          {{ 'FORGOT_COMPONENT.INIT_INFO_TEXT' | translate }}
        </div>

          <tpt-input
            [control]="restorePasswordEmailForm.username"
            placeholder="{{ 'LOGIN_COMPONENT.ENTER_EMAIL_ADDRESS' | translate }}"
          >
            <ng-container label>Email</ng-container>
          </tpt-input>
          <tpt-button
            (onClick)="reset()"
            [disabled]="loading"
            type="primary"
            size="large"
            class="full-width mt-28"
          >
            {{ 'FORGOT_COMPONENT.RESET_PASSWORD' | translate }}
          </tpt-button>
      </div>

      <div
        *ngIf="state == ForgotPasswordStates.EMAIL_SENT"
      >
        <h2 class="check-email">
          {{ 'FORGOT_COMPONENT.EMAIL_SENT' | translate }}
        </h2>
        <p class="link-sent">
          {{ 'FORGOT_COMPONENT.LINK_SEND' | translate }}
          <span class="email">{{restorePasswordEmailForm.username.value}}</span>
        </p>
      </div>


    <div *ngIf="state == ForgotPasswordStates.PASSWORD_INPUT && hasToken">

      <div>
        <div style="position: relative">
          <tpt-input
            [control]="changePasswordForm.changePasswordNew"
            type="password"
            (onFocus)="showTooltip = true"
            (onBlur)="showTooltip = false"
            placeholder="{{ 'FORGOT_COMPONENT.RESTORE_FORGOT_PASSWORD_TEXT' | translate }}"
          >
            <ng-container label>{{ 'FORGOT_COMPONENT.NEW_PASSWORD' | translate }}</ng-container>
          </tpt-input>

          <div class="tooltip" [class.show]="showTooltip">
            <div class="strength-info">
              <div class="arrow"></div>
              <div class="title">Пароль должен содержать:</div>
              <div class="info-row" *ngIf="passwordComponent.enableLengthRule">
                <div *ngIf="passwordComponent.containAtLeastMinChars; then done else error">
                </div>
                <span [class.done]="passwordComponent.containAtLeastMinChars">Как минимум 12 символов</span>
              </div>
              <div class="info-row" *ngIf="passwordComponent.enableDigitRule">
                <div *ngIf="passwordComponent.containAtLeastOneDigit; then done else error">
                </div>
                <span [class.done]="passwordComponent.containAtLeastOneDigit">Как минимум одну цифру</span>
              </div>
              <div class="info-row" *ngIf="passwordComponent.enableLowerCaseLetterRule">
                <div *ngIf="passwordComponent.containAtLeastOneLowerCaseLetter; then done else error">
                </div>
                <span [class.done]="passwordComponent.containAtLeastOneLowerCaseLetter">Как минимум одну букву в нижнем регистре</span>
              </div>
              <div class="info-row" *ngIf="passwordComponent.enableUpperCaseLetterRule">
                <div *ngIf="passwordComponent.containAtLeastOneUpperCaseLetter; then done else error">
                </div>
                <span [class.done]="passwordComponent.containAtLeastOneUpperCaseLetter">Как минимум одну букву в верхнем регистре</span>
              </div>
              <div class="info-row" *ngIf="passwordComponent.enableSpecialCharRule">
                <div *ngIf="passwordComponent.containAtLeastOneSpecialChar; then done else error">
                </div>
                <span [class.done]="passwordComponent.containAtLeastOneSpecialChar">Как минимум один специальный символ</span>
              </div>
              <ng-template #done>
                <svg width="12" height="13" class="mr-8" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M0 6.5C0 3.19531 2.67188 0.5 6 0.5C9.30469 0.5 12 3.19531 12 6.5C12 9.82812 9.30469 12.5 6 12.5C2.67188 12.5 0 9.82812 0 6.5ZM8.69531 5.46875C8.95312 5.21094 8.95312 4.8125 8.69531 4.55469C8.4375 4.29688 8.03906 4.29688 7.78125 4.55469L5.25 7.08594L4.19531 6.05469C3.9375 5.79688 3.53906 5.79688 3.28125 6.05469C3.02344 6.3125 3.02344 6.71094 3.28125 6.96875L4.78125 8.46875C5.03906 8.72656 5.4375 8.72656 5.69531 8.46875L8.69531 5.46875Z"
                    fill="#50997B"/>
                </svg>
              </ng-template>
              <ng-template #error>
                <svg width="12" height="13" class="mr-8" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M12 6.5C12 9.82812 9.30469 12.5 6 12.5C2.67188 12.5 0 9.82812 0 6.5C0 3.19531 2.67188 0.5 6 0.5C9.30469 0.5 12 3.19531 12 6.5ZM6 1.625C3.30469 1.625 1.125 3.82812 1.125 6.5C1.125 9.19531 3.30469 11.375 6 11.375C8.67188 11.375 10.875 9.19531 10.875 6.5C10.875 3.82812 8.67188 1.625 6 1.625Z" fill="#C4C6CC"/>
                </svg>
              </ng-template>
            </div>
          </div>
        </div>
        <div class="strength-bar"
             [class.visible]="changePasswordForm.changePasswordNew?.touched || changePasswordForm.changePasswordNew?.dirty">
          <password-strength [password]="changePasswordForm.changePasswordNew.value"
                             #passwordComponent
                             [min]="minPasswordLength"
                             class="mb-16"
          ></password-strength>
        </div>
      </div>

      <tpt-input
        [control]="changePasswordForm.changePasswordConfirm"
        type="password"
        placeholder="{{ 'FORGOT_COMPONENT.ENTER_PASSWORD_AGAIN' | translate }}"
      >
        <ng-container label>{{ 'REGISTER_COMPONENT.CONFIRM' | translate }}</ng-container>
      </tpt-input>

      <tpt-button type="primary"
                  size="large"
                  class="full-width mt-8"
                  (onClick)="change()"
                  [disabled]="isChangePasswordButtonDisabled"
      >
        {{ 'FORGOT_COMPONENT.CHANGE_PASSWORD' | translate }}
      </tpt-button>
    </div>

      <div *ngIf="state == ForgotPasswordStates.PASSWORD_CHANGED">
        <h2 class="check-email">{{ 'FORGOT_COMPONENT.CHANGED_SUCCESS' | translate }}
          <mat-icon [svgIcon]="svgIconsEnum.ConfirmSuccess" class="success-icon"></mat-icon>
        </h2>
        <p class="link-sent link-sent-pass-changed">{{ 'FORGOT_COMPONENT.YOU_CAN_LOGIN' | translate }}</p>
        <tpt-button class="full-width"
                    type="primary"
                    size="large"
                    [routerLink]="['/login']"
        >
          {{ 'FORGOT_COMPONENT.LOGIN' | translate }}
        </tpt-button>
      </div>
    </div>
  </div>

  <app-auth-pages-footer></app-auth-pages-footer>
</div>
