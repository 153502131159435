import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { SvgIconsEnum } from '../../../../types/svg-icons.enum';
import { FileUpload } from '@/models/fileUpload';
import sanitizeSVG from '@/helpers/sanitizeSVG';
import { SnackbarNotificationsService } from '@/services/snackbar-notifications.service';
import imageCompression from 'browser-image-compression';

@Component({
  selector: 'tpt-drag-n-drop',
  templateUrl: './drag-n-drop.component.html',
  styleUrls: ['./drag-n-drop.component.scss']
})
export class DragNDropComponent implements OnInit{

  @ViewChild('fileDropRef', {static: false}) fileDropEl: ElementRef;
  @Input() files: any[] = [];
  @Input() required = false;
  svgIconsEnum = SvgIconsEnum;
  @Input() size: 'standard' | 'small' = 'standard';
  @Input() invisible = false;
  @Input() message = 'CREATE_PROJECT.DRAG_N_DROP_MESSAGE';
  @Input() tip = 'CREATE_PROJECT.SIZE_INFO';
  @Input() accept = '';
  @Input() filesLimit = 10;
  @Input() sizeLimit = 50;

  @Output() public uploadFileChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() public deleteFileEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() public deleteFileByUuidEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() public fileClickEvent: EventEmitter< File | FileUpload> = new EventEmitter< File | FileUpload>();

  constructor(private snack: SnackbarNotificationsService) {
  }
  ngOnInit() {
    if (!this.files) {
      this.files = [];
    }
  }

  /**
   * on file drop handler
   */
  onFileDropped($event) {
    this.prepareFilesList($event);
  }

  /**
   * handle file from browsing
   */
  fileBrowseHandler(files) {
    this.prepareFilesList(files);
  }

  /**
   * emit file browsing
   */
  openFilePicker() {
    this.fileDropEl?.nativeElement.click();
  }

  /**
   * Delete file from files list
   * @param index (File index)
   * @param file (File)
   */
  deleteFile(index: number, file) {
    if (this.files[index]?.progress < 100) {
      console.log('Upload in progress.');
      return;
    }
    this.files.splice(index, 1);
    this.deleteFileEvent.emit(file.id);
    this.deleteFileByUuidEvent.emit(file.id);
  }

  /**
   * Simulate the upload process
   */
  uploadFilesSimulator(index: number) {
    if (index === this.files?.length) {
      return;
    } else {
      const progressInterval = setInterval(() => {
        if (this.files[index]?.id) {
          this.files[index].progress = 100;
        }
        if (this.files[index]?.progress === 100) {
          clearInterval(progressInterval);
          this.uploadFilesSimulator(index + 1);
        } else {
          this.files[index].progress += 5;
        }
      }, 10);
    }
  }

  /**
   * Convert Files list to normal array list
   * @param files (Files List)
   */
  async prepareFilesList(files: Array<any>) {
    for (let item of files) {
      if (this.files?.length >= this.filesLimit) {
        return;
      }

      if (this.accept) {
        const typesArr = this.accept.split(', ');
        if (!typesArr.includes(item.type)) {
          this.snack.showNotification('Неверный формат файла', 'fail');
          return;
        }
      }

      if (item.size >= this.sizeLimit * 1024 * 1024) {
        this.snack.showNotification('Превышен лимит размера файла', 'fail');
        return;
      }

      if (['image/jpeg', 'image/png'].includes(item?.type) && (item.size >= 5 * 1024 * 1024)) {
        const options = {
          maxSizeMB: 5,
          useWebWorker: true,
        };
        item = await imageCompression(item, options);
      }

      if (item.type === 'image/svg+xml') {
        const clear = await sanitizeSVG(item);
        if (!clear) {
          this.snack.showNotification('Ошибка при загрузке файла', 'fail');
          return;
        }
      }
      item.progress = 0;
      this.files.push(item);
      this.uploadFileChange.emit(item);
    }

    this.fileDropEl.nativeElement.value = '';
    this.uploadFilesSimulator(0);
  }

  /**
   * format bytes
   * @param bytes (File size in bytes)
   * @param decimals (Decimals point)
   */
  formatBytes(bytes, decimals = 2) {
    if (bytes === 0) {
      return '0 Bytes';
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  fileClick(file: File | FileUpload): void {
    this.fileClickEvent.emit(file);
  }
}
