import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { DialogComponent } from '../../../dialog/components/dialog/dialog.component';
import { SvgIconsEnum } from '../../../../types/svg-icons.enum';
import { MatDialogConfig } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { ProfileService } from '../../../../services/profile.service';
import { UntypedFormControl, Validators } from '@angular/forms';
import { cloneDeep } from 'lodash';
import { ChatItemModel } from '../../../contracts/models/chat-item.model';
import { ChatApiService } from '../../../../services/chat.api.service';
import { SpinnerService } from '../../../tpt-ui/services/spinner.service';
import { AddParticipantsComponent } from './add-participants/add-participants.component';
import { ContractsService } from '../../../contracts/services/contracts.service';
import { ProjectApiService } from '../../../../services/project.api.service';
import { ConfirmDestructionDialogComponent } from '../confirm-destruction-dialog/confirm-destruction-dialog.component';
import { DragNDropComponent } from '../../../tpt-ui/components/drag-n-drop/drag-n-drop.component';
import { FileUpload } from '../../../../models/fileUpload';
import { FileUploadService } from '../../../../services/file-upload.service';

@Component({
  selector: 'chat-edit-dialog',
  templateUrl: './chat-edit-dialog.component.html',
  styleUrls: ['./chat-edit-dialog.component.scss']
})
export class ChatEditDialogComponent {

  @Output() editChatMembers: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild(DialogComponent)
  public dialog: DialogComponent;

  @ViewChild(AddParticipantsComponent)
  public addParticipants: AddParticipantsComponent;

  @ViewChild(ConfirmDestructionDialogComponent)
  public destructionDialog: ConfirmDestructionDialogComponent;

  @ViewChild(DragNDropComponent, {static: false})
  public uploadComponent: DragNDropComponent;

  public svgIconsEnum = SvgIconsEnum;

  public chat: ChatItemModel;

  public currentUser;

  public name: UntypedFormControl = new UntypedFormControl('', [Validators.required]);

  public avatar: UntypedFormControl = new UntypedFormControl('');

  private config: MatDialogConfig = {
    width: '580px',
    panelClass: 'chat-dialog-align',
  };

  private className = 'chat-edit-dialog';

  private participantToDeleteId: string;

  constructor(private translate: TranslateService,
              private chatApiService: ChatApiService,
              private contractsService: ContractsService,
              private spinner: SpinnerService,
              private projectApiService: ProjectApiService,
              private filesService: FileUploadService,
              private profileService: ProfileService) {
  }

  public open(chat: ChatItemModel): void {
    this.dialog.config = this.config;
    this.dialog.open(this.className);
    this.chat = chat;
    this.name.setValue(chat.name);
    this.avatar.setValue(chat.avatar && chat.avatar.id || '');

    const id = this.profileService.currentProfile.id;
    this.currentUser = this.chat.participants.filter(item => item.participant.id === id)[0];
  }

  public close(): void {
    this.dialog.close();
  }

  public save(): void {
    const body = this.getBody();
    body.name = this.name.value;
    body.avatarId = this.avatar.value;

    this.spinner.startSpinner();
    this.chatApiService.editChat(body).subscribe(() => {
      this.spinner.stopSpinner();
      this.chat.name = this.name.value;
      this.chat.avatar = {id: this.avatar.value};
      this.editChatMembers.emit();
    }, this.spinner.stopSpinner);
  }

  public makeAdmin(id: string): void {
    const body = this.getBody();

    body.participants.forEach(item => {
      if (item.participantId === id) {
        item.role = 'ADMIN';
      }
    });

    this.spinner.startSpinner();

    this.chatApiService.editChat(body).subscribe(() => {
      this.spinner.stopSpinner();
      this.chat.participants.forEach(item => {
        if ((item.participant.id) === id) {
          item.role = 'ADMIN';
        }
      });

    }, this.spinner.stopSpinner);
  }

  public deleteParticipant(id: string): void {
    const body = this.getBody();

    body.participants = body.participants.filter(item => item.participantId !== id);

    this.spinner.startSpinner();
    this.chatApiService.editChat(body).subscribe(() => {
      this.spinner.stopSpinner();
      this.editChatMembers.emit();
      this.chat.participants = this.chat.participants.filter(item => item.participant.id !== id);
    }, this.spinner.stopSpinner);
  }

  public openDeleteParticipantDialog(id): void {
    this.destructionDialog.open('DELETE_PARTICIPANT', 'CONTRACTS.DELETE_CHAT_MEMBER_TITLE',
      'CONTRACTS.DELETE_CHAT_MEMBER_TEXT', 'warn', 'BUTTON.DELETE');
    this.participantToDeleteId = id;
  }

  public openAddParticipant(): void {
    const id = this.chat.id;
    this.contractsService.findSelectedProject(id);

    const projectId = this.contractsService.selectedProject.projectId;
    this.projectApiService.getFreelancers(projectId).subscribe(res => {
      const allFreelancers = res.filter(item => this.chat.participants
        .every(freel => item.id !== freel.participant.id));

      this.addParticipants.open(allFreelancers, this.getBody());
    });
  }

  public actionApprove(type: string): void {
    if (type === 'DELETE_PARTICIPANT') {
      this.deleteParticipant(this.participantToDeleteId);
    }
  }

  public addParticipant(event): void {
    event.forEach(item => {
      this.chat.participants.push({participant: item, role: 'PARTICIPANT'});
    });
    this.editChatMembers.emit();
  }

  public uploadFile(file: File): void {
    this.filesService.uploadFile(file).subscribe((fileInfo: FileUpload) => {
      this.avatar.patchValue(fileInfo?.id);
    });
  }

  private getBody() {
    if (this.name.invalid) { return; }

    const body = cloneDeep(this.chat);

    body.participants = body.participants.map(part => {
      return {
        participantId: part.participant.id,
        role: part.role
      };
    });

    body.chatId = this.chat.id;
    body.avatarId = null;
    delete body.id;
    delete body.avatar;

    return body;
  }

}
