<tpt-dialog>
  <ng-container *ngIf="employer">
    <div class="header-row">
      <tpt-avatar class="mr-16" size="56" [textSizeRatio]="3" [user]="employer"></tpt-avatar>
      <div>
        <div class="full-name mb-4">{{ employer.firstName }} {{ employer.lastName }}</div>

        <div class="legal-category-row">
          <div>
            {{ 'CONTRACTS.EMPLOYER' | translate }}
          </div>
          <div class="legal-status ml-12"
               *ngIf="employer?.legalStatus && employer.legalStatus !== employerLegalStatusEnum.INDIVIDUAL">
            {{('SETTINGS.' + employer?.legalStatus) | translate}}
          </div>
        </div>

<!--        <span class="caption employer-rating">{{'SETTINGS.RATING' | translate}} {{ employer.rating | percent }}</span>-->
      </div>
    </div>

    <mat-tab-group>
      <mat-tab label="{{'CONTRACTS.ABOUT_EMPLOYER' | translate}}">
        <div class="about-container">
          <div class="subhead-1 mb-4 mt-16">{{'CONTRACTS.ABOUT' | translate}}</div>
          <div class="description regular-14-20" *ngIf="employer?.description; else emptyTemplate">{{ employer.description }}</div>

          <div class="about-grid mt-16">
            <div>
              <div class="subhead-1 mb-4">{{'CONTRACTS.LANGUAGES' | translate}}</div>
              <ng-container *ngIf="employer?.languages?.length; else emptyTemplate">
                <div
                  class="mb-4"
                  *ngFor="let language of employer.languages"
                >
                  <span class="key regular">{{getLangByCode(language)}}:</span>
                  <span class="value regular" *ngIf="language.level">
                {{ 'CREATE_PROJECT.STEP3.' + language.level | translate }}</span>
                </div>
              </ng-container>
            </div>

            <div>
              <div class="subhead-1 mb-4 section-title">{{'CONTRACTS.LINKS' | translate}}</div>
              <div class="section-content" *ngIf="employer?.links?.length; else emptyTemplate">
                <a *ngFor="let link of employer?.links" [href]="link" target="_blank" class="contact-link">{{link}}</a>
              </div>
            </div>
          </div>
          <div>
            <div class="subhead-1 mb-4 mt-12">{{'SETTINGS.LOCATION' | translate}}</div>
            <div class="location caption" *ngIf="employer.country">
              <mat-icon [svgIcon]="svgIconsEnum.Location" class="location-icon"></mat-icon>
              {{ getTranslation(employer.country) }}
            </div>
            <div class="local-time" *ngIf="employer?.timeZone">
              {{'CONTRACTS.LOCAL_TIME' | translate}} {{ getTime(employer.timeZone) }}
            </div>
          </div>
        </div>

      </mat-tab>
      <mat-tab label="{{'CONTRACTS.REVIEWS' | translate}}">
        <div class="reviews">
          <ng-container *ngIf="(reviews$ | async)?.length; else emptyReviews">
            <div *ngFor="let review of reviews$ | async" class="card-wrapper">
              <tpt-review-card [feedback]="review"></tpt-review-card>
            </div>
          </ng-container>
          <ng-template #emptyReviews>
            <div class="no-reviews">
              <img src="assets/images/empty-feedbacks.png" class="empty-feedbacks">

              <div class="title">{{'CONTRACTS.NO_REVIEWS' | translate}}</div>
              <div class="text">{{'CONTRACTS.NO_REVIEWS_EMP' | translate}}</div>
            </div>
          </ng-template>
        </div>
      </mat-tab>
    </mat-tab-group>
  </ng-container>

  <ng-template #emptyTemplate>
    <span class="not-specified">{{'CONTRACTS.NOT_SPECIFIED' | translate}}</span>
  </ng-template>
</tpt-dialog>
