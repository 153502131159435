<tpt-dialog>
  <div class="dialog-root">
    <pdf-viewer [src]="src"
                [render-text]="true"
                [original-size]="true"
                [fit-to-page]="true"
                style="width: 100%; height: calc(100vh - 108px); top: 16px"
    ></pdf-viewer>

    <div class="actions-row mt-28" *ngIf="showActions">
      <tpt-button type="secondary" class="mr-12" *ngIf="docType==='contract'" (onClick)="changeContractOpen()">Изменить условия</tpt-button>
      <tpt-button type="primary" (onClick)="signDocument()">Подписать</tpt-button>
    </div>

    <div class="actions-row mt-28" *ngIf="!showActions">
      <tpt-button type="secondary" class="mr-12" (onClick)="close()">Отмена</tpt-button>
    </div>
  </div>
</tpt-dialog>

<tpt-confirm-destruction-dialog (actionApprove)="change()"></tpt-confirm-destruction-dialog>
