<mat-card (click)="openCardDetails(freelancer)" class="freelancer-card" [class.visited]="visited">
  <div class="first-row">
    <div class="freel-info">
      <tpt-avatar [textSizeRatio]="3"
                  [user]="freelancer"
                  size="48"
                  class="mr-8"></tpt-avatar>
      <div>
        <div class="freelancer-name">{{freelancer.firstName}} {{freelancer.lastName}}</div>
        <div class="freelancer-category">
          <div class="legal-status" *ngIf="freelancer?.selfEmployedStatus">

            <ng-container *ngIf="freelancer?.selfEmployedStatus === 'ACTIVE' &&
              freelancer.bankDetailsValidated && freelancer.legalInfoValidated">
              Самозанятый
              <mat-icon [svgIcon]="svgIconsEnum.CheckSuccess"
                        class="small-icon"></mat-icon>
            </ng-container>

            <ng-container *ngIf="['NEW', 'NOT_VERIFIED'].includes(freelancer?.selfEmployedStatus) ||
              (freelancer?.selfEmployedStatus === 'ACTIVE' && (!freelancer.bankDetailsValidated || !freelancer.legalInfoValidated))">
              Ограниченный доступ
            </ng-container>

            <ng-container *ngIf="freelancer?.selfEmployedStatus === 'DETACHED'">
              Отвязан от платформы
              <mat-icon [svgIcon]=" svgIconsEnum.Deregistered"
                        class="small-icon"></mat-icon>
            </ng-container>

            <ng-container *ngIf="freelancer?.selfEmployedStatus === 'REMOVED_FROM_REGISTER'">
              Снят с учёта
              <mat-icon [svgIcon]=" svgIconsEnum.Deregistered"
                        class="small-icon"></mat-icon>
            </ng-container>
          </div>
<!--          <span class="rating" *ngIf="freelancer?.rating > 0">-->
<!--            {{'SETTINGS.RATING' | translate}} {{ freelancer.rating | percent}}-->
<!--          </span>-->
        </div>
      </div>
    </div>
    <div class="button-block">
      <ng-container *ngIf="isEmployer">
        <tpt-button type="secondary" class="mr-12"
                    *ngIf="filterChats().length"
                    (onClick)="$event.stopPropagation(); openChatsDialog()"
        >
          Перейти в чат
        </tpt-button>
        <tpt-button class="hire-button" type="primary"
                    [disabled]="disableBtn(freelancer)"
                    (onClick)="hireFreelancer(freelancer)"
                    (click)="$event.stopPropagation()"
        >{{'SEARCH.HIRE' | translate}}</tpt-button>
      </ng-container>
      <button mat-icon-button class="more-horiz-btn"
              (click)="$event.stopPropagation(); copyProfilePageLink(freelancer)">
        <mat-icon [svgIcon]="svgIconsEnum.CopyLink"></mat-icon>
      </button>
    </div>
  </div>
  <div class="second-row">
    <div class="left">
      <div class="description">
        <span>{{freelancer?.description}}</span>
      </div>

      <div class="categories">
        <mat-tab-group mat-align-tabs="start" #matTabGroup>
          <mat-tab *ngFor="let spec of freelancer.specializations; let i = index">
            <ng-template mat-tab-label>
              <div class="tab-label"
                   (click)="$event.stopPropagation(); matTabGroup.selectedIndex = i">
                {{spec.subCategory?.id ? getTranslation(spec.subCategory) :
                spec.parentCategory?.id ? getTranslation(spec.parentCategory) : ('CONTRACTS.NOT_SPECIFIED' | translate)}}
              </div>
            </ng-template>
            <div class="category-row">
              <div class="mr-48">
                <div class="caption">{{'SETTINGS.CATEGORY' | translate}}</div>
                <div class="regular-14-20">{{spec.parentCategory ? getTranslation(spec.parentCategory) : '-'}}</div>
              </div>

              <div class="mr-48">
                <div class="caption">{{'SEARCH.OPTIONS.SPECIALIST_LEVEL' | translate}}</div>
                <div class="regular-14-20">{{spec?.expertLevel ? ('SEARCH.LEVEL.'+spec?.expertLevel | translate) : '-'}}</div>
              </div>

              <div>
                <div class="caption">{{'SETTINGS.RATE' | translate}}</div>
                <div class="regular-14-20">{{ spec.hourlyRate ? '₽' + spec.hourlyRate + '/' + ('FINANCE.HOUR' | translate) : '-'}}</div>
              </div>
            </div>

            <div class="skills-wrap">
              <div class="pseudo-chips" *ngFor="let skill of specSkills(spec)" [class.filtered]="getChipStyle(skill)">
                {{getTranslation(skill) || skill}}
              </div>
              <div *ngIf="spec.moreSkillsCount" class="more-skills" (click)="$event.stopPropagation(); showAllSkills(spec)">
                +{{spec.moreSkillsCount}}
              </div>
            </div>
          </mat-tab>
        </mat-tab-group>
      </div>
    </div>

    <div class="right">
      <div *ngIf="freelancer.expertLevel" class="sub-main-info">
        <p class="main-info-category">{{'SEARCH.OPTIONS.SPECIALIST_LEVEL' | translate}}</p>
        <p class="main-info-value">{{ 'SEARCH.LEVEL.'+freelancer?.expertLevel | translate}}</p>
      </div>
      <div *ngIf="freelancer.country" class="sub-main-info">
        <p class="main-info-category">{{'SEARCH.OPTIONS.FREELANCERS_LOCATION' | translate}}</p>
        <p class="main-info-value">{{getTranslation(freelancer?.country)}}</p>
      </div>
    </div>
  </div>
</mat-card>

<hire-freelancer-dialog (successfullyHired)="blurCard($event)"></hire-freelancer-dialog>
<tpt-chats-dialog (openHireDialogEvent)="hireFreelancer($event)"></tpt-chats-dialog>
