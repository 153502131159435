<div class="card-wrapper" *ngIf="feedback">
  <div class="card-content">
    <div class="feedback">
      <div class="title">
        <div class="project-name">
          {{feedback.jobInfo?.projectName}}
        </div>
      </div>
      <div class="job-name">
        {{feedback.jobInfo?.jobName}}
      </div>
      <div class="message">{{feedback.text | tptTruncate: 340}}</div>

      <div class="skills-wrap">
        <div class="skill-chip" *ngFor="let skill of skills()">
          {{getTranslation(skill) || skill}}
        </div>
        <div *ngIf="moreSkillsCount" class="more-skills" (click)="showAllSkills()">
          +{{moreSkillsCount}}
        </div>
      </div>
    </div>

    <div class="stats-block" (click)="feedback.statsExpanded = !feedback.statsExpanded">
      <div class="main-rating-wrapper" [class.stats-expanded]="feedback?.statsExpanded">
        <div class="main-rating">
          <div class="main-rating-text">{{'SETTINGS.rating' | translate}}
            <span class="main-rating-value">{{feedback?.generalAssessment | percent}}</span>
          </div>
          <div class="progress-row">
            <div class="progress-container">
              <mat-progress-bar [value]="feedback?.generalAssessment * 100"
                                mode="determinate"></mat-progress-bar>
            </div>
          </div>
        </div>
        <div class="expand-icon">
          <mat-icon [svgIcon]="svgIconsEnum.Expand"></mat-icon>
        </div>
      </div>

      <div *ngIf="feedback?.statsExpanded" class="main-stats-wrapper">
        <div *ngFor="let stat of isEmployer ? employerStats : freelancerStats">
          <div class="main-rating mb-8">
            <div class="main-rating-text">{{'SETTINGS.' + stat.name | translate}}
              <span class="main-rating-value">{{feedback[stat.field] | percent}}</span>
            </div>
            <div class="progress-row">
              <div class="progress-container">
                <mat-progress-bar [value]="feedback[stat.field] * 100"
                                  mode="determinate"></mat-progress-bar>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="card-footer">
    <div class="sender">
      <tpt-avatar [textSizeRatio]="2.5"
                  [user]="feedback?.estimator"
                  size="32"></tpt-avatar>

      <span class="name">
      {{feedback?.estimator?.firstName}} {{feedback?.estimator?.lastName}}
    </span>
    </div>
    <div class="post-date" *ngIf="feedback?.created">{{feedback?.created | localDate: 'dd.MM.yyyy'}}</div>
  </div>
</div>
