<tpt-dialog>
  <div class="dialog">

    <div *ngIf="fullContent" class="note-full-content">
      <textarea
        [formControl]="noteTextControl"
        class="note-content"
        placeholder="{{'TRACKER.ENTER_TEXT' | translate}}"
        #noteTextarea
      ></textarea>
      <div class="actions">
        <tpt-button (onClick)="saveNote()"
                    type="primary"
                    *ngIf="noteTextControl.value">
          {{'BUTTON.SAVE' | translate}}

        </tpt-button>
      </div>
    </div>

    <div class="notes">
      <div class="notes-header">
        <div class="header-title">
          <span class="title">
            {{'TRACKER.NOTES' | translate}}
          </span>
          <button
            class="add-note-btn"
            (click)="addNote()"
          >
            +
          </button>
        </div>
        <div class="header-actions">
          <mat-icon
            [svgIcon]="svgIconsEnum.Cross"
            (click)="close()"
          ></mat-icon>
        </div>
      </div>

      <div class="notes-list">
        <emp-project-note
          *ngFor="let note of notes | async; let idx = index;"
          [note]="note"
          [activeNote]="selectedNoteId === idx"
          (deleteNote)="handleNoteDelete($event)"
          (pinNote)="handlePinNote($event)"
          (unpinNote)="handleUnpinNote($event)"
          (click)="handleNoteClick(note, idx)"
        ></emp-project-note>
      </div>
    </div>

  </div>
</tpt-dialog>

<emp-delete-note-dialog (deleteNote)="handleNoteDeleteConfirmation()"></emp-delete-note-dialog>
