import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { cloneDeep } from 'lodash';
import { UntypedFormControl } from '@angular/forms';
import { lastValueFrom, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { ProjectRolesService } from '../../../../services/project-roles.service';
import { SpinnerService } from '../../../tpt-ui/services/spinner.service';
import { RoleModel } from '../../../../models/project-role/role.model';
import { PermissionEnum, PermissionModel, PermissionsForGA } from '../../../../models/project-role/permission.model';
import { RoleRightsDialogComponent } from '../../../common-dialogs/components/role-rights-dialog/role-rights-dialog.component';
import { SvgIconsEnum } from '../../../../types/svg-icons.enum';
import { EditRoleModel } from '../../../../models/project-role/edit-role.model';
import { ProfileService } from '../../../../services/profile.service';
import { RouteHelper } from '../../../../helpers/route.helper';
import { SnackbarNotificationsService } from '../../../../services/snackbar-notifications.service';
import { DataLayerService } from '../../../../services/data-layer.service';
import { ProjectApiService } from '../../../../services/project.api.service';
import { SimpleProjectResponseModel } from '../../../employer/models/simple-project-response.model';

@Component({
  selector: 'tpt-roles',
  templateUrl: './roles.component.html',
  styleUrls: ['./roles.component.scss']
})
export class RolesComponent implements OnInit, OnDestroy {

  public get isEmployer(): boolean {
    return this.profileService.currentProfile.isEmployer();
  }

  @ViewChild(RoleRightsDialogComponent) roleRightDialog: RoleRightsDialogComponent;

  public roles: RoleModel[];

  public svgIconsEnum = SvgIconsEnum;

  public activeRole: RoleModel;

  public activeRoleStats: {
    roleId: string,
    name: string,
    rolesEditing: boolean,
    roleAssigning: boolean,
    chatCreating: boolean,
    tasksRead: any[],
    taskCommenting: any[]
  } = {
    roleId: null,
    name: '',
    rolesEditing: false,
    roleAssigning: false,
    chatCreating: false,
    tasksRead: [],
    taskCommenting: []
  };

  public projectId: string;

  public addNewRoleState: boolean;

  public showRolesTab = false;

  public addedRoleId;

  public rolesWithEditing = [];

  public myPermissions;

  public currentProjectControl = new UntypedFormControl();

  public dropdownItems = [];

  public project: SimpleProjectResponseModel;

  private destroy$ = new Subject<void>();

  constructor(private rolesService: ProjectRolesService,
              private spinnerService: SpinnerService,
              private router: Router,
              private routeHelper: RouteHelper,
              private profileService: ProfileService,
              private projectApiService: ProjectApiService,
              private snack: SnackbarNotificationsService,
              private dataLayerService: DataLayerService,
              private activatedRoute: ActivatedRoute) {
    this.projectId = activatedRoute.parent.parent.snapshot.params.id;
  }

  async ngOnInit() {
    this.project = await lastValueFrom(this.projectApiService.getProjectById(this.projectId));
    const { id, name } = this.project;
    this.currentProjectControl.setValue({ id, name });

    this.activatedRoute.parent.parent.params.pipe(takeUntil(this.destroy$)).subscribe(params => {
      if (this.projectId === params.id) {
        return;
      }
      this.projectId = params.id;
      this.getProject();
    });

    this.currentProjectControl.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((val) => {
      if (val.id !== this.projectId) {
        this.router.navigate(this.routeHelper.employerAccessPageRoles(val.id));
      }
    });

    this.getCurrentTabProjects();
    this.getMyPermissions();

    if (this.profileService.currentProfile.isEmployer()) {
      this.getProjectRoles(true);
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public getProject() {
    this.getMyPermissions();

    if (this.profileService.currentProfile.isEmployer()) {
      this.getProjectRoles(true);
    }
  }

  public getProjectRoles(switchToFirst?: boolean, createdNew?: boolean): void {
    this.spinnerService.startSpinner();
    this.rolesService.getProjectRoles(this.projectId).pipe(takeUntil(this.destroy$)).subscribe(res => {
      this.roles = res;
      if (this.roles.length) {
        if (switchToFirst) {
          this.setActiveRole(this.roles[0]);
        }
        if (createdNew) {
          this.switchToJustCreatedRole();
        }

        if (!switchToFirst && !createdNew) {
          this.roles.forEach(role => {
            if (role.roleId === this.addedRoleId) {
              this.setActiveRole(role);
            }
          });
        }
      } else {
        this.addNewRole();
      }
      this.spinnerService.stopSpinner();
    });
  }

  switchToJustCreatedRole() {
    const createdRole = this.roles.filter(role => role.roleId === this.addedRoleId);
    if (createdRole.length) {
      this.activeRole = createdRole[0];
      this.addedRoleId = null;
    }
  }

  public setActiveRole(role: RoleModel): void {
    this.addNewRoleState = false;
    this.activeRole = cloneDeep(role);

    const mainPermissions = this.activeRole.permissions.map(item => item.name);

    this.activeRoleStats.name = this.activeRole.name;
    this.activeRoleStats.roleId = this.activeRole.roleId;
    this.activeRoleStats.rolesEditing = mainPermissions.includes(PermissionEnum.ROLES_EDITING);
    this.activeRoleStats.chatCreating = mainPermissions.includes(PermissionEnum.CHAT_CREATING);
    this.activeRoleStats.roleAssigning = mainPermissions.includes(PermissionEnum.ROLES_ASSIGNING);

    const tasksRead = this.activeRole.permissions.filter(item => item.name === PermissionEnum.TASKS_READ);
    const tasksCommenting = this.activeRole.permissions.filter(item => item.name === PermissionEnum.TASKS_COMMENTING);

    tasksRead.forEach(item => {
      item.name = item.roleName;
    });
    tasksCommenting.forEach(item => item.name = item.roleName);

    this.activeRoleStats.tasksRead = tasksRead;
    this.activeRoleStats.taskCommenting = tasksCommenting;

    if (this.myPermissions && this.myPermissions.roles.length) {
      this.rolesWithEditing = this.myPermissions.roles.filter(item => {
        return item.permissions.some(permission => permission.name === PermissionEnum.ROLES_EDITING);
      }).map(item => item.roleId);
    }
  }

  public saveRole(createdNew?: boolean): void {
    if (!this.activeRoleStats.name.trim()) {
      return;
    }

    const permissions = [];
    if (this.activeRoleStats.rolesEditing) {
      permissions.push({name: PermissionEnum.ROLES_EDITING});
    }

    if (this.activeRoleStats.chatCreating) {
      permissions.push({name: PermissionEnum.CHAT_CREATING});
    }

    if (this.activeRoleStats.roleAssigning) {
      permissions.push({name: PermissionEnum.ROLES_ASSIGNING});
    }

    if (this.activeRoleStats.taskCommenting.length) {
      this.activeRoleStats.taskCommenting.forEach(item => {
        permissions.push({name: PermissionEnum.TASKS_COMMENTING, roleId: item.roleId});
      });
    }

    if (this.activeRoleStats.tasksRead.length) {
      this.activeRoleStats.tasksRead.forEach(item => {
        permissions.push({name: PermissionEnum.TASKS_READ, roleId: item.roleId});
      });
    }

    const body = {
      roleId: this.activeRoleStats.roleId,
      name: this.activeRoleStats.name,
      permissions
    };
    const permissionsForGA = body.permissions.map(item => PermissionsForGA[item.name]);

    this.spinnerService.startSpinner();
    this.rolesService.saveRole(this.projectId, body).pipe(takeUntil(this.destroy$)).subscribe((res) => {
      this.addedRoleId = res.roleId;
      this.spinnerService.stopSpinner();
      this.addNewRoleState = false;
      this.getProjectRoles(false, createdNew);
      if (createdNew) {
        this.snack.showNotification('SNACKBAR.ROLE_SAVED', 'success');
      } else {
        this.snack.showNotification('SNACKBAR.ROLE_UPDATED', 'success');
      }

      if (createdNew) {
        this.pushCreateRoleToDataLayer({projectId: this.projectId, roleRights: permissionsForGA.join()});
      } else {
        this.pushEditRoleToDataLayer({projectId: this.projectId, roleRights: permissionsForGA.join()});
      }
    }, () => {
      this.snack.showNotification('SNACKBAR.SOMETHING_WENT_WRONG', 'fail');
      this.spinnerService.stopSpinner();
    });

  }

  public updateRoles(event: {value: PermissionModel[], isCommenting: boolean}) {
    if (event.isCommenting) {
      this.activeRoleStats.taskCommenting = event.value;
    } else {
      this.activeRoleStats.tasksRead = event.value;
    }

    if (event.isCommenting) {
      const notIncludeArray = this.activeRoleStats.taskCommenting.filter(item => {
        const readIdsArray = this.activeRoleStats.tasksRead.map(role => role.roleId);
        return !readIdsArray.includes(item.roleId);
      });

      notIncludeArray.forEach(item => {
        this.activeRoleStats.tasksRead.push(item);
      });
    } else {
      this.activeRoleStats.taskCommenting = this.activeRoleStats.taskCommenting.filter(item => {
        const readIdsArray = this.activeRoleStats.tasksRead.map(role => role.roleId);
        return readIdsArray.includes(item.roleId);
      });
    }

  }

  addNewRole() {
    this.addNewRoleState = true;
    this.activeRoleStats = new EditRoleModel();
  }

  public openCommentDialog(): void {
    if (this.rolesWithEditing.includes(this.activeRoleStats.roleId)) { return; }
    this.roleRightDialog.open(true, this.projectId, this.activeRoleStats.taskCommenting);
  }

  public openReadDialog(): void {
    if (this.rolesWithEditing.includes(this.activeRoleStats.roleId)) { return; }
    this.roleRightDialog.open(false, this.projectId, this.activeRoleStats.tasksRead);
  }

  public compareFn = (opt, select) => {
    return opt && select && opt?.id === select?.id;
  }

  private getCurrentTabProjects(): void {
    if (!this.project || this.dropdownItems?.length) { return; }
    const state = ['ACTIVE', 'ENDED', 'DRAFT'].includes(this.project.state) ? this.project.state : 'ACTIVE';

    this.projectApiService.getAllJobsList({state}).pipe(takeUntil(this.destroy$)).subscribe(res => {
        this.dropdownItems = res.filter(item => item.type === 'COMPLEX').map(item => {
          return {
            id: item.id,
            name: item.name
          };
        });
      }
    );
  }

  private getMyPermissions() {
    if (this.profileService.currentProfile.isEmployer()) {
      this.showRolesTab = true;
      return;
    }

    this.rolesService.getMyRolesInProject(this.projectId)
      .pipe(takeUntil(this.destroy$)).subscribe(res => {

      this.getProjectRoles(true);
      this.myPermissions = res;
      const permissions = [];
      res.roles.forEach(role => {
        role.permissions.forEach(perm => {
          permissions.push(perm.name);
        });
      });

      this.showRolesTab = permissions.includes(PermissionEnum.ROLES_EDITING);
      if (!this.showRolesTab) {
        this.router.navigate(this.routeHelper.freelancerProjectPageAccess(this.projectId));
      }
    }, () => {});
  }

  private pushCreateRoleToDataLayer(data): void {
    this.dataLayerService.pushToDataLayer({
      event: 'createRole',
      roleRights: data.roleRights,
      projectId: data.projectId,
      projectType: 'complex'  // we can only create roles in complex projects
    });
  }

  private pushEditRoleToDataLayer(data): void {
    this.dataLayerService.pushToDataLayer({
      event: 'editRole',
      roleRights: data.roleRights,
      projectId: data.projectId,
      projectType: 'complex'  // we can only edit roles in complex projects
    });
  }

}
