<tpt-dialog>
  <div class="heading mb-32">
    Чаты с исполнителем
  </div>

  <div class="chat" *ngFor="let chat of chats">
    <div class="">
      <div class="subhead-1 mb-4">{{chat.projectName}}</div>
      <div class="regular-14-20 job-name">{{chat.jobName}}</div>
    </div>
    <div class="">
      <tpt-button type="secondary" class="mr-12" size="large"
                  (onClick)="goToChat(chat)">
        Перейти в чат
      </tpt-button>
    </div>
  </div>

  <tpt-button type="primary" size="large"
              class="full-width mt-48 pt-24 large-button"
              (onClick)="openHireDialog()"
  >
    Пригласить на другой проект
  </tpt-button>
</tpt-dialog>
