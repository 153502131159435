import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { MatDialogConfig } from '@angular/material/dialog';

import { DialogComponent } from '../../../dialog/components/dialog/dialog.component';
import { SvgIconsEnum } from '../../../../types/svg-icons.enum';
import { ResourcesService } from '../../../../services/resources.service';
import { FutureExpenseModel } from '../../../finance/models/future-expense.model';
import { PaymentStatesEnum } from '../../../employer/models/simple-project-fixed-payment.model';

@Component({
  selector: 'tpt-pay-bill-dialog',
  templateUrl: './pay-bill-dialog.component.html',
  styleUrls: ['./pay-bill-dialog.component.scss']
})
export class PayBillDialogComponent {

  @ViewChild(DialogComponent)
  public dialog: DialogComponent;

  @Output()
  updateTable: EventEmitter<any> = new EventEmitter<any>();

  public job: FutureExpenseModel;

  public svgIconsEnum = SvgIconsEnum;

  public paymentStates = PaymentStatesEnum;

  private config: MatDialogConfig = {
    width: '520px',
  };

  private className = 'tpt-pay-bill-dialog';

  constructor(private resource: ResourcesService) {
  }

  public open(job): void {
    this.job = job;
    this.dialog.config = this.config;
    this.dialog.open(this.className);
  }

  public close(): void {
    this.dialog.close();
  }

  public downloadInvoice() {
    const invoice = this.job?.docs?.invoice;

    if (!invoice) {
      return;
    }

    this.resource.getFileByUUID(invoice.id).subscribe(this.handleFile);
  }

  private handleFile = (file: File): void => {
    const objectURL = this.resource.createObjectURL(file);
    const link = document.createElement('a');
    link.download = decodeURIComponent(file.name);
    link.href = objectURL;
    link.click();
    URL.revokeObjectURL(objectURL);
  }
}
