<tpt-dialog>
  <div class="dialog">
    <div class="dialog-title" *ngIf="title">{{ title | translate}}</div>
    <div *ngIf="description" class="dialog-description">{{ description | translate}}</div>
    <ng-content select="[description]"></ng-content>
    <div class="dialog-actions" *ngIf="action && buttonType">
      <tpt-button
        type="secondary"
        class="button decline-button"
        (click)="declineAction()"
      >
        {{'BUTTON.CANCEL' | translate}}
      </tpt-button>
      <tpt-button
        [type]="buttonType"
        class="button approve-button"
        (click)="approveAction()"
      >
        {{action | translate}}
      </tpt-button>
    </div>
  </div>
</tpt-dialog>
