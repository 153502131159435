import { DatePipe, registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import localeRu from '@angular/common/locales/ru';
import { LOCALE_ID, NgModule } from '@angular/core';
import { MatBadgeModule } from '@angular/material/badge';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatMenuModule } from '@angular/material/menu';
import { MatRadioModule } from '@angular/material/radio';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MAT_DATE_FORMATS, MatRippleModule } from '@angular/material/core';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateCompiler, TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { StompRService } from '@stomp/ng2-stompjs';
import { TranslateMessageFormatCompiler } from 'ngx-translate-messageformat-compiler';
import { GoogleLoginProvider, SocialAuthServiceConfig, SocialLoginModule } from '@abacritt/angularx-social-login';
import { environment } from '../environments/environment';
import { AvatarModule } from 'ngx-avatars';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AlertDialog } from './components/common-dialogs/alert-dialog/alert-dialog.component';
import { ConfirmComponent } from './components/confirm/confirm.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { HeaderComponent } from './components/head/header.component';
import { LoginComponent } from './components/login/login.component';
import { NotificationContentComponent } from './components/notification/notification-content.component';
import { NotificationComponent } from './components/notification/notification.component';
import { RegisterComponent } from './components/register/register.component';

import { AuthGuard } from './guards/auth.guard';
import { RoleGuard } from './guards/role.guard';
import { MainPageGuard } from './guards/main-page.guard';
import { RedirectGuard } from './guards/redirect.guard';
import { AuthInterceptor } from './interceptors/auth-interceptor';
import { CommonShareModule } from './modules/common-share.module';
import { SearchModule } from './modules/search/search.module';
import { TptUiModule } from './modules/tpt-ui/tpt-ui.module';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { PortalService } from './services/portal.service';
import { TptFormsModule } from './modules/tpt-forms/tpt-forms.module';
import { ConfirmSuccessComponent } from './components/confirm-success/confirm-success.component';
import { CommonDialogsModule } from './modules/common-dialogs/common-dialogs.module';
import { SnackbarComponent } from './components/snackbar-notifications/snackbar/snackbar.component';
import { SnackbarNotificationsComponent } from './components/snackbar-notifications/snackbar-notifications.component';
import { CheckListComponent } from './components/check-list/check-list.component';
import { CommonComponentsModule } from './modules/common-components/common-components.module';
import { LoadingBarService } from './services/loading-bar.service';
import { ToastrModule } from 'ngx-toastr';
import { CustomToastComponent } from './components/toast/custom-toast.component';
import { AcceptTcComponent } from './components/accept-tc/accept-tc.component';

const CLIENT_ID = environment.google_client_id;
const CLIENT_APP = environment.google_app_name;

export function createHomeTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(
        http,
        `./assets/translations/`,
        '.json?' + environment.timeStamp,
    );
}

registerLocaleData(localeRu);

@NgModule({
  declarations: [
    AlertDialog,
    AppComponent,
    SidebarComponent,
    HeaderComponent,
    LoginComponent,
    ForgotPasswordComponent,
    NotificationComponent,
    NotificationContentComponent,
    RegisterComponent,
    ConfirmComponent,
    ConfirmSuccessComponent,
    SnackbarComponent,
    SnackbarNotificationsComponent,
    CheckListComponent,
    CustomToastComponent,
    AcceptTcComponent,
  ],
  imports: [
    BrowserAnimationsModule,
    CommonShareModule,
    AppRoutingModule,
    HttpClientModule,
    MatMomentDateModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createHomeTranslateLoader,
        deps: [HttpClient],
      },
      compiler: {
        provide: TranslateCompiler,
        useClass: TranslateMessageFormatCompiler,
      },
    }),
    MatCardModule,
    MatChipsModule,
    MatToolbarModule,
    MatBadgeModule,
    MatRadioModule,
    MatMenuModule,
    MatSnackBarModule,
    MatDatepickerModule,
    MatCheckboxModule,
    SearchModule,
    TptUiModule,
    TptFormsModule,
    MatExpansionModule,
    CommonDialogsModule,
    MatDividerModule,
    AvatarModule,
    CommonComponentsModule,
    SocialLoginModule,
    ToastrModule.forRoot({
      toastComponent: CustomToastComponent,
      preventDuplicates: true
    }),
    MatRippleModule,
  ],
  providers: [
    PortalService,
    DatePipe,
    {
      provide: MAT_DATE_FORMATS,
      useValue: {
        parse: {
          dateInput: 'DD.MM.YYYY',
        },
        display: {
          dateInput: 'DD.MM.YYYY',
          monthYearLabel: 'YYYY',
          dateA11yLabel: 'LL',
          monthYearA11yLabel: 'YYYY',
        },
      },
    },
    {provide: LOCALE_ID, useValue: 'ru'},
    {
      provide: MAT_DIALOG_DEFAULT_OPTIONS,
      useValue: {disableClose: true},
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    StompRService,
    AuthGuard,
    RoleGuard,
    MainPageGuard,
    RedirectGuard,
    LoadingBarService,
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(CLIENT_ID, {
              scopes: 'email',
              oneTapEnabled: false
            })
          },
        ],
        onError: (err) => {
          console.error(err);
        }
      } as SocialAuthServiceConfig
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
