<tpt-dialog>
  <div class="dialog-root">
    <div class="heading">
      {{'TRACKER.DELETE_NOTE_CONFIRM' | translate}}
    </div>
    <div class="description">
      {{'TRACKER.DELETE_NOTE_TEXT' | translate}}
    </div>
    <div class="actions-row">
      <tpt-button type="secondary" size="large" class="mr-12" (onClick)="close()">
        {{'BUTTON.CANCEL' | translate}}
      </tpt-button>
      <tpt-button type="accent" size="large" (onClick)="confirmDelete()">
        {{'BUTTON.DELETE' | translate}}
      </tpt-button>
    </div>
  </div>
</tpt-dialog>
