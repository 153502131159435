import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { AlertService } from '../../services/alert.service';
import { AuthService } from '../../services/auth.service';
import { ProfileService } from '../../services/profile.service';
import { DataLayerService } from '../../services/data-layer.service';
import { SvgIconsEnum } from '../../types/svg-icons.enum';
import { LoginForm } from '../../forms/login.form';
import { SpinnerService } from '../../modules/tpt-ui/services/spinner.service';
import {
  AuthErrorDialogComponent
} from '../../modules/common-dialogs/components/auth-error-dialog/auth-error-dialog.component';
import { RouteHelper } from '../../helpers/route.helper';
import { ProfileModel } from '../../models/profile.model';
import { Role } from '../../classes/enums';
import { GoogleLoginProvider, SocialAuthService, SocialUser } from '@abacritt/angularx-social-login';
import { ProfilesApiService } from '../../services/profiles.api.service';
import { UntypedFormControl, Validators } from '@angular/forms';
import { SnackbarNotificationsService } from '../../services/snackbar-notifications.service';
import { Constants } from '../../classes/constants';
import {takeUntil} from 'rxjs/operators';
import { lastValueFrom, Subject } from 'rxjs';
import jwtDecode from 'jwt-decode';

const TOKEN_KEY = 'auth-token';
const tptAuthUsername = 'tpt_client';
const tptAuthPassword = 'gZ+J97Tz';

@Component({
  templateUrl: './login.component.html',
  styleUrls: [ './login.component.scss' ],
})
export class LoginComponent implements OnInit, OnDestroy {

  public form: LoginForm;

  public loading = false;

  public svgIconsEnum = SvgIconsEnum;

  public googleSignUpStepTwo = false;

  public roleControl = new UntypedFormControl('', Validators.required);

  public user: SocialUser;

  public showPass = false;

  @ViewChild(AuthErrorDialogComponent, {static: false}) authErrorDialog: AuthErrorDialogComponent;

  private readonly destroy$ = new Subject<void>();

  constructor(
    private auth: AuthService,
    private profileService: ProfileService,
    private alertService: AlertService,
    private route: ActivatedRoute,
    private router: Router,
    private routeHelper: RouteHelper,
    private authService: SocialAuthService,
    private profileApiService: ProfilesApiService,
    private dataLayerService: DataLayerService,
    private spinnerService: SpinnerService,
    public translate: TranslateService,
    private readonly meta: Meta,
    private cd: ChangeDetectorRef,
    private snack: SnackbarNotificationsService,
  ) { }

  ngOnInit(): void {
    this.form = LoginForm.createForm();

    const metaValue = this.meta.getTag('name="viewport"');
    if (metaValue.content !== 'width=device-width, initial-scale=1') {
      this.meta.removeTag('name="viewport"');
      this.meta.addTag({ name: 'viewport', content: 'width=device-width, initial-scale=1' });
    }

    this.authService.authState.pipe(takeUntil(this.destroy$)).subscribe((user: SocialUser) => {
      if (!user) { return; }
      this.googleSignIn(user);
    });
  }

  ngOnDestroy() {
    const metaValue = this.meta.getTag('name="viewport"');
    if (metaValue.content !== 'width=1200px') {
      this.meta.removeTag('name="viewport"');
      this.meta.addTag({ name: 'viewport', content: 'width=1200px' });
    }

    this.destroy$.next();
    this.destroy$.complete();
  }

  public async googleSignIn(socialUser: SocialUser): Promise<void> {
    const token = socialUser.idToken;
    const tptUser = await lastValueFrom(this.profileApiService.getUserAuthProfile(token));

    if (tptUser.roles.includes(Role.NOT_SELECTED)) {
      this.user = socialUser;
      this.googleSignUpStepTwo = true;
      this.cd.detectChanges();
    } else {
      const tokenDecoded: any = jwtDecode(token);
      localStorage.setItem(TOKEN_KEY, token);
      localStorage.setItem('at', 'social_g');
      if (tokenDecoded?.exp) {
        const expires_at = tokenDecoded.exp;
        localStorage.setItem(Constants.EXPIRES_KEY, expires_at);
      }
      await this.profileService.refreshCurrentProfile();

      const url = tptUser.roles.includes(Role.EMPLOYER) ? this.routeHelper.employerProjectsDashboard :
        this.profileService.currentProfile.selfEmployedStatus === 'ACTIVE' ? this.routeHelper.projectSearch :
          this.routeHelper.fnsAttachPage;
      await this.router.navigate([url]);
    }
  }

  public createAccountGoogle(): void {
    if (this.roleControl.invalid) {
      this.snack.showNotification('REGISTER_COMPONENT.PLEASE_CHOOSE_THE_ROLE', 'fail');
      return;
    }

    this.spinnerService.startSpinner();
    this.profileApiService.selectRole(this.roleControl.value, this.user.idToken).subscribe(async () => {
      localStorage.setItem(TOKEN_KEY, this.user.idToken);
      await this.profileService.getUserInfo();
      this.spinnerService.stopSpinner();

      const url = this.roleControl.value === 'EMPLOYER' ? this.routeHelper.employerProjectsDashboard :
        this.profileService.currentProfile.selfEmployedStatus === 'ACTIVE' ? this.routeHelper.projectSearch :
          this.routeHelper.fnsAttachPage;
      await this.router.navigate([url]);
    }, () => {
      this.spinnerService.stopSpinner();
    });
  }

  public login(): void {
    if (!this.form.validate()) {
      return;
    }

    this.loading = true;
    this.spinnerService.startSpinner();
    const data = this.form.getFormData();
    const { username, password } = data;
    const { inviteCode } = this.route.snapshot.queryParams;

    this.auth.loginV2(username?.trim(), password?.trim(),
      {authUsername: tptAuthUsername, authPassword: tptAuthPassword})
      .subscribe(async (response) => {
          this.auth.updateToken(response);
          localStorage.setItem('login_flow', 'talent_cloud');

          await this.profileService.refreshCurrentProfile();
          const userInvited = localStorage.getItem('invitedUser') === this.profileService.currentProfile.email;
          const url = this.profileService.currentProfile.isEmployer() ? this.routeHelper.employerProjectsDashboard :
            (inviteCode || userInvited) ? this.routeHelper.clientsPage :
            this.profileService.currentProfile.selfEmployedStatus === 'ACTIVE' ? this.routeHelper.projectSearch :
              this.routeHelper.fnsAttachPage;
          this.router.navigate([url], inviteCode ? {queryParams: this.route.snapshot.queryParams} : {});
          this.spinnerService.stopSpinner();
          this.loading = false;
        },
      (error: { error: string, error_description: string }) => {
        const newErrors = ['Неверные учетные данные пользователя', 'Пользователь отключен', 'Пользователь заблокирован',
          'Bad credentials', 'User is locked', 'User is disabled'];

        let errorText;
        switch (true) {
          case ['Bad credentials', 'Неверные учетные данные пользователя'].includes(error?.error_description):
            errorText = 'SERVER_ERRORS.AUTHENTICATION_FAILED';
            break;
          case ['User is disabled', 'Пользователь отключен'].includes(error?.error_description) :
            errorText = 'SERVER_ERRORS.ACCOUNT_DISABLED';
            break;
          case ['User is locked', 'Пользователь заблокирован'].includes(error?.error_description) :
            errorText = 'SERVER_ERRORS.ACCOUNT_LOCKED';
            break;
          default: errorText = 'SERVER_ERRORS.LOGIN_ERROR';
        }

        if (error && newErrors.includes(error.error_description)) {
          this.authErrorDialog.open('AUTH_ERROR', errorText, data.username);
        } else {
          this.authErrorDialog.open('AUTH_ERROR', 'SERVER_ERRORS.LOGIN_ERROR', data.username);
        }
        this.loading = false;
        this.spinnerService.stopSpinner();
      }
    );
  }

  private pushToDataLayer = (profile: ProfileModel): void => {
    this.dataLayerService.pushToDataLayer({
      event: 'login',
      userId: `${profile.id}`,
      userType: profile.roles.includes(Role.EMPLOYER) ? 'customer' : 'freelancer'
    });
  }

}
