import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { UserRoleModel } from '../models/project-role/user-role.model';
import { RoleModel } from '../models/project-role/role.model';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root'
})
export class ProjectRolesService extends BaseService {

  constructor(http: HttpClient) {
    super(http);
  }

  public getMyRolesInProject = (projectId: string): Observable<UserRoleModel> => {
    return this.executeGet(`v2/projectRoles/project/${projectId}/me`);
  }

  public getProjectUsersRoles = (projectId: string): Observable<UserRoleModel[]> => {
    return this.executeGet(`v2/projectRoles/project/${projectId}`);
  }

  public saveRole = (projectId: string, role: RoleModel): Observable<any> => {
    return this.executePut(`v2/projectRoles/project/${projectId}`, role);
  }

  public getProjectUserRoles = (projectId: number, userId: number): Observable<UserRoleModel> => {
    return this.executeGet(`v2/projectRoles/project/${projectId}/user/${userId}`);
  }

  public getProjectPermissions = (projectId: number): Observable<UserRoleModel> => {
    return this.executeGet(`v2/projectRoles/project/${projectId}/permissions`);
  }

  public setAssignedRoles = (projectId: string, userId: string, roles: string[]): Observable<any> => {
    return this.executePut(`v2/projectRoles/project/${projectId}/user/${userId}`, roles);
  }

  public getProjectRoles = (projectId: string): Observable<RoleModel[]> => {
    return this.executeGet(`v2/projectRoles/project/${projectId}/roles`);
  }

  public splitArray(roles: RoleModel[]): {firstPart: RoleModel[], secondPart: RoleModel[], secondPartLength: number} {
    const firstPart = roles.slice(0, 4);
    const secondPart = roles.slice(4);
    const secondPartLength = roles.slice(4).length;
    return {
      firstPart,
      secondPart,
      secondPartLength};
  }
}
