<tpt-dialog>
  <div class="title">{{ 'CONTRACTS.CHAT_EDIT' | translate }}</div>

  <div class="chat-info mt-32" *ngIf="chat">

    <div class="avatar-wrapper" (click)="uploadComponent.openFilePicker()">
      <ngx-avatars [src]="avatar.value | tptAvatar: 'empty'"
                  size="76">
      </ngx-avatars>

      <tpt-drag-n-drop [invisible]="true" (uploadFileChange)="uploadFile($event)"></tpt-drag-n-drop>

      <div class="change-avatar-button"></div>
    </div>

    <input class="input" matInput [formControl]="name"/>
  </div>

  <div class="subhead mt-24 mb-20">{{ 'CONTRACTS.PARTICIPANTS' | translate }}</div>

  <button mat-button class="add-participant"
          (click)="openAddParticipant()"
          *ngIf="currentUser?.role === 'ADMIN' || currentUser?.role === 'CHAT_OWNER'">
    <mat-icon [svgIcon]="svgIconsEnum.PlusIcon"></mat-icon>
    <span>{{'CONTRACTS.ADD_PARTICIPANT' | translate}}</span>
  </button>

  <div class="person-row" *ngFor="let item of chat?.participants">
    <div class="chat-person">
      <tpt-avatar [user]="item?.participant" [textSizeRatio]="2.5"
                  size="32" class="mr-8"></tpt-avatar>
      <div class="person-info">
        <div class="name">
          {{ item.participant?.firstName }} {{ item.participant?.lastName }}
        </div>
        <div class="role caption" *ngIf="item?.role">
          {{ 'CONTRACTS.'+item?.role | translate}}
        </div>
      </div>
    </div>

    <div>
      <button mat-icon-button [matMenuTriggerFor]="menu" *ngIf="currentUser?.participant?.id !== item.participant?.id">
        <mat-icon [svgIcon]="svgIconsEnum.MoreHoriz"></mat-icon>
      </button>
      <mat-menu #menu="matMenu">
        <button mat-menu-item *ngIf="currentUser?.role === 'CHAT_OWNER' && item?.role !== 'ADMIN'"
                (click)="makeAdmin(item?.participant?.id)">
          <span>{{ 'CONTRACTS.MAKE_ADMIN' | translate }}</span>
        </button>
        <button mat-menu-item *ngIf="currentUser?.role === 'ADMIN' || currentUser?.role === 'CHAT_OWNER'"
                (click)="openDeleteParticipantDialog(item?.participant?.id)">
          <span>{{ 'CONTRACTS.DELETE_PARTICIPANT' | translate }}</span>
        </button>
      </mat-menu>
    </div>
  </div>


  <div class="actions-row mt-36">
    <tpt-button type="secondary" class="cancel" (click)="close()">{{'BUTTON.CANCEL' | translate}}</tpt-button>
    <tpt-button type="primary" (click)="save()">{{'BUTTON.SAVE' | translate}}</tpt-button>
  </div>
</tpt-dialog>

<add-participants (addedParticipant)="addParticipant($event)"></add-participants>

<tpt-confirm-destruction-dialog (actionApprove)="actionApprove($event)"></tpt-confirm-destruction-dialog>
