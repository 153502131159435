<tpt-dialog>
  <div class="dialog-title">
    {{'SETTINGS.SE_ONLY_TITLE' | translate}}
  </div>


  <div class="dialog-text">
    {{(isEmployer ? 'SETTINGS.SE_ONLY_TEXT_EMP' : 'SETTINGS.SE_ONLY_TEXT') | translate}}
  </div>

  <div class="actions-row" *ngIf="!isEmployer">
    <tpt-button type="secondary" size="medium" (onClick)="redirectToLegalPage()">{{'SETTINGS.CHANGE_STATUS' | translate}}</tpt-button>
    <tpt-button type="secondary" size="medium" class="ml-12" (onClick)="close()">{{'BUTTON.CLOSE' | translate}}</tpt-button>
  </div>

  <div class="actions-row" *ngIf="isEmployer">
    <tpt-button type="primary" size="medium" class="ml-12" (onClick)="close()">{{'BUTTON.CLOSE' | translate}}</tpt-button>
  </div>
</tpt-dialog>
