import {
  ChangeDetectorRef,
  Component,
  ElementRef, HostListener,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ENTER } from '@angular/cdk/keycodes';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { DecimalPipe } from '@angular/common';

import { lastValueFrom, Subject } from 'rxjs';
import { debounceTime, startWith, takeUntil } from 'rxjs/operators';
import { NouisliderComponent } from 'ng2-nouislider';
import { TranslateService } from '@ngx-translate/core';
import { omitBy } from 'lodash';

import { CategoryService } from 'src/app/services/category.service';
import { CountryService } from 'src/app/services/country.service';
import { LanguageService } from 'src/app/services/language.service';
import { SearchService } from 'src/app/services/search.service';
import { SkillService } from 'src/app/services/skill.service';
import { SpinnerService } from '../../../tpt-ui/services/spinner.service';
import { ProjectApiService } from '../../../../services/project.api.service';
import { SvgIconsEnum } from '../../../../types/svg-icons.enum';
import { Country } from '../../../../models/country';
import { CategoryV2Model } from '../../../../models/category-v2.model';
import { LanguageOption } from '../../../../models/language';
import { CurrencySymbol } from '../../../../models/currency.enum';
import { LoadingBarService } from '../../../../services/loading-bar.service';

@Component({
  selector: 'project-search',
  templateUrl: './project-search.component.html',
  styleUrls: ['./../../filters-common.scss'],
})
export class ProjectSearchComponent implements OnInit, OnDestroy {
  @ViewChild('budgetSlider')
  public budgetSlider: NouisliderComponent;

  @ViewChild('top')
  public top: ElementRef;

  public languageLevels: string[] = [
    'BEGINNER',
    'MEDIUM',
    'ADVANCED',
    'NATIVE'
  ];

  public categories: CategoryV2Model[] = [];

  public languageArray = [];

  public separatorKeysCodes: number[] = [ENTER];

  public skillCtrl = new UntypedFormControl('');

  public skills: string[] = [];

  public allSkills: any[] = [];

  public projects = [];

  public allSkillsArray = [];

  public svgIconsEnum = SvgIconsEnum;

  public filteredItems = [];

  public rubMaxBudget = 3000000;

  public filterSlider = {
    budget: [10, this.rubMaxBudget],
  };

  public budgetSliderConfig = {
    connect: [false, true, false],
    tooltips: [true, true],
    range: {
      min: 10,
      max: this.rubMaxBudget,
    },
    format: {
      to: (val) => this._decimalPipe.transform(val),
      from: (value) => Number(value.replace(/\s/g, '')),
    },
    step: 10
  };

  public countries: Country[];

  public countriesCount: number;

  public languages: LanguageOption[];

  public filter: UntypedFormGroup;

  public levelsFormControl = new UntypedFormGroup({
    beginner: new UntypedFormControl(false),
    intermediate: new UntypedFormControl(false),
    master: new UntypedFormControl(false),
  });

  public currencySymbol = CurrencySymbol;

  public showSkeleton = false;

  public loading: boolean;

  private pageNumber = 0;

  private params;

  private currentPosition = 0;

  private totalPages: number;

  private readonly destroy$ = new Subject<void>();

  constructor(
    private categoryService: CategoryService,
    private countryService: CountryService,
    private languageService: LanguageService,
    private searchService: SearchService,
    private skillService: SkillService,
    private fb: UntypedFormBuilder,
    private cdr: ChangeDetectorRef,
    private spinner: SpinnerService,
    private projectService: ProjectApiService,
    private translate: TranslateService,
    private _decimalPipe: DecimalPipe,
    private loadingBarService: LoadingBarService
  ) {
  }

  @HostListener('scroll', ['$event']) onScroll(event) {
    const containerHeight = event?.target?.offsetHeight;
    const scrollHeight = event?.target?.scrollHeight;
    const scrollTop = event?.target?.scrollTop;

    if (this.currentPosition > scrollTop) {
      return;
    }
    this.currentPosition = scrollTop;

    const a = scrollHeight - scrollTop;
    const b = containerHeight * 0.25 + containerHeight;

    if (a < b && !this.loading) {
      if (this.pageNumber >= this.totalPages - 1) {
        return;
      }
      this.loading = true;
      this.pageNumber = this.pageNumber + 1;
      this.getListOfProjects(this.params);
    }
  }

  ngOnInit(): void {
    this.initForm();
    this.getCategories();
    this.getCountries(true);
    this.getLanguages();
    this.getAllSkills();
    this.getFilteredProjects();
    this.loadingBarService.start();

    this.skillCtrl.valueChanges.pipe(startWith('')).subscribe((value) => {
      this.findSkills(value);
    });
  }

  getAllComplete(category): boolean {
    return category.children.every(item => item.isSelected);
  }

  getFilteredProjects(): void {
    this.searchService.projects$.pipe(takeUntil(this.destroy$)).subscribe(data => {
      this.refreshVariables();

      if (!data) {
        this.params = {};
        this.getListOfProjects({});
        return;
      }

      const mappedData = data.value;
      mappedData.skills = mappedData.skills.map(item => item.id);

      const model = omitBy(mappedData,
        (field: string | number) => !Boolean(field));

      this.params = model;
      this.getListOfProjects(model);
    });
  }

  initForm(): void {
    this.filter = new UntypedFormGroup({
      categories: new UntypedFormControl([]),
      skills: new UntypedFormArray([]),
      levels: new UntypedFormControl(''),
      fixedProject: new UntypedFormControl(false),
      hourlyProject: new UntypedFormControl(false),
      budgetMin: new UntypedFormControl(null),
      budgetMax: new UntypedFormControl(null),
      countryCode: new UntypedFormControl(''),
      languageLevels: new UntypedFormControl([]),
      sortBy: new UntypedFormControl('PUBLISH_DATE_DESC'),
      projectOwnerNameFtsQuery: new UntypedFormControl('')
    });
    this.searchService.filtersProjectsNext(this.filter);
    this.filter.valueChanges.pipe(takeUntil(this.destroy$),
      debounceTime(500)
    ).subscribe(() => {
      this.searchService.filtersProjectsNext(this.filter);
    });

    this.levelsFormControl.valueChanges.pipe(takeUntil(this.destroy$), debounceTime(300))
      .subscribe((levels) => {
      let levelsString = '';
      const levelsArray = [];
      Object.keys(levels).forEach(key => {
        if (levels[key]) {
          levelsArray.push(key.toUpperCase());
        }
      });
      levelsString = levelsArray.join(',');
      this.filter.controls.levels.setValue(levelsString);
    });
  }

  getListOfProjects(filter) {
    const defaultFilter = {pageNumber: this.pageNumber, pageSize: 15};
    this.projectService.getListOfProjects({...defaultFilter, ...filter})
      .subscribe((res) => {
        this.projects = [...this.projects, ...res.content];
        this.loading = false;
        this.totalPages = res.totalPages;
        this.markJobAsVisited();
        this.showSkeleton = false;
        this.loadingBarService.complete();
    });
  }

  markJobAsVisited(): void {
    const visitedJobCards = JSON.parse(localStorage.getItem('visitedJobCards') || '{}');
    const visitedJobIds = Object.keys(visitedJobCards);
    this.projects.forEach(job => {
      if (visitedJobIds.includes(`${job.id}`)) {
        job.visited = true;
      }
    });
  }

  updateSortOrder(event) {
    this.filter.controls.sortBy.patchValue(event);
  }

  addLang(): void {
    this.languageArray.push({
      code: null,
      level: null
    });
  }

  removeLang(i): void {
    this.languageArray = this.languageArray.filter((item, index) => index !== i);
    this.updateLanguageForm();
  }

  updateLanguageForm(): void {
    const updatedArr = this.languageArray.filter(item => {
      return item.code && item.level;
    });

    const selectedLangs = this.languageArray.map(item => item?.code).filter(item => item);
    this.languages.forEach(item => {
      item.disabled = !!selectedLangs.includes(item.code);
    });

    this.filter.controls.languageLevels.patchValue(updatedArr);
  }

  public getAllSkills() {
    this.skillService.getSkillsV2().subscribe(res => {
      this.allSkills = res;
      this.allSkillsArray = this.allSkills;
      this.findSkills('');
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  setAll(checked: boolean, category) {
    category.children.forEach(item => item.isSelected = checked);
  }

  public getTranslation(item): string {
    if (!item) { return; }
    if (this.translate.currentLang === 'ru') {
      return item.nameRu;
    }
    return item.name;
  }

  async getUpdAllSkills(checked, category, subCategory): Promise<void> {

    if (checked && !subCategory) {
      const skillsId = category.id;
      const res = await lastValueFrom(this.skillService.getCategorySkillsV2(skillsId));
      res.forEach(item => {
        if (this.allSkillsArray.some(skill => skill.id === item.id)) {
          return;
        }
        this.allSkillsArray.push(item);
      });
    }

    if (!checked && !subCategory) {
      const skillId = category.id;
      const res = await lastValueFrom(this.skillService.getCategorySkillsV2(skillId));
      const filterArr = res.map(item => item.id);
      this.allSkillsArray = this.allSkillsArray.filter(item => !filterArr.includes(item.id));
      if (!this.allSkillsArray.length) {
        this.allSkillsArray = this.allSkills;
      }
    }

    if (checked && subCategory) {
      const res = await lastValueFrom(this.skillService.getCategorySkillsV2(subCategory.id));
      res.forEach(skill => this.allSkillsArray.push(skill));
    }

    if (!checked && subCategory) {
      if (category.children.every(child => !child.isSelected)) {
        const res = await lastValueFrom(this.skillService.getCategorySkillsV2(category.id));
        const filterArr = res.map(item => item.id);
        this.allSkillsArray = this.allSkillsArray.filter(item => !filterArr.includes(item.id));
        if (!this.allSkillsArray.length) {
          this.allSkillsArray = this.allSkills;
        }
      }
    }

    this.updateCategoryFormControl();
    this.filteredItems = this.allSkillsArray;
  }

  updateCategoryFormControl(): void {
    if (!this.categories) {
      return;
    }

    const categoriesArray = [];

    this.categories.forEach(item => {
      if (item.isSelected) {
        categoriesArray.push(item.id);
      }
    });

    const notAllSelected = this.categories.filter(item => !item.isSelected);
    notAllSelected.forEach(item => {
      item.children.forEach(child => {
        if (child.isSelected) {
          categoriesArray.push(child.id);
        }
      });
    });

    this.filter.controls.categories.patchValue(categoriesArray);
  }

  public getCategories() {
    this.categoryService.getCategoriesV2().subscribe(res => {
      this.categories = res;
      this.categories.forEach(item => {
        item.isSelected = false;
        item.expanded = false;

        item.children.forEach(child => {
          child.isSelected = false;
        });
      });
    });
  }

  public refreshFilters = (): void => {
    const filterInitValue = {
      budgetMax: null,
      budgetMin: null,
      categories: [],
      countryCode: '',
      languageLevels: [],
      levels: ''
    };

    Object.keys(filterInitValue).forEach(key => {
      this.filter.controls[key]?.setValue(filterInitValue[key]);
    });
    (this.filter.controls.skills as UntypedFormArray).clear();

    this.levelsFormControl.setValue({
      beginner: false,
      intermediate: false,
      master: false
    });
    this.categories.forEach(category => {
      category.isSelected = false;
      this.setAll(false, category);
    });
    this.languageArray = [];
    this.filterSlider.budget = [10, this.rubMaxBudget];
  }

  public filtersNotEmpty = (): boolean => {
    const filtersValue = this.filter.value;

    const filterFields = ['countryCode', 'levels'];
    const arrayFilterFields = ['languageLevels', 'skills', 'categories'];

    return Object.keys(filtersValue).some(key => {
      if (arrayFilterFields.includes(key)) {
        return !!filtersValue[key].length;
      }

      if (key === 'budgetMin') {
        return !(!filtersValue[key] || filtersValue[key] === 10);
      }

      if (key === 'budgetMax') {
        return !(!filtersValue[key] || filtersValue[key] === 3000000);
      }

      if (filterFields.includes(key)) {
        return !!filtersValue[key];
      }
      return false;
    });
  }

  public removeSkill(index: number): void {
    const skills = this.filter.get('skills') as UntypedFormArray;

    if (index >= 0) {
      skills.removeAt(index);
    }
  }

  public selectedSkill(event: any): void {
    const skills = this.filter.get('skills') as UntypedFormArray;

    const alreadyAdded = skills.value.some(item => item.id === event.option.value.id);
    if (alreadyAdded) {
      return;
    }

    skills.push(this.fb.control(this.skillCtrl.value));
    this.skillCtrl.setValue('');
    this.cdr.detectChanges();
  }

  public getViewField(): string {
    if (this.translate.currentLang === 'ru') {
      return 'nameRu';
    }
    return 'name';
  }

  public getCountries(setCount: boolean, name?: string): void {
    if (!name) {
      name = '';
    }

    this.countryService.getCountriesV2(name).subscribe(res => {
      this.countries = res;

      if (setCount) {
        this.countriesCount = res.length;
      }
    });
  }

  public budgetChange(val): void {
    this.filter.controls.budgetMin.patchValue(val[0]);
    this.filter.controls.budgetMax.patchValue(val[1]);
    this.filter.controls.fixedProject.setValue(true);
  }

  public setBudgetSliderValue() {
    this.budgetSlider.slider.set(this.filterSlider.budget);
  }

  public toggleSubcats(category): void {
    category.expanded = !category.expanded;
    if (category.expanded) {
      this.categories.forEach(item => {
        item.expanded = item.id === category.id;
      });
    }
  }

  public subCategoryChanged(event, subCat, cat): void {
    subCat.isSelected = !subCat.isSelected;
    cat.isSelected = this.getAllComplete(cat);
  }

  public someChecked(category): boolean {
    const countOfChecked = category.children.filter(item => item.isSelected).length;
    return countOfChecked > 0 && countOfChecked < category.children.length;
  }

  public updateProjects(val): void {
    this.filter.controls.projectOwnerNameFtsQuery.patchValue(val);
  }

  private refreshVariables(): void {
    this.pageNumber = 0;
    this.currentPosition = 0;
    this.showSkeleton = true;
    this.projects = [];
    this.top?.nativeElement.scrollIntoView({behavior: 'smooth'});
  }

  private async getLanguages(): Promise<void> {
    this.languages = await lastValueFrom(this.languageService.getLanguagesV2());
  }

  private async findSkills(val): Promise<void> {
    if (!val) {
      this.filteredItems = this.allSkillsArray;
      return;
    }

    if (typeof val === 'string') {
      this.filteredItems = await lastValueFrom(this.skillService.getCategorySkillsV2(null, val));
    }
  }
}
