import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import { UntypedFormArray, UntypedFormControl } from '@angular/forms';

import { IBaseFilters } from '../../../../../classes/iBaseFilters';
import { FreelancerSearchSorting } from '../../../../../classes/enums';
import { ProfileModel } from '../../../../../models/profile.model';
import { SvgIconsEnum } from '../../../../../types/svg-icons.enum';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

@Component({
  selector: 'freelancer-search-page',
  templateUrl: './freelancer-search-page.component.html',
  styleUrls: ['./freelancer-search-page.component.scss'],
})
export class FreelancerSearchPageComponent implements OnInit, OnDestroy {
  @Output()emitSortChange = new EventEmitter();
  @Input() freelancers: ProfileModel[];
  @Output()searchEvent = new EventEmitter();
  @Input() skillsFilter: UntypedFormArray;
  @Input() showSkeleton: boolean;

  public svgIconsEnum = SvgIconsEnum;

  public sortList = [
    {key: 'RATE_ASC', label: 'Ставке (по возрастанию)'},
    {key: 'RATE_DESC', label: 'Ставке (по убыванию)'},
  ];

  public filters: Partial<IBaseFilters> = {
    sort: FreelancerSearchSorting.RATE_ASC,
    skills: [],
    expertLevel: null,
    location: null,
    searchText: '',
    pageIndex: 0,
    pageSize: 20,
    hourlyRate: [0, 50000],
  };

  public searchControl: UntypedFormControl;
  public sortControl: UntypedFormControl;

  private destroy = new Subject<void>();

  ngOnInit(): void {
    this.searchControl = new UntypedFormControl('');
    this.sortControl = new UntypedFormControl('');

    this.sortControl.valueChanges.pipe(takeUntil(this.destroy)).subscribe((val) => {
      this.emitSortChange.emit(val);
    });
  }

  ngOnDestroy() {
    this.destroy.next();
    this.destroy.complete();
  }

  public searchUsers(): void {
    const searchValue = this.searchControl.value.trim();
    this.searchEvent.emit(searchValue);
  }
}
