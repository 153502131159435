<tpt-dialog>
  <div class="dialog-root">
    <div class="title">У вас есть договор с исполнителем</div>

    <mat-radio-group [(ngModel)]="contractType">
      <mat-radio-button value="FRAMEWORK" color="primary" class="mb-8">Работать по действующему договору</mat-radio-button><br/>
      <mat-radio-button value="REGULAR" color="primary">Сформировать новый договор</mat-radio-button>
    </mat-radio-group>

    <div class="actions-row">
      <tpt-button type="secondary" (onClick)="close()">Отменить</tpt-button>
      <tpt-button type="primary" (onClick)="submit()">Продолжить</tpt-button>
    </div>
  </div>
</tpt-dialog>
