import { Component, OnInit } from '@angular/core';
import { SvgIconsEnum } from '@/types/svg-icons.enum';
import { RouteHelper } from '@/helpers/route.helper';
import { ProfileService } from '@/services/profile.service';
import { ProjectApiService } from '@/services/project.api.service';
import { Router } from '@angular/router';
import { ContractsService } from '@/modules/contracts/services/contracts.service';

@Component({
  selector: 'app-mobile-sidebar',
  templateUrl: './mobile-sidebar.component.html',
  styleUrls: ['./mobile-sidebar.component.scss']
})
export class MobileSidebarComponent implements OnInit {

  public svgIconsEnum = SvgIconsEnum;
  public unreadCount: number;

  public get isEmployer(): boolean {
    if (!this.profileService.currentProfile) { return; }
    return this.profileService.currentProfile.isEmployer();
  }

  get projectsRoute() {
    if (!this.projectService.currentProject) {
      return this.profileService.currentProfile.isEmployer() ?
        this.routeHelper.employerProjectsDashboard :
        this.routeHelper.freelancerProjectsDashboard;
    }

    return this.profileService.currentProfile.isEmployer() ?
      this.routeHelper.employerProjectPage(this.projectService.currentProject) :
      this.routeHelper.freelancerProjectPage(this.projectService.currentProject);
  }

  get searchIsActive(): boolean {
    return this.router.url.includes('/search');
  }

  public show = false;

  constructor(
    public routeHelper: RouteHelper,
    private router: Router,
    private projectService: ProjectApiService,
    public profileService: ProfileService,
    private contractsService: ContractsService,
  ) { }

  ngOnInit(): void {
    this.getUnreadMessages();
  }

  toggleSidebar() {
    this.show = !this.show;
  }

  public getUnreadMessages(): void {
    this.contractsService.getUnreadCount().subscribe(count => {
      this.unreadCount = count;
    });
  }
}
