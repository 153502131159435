<div class="register-root">
  <div class="register-wrap">
    <mat-icon [svgIcon]="svgIconsEnum.FooterLogo" class="logo-full"></mat-icon>

    <div class="register">

      <div class="register-column" *ngIf="registerForm && !googleSignUpStepTwo">
        <h2 class="register-title">{{ 'REGISTER_COMPONENT.ACCOUNT_CREATION' | translate }}</h2>

        <ng-container *ngIf="!basicSignUpStepTwo">
          <div class="regular-16-24">
            {{'REGISTER_COMPONENT.QUICK_JOIN' | translate}}
          </div>

          <asl-google-signin-button type='standard' size='medium' class="mt-24 d-flex justify-center" shape="square"
                                    logo_alignment="center" text="signup_with" locale="ru">
          </asl-google-signin-button>

          <div class="divider-wrapper my-24">
            <mat-divider></mat-divider>
            <div class="mx-16">{{'REGISTER_COMPONENT.OR' | translate}}</div>
            <mat-divider></mat-divider>
          </div>
        </ng-container>

        <form *ngIf="!registerSuccess">
          <ng-container *ngIf="!basicSignUpStepTwo">
            <div class="columns-row">
              <div class="-left">
                <tpt-input
                  [control]="registerForm.email"
                  placeholder="{{ 'REGISTER_COMPONENT.EMAIL_ADDRESS' | translate }}"
                >
                  <ng-container label>{{ 'REGISTER_COMPONENT.EMAIL' | translate }}</ng-container>
                </tpt-input>
              </div>
            </div>

            <div class="register-actions-row">
              <tpt-button
                type="primary"
                size="large"
                class="register-btn"
                (onClick)="basicSignUp()"
              >
                {{'REGISTER_COMPONENT.EMAIL_SIGNUP' | translate}}
              </tpt-button>
            </div>
          </ng-container>

          <ng-container *ngIf="basicSignUpStepTwo">
            <div class="mb-24 info-text"
                 [innerHTML]="'REGISTER_COMPONENT.EMAIL_SIGNUP_TEXT' | translate: {email: registerForm.email.value}">
            </div>

            <div class="choose-role mb-8">
              {{ 'REGISTER_COMPONENT.CHOOSE_THE_ROLE' | translate }}
            </div>

            <mat-radio-group [formControl]="registerForm.role" class="mb-24">
              <mat-radio-button value="EMPLOYER" color="primary"
                                labelPosition="before">{{'REGISTER_COMPONENT.I_AM_CLIENT' | translate}}</mat-radio-button>
              <mat-radio-button value="FREELANCER" color="primary"
                                labelPosition="before">{{'REGISTER_COMPONENT.I_AM_TALENT' | translate}}</mat-radio-button>
            </mat-radio-group>

            <div style="position: relative">
              <tpt-input
                [control]="registerForm.password"
                [type]="showPass ? 'text' : 'password'"
                class="password-input"
                (onFocus)="showTooltip = true"
                (onBlur)="showTooltip = false"
              >
                <ng-container label>{{ 'REGISTER_COMPONENT.PASSWORD' | translate }}</ng-container>
                <ng-container icon>
                  <mat-icon class="input-icon"
                            (click)="showPass = !showPass"
                            [svgIcon]="showPass ? svgIconsEnum.EyeOpen : svgIconsEnum.EyeClose"></mat-icon>
                </ng-container>
              </tpt-input>
              <div class="tooltip" [class.show]="showTooltip">
                <div class="strength-info">
                  <div class="arrow"></div>
                  <div class="title">Пароль должен содержать:</div>
                  <div class="info-row" *ngIf="passwordComponent.enableLengthRule">
                    <div *ngIf="passwordComponent.containAtLeastMinChars; then done else error">
                    </div>
                    <span [class.done]="passwordComponent.containAtLeastMinChars">Как минимум 12 символов</span>
                  </div>
                  <div class="info-row" *ngIf="passwordComponent.enableDigitRule">
                    <div *ngIf="passwordComponent.containAtLeastOneDigit; then done else error">
                    </div>
                    <span [class.done]="passwordComponent.containAtLeastOneDigit">Как минимум одну цифру</span>
                  </div>
                  <div class="info-row" *ngIf="passwordComponent.enableLowerCaseLetterRule">
                    <div *ngIf="passwordComponent.containAtLeastOneLowerCaseLetter; then done else error">
                    </div>
                    <span [class.done]="passwordComponent.containAtLeastOneLowerCaseLetter">Как минимум одну букву в нижнем регистре</span>
                  </div>
                  <div class="info-row" *ngIf="passwordComponent.enableUpperCaseLetterRule">
                    <div *ngIf="passwordComponent.containAtLeastOneUpperCaseLetter; then done else error">
                    </div>
                    <span [class.done]="passwordComponent.containAtLeastOneUpperCaseLetter">Как минимум одну букву в верхнем регистре</span>
                  </div>
                  <div class="info-row" *ngIf="passwordComponent.enableSpecialCharRule">
                    <div *ngIf="passwordComponent.containAtLeastOneSpecialChar; then done else error">
                    </div>
                    <span [class.done]="passwordComponent.containAtLeastOneSpecialChar">Как минимум один специальный символ</span>
                  </div>
                  <ng-template #done>
                    <svg width="12" height="13" class="mr-8" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M0 6.5C0 3.19531 2.67188 0.5 6 0.5C9.30469 0.5 12 3.19531 12 6.5C12 9.82812 9.30469 12.5 6 12.5C2.67188 12.5 0 9.82812 0 6.5ZM8.69531 5.46875C8.95312 5.21094 8.95312 4.8125 8.69531 4.55469C8.4375 4.29688 8.03906 4.29688 7.78125 4.55469L5.25 7.08594L4.19531 6.05469C3.9375 5.79688 3.53906 5.79688 3.28125 6.05469C3.02344 6.3125 3.02344 6.71094 3.28125 6.96875L4.78125 8.46875C5.03906 8.72656 5.4375 8.72656 5.69531 8.46875L8.69531 5.46875Z"
                        fill="#50997B"/>
                    </svg>
                  </ng-template>
                  <ng-template #error>
                    <svg width="12" height="13" class="mr-8" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M12 6.5C12 9.82812 9.30469 12.5 6 12.5C2.67188 12.5 0 9.82812 0 6.5C0 3.19531 2.67188 0.5 6 0.5C9.30469 0.5 12 3.19531 12 6.5ZM6 1.625C3.30469 1.625 1.125 3.82812 1.125 6.5C1.125 9.19531 3.30469 11.375 6 11.375C8.67188 11.375 10.875 9.19531 10.875 6.5C10.875 3.82812 8.67188 1.625 6 1.625Z" fill="#C4C6CC"/>
                    </svg>
                  </ng-template>
                </div>
              </div>
            </div>

            <div class="strength-bar" [class.visible]="registerForm.password?.touched || registerForm.password?.dirty">
              <password-strength [password]="registerForm.password.value"
                                 #passwordComponent
                                 [min]="minPasswordLength"
                                 class="mb-48"
              ></password-strength>
            </div>

            <div class="checkboxes-wrapper">
              <mat-checkbox [formControl]="registerForm.userAgreement" class="green-checkbox">
                {{ 'REGISTER_COMPONENT.I_ACCEPT' | translate }}
                <a (click)="$event.stopPropagation()" [routerLink]="'/legal/terms'"
                   target="_blank">{{ 'REGISTER_COMPONENT.RULES_OF_USE' | translate }}</a>
              </mat-checkbox>

              <mat-checkbox [formControl]="registerForm.privacyPolicy" class="green-checkbox">
                {{ 'REGISTER_COMPONENT.I_GIVE_CONSENT' | translate }}
                <a (click)="$event.stopPropagation()" [routerLink]="'/legal/privacy'"
                   target="_blank">{{ 'REGISTER_COMPONENT.PRIVACY_POLICY' | translate }}</a>
              </mat-checkbox>

              <mat-checkbox *ngIf="registerForm.role.value === 'EMPLOYER'"
                            [formControl]="registerForm.agencyContract" class="green-checkbox">
                Я ознакомился и согласен с условиями
                <a (click)="$event.stopPropagation()" [routerLink]="'/legal/agency-contract'"
                   target="_blank">Агентского договора</a>
              </mat-checkbox>
            </div>

            <tpt-button type="primary" size="large"
                        [disabled]="buttonDisabled()"
                        (onClick)="register()"
            >
              {{'REGISTER_COMPONENT.CREATE_ACCOUNT' | translate}}
            </tpt-button>
          </ng-container>
        </form>

        <div *ngIf="registerSuccess" class="success-wrapper">
          <div class="message-title">{{'REGISTER_COMPONENT.SUCCESS_TITLE' | translate}}</div>
          <div class="message-text">{{'REGISTER_COMPONENT.SUCCESS_TEXT' | translate}}
            <span class="email">{{registerForm.email.value}}</span>
          </div>
        </div>
      </div>

      <div *ngIf="googleSignUpStepTwo" class="register-column">
        <h2 class="register-title">{{ 'REGISTER_COMPONENT.GOOGLE_SIGNUP' | translate}}</h2>

        <div class="mb-24 info-text"
             [innerHTML]="'REGISTER_COMPONENT.GOOGLE_SIGNUP_TEXT' | translate: {email: user?.email}">
        </div>

        <div class="choose-role mb-8">
          {{ 'REGISTER_COMPONENT.CHOOSE_THE_ROLE' | translate }}
        </div>

        <mat-radio-group [formControl]="roleControl">
          <mat-radio-button value="EMPLOYER" color="primary"
                            labelPosition="before">{{'REGISTER_COMPONENT.I_AM_CLIENT' | translate}}</mat-radio-button>
          <mat-radio-button value="FREELANCER" color="primary"
                            labelPosition="before">{{'REGISTER_COMPONENT.I_AM_TALENT' | translate}}</mat-radio-button>
        </mat-radio-group>

        <tpt-button type="primary" size="large" class="mt-32" (onClick)="createAccountGoogle()">
          {{'REGISTER_COMPONENT.CREATE_ACCOUNT' | translate}}
        </tpt-button>
      </div>
    </div>

    <div class="have-acc" *ngIf="!googleSignUpStepTwo">{{'REGISTER_COMPONENT.HAVE_AN_ACCOUNT' | translate}}
      <span [routerLink]="['/login']"
            [queryParams]="activatedRoute.snapshot.queryParams" class="link">{{'REGISTER_COMPONENT.SIGN_IN' | translate}}</span>
    </div>
  </div>
  <app-auth-pages-footer></app-auth-pages-footer>
</div>

<tpt-signup-error-dialog (useAnotherEmailEvent)="goToFirstStep()"></tpt-signup-error-dialog>
