import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormControl, UntypedFormArray, UntypedFormControl } from '@angular/forms';

import { Observable, Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

import { ProjectSearchSorting } from 'src/app/classes/enums';
import { CategoryService } from 'src/app/services/category.service';
import { SearchService } from 'src/app/services/search.service';
import { IBaseFilters } from '../../../../../classes/iBaseFilters';
import { SpinnerService } from '../../../../tpt-ui/services/spinner.service';
import { ProjectSearchItemModel } from '../../../../../models/search/projectSearchItem.model';
import { ProfileService } from '../../../../../services/profile.service';
import { DataLayerService} from '../../../../../services/data-layer.service';
import { SvgIconsEnum } from '../../../../../types/svg-icons.enum';
import { EmployerInfoDialogComponent } from '../../../../common-dialogs/components/info-dialogs/employer-info-dialog/employer-info-dialog.component';
import { CurrencyEnum, CurrencySymbol } from '../../../../../models/currency.enum';
import { ProfilesApiService } from '../../../../../services/profiles.api.service';
import { FreelancerLegalStatusEnum } from '../../../../../models/legalStatuses.enum';
import { RouteHelper } from '../../../../../helpers/route.helper';
import { Clipboard } from '@angular/cdk/clipboard';
import { SnackbarNotificationsService } from '../../../../../services/snackbar-notifications.service';
import { GetAcquaintedDialogComponent } from '../../../../common-dialogs/components/get-acquainted-dialog/get-acquainted-dialog.component';
import { JobApplyErrorDialogComponent } from '../../../../common-dialogs/components/job-apply-error-dialog/job-apply-error-dialog.component';
import { ProfileModel } from '@/models/profile.model';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'tpt-project-card',
  templateUrl: './project-card.component.html',
  styleUrls: ['./project-card.component.scss']
})
export class ProjectCardComponent implements OnInit, OnDestroy {

  get isEmployer(): boolean {
    return this.profileService.currentProfile.isEmployer();
  }

  get restrictedAccess(): boolean {
    return !this.profileService.currentProfile.selfEmployedStatus ||
      this.profileService.currentProfile.selfEmployedStatus === 'NEW' || this.notValidData;
  }

  get disableButton(): boolean {
    return ['DETACHED', 'REMOVED_FROM_REGISTER', 'NOT_VERIFIED', 'NEW']
      .includes(this.profileService.currentProfile.selfEmployedStatus);
  }

  get notValidData(): boolean {
    return this.profileService.currentProfile.selfEmployedStatus === 'ACTIVE' &&
      (!this.profileService.currentProfile.legalInfoValidated || !this.profileService.currentProfile.bankDetailsValidated);
  }

  @ViewChild(EmployerInfoDialogComponent)
  public employerInfoDialog: EmployerInfoDialogComponent;

  @ViewChild(GetAcquaintedDialogComponent)
  public acquaintedDialog: GetAcquaintedDialogComponent;

  @ViewChild(JobApplyErrorDialogComponent)
  public jobApplyErrorDialogComponent: JobApplyErrorDialogComponent;

  @Output()emitSortChange = new EventEmitter();
  @Output()searchEvent = new EventEmitter();
  @Input() projects: ProjectSearchItemModel[];
  @Input() skillsFilter: UntypedFormArray;
  @Input() showSkeleton: boolean;

  public filters: Partial<IBaseFilters> = {
    sort: ProjectSearchSorting.PUBLISH_DATE_DESC,
    skills: [],
    expertLevel: null,
    location: null,
    searchText: '',
    pageIndex: 0,
    pageSize: 20,
    hourlyRate: [0, 1000],
  };

  public profiles: ProfileModel[] = [];
  public categories: any;
  public categories$: Observable<any>;
  public svgIconsEnum = SvgIconsEnum;

  public searchControl: UntypedFormControl;
  public sortControl: FormControl<string>;
  public currencySymbol = CurrencySymbol;
  public currencyEnum = CurrencyEnum;
  public freelancerLegalEnum = FreelancerLegalStatusEnum;

  public sortList = [
    {key: 'PUBLISH_DATE_ASC', label: 'Дате публикации (сначала старые)'},
    {key: 'PUBLISH_DATE_DESC', label: 'Дате публикации (сначала новые)'},
  ];
  private readonly destroy = new Subject<void>();

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private routeHelper: RouteHelper,
    private categoryService: CategoryService,
    private searchService: SearchService,
    private spinner: SpinnerService,
    public profileService: ProfileService,
    private dataLayerService: DataLayerService,
    private translate: TranslateService,
    private profileApiService: ProfilesApiService,
    private clipboard: Clipboard,
    private snack: SnackbarNotificationsService,
  ) {}

  ngOnInit(): void {
    this.getCategories();

    this.searchControl = new UntypedFormControl('');
    this.sortControl = new FormControl<string>('');

    this.sortControl.valueChanges.pipe(takeUntil(this.destroy)).subscribe((val) => {
      this.emitSortChange.emit(val);
    });
    // this.searchControl.valueChanges.pipe(
    //   debounceTime(500)
    // ).subscribe((val) => {
    //   this.searchEvent.emit(val);
    // });
  }

  ngOnDestroy() {
    this.destroy.next();
    this.destroy.complete();
  }

  public openCardDetails(project: ProjectSearchItemModel): void {
    const visitedJobCards = JSON.parse(localStorage.getItem('visitedJobCards') || '{}');
    const threeDaysInMs = 3 * 24 * 60 * 60 * 1000;
    visitedJobCards[project.id] = Date.now() + threeDaysInMs;
    localStorage.setItem('visitedJobCards', JSON.stringify(visitedJobCards));
    project.visited = true;

    const url = this.router.serializeUrl(
      this.router.createUrlTree(this.routeHelper.projectPage(project.id))
    );

    window.open(url, '_blank');
  }

  public getChipStyle(skill): boolean {
    const skillsFilter = this.skillsFilter.value;

    return skillsFilter.some(item => item.id === skill.id);
  }

  public getCategories() {
    this.categories$ = this.categoryService.getCategoriesV2();
  }

  public getProjectCategory(project, categories) {
    const category = categories.find(c => c.id === project.parentCategoryId);
    project.category = category;
    return this.getTranslation(category);
  }

  public getProjectSubCategory(project, categories) {
    const category = categories.find(c => c.id === project.parentCategoryId);
    const subCategory = category.children.find(c => c.id === project.subCategoryId);
    project.subCategory = subCategory;
    return this.getTranslation(subCategory);
  }

  public getTranslation(item): string {
    if (!item) { return; }
    if (this.translate.currentLang === 'ru') {
      return item.nameRu;
    }
    return item.name;
  }

  public copyJobLink(job): void {
    let baseUrl = window.location.href;
    baseUrl = baseUrl.split('/').slice(0, 3).join('/');

    const userUrl = this.router.serializeUrl(
      this.router.createUrlTree(this.routeHelper.projectPage(job.id))
    );

    const fullUrl = `${baseUrl}${userUrl}`;

    this.clipboard.copy(fullUrl);
    this.snack.showNotification('SETTINGS.JOB_LINK_COPIED', 'success');
  }

  public sendProposal(project: ProjectSearchItemModel) {
    if (this.restrictedAccess) {
      this.jobApplyErrorDialogComponent.open();
      return;
    }

    this.spinner.startSpinner();
    this.searchService.sendProposal(project.id)
      .then(res => {
        if (res) {
          project.jobConversations.push({
            chatId: res.id,
            participationState: 'PROPOSAL_SUBMITTED'
          });
          this.spinner.stopSpinner();
        }
      })
      .catch(this.spinner.stopSpinner);
  }

  public openEmployerInfo(owner): void {
    this.employerInfoDialog.open(owner, false, false);
  }

  public searchProject(): void {
    const searchValue = this.searchControl.value.trim();
    this.searchEvent.emit(searchValue);
  }

  public openChat(chatId) {
    this.router.navigate(this.routeHelper.contractsProposalsUrl(chatId));
  }
}
