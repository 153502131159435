<div class="wrapper">
  <div class="search-row">
    <mat-icon class="search-icon" [svgIcon]="svgIconsEnum.Search"></mat-icon>
    <input [formControl]="searchControl"
           matInput
           class="search-input"
           placeholder="{{'SEARCH.USER_SEARCH_PLACEHOLDER' | translate}}"
           (keyup.enter)="searchUsers()"
    >
    <tpt-button type="primary" size="large" (onClick)="searchUsers()">
      {{'BUTTON.SEARCH' | translate}}
    </tpt-button>
  </div>
  <div class="sort mb-12">
    <h2 class="sort-title">{{'SEARCH.SORT.SORT_BY' | translate}}</h2>

    <tpt-select [control]="sortControl"
                [options]="sortList"
                [showLabel]="false"
                class="custom-select no-label ">

    </tpt-select>
  </div>

  <ng-container *ngIf="!showSkeleton; else freelancersLoader">
    <ng-container *ngIf="freelancers.length; else notFound">
      <div *ngFor="let freelancer of freelancers; let i = index" class="mb-16">
        <tpt-freelancer-search-card [freelancer]="freelancer"
                                    [skillsFilter]="skillsFilter">
        </tpt-freelancer-search-card>
      </div>
    </ng-container>

    <ng-template #notFound>
      <div class="not-found">
        <div class="not-found-title">{{'SEARCH.NO_RESULTS_TITLE' | translate}}</div>
        <div class="not-found-subtitle">{{'SEARCH.NO_RESULTS_SUBTITLE' | translate}}</div>
      </div>
    </ng-template>
  </ng-container>


  <ng-template #freelancersLoader>
    <ngx-skeleton-loader
      count="5"
      animation="progress"
      [theme]="{
          'border-radius': '4px',
          height: '264px',
          background: '#eff1f6',
          'box-shadow': '0 4px 4px rgba(0, 0, 0, 0.1)'
        }"
    ></ngx-skeleton-loader>
  </ng-template>
</div>

