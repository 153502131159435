<tpt-dialog>

  <div class="title">{{ 'CONTRACTS.CHAT_INFO' | translate }}
    <mat-icon [svgIcon]="svgIconsEnum.Edit" (click)="editChat.emit()"
              *ngIf="['ADMIN', 'CHAT_OWNER'].includes(currentUser?.role)"></mat-icon>
  </div>

  <div class="chat-info mt-32" *ngIf="chat">
    <img
      [src]="chat.avatar?.id | tptAvatar: 'empty'"
      class="avatar"
      alt="avatar"
    />
    <div class="info">
      <div class="name">
        {{ chat.name }}
      </div>
      <div
        *ngIf="chat.participants?.length"
        class="count"
      >
        {{'CONTRACTS.MEMBERS' | translate:{count: chat.participants?.length} }}
      </div>
    </div>
  </div>

  <div class="subhead mt-24 mb-20">{{ 'CONTRACTS.PARTICIPANTS' | translate }}</div>

  <div class="chat-person" *ngFor="let item of chat?.participants">
    <tpt-avatar [textSizeRatio]="2.5" size="32" class="mr-20" [user]="item?.participant"></tpt-avatar>

    <div class="person-info">
      <div class="name">
        {{ item.participant?.firstName }} {{ item.participant?.lastName }}
      </div>
      <div class="role caption" *ngIf="item.role">
        {{ 'CONTRACTS.'+item?.role | translate}}
      </div>

    </div>
  </div>
</tpt-dialog>
