import { Component, ElementRef, OnDestroy, ViewChild } from '@angular/core';
import { MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { DialogComponent } from '../../dialog/components/dialog/dialog.component';
import { SvgIconsEnum } from '../../../types/svg-icons.enum';
import { DeleteNoteDialogComponent } from '../delete-note-dialog/delete-note-dialog.component';
import { ProjectBoardService } from '../../employer/services/project-board.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { NoteItemModel } from '../../employer/models/project-notes/note-item.model';
import { SpinnerService } from '../../tpt-ui/services/spinner.service';
import { map, switchMap, tap } from 'rxjs/operators';
import { UntypedFormControl } from '@angular/forms';
import {DataLayerService} from '../../../services/data-layer.service';
import { SimpleProjectResponseModel } from '../../employer/models/simple-project-response.model';

@Component({
  selector: 'emp-project-notes-dialog',
  templateUrl: './project-notes-dialog.component.html',
  styleUrls: [ './project-notes-dialog.component.scss' ],
})
export class ProjectNotesDialogComponent implements OnDestroy {

  @ViewChild(DialogComponent)
  public dialog: DialogComponent;

  @ViewChild(DeleteNoteDialogComponent)
  public deleteNoteDialog: DeleteNoteDialogComponent;

  @ViewChild('noteTextarea', { read: ElementRef })
  public noteTextarea: ElementRef<HTMLTextAreaElement>;

  public svgIconsEnum = SvgIconsEnum;

  public fullContent: boolean;

  public notes: Observable<Array<NoteItemModel>>;

  public subject = new BehaviorSubject(null);

  public noteTextControl = new UntypedFormControl(null);

  public selectedNoteId: number;

  private className = 'emp-project-notes-dialog';

  private config: MatDialogConfig = {
    position: { top: '51px', right: '0px' },
    width: '303px',
    height: 'calc(100% - 52px)',
    disableClose: false,
    hasBackdrop: false,
  };

  private dialogRef: MatDialogRef<any>;

  private noteToDelete: NoteItemModel;

  private project: SimpleProjectResponseModel;

  constructor(
    private projectBoardService: ProjectBoardService,
    private spinnerService: SpinnerService,
    private dataLayerService: DataLayerService,
  ) { }

  public open(project: SimpleProjectResponseModel): void {
    this.project = project;
    this.spinnerService.startSpinner();
    this.notes = this.subject.asObservable().pipe(
      switchMap(this.getNotes),
      tap(this.spinnerService.stopSpinner),
    );
    this.dialog.config = this.config;
    this.dialogRef = this.dialog.open(this.className);
    this.dialogRef.afterClosed().subscribe(this.collapseDialog);
  }

  ngOnDestroy() {
    this.close();
  }

  public close = (): void => {
    this.selectedNoteId = null;

    if (!this.dialogRef || !this.dialog) {
      return;
    }

    this.dialog.close();
  }

  public collapseDialog = (): void => {
    this.selectedNoteId = null;
    this.fullContent = false;
  }

  public pinSelectedNote(): void {
  }

  public addNote(): void {
    this.expandDialog();
  }

  public handleNoteClick(note: NoteItemModel, index: number): void {
    this.selectedNoteId = index;
    // this.noteTextControl.setValue(note.text);
    // this.expandDialog();
  }

  public handleNoteDelete(note: NoteItemModel): void {
    this.selectedNoteId = null;
    this.noteToDelete = note;
    this.deleteNoteDialog.open();
    this.pushDeleteNoteToDataLayer();
  }

  public handlePinNote(note: NoteItemModel): void {
    this.projectBoardService.pinNote(note).subscribe(() => {
      note.pinned = true;
    }, () => {});
  }

  public handleUnpinNote(note: NoteItemModel): void {
    this.projectBoardService.unpinNote(note).subscribe(() => {
      note.pinned = false;
    }, () => {});
  }

  public handleNoteDeleteConfirmation = (): void => {
    this.spinnerService.startSpinner();
    this.projectBoardService.deleteNote(this.noteToDelete).subscribe(
      this.handleSuccess,
      this.spinnerService.stopSpinner
    );
  }

  public saveNote = (): void => {
    this.spinnerService.startSpinner();
    this.projectBoardService.createNote(
      this.projectBoardService.currentProjectId,
      this.noteTextControl.value
    ).subscribe(this.handleNoteCreated, this.spinnerService.stopSpinner, this.pushAddNoteToDataLayer);
  }

  private getNotes = (): Observable<Array<NoteItemModel>> => {
    return this.projectBoardService.getNotes(this.projectBoardService.currentProjectId).pipe(
      tap(this.spinnerService.stopSpinner),
    );
  }

  private expandDialog(): void {
    this.fullContent = true;
    this.dialogRef.updateSize('715px', 'calc(100% - 52px)');
    setTimeout(() => {
      this.noteTextarea.nativeElement.focus();
    });
  }

  private handleSuccess = (): void => {
    this.noteTextControl.setValue(null);
    this.fullContent = false;
    this.dialogRef.updateSize('303px', 'calc(100% - 52px)');
    this.spinnerService.stopSpinner();
    this.subject.next(null);
  }

  private handleNoteCreated = (): void => {
    this.spinnerService.stopSpinner();
    this.noteTextControl.setValue(null);
    this.subject.next(null);
    this.selectedNoteId = null;
    this.fullContent = false;
    this.dialogRef.updateSize('303px', 'calc(100% - 52px)');
  }

  private pushAddNoteToDataLayer = (): void => {
    this.dataLayerService.pushToDataLayer({
      event: 'addNote',
      projectId: `${this.projectBoardService.currentProjectId}`,
      projectType: this.project.type === 'COMPLEX' ? 'complex' : 'single'
    });
  }

  private pushDeleteNoteToDataLayer = (): void => {
    this.dataLayerService.pushToDataLayer({
      event: 'deleteNote',
      projectId: `${this.projectBoardService.currentProjectId}`,
      projectType: this.project.type === 'COMPLEX' ? 'complex' : 'single'
    });
  }
}
