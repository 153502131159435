<tpt-dialog>
  <div class="dialog-root">
    <div class="step">
      <div class="title">{{'SIDEBAR.HELP' | translate}}</div>

      <div>
        <a href="https://talent-cloud.ru/legal"
           target="_blank"
           class="link">
          <div class="section">
            <mat-icon class="section-icon" [svgIcon]="svgIconsEnum.LegalInfo"></mat-icon>
            <div class="section-info">
              <div class="subhead-1">
                {{'HELP.LEGAL_INFO' | translate}}
              </div>
              <div class="caption">
                {{'HELP.LEGAL_INFO_TEXT' | translate}}
              </div>
            </div>
            <mat-icon class="navigate-icon" [svgIcon]="svgIconsEnum.Link"></mat-icon>
          </div>
        </a>

        <a href="https://npd.nalog.ru/faq/"
           class="link"
           target="_blank">
          <div class="section">
            <img src="assets/images/fns.png" class="section-icon" alt="fns-icon"/>
            <div class="section-info">
              <div class="subhead-1">
                База знаний самозанятого
              </div>
              <div class="caption">
                База знаний по налогу на профессиональный доход на сайте ФНС
              </div>
            </div>
            <mat-icon class="navigate-icon" [svgIcon]="svgIconsEnum.Link"></mat-icon>
          </div>
        </a>
      </div>

      <div class="bottom-section">
        <mat-icon class="section-icon" [svgIcon]="svgIconsEnum.Contact"></mat-icon>
        <div class="section-info">
          <div class="subhead-1">
            {{'HELP.BOTTOM_TITLE' | translate}}
          </div>
          <div class="caption">
            {{'HELP.BOTTOM_TITLE_TEXT' | translate}}
          </div>
        </div>
        <div class="buttons-block">
          <tpt-button type="accent" size="small" class="contact-btn mb-4 d-flex full-width"
                      (onClick)="createChatWithSupport()">
            Открыть чат
          </tpt-button>
          <a href="mailto:info@tp.team" class="link contact-link">
            <tpt-button type="secondary" size="small" class="contact-btn">Связаться по почте</tpt-button>
          </a>
        </div>
      </div>
    </div>
  </div>
</tpt-dialog>
