import { Component, OnInit, ViewChild } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../environments/environment';
import { LogService } from './services/log.service';
import { ProfileService } from './services/profile.service';
import { OverlayContainer } from '@angular/cdk/overlay';
import { DateAdapter } from '@angular/material/core';
import { DataLayerService } from './services/data-layer.service';
import { IconsService } from './services/icons.service';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { Meta, Title } from '@angular/platform-browser';
import moment from 'moment';
import { MobileSidebarComponent } from '@/modules/common-components/mobile-sidebar/mobile-sidebar.component';

@Component({
  selector: 'xln-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {

  public isEmployer = false;

  public loaded = false;

  public hideHeader = true;

  public hideHeaderOnMainPage = true;

  public hideSidebar = true;

  public isMobile = false;

  @ViewChild(SidebarComponent) sidebar: SidebarComponent;
  @ViewChild(MobileSidebarComponent) mobileSidebar: MobileSidebarComponent;

  constructor(
    public profileService: ProfileService,
    private router: Router,
    private translate: TranslateService,
    private logService: LogService,
    private overlayContainer: OverlayContainer,
    private adapter: DateAdapter<any>,
    private titleService: Title,
    private meta: Meta,
    private dataLayerService: DataLayerService,
    private iconsService: IconsService
  ) {
    (window as any).pdfWorkerSrc = `./scripts/pdf.worker.min.js`;
    iconsService.registerIcons();
    translate.addLangs(['ru']);
    translate.use('ru');

    if (environment.MAIN_HOST.indexOf('localhost') > -1) {
      logService.warn(environment.MAIN_HOST + ' valid only for DEVELOPMENT');
    }

    if (environment.STOMP_PORT === 10678) {
      logService.warn(environment.STOMP_PORT + ' valid only for DEVELOPMENT');
    }
    this.loaded = true;

    router.events.subscribe((val) => {
      if (val instanceof NavigationEnd || val instanceof NavigationStart) {
        this.hideHeader = val.url === '/' ||
          val.url.startsWith('/login') ||
          val.url.startsWith('/forgotPassword') ||
          val.url.includes('/book-demo') ||
          val.url.includes('/contact-us') ||
          val.url.includes('/confirm-success') ||
          val.url.includes('/check-list') ||
          val.url.includes('/rsp') ||
          val.url.includes('/creative-objects') ||
          (val.url.includes('/profiles') && !this.profileService?.currentProfile) ||
          (val.url.includes('/projects') && !this.profileService?.currentProfile) ||
          val.url.includes('/register');

        this.hideHeaderOnMainPage = val.url.includes('/rsp') || val.url.includes('/check-list') ||
          val.url.includes('/creative-objects') ||
          val.url === '/';

        this.hideSidebar = val.url.includes('/contact-us') || val.url.includes('/creative-objects');
      }

      if (val instanceof NavigationEnd && val.urlAfterRedirects === '/') {
        this.hideHeader = true;
      }
    });

    window.addEventListener('resize', this.appHeight);
    this.appHeight();
  }

  appHeight = () => {
    const doc = document.documentElement;
    doc.style.setProperty('--app-height', `${window.innerHeight}px`);
  };

  async ngOnInit(): Promise<void> {
    await this.profileService.refreshCurrentProfile();

    console.log(`Application version is: ${environment.timeStamp || moment().format('DD.MM HH:mm')}`);
  }

  public toggleMobileSidebar(): void {
    this.mobileSidebar.toggleSidebar();
  }

  get getImage(): string {
    const lang = this.translate.currentLang;
    if (!lang) { return; }
    return `assets/images/onboarding-${lang}.png`;
  }
}
