import {Injectable} from '@angular/core';

import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {BaseService} from './base.service';
import { Observable, of } from 'rxjs';
import { fromPromise } from 'rxjs/internal/observable/innerFrom';
import { Constants } from '../classes/constants';
import jwtDecode from 'jwt-decode';

export interface TokenData {
  access_token: string;
  token_type: string;
  refresh_token: string;
  expires_in: number;
  scope: string;
  jti: string;
}

const tptAuthUsername = 'tpt_client';
const tptAuthPassword = 'gZ+J97Tz';
const coAuthUsername = 'co_client';
const coAuthPassword = '4]?RF;a{';

@Injectable({
  providedIn: 'root',
})
export class AuthService extends BaseService {
  constructor(http: HttpClient) {
    super(http);

    this.baseUrl = `${window.location.protocol}//${environment.MAIN_HOST}/`;
  }

  public login(username: string, password: string): Promise<any> {
    return this.executePost<any>('auth/login', JSON.stringify({username, password}));
  }

  public loginV2(username: string, password: string,
                 authHeaders: {authUsername: string, authPassword: string}): Observable<TokenData> {
    const params = {
      username: username.toLowerCase(),
      password,
      grant_type: 'password'
    };

    const { authUsername, authPassword } = authHeaders;
    const headers = {
      Authorization: 'Basic ' + btoa(`${authUsername}:${authPassword}`)
    };

    return fromPromise(this.executePost<any>('oauth/token', null, { params, headers }));
  }

  public refreshToken(refreshToken): Observable<any> {
    const params = {
      grant_type: 'refresh_token',
      refresh_token: refreshToken
    };

    const decodedToken: any = jwtDecode(refreshToken);
    const client = decodedToken.client_id;

    const username = client === 'co_client' ? coAuthUsername : tptAuthUsername;
    const password = client === 'co_client' ? coAuthPassword : tptAuthPassword;

    const headers = {
      Authorization: 'Basic ' + btoa(`${username}:${password}`)
    };

    return fromPromise(this.executePost<any>('oauth/token', null, { params, headers }));
  }

  public deleteRefreshToken(): Observable<any> {
    const token = localStorage.getItem(Constants.REFRESHTOKEN_KEY);
    if (!token) { return of(); }

    return fromPromise(this.executeDelete('oauth/token/revoke', { params: {token} }));
  }

  public updateToken(data: TokenData): void {
    localStorage.setItem(Constants.TOKEN_KEY, data.access_token);
    localStorage.setItem(Constants.REFRESHTOKEN_KEY, data.refresh_token);

    const now = Date.now();
    const expiresInMs = data.expires_in * 1000;
    localStorage.setItem(Constants.EXPIRES_KEY, `${now + expiresInMs}`);
  }

  public switchFlow(client: 'tpt_client' | 'co_client'): Promise<any> {
    return this.executePost<any>('oauth/switchCode', null, { params: { client } });
  }

  public switchAuth(code: string, client: 'tpt_client' | 'co_client'): Promise<any> {
    const params = {
      grant_type: 'authorization_code',
      code,
    };

    const username = client === 'co_client' ? coAuthUsername : tptAuthUsername;
    const password = client === 'co_client' ? coAuthPassword : tptAuthPassword;

    const headers = {
      Authorization: 'Basic ' + btoa(`${username}:${password}`)
    };

    return this.executePost<any>('oauth/token', null, { params, headers  });
  }
}
