import { Component, ViewChild } from '@angular/core';
import { DialogComponent } from '../../../dialog/components/dialog/dialog.component';
import { MatDialogConfig } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { RouteHelper } from '../../../../helpers/route.helper';
import { ProfileService } from '../../../../services/profile.service';

@Component({
  selector: 'tpt-self-employed-warning-dialog',
  templateUrl: './self-employed-warning-dialog.component.html',
  styleUrls: ['./self-employed-warning-dialog.component.scss']
})
export class SelfEmployedWarningDialogComponent {

  get isEmployer(): boolean {
    return this.profile?.currentProfile?.isEmployer();
  }

  @ViewChild(DialogComponent)
  public dialog: DialogComponent;

  private config: MatDialogConfig = {
    width: '620px',
  };

  private className = 'tpt-self-employed-warning-dialog';

  constructor(private router: Router,
              private profile: ProfileService,
              private routeHelper: RouteHelper) { }

  public open(): void {
    this.dialog.config = this.config;
    this.dialog.open(this.className);
  }

  public close(): void {
    this.dialog.close();
  }

  public redirectToLegalPage(): void {
    this.router.navigate(this.routeHelper.settingsLegalStatus);
    this.close();
  }

}
