<tpt-dialog>
  <div class="wrapper">
    <div class="dialog-header">
      <div>{{'CONTRACTS.FILTERS' | translate}}</div>
    </div>

    <div class="content" [ngClass]="{'opened': auto.isOpen}">
      <div class="filters-row">
        <div class="left">
          <ng-container *ngIf="isEmployer()">
            <div class="filter-label">
              {{'CONTRACTS.PROJECT' | translate}}
            </div>

            <tpt-select-search class="custom-select" [options]="projects"
                               panelClass="custom-select-search filter"
                               [count]="count"
                               [showEmptyValue]="true"
                               [control]="form.get('projectId')"
                               [placeholder]="'CONTRACTS.SELECT_PROJECT' | translate">
            </tpt-select-search>
          </ng-container>


          <div class="filter-label">
            {{'CONTRACTS.CATEGORY' | translate}}
          </div>

          <div *ngIf="categories" class="categories">
            <div *ngFor="let category of categories; let i = index" class="category-section">
            <span class="list-section">
              <mat-checkbox class="list-section main-checkbox"
                            [ngClass]="{'mat-checkbox-indeterminate': someChecked(category)}"
                            [(ngModel)]="category.isSelected"
                            color="primary"
                            (change)="setAll($event.checked, category);
                            getUpdAllSkills($event.checked, category, null)">
              </mat-checkbox>
              <span class="cat-name" (click)="toggleSubcats(category)">{{getTranslation(category)}}</span>
              <mat-icon [svgIcon]="svgIconsEnum.ExpandMore"
                        [class.expanded]="category.expanded"
                        (click)="toggleSubcats(category)"
                        class="expand-icon"></mat-icon>
            </span>
              <span *ngIf="category.expanded">
              <ul class="sublist-section">
                <li *ngFor="let subCategory of category.children">
                  <mat-checkbox color="primary"
                                [class.selected-sub]="subCategory.isSelected"
                                [checked]="subCategory.isSelected"
                                (change)="subCategoryChanged($event.checked, subCategory, category);
                                getUpdAllSkills($event.checked, category, subCategory)">
                    {{getTranslation(subCategory)}}
                  </mat-checkbox>
                </li>
              </ul>
            </span>
            </div>
          </div>
        </div>

        <div class="right">
          <div class="filter-label">
            {{'CONTRACTS.SKILLS' | translate}}
          </div>

          <div class="skills">
            <mat-form-field class="chip-list" appearance="none">
              <mat-chip-list #chipList>
                <input
                  #inputElement
                  [placeholder]="'SEARCH.OPTIONS.ENTER_SKILLS' | translate"
                  [formControl]="skillControl"
                  [matAutocomplete]="auto"
                  [matChipInputFor]="chipList"
                  [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                >
              </mat-chip-list>
              <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectedSkill($event); inputElement.blur()">
                <mat-option *ngFor="let skill of filteredItems" [value]="skill">
                  {{getTranslation(skill) || skill}}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
            <div class="chips-wrapper">
              <div class="pseudo-chips"
                   *ngFor="let skill of skillsFormArray?.controls; let i = index">
                {{getTranslation(skill?.value) || skill?.value}}
                <mat-icon (click)="removeSkill(i)" [svgIcon]="svgIconsEnum.Close"></mat-icon>
              </div>
            </div>
          </div>

<!--          <ng-container *ngIf="isEmployer()">-->
<!--            <div class="filter-label experience-proven">-->
<!--              {{'CONTRACTS.PROVEN_EXP' | translate}}-->
<!--              <mat-slide-toggle color="primary" [formControl]="form.controls.provenRecord"></mat-slide-toggle>-->
<!--            </div>-->

<!--            <div class="filter-label">-->
<!--              {{'CONTRACTS.RATING' | translate}}-->
<!--            </div>-->
<!--            <div class="rating-level">-->
<!--              <mat-radio-group [formControl]="form.controls.ratingLevel">-->
<!--                <div class="checkbox-container">-->
<!--                  <mat-radio-button value="over90" color="primary">{{'CONTRACTS.MORE_THAN' | translate}} 90%</mat-radio-button>-->
<!--                </div>-->
<!--                <div class="checkbox-container">-->
<!--                  <mat-radio-button value="over80" color="primary">{{'CONTRACTS.MORE_THAN' | translate}} 80%</mat-radio-button>-->
<!--                </div>-->
<!--                <div class="checkbox-container">-->
<!--                  <mat-radio-button value="any" color="primary">{{'CONTRACTS.ANY' | translate}}</mat-radio-button>-->
<!--                </div>-->
<!--              </mat-radio-group>-->
<!--            </div>-->
<!--          </ng-container>-->

<!--          <ng-container *ngIf="!isEmployer()">-->
<!--            <div class="filter-label">-->
<!--              {{'CONTRACTS.PAYMENT_TYPE' | translate}}-->
<!--            </div>-->

<!--            <div class="payment-type">-->
<!--              <div class="checkbox-container">-->
<!--                <mat-checkbox color="primary"-->
<!--                              [formControl]="form.get('fixedProject')">{{'SEARCH.PAYMENT.FIXED' | translate}}</mat-checkbox>-->
<!--              </div>-->
<!--              <div class="checkbox-container">-->
<!--                <mat-checkbox class="hourly-checkbox" color="primary"-->
<!--                              [formControl]="form.get('hourlyProject')">{{'SEARCH.PAYMENT.HOURLY' | translate}}</mat-checkbox>-->
<!--              </div>-->
<!--            </div>-->
<!--          </ng-container>-->

          <div class="filter-label">
            {{'CONTRACTS.SPECIALIST_LEVEL' | translate}}
          </div>
          <div [formGroup]="form.controls.levels" class="specialist-level">
            <div class="checkbox-container">
              <mat-checkbox color="primary"
                            formControlName="beginner">{{'SEARCH.LEVEL.BEGINNER' | translate}}</mat-checkbox>
            </div>
            <div class="checkbox-container">
              <mat-checkbox color="primary"
                            formControlName="intermediate">{{'SEARCH.LEVEL.INTERMEDIATE' | translate}}</mat-checkbox>
            </div>
            <div class="checkbox-container">
              <mat-checkbox color="primary"
                            formControlName="master">{{'SEARCH.LEVEL.MASTER' | translate}}</mat-checkbox>
            </div>
          </div>

          <div class="filter-label">
            {{'CONTRACTS.HOURLY_RATE' | translate}}
          </div>
          <nouislider #hourlyRateSlider
                      [ngModelOptions]="{standalone: true}"
                      [config]="hourlyRateSliderConfig"
                      [(ngModel)]="filterSlider.hourlyRate"
                      (ngModelChange)="rateChange($event)"
                      name="rate">
          </nouislider>
          <div class="slider-inputs">
            <div class="input-wrapper">
              <div class="prefix">{{'CONTRACTS.FROM' | translate}}</div>
              <input type="number" [min]="0" [(ngModel)]="filterSlider.hourlyRate[0]"
                     (ngModelChange)="setRateSliderValue()">
            </div>
            <div class="input-wrapper">
              <div class="prefix">{{'CONTRACTS.TO' | translate}}</div>
              <input type="number" [max]="10000" [(ngModel)]="filterSlider.hourlyRate[1]"
                     (ngModelChange)="setRateSliderValue()">
            </div>
          </div>

          <ng-container *ngIf="!isEmployer()">
            <div class="filter-label">
              {{'CONTRACTS.PROJECT_BUDGET' | translate}}
            </div>
            <nouislider #budgetSlider
                        [ngModelOptions]="{standalone: true}"
                        [config]="budgetSliderConfig"
                        [(ngModel)]="filterSlider.budget"
                        (ngModelChange)="budgetChange($event)"
                        name="rate">
            </nouislider>
            <div class="slider-inputs">
              <div class="input-wrapper">
                <div class="prefix">{{'CONTRACTS.FROM' | translate}}</div>
                <input type="number" [min]="0" [(ngModel)]="filterSlider.budget[0]"
                       (ngModelChange)="setBudgetSliderValue()">
              </div>
              <div class="input-wrapper">
                <div class="prefix">{{'CONTRACTS.TO' | translate}}</div>
                <input type="number" [max]="3000" [(ngModel)]="filterSlider.budget[1]"
                       (ngModelChange)="setBudgetSliderValue()">
              </div>
            </div>
          </ng-container>


          <ng-container *ngIf="isEmployer()">
            <div class="filter-label">
              {{'CONTRACTS.FREELANCER_LOCATION' | translate}}
            </div>
            <tpt-select-search class="custom-select"
                               panelClass="custom-select-search filter"
                               [control]="form.get('countryCode')"
                               valueField="code"
                               [options]="countries"
                               [nameField]="getViewField()"
                               [count]="countriesCount"
                               (searchOptions)="getCountries(false, $event)">
            </tpt-select-search>

            <div class="filter-label">
              {{'CONTRACTS.LANGUAGE_SKILLS' | translate}}
            </div>
            <div class="language-block" *ngFor="let control of languagesForm.controls; index as i">
              <div class="selects">
                <mat-select placeholder="{{'SEARCH.OPTIONS.CHOOSE_LANGUAGE' | translate }}"
                            class="lang-select"
                            [formControl]="control.get('code')">
                  <mat-option *ngFor="let language of languages" [value]="language.code" [disabled]="language.disabled">
                    {{getTranslation(language)}}
                  </mat-option>
                </mat-select>
                <mat-select placeholder="{{'SEARCH.OPTIONS.LANGUAGE_SKILLS' | translate }}"
                            class="lang-select"
                            [formControl]="control.get('level')">
                  <mat-option *ngFor="let level of languageLevels" [value]="level">
                    {{ 'CREATE_PROJECT.STEP3.' + level | translate}}
                  </mat-option>
                </mat-select>
              </div>
              <div class="trash-icon-block" *ngIf="languagesForm.controls?.length !== 1">
                <mat-icon [svgIcon]="svgIconsEnum.Close"
                          (click)="removeLang(i)"
                          class="trash-icon"
                ></mat-icon>
              </div>
            </div>
            <button mat-button (click)="addLang()"
                    *ngIf="languagesForm.controls.length < 10"
                    class="add-lang-btn">+ {{'SEARCH.OPTIONS.ADD_LANGUAGE' | translate}}</button>
          </ng-container>
        </div>
      </div>

    </div>
    <div class="dialog-footer">
      <tpt-button type="secondary"
                  (onClick)="refreshFilters()">{{'CONTRACTS.CLEAR_BUTTON' | translate}}
      </tpt-button>
      <tpt-button type="primary" class="apply" (click)="applyFilters()">
        {{'BUTTON.APPLY' | translate}}
      </tpt-button>
    </div>

  </div>
</tpt-dialog>


