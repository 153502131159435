<tpt-dialog>
  <div class="tpt-failure-dialog-root">
    <mat-icon [svgIcon]="svgIconsEnum.DialogFailureIcon"></mat-icon>

    <div class="failure-phrase">
      {{'SERVER_ERROR.SOMETHING_WENT_WRONG_TITLE' | translate}}
    </div>

    <div class="secondary-phrase">
      {{'SERVER_ERROR.SOMETHING_WENT_WRONG' | translate}}
      <a href="mailto:info@tp.team">info@tp.team</a>
    </div>

    <button mat-button class="close" (click)="close()">
      {{'BUTTON.CLOSE' | translate}}
    </button>
  </div>
</tpt-dialog>
