import {
  ChangeDetectionStrategy,
  Component, ElementRef, Input,
  OnInit,
  Renderer2, ViewChild,
} from '@angular/core';

@Component({
  selector: 'tpt-job-card-progress',
  templateUrl: './job-card-progress.component.html',
  styleUrls: [ './job-card-progress.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class JobCardProgressComponent implements OnInit {

  @Input()
  public total: number;

  @Input()
  public completed: number;

  @ViewChild('progressPercentage', { read: ElementRef, static: true })
  public progressPercentage: ElementRef<HTMLSpanElement>;

  public get progress(): number {
    if (!this.total || !this.completed) {
      return 0;
    }

    return Math.floor(this.completed / this.total * 100);
  }

  constructor(
    private renderer: Renderer2,
  ) { }

  public ngOnInit(): void {
    const width = this.getProgressBarWidth();
    const shift = this.getLeftShiftInPx(width, this.progress);
    this.setLeftPadding(shift);
  }

  private getProgressBarWidth(): number {
    return this.progressPercentage.nativeElement.offsetWidth;
  }

  private getLeftShiftInPx(totalWidth: number, progressPercentage: number): number {
    return totalWidth * progressPercentage / 100;
  }

  private setLeftPadding(shiftInPx: number): void {
    const shiftLabelInPxToBeCentered = 11;

    this.renderer.setStyle(
      this.progressPercentage.nativeElement,
      'padding-left',
      `${shiftInPx - shiftLabelInPxToBeCentered}px`,
    );
  }

}
