import { Component, Input } from '@angular/core';

@Component({
  selector: 'tpt-chip',
  templateUrl: './chip.component.html',
  styleUrls: ['./chip.component.scss']
})
export class ChipComponent {

  @Input() type: 'outline' | 'fill' | 'disabled' = 'outline';
  @Input() size: 'small' | 'medium' = 'small';
}
