<div class="page-root p-32">
  <mat-card class="job-card" *ngIf="project">
    <div class="left">
      <div class="name">{{project?.projectName}}</div>

      <div class="regular-16-24 gray-lighter mb-4">{{'CREATE_PROJECT.PROJECT_DESCRIPTION' | translate}}</div>
      <div class="regular-16-24 description">
        {{project?.description}}
      </div>

        <div class="tasks">
          <div *ngFor="let task of project?.tasks; let idx = index;" class="mb-32">
            <div>
              <div class="regular-16-24 gray-lighter mb-4">
                {{'CONTRACTS.TASK' | translate}} #{{ idx + 1 }}
              </div>
              <div class="task-title mb-4">
                {{ task?.taskTitle }}
              </div>
            </div>

            <div class="regular-16-24 gray-dark mb-12">
              {{ task?.description }}
            </div>
            <div class="files-deadline">
              <div *ngIf="task?.files?.length">
                <div class="regular-16-24 gray-lighter mb-4">Файлы</div>

                <div class="mb-8" *ngFor="let file of task?.files">
                  <tpt-uploaded-file [file]="file" (click)="$event.stopPropagation()"></tpt-uploaded-file>
                </div>
              </div>

              <div *ngIf="task?.deadline">
                <div class="regular-16-24 gray-lighter mb-4">{{'FINANCE.COLUMN.DEADLINE' | translate}}</div>
                {{task?.deadline | localDate : 'dd.MM.yy'}}
              </div>
            </div>
          </div>
        </div>
        <div class="requirements">
          <div>
            <div>
              <div class="regular-16-24 gray-lighter mb-4">Уровень специалиста</div>
              <div *ngFor="let level of project?.expertLevels; let lst = last;"
                   class="regular-16-24 mb-8">
                {{ 'SEARCH.LEVEL.' + level | translate}}{{ lst ? '' : ',' }}
              </div>
              <div *ngIf="!project?.expertLevels?.length">-</div>
            </div>

            <div class="mt-20">
              <div class="regular-16-24 gray-lighter mb-4">{{'CONTRACTS.LANGUAGE_SKILLS' | translate}}</div>
              <div class="regular-16-24">
                <div *ngFor="let language of project?.languages" class="mb-2 d-flex">
                  <div class="regular-16-24">{{getLangByCode(language)}}</div>
                  <div class="mx-8">-</div>
                  <span class="level" *ngIf="language?.level">{{'CREATE_PROJECT.STEP3.' + language.level | translate}}</span>
                </div>
                <div *ngIf="!project?.languages?.length">-</div>
              </div>
            </div>

            <div class="mt-20">
              <div class="regular-16-24 gray-lighter mb-4">{{'CONTRACTS.FREELANCER_LOCATION' | translate}}</div>
              <div class="regular-16-24">
                {{getTranslation(project?.country)}}
                <div *ngIf="!project.country || !project.country.name">{{'SEARCH.ANY' | translate}}</div>
              </div>
            </div>
          </div>

          <div class="skills-wrap">
            <div class="pseudo-chips" *ngFor="let skill of specSkills(project)">
              {{getTranslation(skill) || skill}}
            </div>
            <div *ngIf="project.moreSkillsCount" class="more-skills" (click)="showAllSkills(project)">
              +{{project.moreSkillsCount}}
            </div>
          </div>
        </div>

        <div class="budget">
          <div class="budget-info">
            <div class="budget-row total-amount">
              <div >Бюджет контракта</div>
              <div>{{currencySymbol.RUB}}{{project?.budget | number}}</div>
            </div>
          </div>
        </div>
    </div>

    <div class="right">
      <div class="owner-wrapper">
        <div class="owner mb-20">
          <div class="owner-info mr-12">
            <div *ngIf="project.employer?.firstName || project.employer?.lastName" class="owner-fullname">
              {{project.employer?.firstName}} {{project.employer?.lastName}}
            </div>
            <button class="about-button"
                    (click)="openEmployerInfo(project?.employer)"
            >{{'SEARCH.ABOUT_EMPLOYER' | translate}}</button>
          </div>
          <div class="avatar">
            <tpt-avatar [user]="project?.employer" [textSizeRatio]="2.5"
                        size="32"></tpt-avatar>
          </div>
        </div>
      </div>

      <div class="regular-16-24 gray-lighter mb-4">{{'CONTRACTS.CATEGORY' | translate}}</div>
      <div class="regular-16-24 gray mb-20" *ngIf="project?.parentCategoryId">{{getProjectCategory(project)}}
        <span *ngIf="project?.subCategoryId">
          <span class="gray-lighter">/</span>
          {{getProjectSubCategory(project)}}</span>
      </div>


      <div class="regular-16-24 gray-lighter mb-4">
        {{'CONTRACTS.PUBLICATION_DATE' | translate}}
      </div>

      <div class="regular-16-24 gray mb-20" *ngIf="project.publishDate">
        {{project.publishDate | localDate: 'dd.MM.yyyy'}}
      </div>

      <div class="mb-32" *ngIf="project?.files?.length">
        <div class="regular-16-24 gray-lighter mb-4">
          Файлы
        </div>

        <tpt-uploaded-file
          class="mb-4"
          *ngFor="let file of project.files"
          [file]="file"
        ></tpt-uploaded-file>
      </div>

    </div>
  </mat-card>

</div>

<tpt-employer-info-dialog></tpt-employer-info-dialog>
