<div
  *ngIf="profileService.currentProfile"
  class="sidebar"
  [class.-extended]="extendedSidebar"
>
  <div
    class="sidebar-main"
    [class.-expanded]="expanded"
  >
    <div class="logo-and-menu">
      <mat-icon
        class="logo"
        [class.-hidden]="!expanded"
        [svgIcon]="svgIconsEnum.FooterLogo"
        [routerLink]="'/landing'"
      ></mat-icon>
      <mat-icon
        class="logo"
        [class.-hidden]="expanded"
        [svgIcon]="svgIconsEnum.HeaderLogo"
        [routerLink]="'/landing'"
      ></mat-icon>

      <div class="menu">
        <a [routerLink]="isEmployer ? routeHelper.freelancerSearch : routeHelper.projectSearch">
          <div
            class="menu-item"
            [class.active-link]="searchIsActive"
          >
            <mat-icon
              [svgIcon]="svgIconsEnum.SidebarHome"
              routerLinkActive="active-link"
            ></mat-icon>
            <span class="item-text">
            <span class="tab-name">{{'SIDEBAR.HOME' | translate}}</span>
          </span>
          </div>
        </a>
        <a [routerLink]="projectsRoute">
          <div
            class="menu-item"
            routerLinkActive="active-link"
          >
            <mat-icon
              [svgIcon]="svgIconsEnum.SidebarProjects"
            ></mat-icon>
            <span class="item-text">
            <span class="tab-name">{{'SIDEBAR.MY_PROJECTS' | translate}}</span>
          </span>
          </div>
        </a>
        <a [routerLink]="routeHelper.finances">
          <div
            class="menu-item"
            routerLinkActive="active-link"
          >
            <mat-icon
              [svgIcon]="svgIconsEnum.SidebarFinances"
            ></mat-icon>
            <span class="item-text">
            <span class="tab-name">{{'SIDEBAR.FINANCE' | translate}}</span>
          </span>
          </div>
        </a>
        <a [routerLink]="routeHelper.activeExecutorsPage" *ngIf="isEmployer">
          <div
            class="menu-item"
            routerLinkActive="active-link"
          >
            <mat-icon
              [svgIcon]="svgIconsEnum.SidebarContracts"
            ></mat-icon>
            <span class="item-text">
            <span class="tab-name wrap">Мои исполнители</span>
          </span>
          </div>
        </a>
        <a [routerLink]="routeHelper.clientsPage" *ngIf="!isEmployer">
          <div
            class="menu-item"
            routerLinkActive="active-link"
          >
            <mat-icon
              [svgIcon]="svgIconsEnum.SidebarContracts"
            ></mat-icon>
            <span class="item-text">
            <span class="tab-name wrap">Мои заказчики</span>
          </span>
          </div>
        </a>
        <a [routerLink]="routeHelper.contracts">
          <div
            class="menu-item message"
            routerLinkActive="active-link"
          >
            <div class="menu-item">
              <mat-icon matBadge="{{unreadCount > 99 ? '99+' : unreadCount}}"
                        [matBadgeHidden]="expanded || !unreadCount" matBadgeColor="primary"
                        [svgIcon]="svgIconsEnum.SidebarMessages"
              ></mat-icon>
              <span class="item-text">
              <span class="tab-name">{{'SIDEBAR.MESSAGES' | translate}}</span>
              <div *ngIf="unreadCount && expanded" class="unread-messages">{{unreadCount > 99 ? '99+' : unreadCount}}</div>
            </span>
              <div
                *ngIf="profileService.onBoardingInfo.launched"
              ></div>
            </div>
          </div>
        </a>
        <a [routerLink]="routeHelper.settings">
          <div
            class="menu-item"
            routerLinkActive="active-link"
          >
            <mat-icon
              [svgIcon]="svgIconsEnum.SidebarSettings"
            ></mat-icon>
            <span class="item-text">
              <span class="tab-name">{{'SIDEBAR.SETTINGS' | translate}}</span>
            </span>
          </div>
        </a>
        <a [routerLink]="routeHelper.fnsAttachPage" *ngIf="!isEmployer">
          <div
            class="menu-item"
            routerLinkActive="active-link"
          >
            <mat-icon
              [svgIcon]="svgIconsEnum.SidebarFns"
            ></mat-icon>
            <span class="item-text">
              <span class="tab-name fns">Привязка к ФНС</span>
            </span>
          </div>
        </a>
      </div>
    </div>

    <div
      *ngIf="!extendedSidebar"
      class="toggle-sidebar-button"
      (click)="toggleSidebar()"
    >
      <mat-icon [svgIcon]="svgIconsEnum.SidebarArrow"></mat-icon>
    </div>
  </div>

  <div *ngIf="extendedSidebar" class="sidebar-extended">
    <ng-template [cdkPortalOutlet]="sidebarPortal"></ng-template>
  </div>
</div>
