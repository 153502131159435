import { Injectable, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { BehaviorSubject, lastValueFrom, Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { SocialAuthService } from '@abacritt/angularx-social-login';

import { deserializationJacksonKey } from '../classes/jacksonUtils';
import { LogoutService } from './logout.service';
import { NewWsService } from './new-ws.service';
import { ProfilesApiService } from './profiles.api.service';
import { ProfileModel } from '../models/profile.model';
import { SpinnerService } from '../modules/tpt-ui/services/spinner.service';
import { AuthService } from './auth.service';
import { ToastrService } from 'ngx-toastr';

const TOKEN_KEY = 'auth-token';
const EXPIRES_KEY = 'expires_at';
const REFRESHTOKEN_KEY = 'auth-refreshtoken';
const USER_KEY = 'auth-user';

@Injectable({
  providedIn: 'root',
})

export class ProfileService {

  public startOnBoardingSubject = new Subject<void>();

  public switchToChats = new Subject<void>();

  public openInfo = new Subject<void>();

  public mainOnBoardingViewed = new BehaviorSubject<boolean>(true);

  public onBoardingInfo$ = new BehaviorSubject<any>(null);

  public service: string;

  public onBoardingInfo = {
    isIncoming: false,
    isOutgoing: false,
    chatId: null,
    launched: false
  };

  get currentProfile(): ProfileModel {
    return this._currentProfile;
  }

  set currentProfile(value: ProfileModel) {
    this._currentProfile = value;
    this.currentProfile$.next(value);
  }

  public newProfileObject = new BehaviorSubject<ProfileModel>(null);

  public get isLoggedIn(): boolean {
    return Boolean(localStorage.getItem(TOKEN_KEY));
  }

  public currentProfile$: BehaviorSubject<ProfileModel> = new BehaviorSubject<ProfileModel>(this.currentProfile);

  @Input() public _currentProfile: ProfileModel;

  constructor(public profileApiService: ProfilesApiService,
              private webSocket: NewWsService,
              private router: Router,
              private dialog: MatDialog,
              private translate: TranslateService,
              private auth: AuthService,
              private socialAuthService: SocialAuthService,
              private toast: ToastrService,
              private spinner: SpinnerService) {
    LogoutService.registerService(this);

    const user: ProfileModel = JSON.parse(localStorage.getItem(USER_KEY));
    this.currentProfile = deserializationJacksonKey(ProfileModel, user);
  }

  public async refreshCurrentProfile() {
    const token = localStorage.getItem(TOKEN_KEY);

    if (!token) {
      return;
    }

    await this.getUserInfo();
    this.afterLogin();
  }

  public afterLogin(): void {
    if (this.currentProfile) {
      this.webSocket.updateUnreadCount.next();
    }

    this.webSocket.connect();
  }

  async getUserInfo(): Promise<void> {
    try {
      const user = await lastValueFrom(this.profileApiService.getUserProfile());
      this.currentProfile = deserializationJacksonKey(ProfileModel, user);
      localStorage.setItem(USER_KEY, JSON.stringify(user));

      this.newProfileObject.next(user);

      // if (['en', 'ru'].includes(user.interfaceLanguage)) {
      //   this.translate.use(user.interfaceLanguage);
      // } else {
      //   this.translate.use(localStorage.getItem('currentLang') || Cookies.get('lang') || 'en');
      // }
      this.translate.use('ru');
    } catch (err) {
      console.log(err);
    }
  }

  public logout(retURL?: boolean) {
    this.webSocket.disconnect();
    this.currentProfile = null;
    this.newProfileObject.next(null);
    this.auth.deleteRefreshToken().subscribe();
    this.socialAuthService.signOut().catch(() => {});
    localStorage.removeItem(TOKEN_KEY);
    localStorage.removeItem(REFRESHTOKEN_KEY);
    localStorage.removeItem(USER_KEY);
    localStorage.removeItem(EXPIRES_KEY);
    localStorage.removeItem('login_flow');
    localStorage.removeItem('at');
    this.toast.clear();
    this.dialog.closeAll();
    this.service = window.location.href.includes('creative-objects') ? 'CREATIVE_OBJECTS' : 'TALENT_CLOUD';
    this.router.navigate(this.service === 'CREATIVE_OBJECTS' ? ['/creative-objects'] : ['/login'] );
    this.spinner.stopSpinner();
  }
}
