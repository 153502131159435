<mat-chip-list
    #chipList
    class="chips-autocomplete"
    [ngClass]="(chipsBelow ? 'chips-autocomplete_below ' : 'chips-autocomplete_above ') + cssChipsClass"
>
    <ng-container *ngIf="!chipsBelow" [ngTemplateOutlet]="chips"></ng-container>
    <mat-form-field class="chips-autocomplete__field" floatLabel="always" [appearance]="appearance">
        <mat-label></mat-label>
        <input
            matInput
            class="chips-autocomplete__input"
            name="chips-autocomplete"
            [placeholder]="title"
            #itemInput
            [formControl]="itemCtrl"
            [matAutocomplete]="auto"
            [matChipInputFor]="chipList"
            [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
            [matChipInputAddOnBlur]="false"
            (matChipInputTokenEnd)="addItem($event)"
            maxlength="64"
        />
      <span class="enter-tip" *ngIf="showTip">
        Enter
        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M9.5 3.5V5.5H2.915L4.705 3.705L4 3L1 6L4 9L4.705 8.295L2.915 6.5H10.5V3.5H9.5Z" fill="#434C58"/>
        </svg>
      </span>
    </mat-form-field>
    <ng-container *ngIf="chipsBelow" [ngTemplateOutlet]="chips"></ng-container>
</mat-chip-list>
<mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
    <mat-option *ngFor="let item of filteredItems" [value]="item">
        {{ item[viewField] || item | translate }}
    </mat-option>
</mat-autocomplete>

<ng-template #chips>
    <mat-chip
        *ngFor="let item of selectedItems, let i = index"
        [selectable]="false"
        [removable]="true"
        (removed)="removeItem(item, i)"
        [ngClass]="{ blue: blue }"
        class="chips-autocomplete__list-item"
    >
        <span>{{ (item[viewField] || item) | translate }}</span>
        <mat-icon matChipRemove class="chips-autocomplete__list-icon">clear</mat-icon>
    </mat-chip>
</ng-template>
