<tpt-dialog>
  <div class="wrapper">
    <mat-icon [svgIcon]="svgIconsEnum.ErrIcon" class="mb-28"></mat-icon>
    <div class="heading mb-16">
      Ограниченный доступ
    </div>

    <div class="regular-14-20 text">
      {{isInvite ? 'Заполните личный кабинет для отправки приглашения на проект' : 'Для публикации проекта необходимо заполнить профиль.'}}
    </div>
    <div class="actions-row mt-48">
      <tpt-button type="secondary" size="large" (onClick)="close()">
        Закрыть
      </tpt-button>
      <tpt-button type="primary" size="large" class="ml-12"
                  (onClick)="fillProfile()"
                  [routerLink]="routeHelper.settingsLegalData">
        Заполнить
      </tpt-button>
    </div>
  </div>
</tpt-dialog>
