import { Injectable } from '@angular/core';
import { ProfileModel } from '../models/profile.model';
import { Role } from '../classes/enums';
import { EmployerLegalStatusEnum } from '../models/legalStatuses.enum';
import { AddressForm } from '../modules/edit-profile/forms/address.form';
import { SignerForm } from '../modules/edit-profile/forms/signer.form';
import { PassportForm } from '../modules/edit-profile/forms/passport.form';
import { ForeignAgencyRequisitesForm } from '../modules/edit-profile/forms/foreign-agency-requisites.form';
import { LegalEntityModel } from '../models/userLegalStatus.model';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

@Injectable({
  providedIn: 'root',
})
export class ProfileFilledService {

  public static clientGeneralInfoValid(profile: ProfileModel): boolean {
    return !!profile.firstName && !!profile.lastName && !!profile.phone;
  }

  public static clientDataInvalid(profile: ProfileModel, legalInfo): boolean {
    return profile.roles.includes(Role.EMPLOYER) && (!this.bankDetailsValid(profile) ||
      !this.clientGeneralInfoValid(profile) || !this.clientLegalDataValid(legalInfo));
  }

  public static bankDetailsValid(profile: ProfileModel): boolean {
    return profile.bankDetailsValidated;
  }

  public static clientLegalDataValid(legalInfo): boolean {
    switch (legalInfo.legalStatus) {
      case EmployerLegalStatusEnum.INDIVIDUAL_ENTREPRENEUR: {
        const iePersonForm = ProfileFilledService.ieRequisites(legalInfo);
        iePersonForm.markAllAsTouched();

        const addressForm = AddressForm.createForm(legalInfo);
        return this.validateIeForm(iePersonForm) && this.validateAddressForm(addressForm);
      }

      case EmployerLegalStatusEnum.LEGAL_ENTITY: {
        const signerForm = SignerForm.createForm(legalInfo);
        signerForm.validate();

        const addressForm = AddressForm.createForm(legalInfo);
        addressForm.validate();

        const requisitesForm = ProfileFilledService.requisitesForm(legalInfo);
        requisitesForm.markAllAsTouched();

        return this.validateSignerForm(signerForm) && this.validateAddressForm(addressForm) &&
          !requisitesForm.invalid;
      }

      case EmployerLegalStatusEnum.INDIVIDUAL: {
        const passportForm = PassportForm.createForm(legalInfo?.passport, [Role.EMPLOYER]);
        passportForm.validate();

        const addressForm = AddressForm.createForm(legalInfo);
        addressForm.validate();

        return !passportForm.invalid && this.validateAddressForm(addressForm);
      }
      case EmployerLegalStatusEnum.FOREIGN_AGENCY: {
        const faRequisitesForm = ForeignAgencyRequisitesForm.createForm(legalInfo);
        const signerForm = SignerForm.createForm(legalInfo);
        const addressForm = AddressForm.createForm(legalInfo);
        return this.validateSignerForm(signerForm) && this.validateAddressForm(addressForm) &&
          !faRequisitesForm.invalid;
      }
    }
    return true;
  }

  private static validateSignerForm(signerForm): boolean {
    if (!signerForm.headIsSigner.value) {
      signerForm.validate();
      if (signerForm.invalid) {
        return false;
      }
    } else {
      signerForm.head.markAllAsTouched();
      if (signerForm.head.invalid) {
        return false;
      }
    }
    return true;
  }

  private static validateAddressForm(addressForm): boolean {
    if (!addressForm.actualAddressEqualLegal.value) {
      addressForm.validate();
      if (addressForm.invalid) {
        return false;
      }
    } else {
      addressForm.legalAddress.markAllAsTouched();
      if (addressForm.legalAddress.invalid) {
        return false;
      }
    }
    return true;
  }

  private static validateIeForm(ieForm: UntypedFormGroup): boolean {
    const value = ieForm.value;

    if (!value.headIsSigner) {
      ieForm.markAllAsTouched();
      if (ieForm.invalid) {
        return false;
      }
    } else {
      ieForm.markAllAsTouched();
      if (this.validateForm(ieForm)) {
        return false;
      }
    }
    return true;
  }

  private static validateForm(ieForm): boolean {
    const fields = ['firstName', 'lastName', 'phone', 'email', 'ogrn', 'inn', 'ieRegistrationCertificate', 'fileIds', 'taxInvoiceWayToGet'];
    return fields.some(item => ieForm.controls[item]?.invalid);
  }

  private static requisitesForm(legalEntity?: LegalEntityModel): UntypedFormGroup {
    return new UntypedFormGroup({
      companyName: new UntypedFormControl(legalEntity.companyName || '', [Validators.required]),
      fullCompanyName: new UntypedFormControl(legalEntity.fullCompanyName || '', [Validators.required]),
      inn: new UntypedFormControl(legalEntity.inn || '', [Validators.required]),
      ogrn: new UntypedFormControl(legalEntity.ogrn || '', [Validators.required]),
      kpp: new UntypedFormControl(legalEntity.kpp || '', [Validators.required]),
      phone: new UntypedFormControl(legalEntity.phone, [Validators.required]),
      email: new UntypedFormControl(legalEntity.email, [Validators.required]),
      taxInvoiceWayToGet: new UntypedFormControl(legalEntity?.taxInvoiceWayToGet || '', [Validators.required]),
      postAddressType: new UntypedFormControl('SEPARATE'),
      postalAddress: new UntypedFormGroup({
        flatAddress: new UntypedFormControl(legalEntity?.postalAddress?.flatAddress || '',
          legalEntity?.taxInvoiceWayToGet === 'RUSSIAN_POST' ? [Validators.required] : []),
      }),
    });
  }

  private static ieRequisites(legalModel): UntypedFormGroup {
    const legalStatus = legalModel.legalStatus;

    return new UntypedFormGroup({
      firstName: new UntypedFormControl(legalModel?.head?.firstName || '', [Validators.required]),
      lastName: new UntypedFormControl(legalModel?.head?.lastName || '', [Validators.required]),
      patronymic: new UntypedFormControl(legalModel?.head?.patronymic || '', []),
      ieRegistrationCertificate: new UntypedFormControl(legalModel?.head?.ieRegistrationCertificate, [Validators.required]),
      phone: new UntypedFormControl(legalModel?.head?.phone, [Validators.required]),
      email: new UntypedFormControl(legalModel?.head?.email, [Validators.required]),
      ogrn: new UntypedFormControl(legalModel?.head?.ogrn, legalStatus === 'INDIVIDUAL_ENTREPRENEUR' ? [Validators.required] : []),
      inn: new UntypedFormControl(legalModel?.head?.inn, legalStatus === 'INDIVIDUAL_ENTREPRENEUR' ? [Validators.required] : []),
      fileIds: new UntypedFormControl(legalModel?.head?.files, [Validators.required]),
      position: new UntypedFormControl(legalModel?.head?.position, []),
      signer: new UntypedFormGroup({
        firstName: new UntypedFormControl(legalModel?.head?.signer?.firstName || '', [Validators.required]),
        lastName: new UntypedFormControl(legalModel?.head?.signer?.lastName || '', [Validators.required]),
        patronymic: new UntypedFormControl(legalModel?.signer?.head?.patronymic || '', []),
        document: new UntypedFormControl(legalModel?.head?.signer?.document, [Validators.required]),
        phone: new UntypedFormControl(legalModel?.head?.signer?.phone, [Validators.required]),
        email: new UntypedFormControl(legalModel?.head?.signer?.email, [Validators.required]),
        fileIds: new UntypedFormControl(legalModel?.head?.signer?.files, [Validators.required]),
        position: new UntypedFormControl(legalModel?.head?.signer?.position)
      }),
      headIsSigner: new UntypedFormControl(legalModel?.head?.headIsSigner || false),
      taxInvoiceWayToGet: new UntypedFormControl(legalModel?.head?.taxInvoiceWayToGet || '', [Validators.required]),
      postAddressType: new UntypedFormControl('SEPARATE'),
      postalAddress: new UntypedFormGroup({
        flatAddress: new UntypedFormControl(legalModel?.head?.postalAddress?.flatAddress || '',
          legalModel?.head?.taxInvoiceWayToGet === 'RUSSIAN_POST' ? [Validators.required] : []),
      }),
    });
  }

}
