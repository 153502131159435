import { Component, ViewChild } from '@angular/core';
import { MatDialogConfig } from '@angular/material/dialog';
import { Router } from '@angular/router';

import { DialogComponent } from '../../../dialog/components/dialog/dialog.component';
import { SvgIconsEnum } from '../../../../types/svg-icons.enum';
import { ProfileService } from '../../../../services/profile.service';
import { RouteHelper } from '../../../../helpers/route.helper';
import { DialogService } from '../../../../services/dialog.service';
import { ChatApiService } from '../../../../services/chat.api.service';

@Component({
  selector: 'tpt-help-center',
  templateUrl: './help-center.component.html',
  styleUrls: ['./help-center.component.scss']
})
export class HelpCenterComponent {

  @ViewChild(DialogComponent)
  public dialog: DialogComponent;

  public svgIconsEnum = SvgIconsEnum;

  public step = 1;

  private className = 'tpt-help-center';

  private config: MatDialogConfig = {
    position: { top: '54px', right: '0px' },
    width: '420px',
    height: 'calc(100% - 55px)',
    hasBackdrop: false,
    maxWidth: '100vw',
    minWidth: '375px',
    panelClass: 'help-center-dialog'
  };

  constructor(private profileService: ProfileService,
              private chatService: ChatApiService,
              private dialogService: DialogService,
              private router: Router,
              private routeHelper: RouteHelper) {
  }

  public open(): void {
    this.dialog.config = this.config;

    if (this.dialog.dialogRef) {
      this.close();
      return;
    }

    if (this.dialogService.helpDialogSubject.value) {
      return;
    }

    this.dialog.open(this.className);
    this.dialogService.helpDialogSubject.next(true);
  }

  public close(): void {
    this.dialog.close();
    this.dialogService.helpDialogSubject.next(false);
  }

  public openOnBoardingsStep(): void {
    this.step = 2;
  }

  public createChatWithSupport(): void {
    this.chatService.createSupportChat().subscribe((chat) => {
      this.router.navigate(this.routeHelper.contractsChatsUrl(chat.id));
      this.close();
    }, () => {});
  }
}
