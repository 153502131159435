import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { TptUiModule } from '../tpt-ui/tpt-ui.module';
import { TptFormsModule } from '../tpt-forms/tpt-forms.module';
import { SuccessDialogComponent } from './components/success-dialog/success-dialog.component';
import { FailureDialogComponent } from './components/failure-dialog/failure-dialog.component';
import { WithdrawFundsDialogComponent } from './components/withdraw-funds-dialog/withdraw-funds-dialog.component';
import { SmthWentWrongComponent } from './components/smth-went-wrong/smth-went-wrong.component';
import { CommonPipesModule } from '../common-pipes/common-pipes.module';
import { UserRolesDialogComponent } from './components/user-roles-dialog/user-roles-dialog.component';
import { AvatarModule } from 'ngx-avatars';
import { MatRippleModule } from '@angular/material/core';
import { MatDividerModule } from '@angular/material/divider';
import { MatListModule } from '@angular/material/list';
import { MatRadioModule } from '@angular/material/radio';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTabsModule } from '@angular/material/tabs';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RoleRightsDialogComponent } from './components/role-rights-dialog/role-rights-dialog.component';
import { FreelancerInfoDialogComponent } from './components/info-dialogs/freelancer-info-dialog/freelancer-info-dialog.component';
import { EmployerInfoDialogComponent } from './components/info-dialogs/employer-info-dialog/employer-info-dialog.component';
import { NewChatDialogComponent } from './components/new-chat-dialog/new-chat-dialog.component';
import { ChatInfoDialogComponent } from './components/chat-info-dialog/chat-info-dialog.component';
import { ChatEditDialogComponent } from './components/chat-edit-dialog/chat-edit-dialog.component';
import { AddParticipantsComponent } from './components/chat-edit-dialog/add-participants/add-participants.component';
import { ConfirmDestructionDialogComponent } from './components/confirm-destruction-dialog/confirm-destruction-dialog.component';
import { RatingMetricsDialogComponent } from './components/rating-metrics-dialog/rating-metrics-dialog.component';
import { ReviewCardComponent } from './components/review-card/review-card.component';
import { ContactUsMobileComponent } from './contact-us-mobile/contact-us-mobile.component';
import { FilterProposalsDialogComponent } from './components/filter-proposals-dialog/filter-proposals-dialog.component';
import { ChipsAutocompleteModule } from '../chips-autocomplete/chips-autocomplete.module';
import { NouisliderModule } from 'ng2-nouislider';
import { LegalStatusDialogComponent } from './components/legal-status-dialog/legal-status-dialog.component';
import { SelfEmployedWarningDialogComponent } from './components/self-employed-warning-dialog/self-employed-warning-dialog.component';
import { PaymentDocsDialogComponent } from './components/payment-docs-dialog/payment-docs-dialog.component';
import { ConfirmManualPaymentDialogComponent } from './components/confirm-manual-payment-dialog/confirm-manual-payment-dialog.component';
import { OpenDisputeDialogComponent } from './components/open-dispute-dialog/open-dispute-dialog.component';
import { ChallengeDecisionDialogComponent } from './components/challenge-decision-dialog/challenge-decision-dialog.component';
import { AuthErrorDialogComponent } from './components/auth-error-dialog/auth-error-dialog.component';
import { SuccessActionDialogComponent } from './components/success-action-dialog/success-action-dialog.component';
import { EmailChangeDialogComponent } from './components/email-change-dialog/email-change-dialog.component';
import { HelpCenterComponent } from './components/help-center/help-center.component';
import { MatCardModule } from '@angular/material/card';
import { LegalInfoDialogComponent } from './components/legal-info-dialog/legal-info-dialog.component';
import { GetAcquaintedDialogComponent } from './components/get-acquainted-dialog/get-acquainted-dialog.component';
import { PayBillDialogComponent } from './components/pay-bill-dialog/pay-bill-dialog.component';
import { ReceiptDialogComponent } from './components/receipt-dialog/receipt-dialog.component';
import { CancelReceiptDialogComponent } from './components/cancel-receipt-dialog/cancel-receipt-dialog.component';
import { ContractSignDialogComponent } from './components/contract-sign-dialog/contract-sign-dialog.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { PaySbpDialogComponent } from './components/pay-sbp-dialog/pay-sbp-dialog.component';
import { JobApplyErrorDialogComponent } from './components/job-apply-error-dialog/job-apply-error-dialog.component';
import { JobInviteErrorDialogComponent } from './components/job-invite-error-dialog/job-invite-error-dialog.component';
import { ChatsDialogComponent } from './components/chats-dialog/chats-dialog.component';
import { SignupErrorDialogComponent } from './components/signup-error-dialog/signup-error-dialog.component';
import { SelectContractDialogComponent } from './components/select-contract-dialog/select-contract-dialog.component';
import { PhotoCropDialogComponent } from './components/photo-crop-dialog/photo-crop-dialog.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { FinishJobDialogComponent } from './components/finish-job-dialog/finish-job-dialog.component';
import { MatSliderModule } from '@angular/material/slider';
import { WarningDialogComponent } from './components/warning-dialog/warning-dialog.component';
import { BalanceTopUpDialogComponent } from './components/balance-top-up-dialog/balance-top-up-dialog.component';

@NgModule({
  imports: [
    CommonModule,
    TptUiModule,
    TptFormsModule,
    RouterModule,
    TranslateModule,
    CommonPipesModule,
    AvatarModule,
    MatDividerModule,
    MatListModule,
    FormsModule,
    ReactiveFormsModule,
    MatTabsModule,
    ChipsAutocompleteModule,
    MatSlideToggleModule,
    NouisliderModule,
    MatRadioModule,
    MatRippleModule,
    MatCardModule,
    PdfViewerModule,
    ImageCropperModule,
    MatSliderModule,
  ],
  declarations: [
    WithdrawFundsDialogComponent,
    SuccessDialogComponent,
    FailureDialogComponent,
    SmthWentWrongComponent,
    UserRolesDialogComponent,
    RoleRightsDialogComponent,
    FreelancerInfoDialogComponent,
    EmployerInfoDialogComponent,
    NewChatDialogComponent,
    ChatInfoDialogComponent,
    ChatEditDialogComponent,
    AddParticipantsComponent,
    ConfirmDestructionDialogComponent,
    RatingMetricsDialogComponent,
    ReviewCardComponent,
    ContactUsMobileComponent,
    FilterProposalsDialogComponent,
    LegalStatusDialogComponent,
    SelfEmployedWarningDialogComponent,
    PaymentDocsDialogComponent,
    ConfirmManualPaymentDialogComponent,
    OpenDisputeDialogComponent,
    ChallengeDecisionDialogComponent,
    AuthErrorDialogComponent,
    SuccessActionDialogComponent,
    EmailChangeDialogComponent,
    HelpCenterComponent,
    LegalInfoDialogComponent,
    GetAcquaintedDialogComponent,
    PayBillDialogComponent,
    ReceiptDialogComponent,
    CancelReceiptDialogComponent,
    ContractSignDialogComponent,
    PaySbpDialogComponent,
    JobApplyErrorDialogComponent,
    JobInviteErrorDialogComponent,
    ChatsDialogComponent,
    SignupErrorDialogComponent,
    SelectContractDialogComponent,
    PhotoCropDialogComponent,
    FinishJobDialogComponent,
    WarningDialogComponent,
    BalanceTopUpDialogComponent,
  ],
  exports: [
    WithdrawFundsDialogComponent,
    SuccessDialogComponent,
    FailureDialogComponent,
    UserRolesDialogComponent,
    RoleRightsDialogComponent,
    FreelancerInfoDialogComponent,
    EmployerInfoDialogComponent,
    NewChatDialogComponent,
    ChatInfoDialogComponent,
    ChatEditDialogComponent,
    ConfirmDestructionDialogComponent,
    RatingMetricsDialogComponent,
    ContactUsMobileComponent,
    ReviewCardComponent,
    FilterProposalsDialogComponent,
    LegalStatusDialogComponent,
    SelfEmployedWarningDialogComponent,
    PaymentDocsDialogComponent,
    ConfirmManualPaymentDialogComponent,
    OpenDisputeDialogComponent,
    ChallengeDecisionDialogComponent,
    AuthErrorDialogComponent,
    SuccessActionDialogComponent,
    EmailChangeDialogComponent,
    HelpCenterComponent,
    LegalInfoDialogComponent,
    GetAcquaintedDialogComponent,
    PayBillDialogComponent,
    ReceiptDialogComponent,
    ContractSignDialogComponent,
    CancelReceiptDialogComponent,
    PaySbpDialogComponent,
    JobApplyErrorDialogComponent,
    JobInviteErrorDialogComponent,
    ChatsDialogComponent,
    SignupErrorDialogComponent,
    PhotoCropDialogComponent,
    SelectContractDialogComponent,
    FinishJobDialogComponent,
    WarningDialogComponent,
    BalanceTopUpDialogComponent,
  ],
})
export class CommonDialogsModule {

}
