import { MilestoneModel } from './milestone.model';
import { CurrencyModel } from '../../../models/financev2/currency.model';

export enum PaymentStatesEnum {
  INIT = 'INIT',
  AWAITING_CONTRACT = 'AWAITING_CONTRACT',
  AWAITING_EMPLOYER_CONTRACT_SIGN = 'AWAITING_EMPLOYER_CONTRACT_SIGN',
  AWAITING_FREELANCER_CONTRACT_SIGN = 'AWAITING_FREELANCER_CONTRACT_SIGN',
  CONTRACT_SIGN_EXPIRED = 'CONTRACT_SIGN_EXPIRED',
  AWAITING_INVOICE = 'AWAITING_INVOICE',
  AWAITING_PAYMENT = 'AWAITING_PAYMENT',
  PAYMENT_VERIFICATION = 'PAYMENT_VERIFICATION',
  PAYMENT_VERIFIED = 'PAYMENT_VERIFIED',
  PAYMENT_EXPIRED = 'PAYMENT_EXPIRED',
  AWAITING_CLOSING_DOCS = 'AWAITING_CLOSING_DOCS',
  READY_TO_FINISH_AND_SIGN = 'READY_TO_FINISH_AND_SIGN',
  AWAITING_FREELANCER_WCC_SIGN = 'AWAITING_FREELANCER_WCC_SIGN',
  CLOSED = 'CLOSED'
}

export class SimpleProjectFixedPaymentModel {

  public paymentMethod: 'HOURLY' | 'FIXED' = 'FIXED';

  public reviewPeriod: number;

  public arbitragePaid: boolean;

  public tillDate: string;

  public deadline: string;

  public milestones: Array<MilestoneModel>;

  public budget: number;

  public budgetWithCommission: number;

  public commissionValue: number;

  public commission: number;

  public arbitrageValue: number;

  public budgetWithCommissionAndArbitrage: number;

  public currency: CurrencyModel;

  public paymentState: PaymentStatesEnum;
}
