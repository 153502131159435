<tpt-dialog>
  <div class="content">
    <div class="heading mb-32">{{'CONTRACTS.ALL_ATTACHMENTS' | translate}} </div>
    <div class="files-list" *ngIf="files.length; else empty">
      <emp-attached-file-item
        *ngFor="let file of files"
        [file]="file"
      ></emp-attached-file-item>
    </div>

    <ng-template #empty>
      <div class="no-files-wrapper">
        <img alt="no-files" src="assets/images/no-files.png"/>
        <div class="headline mb-8">Нет вложений</div>
        <div class="regular-14-20">Все отправленные в чате файлы будут отображаться здесь</div>
      </div>
    </ng-template>
  </div>
</tpt-dialog>
