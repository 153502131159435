import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { DialogComponent } from '../../../dialog/components/dialog/dialog.component';
import { MatDialogConfig } from '@angular/material/dialog';

@Component({
  selector: 'tpt-confirm-manual-payment-dialog',
  templateUrl: './confirm-manual-payment-dialog.component.html',
  styleUrls: ['./confirm-manual-payment-dialog.component.scss']
})
export class ConfirmManualPaymentDialogComponent {

  @Output()
  public readonly actionApprove = new EventEmitter<string>();

  @ViewChild(DialogComponent)
  public dialog: DialogComponent;

  private readonly className = 'tpt-confirm-manual-payment-dialog';

  private config: MatDialogConfig = {
    width: '520px',
  };

  constructor() { }

  public open(): void {
    this.dialog.config = this.config;
    this.dialog.open(this.className);
  }

  public close(): void {
    this.dialog.close();
  }

  public approveAction(): void {
    this.actionApprove.emit();
    this.close();
  }
}
