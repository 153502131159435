<tpt-dialog>
  <div class="subhead mb-24">
    {{'CONTRACTS.ADDING_PARTICIPANTS' | translate}}
  </div>

  <ng-container *ngIf="freelancers.length; else empty">
    <mat-selection-list color="primary">
      <mat-list-option *ngFor="let item of freelancers" [value]="item"
                       (selectedChange)="changeSelection($event, item)"
                       [selected]="isChecked(item.id)">
        <div class="chat-person">
          <tpt-avatar [textSizeRatio]="2.5" class="mr-8" size="32" [user]="item"></tpt-avatar>
          <div class="person-info">
          <span class="name">
            {{ item.firstName }} {{ item.lastName }}
          </span>
            <div
              *ngIf="item?.jobCategoryInfo?.parentCategory"
              class="caption"
            >
              {{ getTranslation(item?.jobCategoryInfo?.parentCategory) }}
            </div>
          </div>
        </div>

      </mat-list-option>
    </mat-selection-list>

    <div class="actions-row mt-36">
      <tpt-button type="secondary" class="cancel"
                  (click)="close()">{{'BUTTON.CANCEL' | translate}}</tpt-button>
      <tpt-button type="primary" (click)="add()">{{'BUTTON.ADD' | translate}}</tpt-button>
    </div>
  </ng-container>


  <ng-template #empty>
    {{'CONTRACTS.EMPTY_PARTICIPANTS_LIST' | translate}}
  </ng-template>

</tpt-dialog>
