<div class="page-wrapper">
  <div class="register-root">
    <div>
      <mat-icon [svgIcon]="svgIconsEnum.FooterLogo" class="logo-full"></mat-icon>

      <div class="register">
        <div class="register-column">
          <h2 class="register__title">{{ 'REGISTER_COMPONENT.REGISTER' | translate }}</h2>

          <ng-container>
            <div class="message-title">{{'REGISTER_COMPONENT.SUCCESSFULLY_CONFIRMED' | translate}}
              <mat-icon [svgIcon]="svgIconsEnum.ConfirmSuccess" class="success-icon"></mat-icon>
            </div>
            <div class="message-text" innerHTML="{{'REGISTER_COMPONENT.SUCCESSFULLY_CONFIRMED_TEXT' | translate}}"></div>
          </ng-container>

          <div class="btn-wrap">
            <tpt-button type="primary" size="large"
                        class="full-width mt-36"
                        [routerLink]="'/login'">{{'LOGIN_COMPONENT.LOGIN' | translate}}</tpt-button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <app-auth-pages-footer></app-auth-pages-footer>
</div>
