<tpt-dialog>
  <div class="dialog-wrapper">
    <div class="dialog-title">
      Оплата счета
    </div>

    <div class="dialog-text">
      Для начала работы над проектом необходимо внести предоплату.<br/>
      Скачайте счет и оплатите. После успешной оплаты вы увидите платеж в истории транзакций.<br/>
      Исполнитель получит вознаграждение только после приемки выполненной работы
    </div>

    <tpt-uploaded-file *ngIf="job?.docs?.invoice" [file]="job.docs.invoice"></tpt-uploaded-file>

    <div class="actions-row mt-40" *ngIf="job?.paymentState === paymentStates.AWAITING_PAYMENT || job?.paymentState === paymentStates.PAYMENT_VERIFICATION">
      <tpt-button type="secondary" size="medium" (onClick)="downloadInvoice()">
        Скачать счет
      </tpt-button>
    </div>

    <div class="actions-row mt-40" *ngIf="job?.paymentState === paymentStates.AWAITING_INVOICE">
      <div class="await-invoice">
        Формирование счета
        <mat-icon [svgIcon]="svgIconsEnum.Hourglass" class="hourglass-icon"></mat-icon>
      </div>
    </div>
  </div>
</tpt-dialog>
