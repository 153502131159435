import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CoRouteHelper {

  static EMPTY_PATH = '';

  static APP = 'app';

  static HOME = 'home';

  static MESSAGES = 'messages';

  static SETTINGS = 'settings';

  static LEGAL = 'legal';

  static BANK_DETAILS = 'bank-details';

  static SECURITY = 'security';

  static PRODUCTS = 'products';

  static MY_PRODUCTS = 'my-products';

  static HISTORY = 'history';

  static NEW_PRODUCT = 'new-product';

  static EDIT_PRODUCT = 'edit-product';

  static PRODUCT = 'product';

  static USER = 'user';

  static CATALOG = 'catalog';

  static ACCEPT = 'accept';

  static WALLET = 'wallet';

  public get landing(): Array<any> {
    return [CoRouteHelper.EMPTY_PATH];
  }

  public productLandingPage(id): Array<any> {
    return [CoRouteHelper.EMPTY_PATH, CoRouteHelper.CATALOG, id];
  }

  public get home(): Array<any> {
    return [CoRouteHelper.EMPTY_PATH, CoRouteHelper.APP];
  }

  public get settings(): Array<any> {
    return [CoRouteHelper.EMPTY_PATH, CoRouteHelper.APP, CoRouteHelper.SETTINGS];
  }

  public get settingsLegal(): Array<any> {
    return [CoRouteHelper.EMPTY_PATH, CoRouteHelper.APP, CoRouteHelper.SETTINGS, CoRouteHelper.LEGAL];
  }

  public get settingsBankDetails(): Array<any> {
    return [CoRouteHelper.EMPTY_PATH, CoRouteHelper.APP, CoRouteHelper.SETTINGS, CoRouteHelper.BANK_DETAILS];
  }

  public get settingsSecurity(): Array<any> {
    return [CoRouteHelper.EMPTY_PATH, CoRouteHelper.APP, CoRouteHelper.SETTINGS, CoRouteHelper.SECURITY];
  }

  public get products(): Array<any> {
    return [CoRouteHelper.EMPTY_PATH, CoRouteHelper.APP, CoRouteHelper.PRODUCTS];
  }

  public get myProducts(): Array<any> {
    return [CoRouteHelper.EMPTY_PATH, CoRouteHelper.APP, CoRouteHelper.PRODUCTS, CoRouteHelper.MY_PRODUCTS];
  }

  public get productsHistory(): Array<any> {
    return [CoRouteHelper.EMPTY_PATH, CoRouteHelper.APP, CoRouteHelper.PRODUCTS, CoRouteHelper.HISTORY];
  }

  public get newProduct(): Array<any> {
    return [CoRouteHelper.EMPTY_PATH, CoRouteHelper.APP, CoRouteHelper.PRODUCTS, CoRouteHelper.NEW_PRODUCT];
  }

  public editProduct(id: string): Array<any> {
    return [CoRouteHelper.EMPTY_PATH, CoRouteHelper.APP, CoRouteHelper.PRODUCTS, CoRouteHelper.EDIT_PRODUCT, id];
  }

  public productPage(id: string): Array<any> {
    return [CoRouteHelper.EMPTY_PATH, CoRouteHelper.APP, CoRouteHelper.PRODUCT, id];
  }

  public userPage(id: number): Array<any> {
    return [CoRouteHelper.EMPTY_PATH, CoRouteHelper.APP, CoRouteHelper.USER, id];
  }

  public get catalogPage(): Array<any> {
    return [CoRouteHelper.EMPTY_PATH, CoRouteHelper.APP, CoRouteHelper.CATALOG];
  }

  public get acceptPage(): Array<any> {
    return [CoRouteHelper.EMPTY_PATH, CoRouteHelper.APP, CoRouteHelper.ACCEPT];
  }

  public get walletPage(): Array<any> {
    return [CoRouteHelper.EMPTY_PATH, CoRouteHelper.APP, CoRouteHelper.WALLET];
  }

  public get messages(): Array<any> {
    return [CoRouteHelper.EMPTY_PATH, CoRouteHelper.APP, CoRouteHelper.MESSAGES];
  }

  public chat(id: number): Array<any> {
    return [CoRouteHelper.EMPTY_PATH, CoRouteHelper.APP, CoRouteHelper.MESSAGES, id];
  }
}
