import { Injectable, OnDestroy } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import {EMPTY, Observable, Subject} from 'rxjs';
import { Button } from '../classes/button';
import { AlertDialog } from '../components/common-dialogs/alert-dialog/alert-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import { IJavaxErrors } from '../classes/iJavaxErrors';
import { IMessageParam, IMessageWithParams } from '../classes/iMessageWithParams';
import { takeUntil } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AlertService implements OnDestroy {

  private errorDialogRef: MatDialogRef<AlertDialog> = null;
  private destroy = new Subject<void>();

  constructor(
    public dialog: MatDialog,
    private translateService: TranslateService,
  ) { }

  public ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
  }

  public success(message: string): void {
    const dialogCfg: MatDialogConfig = new MatDialogConfig();
    dialogCfg.data = { type: 'success', message };
    this.dialog.open(AlertDialog, dialogCfg);
  }

  public error(message: string | IMessageWithParams | IJavaxErrors): Observable<any> {
    // show only one first error
    if (this.errorDialogRef) {
      return;
    }

    this.parseMessage(message)
      .then((result) => {
        const dialogCfg: MatDialogConfig = new MatDialogConfig();
        dialogCfg.data = {
          type: 'error',
          message: result.message,
          params: result.params,
        };
        this.errorDialogRef = this.dialog.open(AlertDialog, dialogCfg);
        this.errorDialogRef.afterClosed().pipe(
          takeUntil(this.destroy),
        ).subscribe(() => this.errorDialogRef = null);
      });

    return EMPTY;
  }

  public showCustomAlert(
    message: string | IMessageWithParams,
    buttons: Button[],
    callback?: (result: any) => void,
    textPlaceholder?: string
  ): void {
    this.parseMessage(message)
      .then((messageWithParams) => {
        const dialogCfg: MatDialogConfig = new MatDialogConfig();
        dialogCfg.data = {
          type: 'custom',
          message: messageWithParams.message,
          params: messageWithParams.params,
          buttons,
          textPlaceholder,
        };
        const dialogRef: MatDialogRef<AlertDialog> = this.dialog.open(AlertDialog, dialogCfg);
        if (callback) {
          dialogRef.afterClosed().pipe(
            takeUntil(this.destroy),
          ).subscribe((result) => {
            if (result) {
              callback(result);
            }
          });
        }
      });
  }

  private parseMessage(message: string | IMessageWithParams | IJavaxErrors): Promise<IMessageWithParams> {
    if (message && (message as IJavaxErrors).errors) {
      return this.javaxErrorsToMessage((message as IJavaxErrors));
    }

    return new Promise<IMessageWithParams>((resolve, reject) => {
      let m: string;
      let params = {};
      if (typeof message === 'string') {
        m = message;
      } else if (message instanceof ErrorEvent) {
        m = message.message;
      } else if (message && message.message) {
        m = message.message;
        if ((message as IMessageWithParams).params) {
          params = (message as IMessageWithParams).params;
        }
      } else {
        m = 'UNEXPECTED ERROR';
      }

      resolve({
        message: m,
        params
      });
    });
  }

  private javaxErrorsToMessage(result: IJavaxErrors): Promise<IMessageWithParams> {
    let message: string = result.message;
    const params: IMessageParam = {};

    return new Promise<IMessageWithParams>((resolve, reject) => {
      if (result.errors.length > 0) {
        const error = result.errors[0];
        this.translateService.get(error.defaultMessage).pipe(
          takeUntil(this.destroy),
        ).subscribe((field) => {
          params.field = error.defaultMessage;

          if (error.code === 'Size') {
            if (error.arguments.length >= 3) {
              if (error.arguments[2] > 0 && error.arguments[1] < 2147483647) {
                message = 'JAVAX_ERROR.SIZE_MIN_MAX';
                params.max = error.arguments[1];
                params.min = error.arguments[2];
              } else if (error.arguments[2] > 0) {
                message = 'JAVAX_ERROR.SIZE_MIN';
                params.min = error.arguments[2];
              } else {
                message = 'JAVAX_ERROR.SIZE_MAX';
                params.max = error.arguments[1];
              }
            }
          }
          resolve({
            message,
            params
          });
        });
      } else {
        resolve({
          message,
          params
        });
      }
    });
  }

}
