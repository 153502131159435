import { Injectable } from '@angular/core';
import { BaseService } from '../../../services/base.service';
import { Observable } from 'rxjs';
import * as moment from 'moment';
import { MilestoneModel } from '../models/milestone.model';
import { FileUpload } from '../../../models/fileUpload';
import { map as _map } from 'lodash';
import {HttpClient} from '@angular/common/http';
import { fromPromise } from 'rxjs/internal/observable/innerFrom';

@Injectable({
  providedIn: 'root'
})
export class JobService extends BaseService {

  constructor(http: HttpClient) {
    super(http);
  }

  public saveJobToDrafts = (form: any, id: string): Observable<any> => {
    const job = this.prepareJobToSave(form.value);
    job.projectId = +id;
    return this.executePut('v2/jobs', job);
  }

  public publishJob = (id: string): Observable<any> => {
    return this.executePut(`v2/jobs/${id}/publish`, null);
  }

  public deleteJob = (id: string): Observable<any> => {
    return fromPromise(this.executeDelete(`v2/jobs/`, {body: {id}}));
  }

  public prepareJobToSave(job) {
    if (job.paymentMethod === 'FIXED') {
      job.details = job.fixedPayment.getFormData();
      job.details.milestones.map(this.mapMilestoneToSendToBackendV2);
      job.details.deadline = job.details.deadline ? moment(job.details.deadline).format('YYYY-MM-DD') : null;
      job.details.paymentMethod = 'FIXED';
      job.arbitragePaid = job.details.arbitragePaid;
      delete job.details.currency;
    }

    if (job.paymentMethod === 'HOURLY') {
      job.details = job.hourlyPayment.getFormData();
      job.details.paymentMethod = 'HOURLY';
      job.arbitragePaid = job.details.arbitragePaid;
    }

    if (job.languages) {
      job.languages.forEach(lang => delete lang.name);
      job.languages = job.languages.filter(lang => lang.code && lang.level);
    }

    if (job.freelancerLocation && typeof job.freelancerLocation !== 'string') {
      job.freelancerLocation = job.freelancerLocation.code || '';
    }

    if (job.skillIds && job.skillIds.length) {
      const skillsObjects = job.skillIds.filter(item => typeof item !== 'string');
      const customSkills = job.skillIds.filter(item => typeof item === 'string');
      job.skillIds = skillsObjects.map((skill: any) => skill.id);
      job.customSkills = customSkills;
    }

    if (job.tasks && job.tasks.length) {
      job.tasks = job.tasks.filter(item => item.taskTitle);
      job.tasks.forEach(task => {
        task.fileIds = this.mapFilesToFileIds(task.fileIds);
        if (task.deadline) {
          task.deadline = moment(task.deadline).format('YYYY-MM-DD');
        }
      });

      const dates = job.tasks.filter(task => task.deadline).map(task => moment(task.deadline));
      const maxDate = moment.max(dates).format('YYYY-MM-DD');

      if (job.details.milestones?.some(item => !item.deadline)) {
        job.details.milestones.forEach(item => item.deadline = maxDate);
      }
    }

    if (job.subCategoryId) {
      job.categoryId = job.subCategoryId;
    }

    delete job.fixedPayment;
    delete job.hourlyPayment;
    delete job.paymentMethod;
    delete job.subCategoryId;

    return job;
  }

  private mapMilestoneToSendToBackendV2 = (milestone: MilestoneModel): MilestoneModel => {
    if (milestone.deadline) {
      milestone.deadline = moment(milestone.deadline).format('YYYY-MM-DD');
    }

    if (milestone.budget) {
      milestone.budget = parseFloat(milestone.budget as unknown as string);
    }

    return milestone;
  }

  private mapFilesToFileIds(files: Array<FileUpload>): void {
    return _map(files, (_: FileUpload) => _.id);
  }

}
