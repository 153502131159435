import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialogConfig } from '@angular/material/dialog';
import { MatSelectionListChange } from '@angular/material/list';
import { DialogComponent } from '../../../../dialog/components/dialog/dialog.component';
import { SvgIconsEnum } from '../../../../../types/svg-icons.enum';
import { ProjectApiService } from '../../../../../services/project.api.service';
import { ChatApiService } from '../../../../../services/chat.api.service';
import { SpinnerService } from '../../../../tpt-ui/services/spinner.service';
import { TranslateService } from '@ngx-translate/core';
import {ProfileModel} from '@/models/profile.model';

@Component({
  selector: 'add-participants',
  templateUrl: './add-participants.component.html',
  styleUrls: ['./add-participants.component.scss']
})
export class AddParticipantsComponent implements OnInit {

  @Output() addedParticipant: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild(DialogComponent)
  public dialog: DialogComponent;

  public svgIconsEnum = SvgIconsEnum;

  public allFreelancers;

  public freelancers = [];

  public selectedFreelancers = [];

  body;

  private config: MatDialogConfig = {
    width: '500px',
    panelClass: 'chat-dialog-align',
  };

  private className = 'add-participants';

  constructor(
    private projectApiService: ProjectApiService,
    private chatApiService: ChatApiService,
    private spinner: SpinnerService,
    private translate: TranslateService
  ) { }

  ngOnInit() {
  }

  public open(freelancers, body): void {
    this.dialog.config = this.config;
    this.dialog.open(this.className);
    this.freelancers = freelancers;
    this.selectedFreelancers = [];
    this.body = body;
  }

  public close(): void {
    this.dialog.close();
  }

  public add(): void {
    if (!this.selectedFreelancers.length) { return; }

    const selectedFreels = this.selectedFreelancers.map(item  => {
      return {
        participantId: item.id,
        role: 'PARTICIPANT'
      };
    });

    selectedFreels.forEach(item => {
      this.body.participants.push(item);
    });

    this.chatApiService.editChat(this.body).subscribe(() => {
      this.spinner.stopSpinner();
      this.addedParticipant.emit(this.selectedFreelancers);
      this.close();
    }, this.spinner.stopSpinner);
  }

  public changeSelection(event: boolean, user: ProfileModel) {
    if (event) {
      this.selectedFreelancers.push(user);
    } else {
      this.selectedFreelancers = this.selectedFreelancers.filter(freelancer => freelancer.id !== user.id);
    }
  }

  public isChecked(id): boolean {
    return !!this.selectedFreelancers.filter(freelancer => freelancer.id === id).length;
  }

  public getTranslation(item): string {
    if (!item) { return; }
    if (this.translate.currentLang === 'ru') {
      return item.nameRu;
    }
    return item.name;
  }
}
