<tpt-dialog>
  <div class="dialog-wrapper">
    <div class="dialog-title mb-12">
      {{'SETTINGS.LEGAL_STATUS' | translate}}
    </div>

    <div class="regular-16-24 mb-20">
      {{'PROJECTS.LEGAL_DIALOG_SUBTEXT' | translate}}
    </div>

    <div class="legal-type-row">
      <mat-icon [svgIcon]="svgIconsEnum.Individual"></mat-icon>
      <div class="legal-name">{{'SETTINGS.INDIVIDUAL' | translate}}</div>
    </div>

    <div class="regular-16-24 mb-40" [innerHTML]="'PROJECTS.INDIVIDUAL_INFO' | translate"></div>

    <div class="legal-type-row">
      <mat-icon [svgIcon]="svgIconsEnum.IndividualEntrepreneur"></mat-icon>
      <div class="legal-name"> {{'PROJECTS.LEGAL_ENTITY_NAME' | translate}}</div>
    </div>

    <div class="regular-16-24 mb-40" [innerHTML]="'PROJECTS.LEGAL_ENTITY_INFO' | translate"></div>

    <div class="actions-row mt-40">
      <tpt-button class="mr-12" type="secondary" size="medium" (onClick)="close()">{{'BUTTON.CANCEL' | translate}}</tpt-button>
      <tpt-button type="primary" size="medium" (onClick)="changeStatus.emit()">{{'SETTINGS.CHANGE_STATUS' | translate}}</tpt-button>
    </div>

  </div>
</tpt-dialog>
