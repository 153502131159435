<mat-select
  [disableOptionCentering]="true"
  [formControl]="control"
  (selectionChange)="selectionChange.emit($event)"
  [placeholder]="placeholder"
  [panelClass]="panelClass"
  [class.-error-state]="errorState"
>
  <ng-container *ngIf="count > 4">
    <div class="search-project">
      <mat-icon [svgIcon]="svgIconsEnum.Search"></mat-icon>
      <input (keyup)="$event.preventDefault()" [formControl]="inputForm" (keydown)="$event.stopPropagation()">
    </div>
  </ng-container>

  <div class="options-wrapper" [class.has-input]="count > 4" *ngIf="!userSearchSelect">
    <ng-container *ngIf="showEmptyValue">
      <mat-option [value]="null">
        {{'FINANCE.ALL_PROJECTS' | translate}}
      </mat-option>
    </ng-container>
    <ng-container *ngFor="let option of options">
      <mat-option [value]="option[valueField]">
        {{option[nameField]}}
      </mat-option>
    </ng-container>
  </div>

  <div class="options-wrapper"
       [class.has-input]="count > 4"
       *ngIf="userSearchSelect">
    <ng-container *ngFor="let option of options">
      <mat-option [value]="option[valueField]">
        {{option.firstName}} {{option.lastName}}
        <span class="foreigner" *ngIf="option.citizenship && option.citizenship !== 'RUSSIA'">Иностранный гражданин</span>
      </mat-option>
    </ng-container>
    <ng-container *ngIf="!options.length">
      <div class="not-found">
        Никто не найден. Возможно, профиль исполнителя заполнен не полностью или не привязан к ФНС — для приглашения отправьте ссылку
      </div>
    </ng-container>
  </div>

</mat-select>
<mat-error [class.-invisible]="!errorState">
  {{ error | translate}}
</mat-error>
