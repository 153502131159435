import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

import * as moment from 'moment';

import { SvgIconsEnum } from '../../../../types/svg-icons.enum';
import { ContractsService } from '../../../contracts/services/contracts.service';
import { ProfileService } from '../../../../services/profile.service';
import { ContractSignDialogComponent } from '../../../common-dialogs/components/contract-sign-dialog/contract-sign-dialog.component';
import { ResourcesService } from '../../../../services/resources.service';
import { PaySbpDialogComponent } from '../../../common-dialogs/components/pay-sbp-dialog/pay-sbp-dialog.component';
import { contractsStates, greenMessages } from '../../../contracts/models/message.model';
import { ContractEventModel } from '../../../contracts/models/contract-event.model';
import { environment } from '../../../../../environments/environment';
import { ProjectApiService } from '@/services/project.api.service';
import { SpinnerService } from '@/modules/tpt-ui/services/spinner.service';
import { SnackbarNotificationsService } from '@/services/snackbar-notifications.service';
import { RouteHelper } from '@/helpers/route.helper';

@Component({
  selector: 'chat-own-message',
  templateUrl: './chat-own-message.component.html',
  styleUrls: [ './chat-own-message.component.scss' ],
})
export class ChatOwnMessageComponent {

  @Input()
  public message: ContractEventModel;

  @Input()
  public chatType: 'JOB_CHAT' | 'PROJECT_CHAT' | 'SUPPORT' | 'CREATIVE_OBJECTS_PRODUCT';

  @ViewChild(ContractSignDialogComponent) contractSignDialogComponent: ContractSignDialogComponent;

  @ViewChild(PaySbpDialogComponent) sbpDialogComponent: PaySbpDialogComponent;

  @Output() public updateChatEvents = new EventEmitter<void>();

  public svgIconsEnum = SvgIconsEnum;

  public greenMessages = greenMessages;

  constructor(public contractsService: ContractsService,
              public profileService: ProfileService,
              private projectApiService: ProjectApiService,
              private spinner: SpinnerService,
              private snack: SnackbarNotificationsService,
              private router: Router,
              private routeHelper: RouteHelper,
              private resource: ResourcesService) {
  }

  public get sendDate(): string {
    return moment(this.message.date).format('DD.MM.YYYY HH:mm');
  }

  public get isTextMessage(): boolean {
    return this.message.type === 'm.chat.message' && (this.message.content.body || this.message.content.files.length);
  }

  public get isStateMessage(): boolean {
    const states = ['state.owner_paid_job', 'state.owner_paid_job_milestone', ...contractsStates,
      'state.freelancer_was_invited', 'state.freelancer_was_accepted', 'state.freelancer_made_proposal'];
    return states.includes(this.message.type);
  }

  public get isEmployer(): boolean {
    return this.profileService.currentProfile.isEmployer();
  }

  public get isGroupChatEventMessage(): boolean {
    const groupChatEvents = ['m.room.avatar', 'm.room.name', 'm.room.create', 'm.room.member'];
    return groupChatEvents.includes(this.message.type);
  }

  public get isProductChatEventMessage(): boolean {
    const productChatEvents = ['state.product.purchase', 'state.product.act', 'state.product', 'state.product.deal_completed'];
    return productChatEvents.includes(this.message.type);
  }

  public deleteMessage(): void {
    const deleteMessageIds = JSON.parse(localStorage.getItem('deleteMessageIds')) || [];

    deleteMessageIds.push(this.message.eventId);
    localStorage.setItem('deleteMessageIds', JSON.stringify(deleteMessageIds));
    this.message.deleted = true;
  }

  public undoDelete(): void {
    let deleteMessageIds = JSON.parse(localStorage.getItem('deleteMessageIds')) || [];

    deleteMessageIds = deleteMessageIds.filter(item => item !== this.message.eventId);
    localStorage.setItem('deleteMessageIds', JSON.stringify(deleteMessageIds));
    this.message.deleted = false;
  }

  public getUrl(fileId): string {
    return this.resource.getUrl(fileId);
  }

  public downloadFile(fileId): void {
    this.resource.getFileByUUID(fileId).subscribe(this.handleFile);
  }

  public getReceiptLink(content): void {
    const baseUrl = environment.MAIN_HOST;
    window.open(`${window.location.protocol}//${baseUrl}/api/v1/receipt/${content.inn}/${content.receiptId}/print`, '_blank');
  }

  public signDocument(docType: 'contract' | 'wcc'): void {
    const projectId = this.contractsService.selectedChat.projectId;

    switch (docType) {
      case 'contract':
        this.spinner.startSpinner();
        this.projectApiService.signContract(projectId).subscribe(() => {
            this.spinner.stopSpinner();
            this.updateChatEvents.emit();
          },
          () => {
            this.spinner.stopSpinner();
            this.snack.showSmthWentWrong();
          });
        return;
      case 'wcc':
        this.spinner.startSpinner();
        this.projectApiService.signWCC(projectId).subscribe(() => {
            this.spinner.stopSpinner();
            this.updateChatEvents.emit();
          },
          (err) => {
            this.spinner.stopSpinner();
            if (['FNS.TAXPAYER_UNBOUND', 'FNS.TAXPAYER_UNREGISTERED'].includes(err?.errorCode?.message)) {
              this.snack.showNotification(err.message, 'fail');
              return;
            }
            this.snack.showSmthWentWrong();
          });
    }
  }

  public topUpBalance(amount) {
    this.router.navigate(this.routeHelper.financesCommon, {queryParams: {amount}});
  }

  private handleFile = (file: File): void => {
    const objectURL = this.resource.createObjectURL(file);
    const link = document.createElement('a');
    link.download = decodeURIComponent(file.name);
    link.href = objectURL;
    link.click();
    URL.revokeObjectURL(objectURL);
  }
}
