<div class="legal-status" *ngIf="status">
  <ng-container *ngIf="status === 'ACTIVE' && bankDetailsValidated && legalInfoValidated">
    Самозанятый
    <mat-icon [svgIcon]="svgIconsEnum.CheckSuccess"
              class="small-icon"></mat-icon>
  </ng-container>

  <ng-container *ngIf="['NEW', 'NOT_VERIFIED'].includes(status) ||
              (status === 'ACTIVE' && (!bankDetailsValidated || !legalInfoValidated))">
    Ограниченный доступ
    <mat-icon [svgIcon]=" svgIconsEnum.Deregistered"
              class="small-icon"></mat-icon>
  </ng-container>

  <ng-container *ngIf="status === 'DETACHED'">
    Отвязан от платформы
    <mat-icon [svgIcon]=" svgIconsEnum.Deregistered"
              class="restricted"></mat-icon>
  </ng-container>

  <ng-container *ngIf="status === 'REMOVED_FROM_REGISTER'">
    Снят с учёта
    <mat-icon [svgIcon]=" svgIconsEnum.Deregistered"
              class="small-icon"></mat-icon>
  </ng-container>
</div>
