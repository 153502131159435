<ng-container>
  <tpt-spinner></tpt-spinner>
  <tpt-loading-bar></tpt-loading-bar>
  <ng-container *ngIf="loaded">
    <div class="xln body">
<!--      <tpt-sidebar *ngIf="!hideSidebar" class="desktop"></tpt-sidebar>-->
<!--      <app-mobile-sidebar *ngIf="!hideHeader" class="mobile"></app-mobile-sidebar>-->
      <div class="app-content">
<!--        <tpt-header-->
<!--          [ngClass]="{'loaded': loaded, 'hide-header': hideHeader}"-->
<!--          class="app-header"-->
<!--          [class.loaded]="loaded"-->
<!--          *ngIf="profileService?.currentProfile"-->
<!--          (toggleMobileSidebar)="toggleMobileSidebar()"-->
<!--        >-->
<!--        </tpt-header>-->
<!--        <tpt-header-light *ngIf="!profileService?.currentProfile"-->
<!--                          class="app-header"-->
<!--                          [ngClass]="{'hide-on-main-page': hideHeaderOnMainPage}">-->
<!--        </tpt-header-light>-->

        <div class="body-content" [ngClass]="{'body-content_main': hideHeader}" cdkScrollable>
          <router-outlet></router-outlet>
<!--          <tpt-snackbar-notifications></tpt-snackbar-notifications>-->
        </div>
      </div>
    </div>
  </ng-container>
</ng-container>
