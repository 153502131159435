import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialogConfig } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { find } from 'lodash';

import { DialogComponent } from '../../../../dialog/components/dialog/dialog.component';
import { SvgIconsEnum } from '../../../../../types/svg-icons.enum';
import { ProfileModel } from '../../../../../models/profile.model';
import { ProfileService } from '../../../../../services/profile.service';
import { SpinnerService } from '../../../../tpt-ui/services/spinner.service';
import { SearchService } from '../../../../../services/search.service';
import { ProjectApiService } from '../../../../../services/project.api.service';
import { DataLayerService } from '../../../../../services/data-layer.service';
import { RouteHelper } from '../../../../../helpers/route.helper';
import { CategoryService } from '../../../../../services/category.service';
import { CategoryV2Model } from '../../../../../models/category-v2.model';
import { ProfileFilledService } from '../../../../../services/profile-filled.service';
import { EditProfileService } from '../../../../edit-profile/services/edit-profile.service';
import { JobInviteErrorDialogComponent } from '../../../../common-dialogs/components/job-invite-error-dialog/job-invite-error-dialog.component';
import { Router } from '@angular/router';
import { FrameworkContractsService } from '@/services/framework-contracts.service';
import {
  SelectContractDialogComponent
} from '@/modules/common-dialogs/components/select-contract-dialog/select-contract-dialog.component';
import * as moment from 'moment';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'hire-freelancer-dialog',
  templateUrl: './hire-freelancer-dialog.component.html',
  styleUrls: ['./hire-freelancer-dialog.component.scss']
})
export class HireFreelancerDialogComponent implements OnInit {

  get isEmployer(): boolean {
    return this.profileService.currentProfile.isEmployer();
  }
  @ViewChild(DialogComponent)
  public dialog: DialogComponent;

  @ViewChild(JobInviteErrorDialogComponent)
  public jobInviteErrorDialogComponent: JobInviteErrorDialogComponent;

  @ViewChild(SelectContractDialogComponent)
  public selectContractDialog: SelectContractDialogComponent;

  @Output() successfullyHired = new EventEmitter();

  public freelancer: ProfileModel;

  public svgIconsEnum = SvgIconsEnum;

  public vacancies: any[] = [];

  public currentProject;

  public detailsOpened = false;

  public categories: CategoryV2Model[];

  public fullProjectInfo = null;

  private className = 'hire-freelancer-dialog';

  private config: MatDialogConfig = {
    width: '729px',
    minHeight: '200px',
    maxHeight: '100vh',
  };

  private freelancerContracts = [];

  constructor(private profileService: ProfileService,
              private spinner: SpinnerService,
              private searchService: SearchService,
              private editProfileService: EditProfileService,
              public projectService: ProjectApiService,
              public routeHelper: RouteHelper,
              private router: Router,
              private dataLayerService: DataLayerService,
              private categoryService: CategoryService,
              private translate: TranslateService,
              private frameworkService: FrameworkContractsService,
  ) {
  }

  ngOnInit() {
    this.categoryService.getCategoriesV2().subscribe(categories => {
      this.categories = categories;
    });
  }

  public open(freelancer, vacancies): void {
    this.vacancies = vacancies;
    this.freelancer = freelancer;

    this.getFreelancerContracts();

    if (this.vacancies?.length === 1) {
      this.currentProject = this.vacancies[0];
      this.changed(this.currentProject);
    }

    this.dialog.config = this.config;
    this.dialog.open(this.className);
  }

  changed(project) {
    this.currentProject = project;
    this.getProject(this.currentProject.projectId);
  }

  public getFreelancerContracts(): void {
    lastValueFrom(this.frameworkService.getContracts(this.freelancer.id)).then((contracts) => {
      this.freelancerContracts = contracts;
    });
  }

  public close(): void {
    this.dialog.close();
  }

  public clearCurrentProject() {
    this.currentProject = null;
  }

  public hireFreelancer = async (): Promise<void> => {
    const legalInfo = await lastValueFrom(this.editProfileService.getUserLegal());
    const isInvalid = ProfileFilledService.clientDataInvalid(this.profileService.currentProfile, legalInfo);

    if (isInvalid) {
      this.jobInviteErrorDialogComponent.open();
      return;
    }

    if (this.freelancerContracts?.length) {
      const fromDate = moment(this.freelancerContracts[0].fromDate);
      const toDate = moment(this.freelancerContracts[0].toDate);
      const deadline = moment(this.fullProjectInfo.deadline);

      if (deadline > fromDate && deadline <= toDate) {
        this.selectContractDialog.open();
        return;
      }
    }

    this.spinner.startSpinner();
    this.searchService.sendInvite(this.freelancer.id, this.currentProject.projectId)
      .then(res => {
        if (res) {
          this.freelancer.jobParticipating.push(
            {
              projectId: this.currentProject.projectId,
              projectName: this.currentProject.projectName,
              jobName: this.currentProject.jobName,
              jobParticipationState: 'INVITED',
              jobState: this.currentProject.jobState,
              currentUserJobChatId: res.id
            }
          );
          this.successfullyHired.emit(this.freelancer.id);
        }
        this.close();
        this.spinner.stopSpinner();
      })
      .catch(this.spinner.stopSpinner);
  }

  public selectContractType(type: string): void {
    this.spinner.startSpinner();
    this.searchService.sendInvite(this.freelancer.id, this.currentProject.projectId)
      .then(res => {
        if (res) {
          this.freelancer.jobParticipating.push(
            {
              projectId: this.currentProject.projectId,
              projectName: this.currentProject.projectName,
              jobName: this.currentProject.jobName,
              jobParticipationState: 'INVITED',
              jobState: this.currentProject.jobState,
              currentUserJobChatId: res.id
            }
          );
          this.successfullyHired.emit(this.freelancer.id);
        }

        const body = {
          chatId: res.id,
          frameworkContractId: type === 'FRAMEWORK' ? this.freelancerContracts[0]?.id : null
        };
        return lastValueFrom(this.searchService.setFrameworkContract(body));
      })
      .then((res) => {
        this.close();
        this.spinner.stopSpinner();
      })
      .catch(this.spinner.stopSpinner);
  }

  public category(id): string {
    const category: CategoryV2Model = find(this.categories, (_: CategoryV2Model) => _.id === id);
    if (category) {
      return this.translate.currentLang === 'ru' ? category.nameRu : category.name;
    }
    return 'CREATE_PROJECT.STEP5.CATEGORY_NOT_SELECTED';
  }

  public subcategory(id, subId?: string): string {
    const category: CategoryV2Model = find(this.categories, (_: CategoryV2Model) => _.id === id);
    if (!category) {
      return 'CREATE_PROJECT.STEP5.SUBCATEGORY_NOT_SELECTED';
    }

    const subCategory: CategoryV2Model = find(category.children, (_: CategoryV2Model) => _.id === subId);
    if (subCategory) {
      return this.translate.currentLang === 'ru' ? subCategory.nameRu : subCategory.name;
    }
    return 'CREATE_PROJECT.STEP5.SUBCATEGORY_NOT_SELECTED';
  }

  public disabledJob(project): boolean {
    const allTalentJobs = this.freelancer.jobParticipating?.filter(item =>
      ['INVITED', 'PROPOSAL_SUBMITTED'].includes(item.jobParticipationState)
    ).map(item => item.projectId);

    return allTalentJobs?.some(item => project.projectId === item);
  }

  public goToChat(job): void {
    const allTalentJobs = this.freelancer.jobParticipating.filter(
      item => ['INVITED', 'PROPOSAL_SUBMITTED'].includes(item.jobParticipationState)
    ).filter(item => item.jobState === 'INIT');

    const foundJob = allTalentJobs.find(item => item.projectId === job.projectId);

    if (!foundJob) {
      return;
    }

    const chatId = foundJob.currentUserJobChatId;

    this.router.navigate(this.routeHelper.contractsProposalsUrl(chatId));
    this.close();
  }

  public getProject = async (id: string): Promise<void> => {
    if (!id) {
      return;
    }

    this.fullProjectInfo = await lastValueFrom(this.projectService.getProjectById(id));
  }

}
