import { Component, ViewChild } from '@angular/core';
import { MatDialogConfig } from '@angular/material/dialog';

import { SvgIconsEnum } from '@/types/svg-icons.enum';
import { DialogComponent } from '@/modules/dialog/components/dialog/dialog.component';

@Component({
  selector: 'app-warning-dialog',
  templateUrl: './warning-dialog.component.html',
  styleUrls: ['./warning-dialog.component.scss']
})
export class WarningDialogComponent {

  @ViewChild(DialogComponent)
  public dialog: DialogComponent;

  public svgIconsEnum = SvgIconsEnum;

  public access: number;

  public title = '';
  public text = '';

  private readonly className = 'app-warning-dialog';

  private readonly config: MatDialogConfig = {
    width: '520px',
    minHeight: '316px'
  };

  public open(title: string, text: string): void {
    this.title = title;
    this.text = text;
    this.dialog.config = this.config;
    this.dialog.open(this.className);
  }

  public close(): void {
    this.dialog.close();
  }
}
