<tpt-dialog>
  <div class="headline">
    Чек
  </div>

  <img *ngIf="tempUrl" [src]="getUrl(tempUrl)" class="check">

  <div class="actions-row mt-40">
    <tpt-button type="primary" size="medium" (onClick)="download()">
      Скачать
    </tpt-button>
    <tpt-button type="info-warn" size="medium" class="ml-12"
                *ngIf="!profile.currentProfile.isEmployer() && !receiptCanceled"
                (onClick)="openCheckCancelDialog()">
      Аннулировать чек
    </tpt-button>
  </div>
</tpt-dialog>

<tpt-cancel-receipt-dialog (cancelReceiptEvent)="cancelReceipt($event)"></tpt-cancel-receipt-dialog>
