import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AlertService } from './alert.service';
import { fromPromise } from 'rxjs/internal/observable/innerFrom';
import { BaseService } from "@/services/base.service";

@Injectable({
  providedIn: 'root',
})
export class FileUploadService extends BaseService {
  constructor(http: HttpClient,
              alertService: AlertService,) {
    super(http, alertService);
  }

  public uploadFile = (file: File): Observable<any> => {
    const formData = new FormData();
    formData.append('file', file, file.name);
    return fromPromise(this.executePost('v2/files', formData));
  }
}
