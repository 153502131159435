<div class="check-list-root">

  <div class="title">
    <mat-icon [svgIcon]="svgIconsEnum.FooterLogo"></mat-icon>
    Employee check list
  </div>

  <div class="personal-info-label mb-24">
    Personal Info
  </div>

  <div class="double-grid mb-20">
    <div>
      <div class="label">Surname</div>
      <input matInput class="input-field" [(ngModel)]="checkList.surname">
    </div>
    <div>
      <div class="label">Name</div>
      <input matInput class="input-field" [(ngModel)]="checkList.name">
    </div>
  </div>

  <div class="double-grid mb-24">
    <div>
      <div class="label">Other given name</div>
      <input matInput class="input-field" [(ngModel)]="checkList.givenNames">
    </div>
    <div>
      <div class="label">Date of birth</div>
      <div class="birth-datepicker">
        <mat-form-field appearance="outline">
          <input matInput [matDatepicker]="birthPicker" placeholder="Select date"
                 [ngModelOptions]="{standalone: true}"
                 [(ngModel)]="checkList.birthDate">
          <mat-datepicker-toggle matSuffix [for]="birthPicker"></mat-datepicker-toggle>
          <mat-datepicker #birthPicker></mat-datepicker>
        </mat-form-field>
      </div>
    </div>
  </div>

  <div class="personal-info-label mb-24">
    Citizenship
  </div>


  <div class="double-grid mb-20">
    <div>
      <div class="label">Citizenship</div>
      <input matInput class="input-field" [(ngModel)]="checkList.citizenShip">
    </div>
    <div>
      <div class="label">Permanently residing in</div>
      <input matInput class="input-field" [(ngModel)]="checkList.permanentlyResiding">
    </div>
  </div>

  <div class="double-grid mb-20">
    <div>
      <div class="label">Tax resident of</div>
      <input matInput class="input-field" [(ngModel)]="checkList.taxResidentOf">
    </div>
    <div>
      <div class="label">ITN in the country of tax residence</div>
      <input matInput class="input-field" [(ngModel)]="checkList.ITNinCountryResidence">
    </div>
  </div>

  <div class="full-width mb-36">
    <div class="label">Double tax residence. Please include extra country, if any</div>
    <input matInput class="input-field" [(ngModel)]="checkList.taxExtraCountry">
  </div>

  <div class="personal-info-label mb-24">
    Legal entity
  </div>

  <div class="double-grid mb-20">
    <div>
      <div class="label mb-20">Registered as legal entity</div>
      <div>
        <mat-radio-group [(ngModel)]="checkList.isRegisteredAsLegalEntity">
          <mat-radio-button labelPosition="after" class="mr-40" [value]="true">Yes</mat-radio-button>
          <mat-radio-button labelPosition="after" [value]="false">No</mat-radio-button>
        </mat-radio-group>
      </div>
    </div>
    <div>
      <div class="label">Tax number of your own legal entity</div>
      <input matInput class="input-field" [(ngModel)]="checkList.taxNumberOfLegalEntity">
    </div>
  </div>

  <div class="full-width mb-20">
    <div class="label mb-20">Are you willing to be employed or work via own legal entity?</div>
    <div>
      <mat-radio-group [(ngModel)]="checkList.willBeEmployedAsLegal">
        <mat-radio-button labelPosition="after" class="mr-40" [value]="true">Yes</mat-radio-button>
        <mat-radio-button labelPosition="after" [value]="false">No</mat-radio-button>
      </mat-radio-group>
    </div>
  </div>

  <div class="full-width mb-40">
    <div class="label mb-20">Do you need assistance in registering your own legal entity?</div>
    <div>
      <mat-radio-group [(ngModel)]="checkList.needAssistanceInRegisteringAsLegal">
        <mat-radio-button labelPosition="after" class="mr-40" [value]="true">Yes</mat-radio-button>
        <mat-radio-button labelPosition="after" [value]="false">No</mat-radio-button>
      </mat-radio-group>
    </div>
  </div>

  <div class="personal-info-label mb-24">
    Engagement
  </div>

  <div class="full-width mb-20">
    <div class="label mb-20">Engagement model</div>
    <div>
      <mat-radio-group [(ngModel)]="checkList.engagementModel">
        <mat-radio-button labelPosition="after" class="mr-40" value="Fulltime">Fulltime</mat-radio-button>
        <mat-radio-button labelPosition="after" value="Parttime">Parttime</mat-radio-button>
      </mat-radio-group>
    </div>
  </div>

  <div class="double-grid mb-20">
    <div>
      <div class="label">Amount of working hours weekly</div>
      <input matInput class="input-field" [(ngModel)]="checkList.amountOfWorkingHours">
    </div>
    <div>
      <div class="label">Possible start date</div>
      <div class="start-datepicker">
        <mat-form-field appearance="outline">
          <input matInput [matDatepicker]="picker" placeholder="Select date"
                 [ngModelOptions]="{standalone: true}"
                 [(ngModel)]="checkList.startDate">
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
      </div>
    </div>
  </div>

  <div class="full-width mb-20">
    <div class="label">Limitations or extra requirements</div>
    <div class="textarea-form-field">
      <textarea [(ngModel)]="checkList.limitations"></textarea>
      <div class="caption mt-8">Here you need to state if you have right to decreased work day due to kids or disabilities, etc.</div>
    </div>
  </div>

  <div class="full-width mb-20">
    <div class="label mb-20">Do you need workspace/office?</div>
    <div>
      <mat-radio-group [(ngModel)]="checkList.needWorkspace">
        <mat-radio-button labelPosition="after" class="mr-40" [value]="true">Yes</mat-radio-button>
        <mat-radio-button labelPosition="after" [value]="false">No</mat-radio-button>
      </mat-radio-group>
    </div>
  </div>

  <div class="full-width mb-20">
    <div class="label">Work equipment preferences, if any</div>
    <div class="textarea-form-field">
      <textarea [(ngModel)]="checkList.workEquipment"></textarea>
    </div>
  </div>

  <div *ngFor="let education of checkList.education; let i = index">
    <div class="education-label mb-24">
      <div>Education #{{i + 1}}</div>
      <div>
        <tpt-button size="small" type="secondary" class="delete-button"
                    *ngIf="checkList?.education?.length > 1"
                    (onClick)="removeEducation(i)">
          Delete
        </tpt-button>
      </div>
    </div>

    <div class="double-grid mb-20">
      <div>
        <div class="label">Name</div>
        <input matInput class="input-field" [(ngModel)]="education.name">
      </div>
      <div>
        <div class="label">Period</div>
        <input matInput class="input-field" [(ngModel)]="education.period">
      </div>
    </div>

    <div class="double-grid mb-20">
      <div>
        <div class="label">Degree</div>
        <input matInput class="input-field" [(ngModel)]="education.degree">
      </div>
      <div>
        <div class="label">Mentor contacts, if available</div>
        <input matInput class="input-field" [(ngModel)]="education.mentorContacts">
      </div>
    </div>
  </div>

  <tpt-button type="info" class="mb-40 add-button"
              (onClick)="addEducation()"
              *ngIf="checkList?.education?.length < 10">
    + Add
  </tpt-button>

  <div *ngFor="let exp of checkList.workExperience; let i = index">
    <div class="education-label mb-24">
      <div>Work experience #{{i + 1}}</div>
      <div>
        <tpt-button size="small" type="secondary" class="delete-button"
                    *ngIf="checkList?.workExperience?.length > 1"
                    (onClick)="removeExperience(i)">
          Delete
        </tpt-button>
      </div>
    </div>

    <div class="double-grid mb-20">
      <div>
        <div class="label">Company</div>
        <input matInput class="input-field" [(ngModel)]="exp.company">
      </div>
      <div>
        <div class="label">Period</div>
        <input matInput class="input-field" [(ngModel)]="exp.period">
      </div>
    </div>

    <div class="double-grid mb-20">
      <div>
        <div class="label">Position</div>
        <input matInput class="input-field" [(ngModel)]="exp.position">
      </div>
      <div>
        <div class="label">Form of Employment</div>
        <input matInput class="input-field" [(ngModel)]="exp.employmentForm">
      </div>
    </div>

    <div class="double-grid mb-20">
      <div>
        <div class="label">Reference</div>
        <input matInput class="input-field" [(ngModel)]="exp.reference">
      </div>
    </div>
  </div>

  <tpt-button type="info" class="mb-32 add-button"
              (onClick)="addExperience()"
              *ngIf="checkList?.workExperience?.length < 10">
    + Add
  </tpt-button>

  <div class="checkboxes-wrapper">
    <mat-checkbox class="green-checkbox mb-12" [(ngModel)]="confirmInfo">
      I confirm that the information provided above is true and correct.
    </mat-checkbox>

    <mat-checkbox class="green-checkbox mb-24" [(ngModel)]="confirmStorageData">
      I consent processing and storage of my personal data and information provided above.
    </mat-checkbox>
  </div>

  <div class="mb-24 save-btn">
    <tpt-button type="primary" [disabled]="!confirmInfo || !confirmStorageData"
                (onClick)="saveData()">Submit</tpt-button>
  </div>
</div>
