<div class="component-wrapper mb-16">
  <div class="category" *ngIf="project.parentCategoryId">{{getProjectCategory()}}
    <span class="subcategory" *ngIf="project.subCategoryId">{{getProjectSubCategory()}}</span>
  </div>
  <div class="title">{{project?.projectName}}</div>

  <div class="main-info">
    <div *ngIf="project?.deadline" class="sub-main-info">
      <p class="main-info-category">{{ 'FINANCE.COLUMN.DEADLINE' | translate }}</p>
      <p class="main-info-value" [class.red]="deadlinePassed(project.deadline)">
        <mat-icon class="calendar-icon" [svgIcon]="svgIconsEnum.DatepickerCalendar"></mat-icon>
        {{project.deadline | localDate: 'dd.MM.yyyy'}}
      </p>
    </div>
  </div>

  <div class="tasks">
    <div *ngFor="let task of project?.tasks; let idx = index;" class="project-task">
      <div class="project-task-title-row">
        <div class="task-title">
          {{ task?.taskTitle }}
        </div>
        <div class="task-number-block">
          {{'CONTRACTS.TASK' | translate}}#{{ idx + 1 }}
        </div>
      </div>

      <div class="project-task-description">
        <ng-container>
          {{ task?.description }}
        </ng-container>
      </div>
      <div class="project-task-attachments">
        <ng-container>
          <tpt-uploaded-file
            *ngFor="let file of task?.files"
            [file]="file"
          ></tpt-uploaded-file>
        </ng-container>
      </div>
    </div>
  </div>

  <div class="requirements">
    <div class="requirements-column">
      <div class="requirement-block">
        <div class="requirement-label">{{'CONTRACTS.EXPERT_LEVEL' | translate}}</div>
        <div *ngFor="let level of project?.expertLevels; let lst = last;"
             class="requirement-value">
          {{ 'SEARCH.LEVEL.'+level | translate}}{{ lst ? '' : ',' }}
        </div>
      </div>

      <div class="requirement-block">
        <div class="requirement-label">{{'CONTRACTS.LANGUAGE_SKILLS' | translate}}</div>
        <div class="requirement-value">
          <div *ngFor="let language of project?.languages"
               class="language-requirements-row">
            <span class="language">{{getLangByCode(language)}}</span>
            <span class="level" *ngIf="language?.level">{{'CREATE_PROJECT.STEP3.'+language.level | translate}}</span>
          </div>
        </div>
      </div>

      <div class="requirement-block">
        <div class="requirement-label">{{'CONTRACTS.FREELANCER_LOCATION' | translate}}</div>
        <div class="requirement-value freelancer-location">
          {{ project?.country?.name || '-' }}
        </div>
      </div>
    </div>

    <div class="skills-column">
      <div *ngFor="let skill of project?.skills"
           class="skill-item">
        {{ skill.name }}
      </div>
    </div>
  </div>

  <div class="budget">
    <div class="budget-row">
      <div class="label">
        {{'CONTRACTS.TOTAL_PROJECT_BUDGET' | translate}}
      </div>
      <div class="budget-value">
        {{currencySymbol.RUB}}{{ project?.budget }}
      </div>
    </div>
  </div>
</div>
