import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { MatDialogConfig } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { DialogComponent } from '../../../dialog/components/dialog/dialog.component';

@Component({
  selector: 'tpt-signup-error-dialog',
  templateUrl: './signup-error-dialog.component.html',
  styleUrls: ['./signup-error-dialog.component.scss']
})
export class SignupErrorDialogComponent {

  @ViewChild(DialogComponent)
  public dialog: DialogComponent;

  @Output() useAnotherEmailEvent = new EventEmitter();

  private config: MatDialogConfig = {
    width: '612px',
  };

  private className = 'tpt-signup-error-dialog';

  constructor(private router: Router) { }

  public open(): void {
    this.dialog.config = this.config;
    this.dialog.open(this.className);
  }

  public close(): void {
    this.dialog.close();
  }

  public goToRestorePassword(): void {
    this.router.navigate(['/forgotPassword']);
    this.close();
  }

  public useAnotherEmail(): void {
    this.useAnotherEmailEvent.emit();
    this.close();
  }
}
