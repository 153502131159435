import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { SvgIconsEnum } from '../../../../types/svg-icons.enum';
import { DialogComponent } from '../../../dialog/components/dialog/dialog.component';
import { MatDialogConfig } from '@angular/material/dialog';
import { EmployerLegalStatusEnum, FreelancerLegalStatusEnum } from '../../../../models/legalStatuses.enum';

@Component({
  selector: 'tpt-legal-status-dialog',
  templateUrl: './legal-status-dialog.component.html',
  styleUrls: ['./legal-status-dialog.component.scss']
})
export class LegalStatusDialogComponent implements OnInit {
  @ViewChild(DialogComponent)
  public dialog: DialogComponent;

  @Output() statusChanged: EventEmitter<any> = new EventEmitter<any>();

  public svgIconsEnum = SvgIconsEnum;

  public entityType: EmployerLegalStatusEnum | FreelancerLegalStatusEnum;

  public role: string;

  public cards;

  public employerCards = [
    // {
    //   id: EmployerLegalStatusEnum.INDIVIDUAL,
    //   icon: SvgIconsEnum.Individual,
    //   name: 'INDIVIDUAL',
    //   description: 'Тип профиля по умолчанию',
    // },
    {
      id: EmployerLegalStatusEnum.LEGAL_ENTITY,
      icon: SvgIconsEnum.LegalEntity,
      name: 'LEGAL_ENTITY',
      description: 'Организация с обособленным имуществом',
    },
    {
      id: EmployerLegalStatusEnum.INDIVIDUAL_ENTREPRENEUR,
      icon: SvgIconsEnum.IndividualEntrepreneur,
      name: 'INDIVIDUAL_ENTREPRENEUR_SHORT',
      description: 'Физическое лицо с зарегистрированным правом предпринимательской деятельности',
    },
    // {
    //   id: EmployerLegalStatusEnum.FOREIGN_AGENCY,
    //   icon: SvgIconsEnum.LegalEntity,
    //   name: 'FOREIGN_AGENCY',
    //   description: 'Организация с обособленным имуществом, зарегистрированная за пределами РФ',
    // }
  ];

  public freelancerCards = [
    {
      id: FreelancerLegalStatusEnum.INDIVIDUAL,
      icon: SvgIconsEnum.Individual,
      name: 'INDIVIDUAL',
      description: 'Тип профиля по умолчанию',
    },
    {
      id: FreelancerLegalStatusEnum.INDIVIDUAL_ENTREPRENEUR,
      icon: SvgIconsEnum.IndividualEntrepreneur,
      name: 'INDIVIDUAL_ENTREPRENEUR_SHORT',
      description: 'Физическое лицо с зарегистрированным правом предпринимательской деятельности.',
    },
  ];

  private config: MatDialogConfig = {
    width: '720px',
  };

  private className = 'tpt-legal-status-dialog';

  constructor() { }

  ngOnInit() {
    this.cards = this.employerCards;
  }

  public open(legalType: (EmployerLegalStatusEnum | FreelancerLegalStatusEnum), role: string): void {
    this.entityType = legalType || EmployerLegalStatusEnum.INDIVIDUAL;
    this.role = role;
    if (this.role === 'freelancer') {
      this.cards = this.freelancerCards;
    }
    this.dialog.config = this.config;
    this.dialog.open(this.className);
  }

  public close(): void {
    this.dialog.close();
  }

  public selectType(type: EmployerLegalStatusEnum): void {
    this.entityType = type;
  }

  public changeType(): void {
    this.statusChanged.emit(this.entityType);
    this.close();
  }
}
