<tpt-dialog (dialogClose)="clearCurrentProject()">
  <div class="title">{{'SEARCH.HIRE' | translate}}</div>

  <ng-container *ngIf="vacancies.length; else emptyProjects">
    <div class="select-title subhead">{{'SEARCH.CHOOSE_PROJECT' | translate}}:</div>

    <mat-select class="select" [(value)]="currentProject" panelClass="project-select"
                [disableOptionCentering]="true" placeholder="Выберите проект" (selectionChange)="changed($event.value)">
      <mat-select-trigger *ngIf="currentProject">
        <span class="caption">Проект</span>
        <div class="project-name"> {{currentProject.projectName}}</div>
      </mat-select-trigger>
      <mat-option *ngFor="let project of vacancies" [value]="project">
        <div class="d-flex align-items-center">
          <div>
            <div class="job caption">{{project?.category?.nameRu}} </div>
            <div class="project-name">{{project.projectName}}</div>
          </div>
          <div *ngIf="disabledJob(project)" class="go-to-chat">
            <tpt-button type="secondary" (onClick)="goToChat(project)">Перейти в чат</tpt-button>
          </div>
        </div>
      </mat-option>
    </mat-select>

    <ng-container *ngIf="currentProject && detailsOpened && fullProjectInfo">
      <project-details-card [project]="fullProjectInfo"></project-details-card>
    </ng-container>

    <div class="show-details" (click)="detailsOpened = !detailsOpened" *ngIf="!!fullProjectInfo && currentProject">
      {{(!detailsOpened ? 'SEARCH.VIEW_DETAILS' : 'SEARCH.HIDE_CONTRACT_DETAILS') | translate}}
    </div>

    <div class="actions-row">
      <tpt-button type="secondary" (onClick)="close()">
        {{'BUTTON.CANCEL' | translate}}
      </tpt-button>
      <tpt-button class="ml-12" type="primary" [disabled]="!currentProject" (onClick)="hireFreelancer()">
        {{'BUTTON.HIRE' | translate}}
      </tpt-button>
    </div>
  </ng-container>

  <ng-template #emptyProjects>
    <div class="empty-text">{{'SEARCH.NO_PROJECT_TEXT' | translate}}
      <span class="link" (click)="close()" [routerLink]="routeHelper.employerProjectsDrafts">{{'SEARCH.DRAFTS' | translate}}</span>
    </div>

    <div class="actions-row">
      <tpt-button type="secondary" (onClick)="close()">
        {{'BUTTON.CANCEL' | translate}}
      </tpt-button>
      <tpt-button class="ml-12" type="primary" (onClick)="close()" [routerLink]="routeHelper.newProjectEditor">
        {{'CREATE_PROJECT.STEP1.CREATE_A_PROJECT' | translate}}
      </tpt-button>
    </div>
  </ng-template>
</tpt-dialog>

<job-invite-error-dialog [isInvite]="true" (closeEvent)="close()"></job-invite-error-dialog>
<app-select-contract-dialog (selectContract)="selectContractType($event)"></app-select-contract-dialog>
