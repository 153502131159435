<tpt-dialog>
  <div class="headline mb-32">{{'CONTRACTS.NEW_CHAT' | translate}}</div>

  <ng-container *ngIf="form">
    <form [formGroup]="form">
      <mat-stepper>
        <mat-step>
          <div class="subhead mb-16">
            {{'CONTRACTS.CHAT_NAME' | translate}}
          </div>
          <tpt-input class="name-input" [control]="form.get('name')"></tpt-input>

          <div class="subhead mt-12 mb-16">
            {{'CONTRACTS.PROJECT' | translate}}
          </div>

          <tpt-select-search  class="custom-select" [options]="projects"
                              panelClass="custom-select-search"
                              [count]="count"
                              [control]="form.get('projectId')"
                              [placeholder]="'CONTRACTS.SELECT_PROJECT' | translate"
                              (searchOptions)="searchProjects($event)"
                              (selectionChange)="getFreelancers()">

          </tpt-select-search>
          <div class="actions-row mt-36">
            <tpt-button type="secondary" class="cancel" (click)="close()">{{'BUTTON.CANCEL' | translate}}</tpt-button>
            <tpt-button type="primary" (click)="nextStep()">{{'BUTTON.NEXT' | translate}}</tpt-button>
          </div>
        </mat-step>
        <mat-step>
          <div class="subhead mb-24">
            {{'CONTRACTS.ADD_PARTICIPANTS' | translate}}
          </div>

          <mat-selection-list *ngIf="freelancers.length">
            <mat-list-option *ngFor="let freelancer of freelancers" [value]="freelancer"
                             (selectedChange)="changeSelection($event, freelancer)"
                             [selected]="isChecked(freelancer.id)">
              <div class="chat-person">
                <tpt-avatar class="mr-8" size="32" [textSizeRatio]="2.5" [user]="freelancer"></tpt-avatar>

                <div class="person-info">
                    <span class="name">
                      {{ freelancer.firstName }} {{ freelancer.lastName }}
                    </span>
                  <div
                    *ngIf="freelancer?.jobCategoryInfo?.parentCategory"
                    class="caption"
                  >
                    {{ getTranslation(freelancer?.jobCategoryInfo?.parentCategory) }}
                  </div>
                </div>
              </div>

            </mat-list-option>
          </mat-selection-list>

          <div class="actions-row mt-36">
            <tpt-button type="secondary" class="cancel"
                        (click)="stepper.previous()">{{'BUTTON.BACK' | translate}}</tpt-button>
            <tpt-button type="primary" (click)="createChat()">{{'BUTTON.CREATE' | translate}}</tpt-button>
          </div>
        </mat-step>
      </mat-stepper>

    </form>
  </ng-container>
</tpt-dialog>
