<div *ngIf="categories$ | async as categories" class="wrapper">
  <div class="search-row">
    <mat-icon class="search-icon" [svgIcon]="svgIconsEnum.Search"></mat-icon>
    <input [formControl]="searchControl"
           matInput
           class="search-input"
           placeholder="{{'SEARCH.PROJECT_SEARCH_PLACEHOLDER' | translate}}"
           (keyup.enter)="searchProject()"
    >
    <tpt-button type="primary" size="large" (onClick)="searchProject()">
      {{'BUTTON.SEARCH' | translate}}
    </tpt-button>
  </div>

  <div class="sort mb-12">
    <h2 class="sort-title">{{'SEARCH.SORT.SORT_BY' | translate}}</h2>
    <tpt-select [control]="sortControl"
                [options]="sortList"
                [showLabel]="false"
                class="custom-select no-label">
    </tpt-select>
  </div>

  <ng-container *ngIf="!showSkeleton; else projectsLoader">
    <div *ngIf="disableButton" class="info-message">
      <mat-icon class="info-icon" [svgIcon]="svgIconsEnum.InfoIcon"></mat-icon>
      <div>
        Для отправки заявки на&nbsp;участие в&nbsp;проектах выполните привязку профиля к&nbsp;ФНС.
      </div>
    </div>
    <ng-container *ngIf="projects.length; else notFound">
      <div *ngFor="let project of projects" class="mb-16 job-card" [class.visited]="project?.visited">
        <mat-card (click)="openCardDetails(project)">
          <div class="grid-card">
            <div class="left">
              <div class="category-block">
                <div class="category" *ngIf="project?.parentCategoryId">{{getProjectCategory(project, categories)}}
                  <span class="subcategory"
                        *ngIf="project?.subCategoryId">/{{getProjectSubCategory(project, categories)}}</span>
                </div>
                <div class="publication-time-ago">
                  <span class="caption">{{'SEARCH.PUBLISHED' | translate}} {{project.publishDate | timeAgo}}</span>
                </div>
              </div>
              <div class="headline name">{{project?.projectName}}</div>
              <div class="regular description">
                {{project?.description | tptTruncate: 220}}
              </div>

              <div class="main-info">
                <div *ngIf='project.country && project.country.name' class="country-name sub-main-info">
                  <p class="main-info-category">{{'SEARCH.OPTIONS.FREELANCERS_LOCATION' | translate}}</p>
                  <p class="main-info-value">{{getTranslation(project.country)}}</p>
                </div>

                <div *ngIf='!project.country || !project.country.name' class="country-name sub-main-info">
                  <p class="main-info-category">{{'SEARCH.OPTIONS.FREELANCERS_LOCATION' | translate}}</p>
                  <p class="main-info-value">{{'SEARCH.ANY' | translate}}</p>
                </div>
              </div>

            </div>
            <div class="right">
              <div class="owner-wrapper">
                <div class="owner">
                  <div class="owner-info">
                    <div *ngIf="project.employer?.firstName || project.employer?.lastName" class="owner-fullname">
                      {{project.employer?.firstName}} {{project.employer?.lastName}}
                    </div>
<!--                    <div class="owner-rating caption" *ngIf="project?.owner?.rating">-->
<!--                      {{'SETTINGS.RATING' | translate}} {{ project.owner.rating | percent }}-->
<!--                    </div>-->
                  </div>
                  <div class="avatar">
                    <tpt-avatar [textSizeRatio]="2.5" [user]="project?.employer"
                                size="32"></tpt-avatar>
                  </div>
                </div>

                <button mat-stroked-button
                        class="about-button"
                        (click)="$event.stopPropagation(); openEmployerInfo(project?.employer)"
                >{{'SEARCH.ABOUT_EMPLOYER' | translate}}</button>
              </div>

              <div>
                <div class="price-rate">
                  {{currencySymbol.RUB}}{{project.budget | number}}
                </div>
              </div>
            </div>
          </div>

          <div class="grid-card bottom mt-20">
            <div class="left">
              <div class="skills-wrap">
                <div class="skills pseudo-chips" [class.filtered]="getChipStyle(skill)"
                     *ngFor="let skill of project.skills">
                  {{getTranslation(skill)}}
                </div>
                <div class="skills pseudo-chips" [class.filtered]="getChipStyle(skill)"
                     *ngFor="let skill of project.customSkills">
                  {{skill}}
                </div>
              </div>
            </div>
            <div class="right">
              <div class="actions">
                <div class="request-btn d-flex" *ngIf="!isEmployer">
                  <tpt-button type="primary"
                              *ngIf="!project.jobConversations?.length"
                              [disabled]="disableButton" class="send-request"
                              (click)="$event.stopPropagation()"
                              matTooltip="Для отправки заявки на участие в проектах выполните привязку профиля к ФНС."
                              matTooltipClass="disabled-button-tooltip"
                              matTooltipPosition="above"
                              [matTooltipDisabled]="!disableButton"
                              [matTooltipShowDelay]="200"
                              (onClick)="sendProposal(project)">
                    {{'SEARCH.SEND_REQUEST' | translate}}
                  </tpt-button>

                  <div *ngIf="['PROPOSAL_SUBMITTED', 'INVITED'].includes(project.jobConversations?.[0]?.participationState)"
                       class="request-sent"
                       (click)="$event.stopPropagation()">
                    {{(project.jobConversations?.[0]?.participationState === 'PROPOSAL_SUBMITTED' ? 'SEARCH.REQUEST_SENT' : 'SEARCH.INVITE_RECEIVED') | translate}}
                    <mat-icon [svgIcon]="svgIconsEnum.CheckSmall" class="check-icon"></mat-icon>
                  </div>

                  <tpt-button type="primary"
                              *ngIf="['PROPOSAL_SUBMITTED', 'INVITED'].includes(project.jobConversations?.[0]?.participationState) &&
                                      project?.jobConversations?.[0]?.chatId"
                              class="ml-4"
                              (click)="$event.stopPropagation()"
                              (onClick)="openChat(project?.jobConversations?.[0]?.chatId)"
                  >
                    Перейти в чат
                  </tpt-button>

                  <div *ngIf="project.jobConversations?.[0]?.participationState === 'PARTICIPANT'" class="request-sent"
                       (click)="$event.stopPropagation()">
                    {{'SEARCH.IN_PROGRESS' | translate}}
                  </div>
                </div>

                <button mat-icon-button class="copy-btn"
                        (click)="$event.stopPropagation(); copyJobLink(project)">
                  <mat-icon [svgIcon]="svgIconsEnum.CopyLink"></mat-icon>
                </button>
              </div>
            </div>
          </div>
        </mat-card>
      </div>
    </ng-container>

    <ng-template #notFound>
      <div class="not-found">
        <div class="not-found-title">{{'SEARCH.NO_RESULTS_TITLE' | translate}}</div>
        <div class="not-found-subtitle">{{'SEARCH.NO_RESULTS_SUBTITLE' | translate}}</div>
      </div>
    </ng-template>
  </ng-container>

  <ng-template #projectsLoader>
    <ngx-skeleton-loader
      count="9"
      animation="progress"
      [theme]="{
          'border-radius': '4px',
          height: '264px',
          background: '#eff1f6',
          'box-shadow': '0 4px 4px rgba(0, 0, 0, 0.1)'
        }"
    ></ngx-skeleton-loader>
  </ng-template>
</div>

<tpt-employer-info-dialog></tpt-employer-info-dialog>

<tpt-get-acquainted-dialog></tpt-get-acquainted-dialog>

<job-apply-error-dialog></job-apply-error-dialog>
