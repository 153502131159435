import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { CoRouteHelper } from '@/modules/creative-objects/helpers/co-router-helper';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(private router: Router) { }

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (localStorage.getItem('auth-token')) {
      // logged in so return true
      return true;
    }

    const routerStateSnapshotUrl: string = state.url;
    if (routerStateSnapshotUrl.includes('/creative-objects/app/product/')) {
      const idArray = routerStateSnapshotUrl.split('/');
      const id = idArray[idArray.length - 1];
      this.router.navigate([`/creative-objects/catalog/${id}`]);
      return false;
    }

    const service = route.data.service;
    if (service === 'CREATIVE_OBJECTS') {
      this.router.navigate([CoRouteHelper.EMPTY_PATH]);
      return false;
    }

    // not logged in so redirect to login page with the return url
    this.router.navigate(['/login'], { queryParams: { returnUrl: state.url }});
    return false;
  }
}
