<tpt-dialog>
    <div *ngIf="selfEmployedStatus === 'NEW' || selfEmployedStatus === null" class="wrapper">
      <mat-icon [svgIcon]="svgIconsEnum.ErrIcon" class="mb-28"></mat-icon>

      <div class="heading mb-16">Ограниченный доступ</div>

      <div class="regular-14-20 text">
        Свяжите свой профиль самозанятого с профилем Talent Cloud и заполните личный кабинет для отправки заявки на проект.
      </div>

      <div class="actions-row mt-48">
        <tpt-button type="secondary" size="large" (onClick)="close()">
          Закрыть
        </tpt-button>
        <tpt-button type="primary" size="large" class="ml-12"
                    (onClick)="close()"
                    [routerLink]="routeHelper.fnsAttachPage">
          К привязке к ФНС
        </tpt-button>
      </div>
    </div>

    <div *ngIf="selfEmployedStatus === 'ACTIVE'" class="wrapper">
      <mat-icon [svgIcon]="svgIconsEnum.ErrIcon" class="mb-28"></mat-icon>

      <div class="heading mb-16">
        Ограниченный доступ
      </div>

      <div class="regular-14-20 text">
        Заполните разделы “Юридические данные” и “Банковские реквизиты” для отправки заявки на проект.
      </div>
      <div class="actions-row mt-48">
        <tpt-button type="secondary" size="large" (onClick)="close()">
          Закрыть
        </tpt-button>
        <tpt-button type="primary" size="large" class="ml-12"
                    (onClick)="close()"
                    [routerLink]="routeHelper.settingsLegalData">
          Заполнить
        </tpt-button>
      </div>
    </div>
</tpt-dialog>
