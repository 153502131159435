import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AbstractControl, UntypedFormControl } from '@angular/forms';
import { SvgIconsEnum } from '../../../../types/svg-icons.enum';
import { debounceTime } from 'rxjs/operators';
import { TptControlDirective } from '../../directives/tpt-control.directive';
import { ValidationErrorService } from '../../services/validation-error.service';
import { MatSelect } from '@angular/material/select';

@Component({
  selector: 'tpt-select-search',
  templateUrl: './select-search.component.html',
  styleUrls: ['./select-search.component.scss']
})
export class SelectSearchComponent extends TptControlDirective  implements OnInit, AfterViewInit  {
  @ViewChild(MatSelect) select: MatSelect;

  @Input()
  public control: AbstractControl = new UntypedFormControl(null);

  @Input()
  public options = [];

  @Input()
  public placeholder = '';

  @Input()
  public count = 5;

  @Input()
  public panelClass = '';

  @Input()
  public showEmptyValue = false;

  @Input()
  public valueField = 'id';

  @Input()
  public nameField = 'name';

  @Input()
  public userSearchSelect = false;

  @Output()
  public selectionChange: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  searchOptions: EventEmitter<any> = new EventEmitter<any>();

  public svgIconsEnum = SvgIconsEnum;

  public inputForm: UntypedFormControl;

  constructor(protected validationErrorService: ValidationErrorService) {
    super(validationErrorService);
  }

  ngOnInit() {
    this.inputForm = new UntypedFormControl('');

    this.inputForm.valueChanges.pipe(debounceTime(200)).subscribe((val) => {
      if (!val.trim) {
        return;
      }

      this.searchOptions.emit(val);
    });
  }

  ngAfterViewInit() {
    this.select._positions = [
      {
        originX: 'center',
        originY: 'bottom',
        overlayX: 'center',
        overlayY: 'top',
        offsetY: 1
      },
      {
        originX: 'center',
        originY: 'top',
        overlayX: 'center',
        overlayY: 'bottom',
        offsetY: -1
      },
    ];
  }

}
