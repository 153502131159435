import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Role } from '../classes/enums';
import { ProfileService } from '../services/profile.service';
import {RouteHelper} from '../helpers/route.helper';

const USER_KEY = 'auth-user';

@Injectable()
export class MainPageGuard implements CanActivate {

  constructor(private profileService: ProfileService, private router: Router) { }

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const currentUser = JSON.parse(localStorage.getItem(USER_KEY));

    const roles = currentUser?.roles || [];

    if (!currentUser) {
      // not authorized so return true
      return true;
    }

    const page = route.data.page;
    if (page === 'product-page') {
      const routerStateSnapshotUrl: string = state.url;
      if (routerStateSnapshotUrl.includes('/creative-objects/catalog/')) {
        const idArray = routerStateSnapshotUrl.split('/');
        const id = idArray[idArray.length - 1];
        this.router.navigate([`/creative-objects/app/product/${id}`]);
        return false;
      }
    }

    if (currentUser && roles.includes(Role.EMPLOYER)) {
      this.router.navigate([RouteHelper.PROJECTS_DASHBOARD_PATH]);
      return false;
    }

    if (currentUser && roles.includes(Role.FREELANCER)) {
      const invitedUser = localStorage.getItem('invitedUser');
      if (route.queryParams?.inviteCode || invitedUser === currentUser.email) {
        localStorage.removeItem('invitedUser');
        this.router.navigate([RouteHelper.CLIENTS], {queryParams: route.queryParams});
        return false;
      }

      if (currentUser.selfEmployedStatus !== 'ACTIVE') {
        this.router.navigate([RouteHelper.FNS_PAGE]);
        return false;
      }

      this.router.navigate([RouteHelper.SEARCH_PATH]);
      return false;
    }
  }
}
