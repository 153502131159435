import { Component, EventEmitter, OnDestroy, Output, ViewChild } from '@angular/core';
import { DialogComponent } from '../../../dialog/components/dialog/dialog.component';
import { SpinnerService } from '../../../tpt-ui/services/spinner.service';
import { RouteHelper } from '../../../../helpers/route.helper';
import { MatDialogConfig } from '@angular/material/dialog';
import { SvgIconsEnum } from '../../../../types/svg-icons.enum';
import {UntypedFormControl} from '@angular/forms';
import {FinanceServiceV2} from '@/modules/common-dialogs/services/finance.service';

enum WithdrawStateEnum {
  Initial,
  Loading,
  Success,
  Error,
}

@Component({
  selector: 'tpt-withdraw-funds-dialog',
  templateUrl: './withdraw-funds-dialog.component.html',
  styleUrls: [ './withdraw-funds-dialog.component.scss' ],
})
export class WithdrawFundsDialogComponent implements OnDestroy {

  @Output()
  public readonly dialogClose = new EventEmitter<void>();

  @Output()
  public readonly updateBalance = new EventEmitter<void>();

  @ViewChild(DialogComponent)
  public dialog: DialogComponent;

  public formControl = new UntypedFormControl('');

  public withdrawStateEnum = WithdrawStateEnum;

  public svgIconsEnum = SvgIconsEnum;

  public withdrawState: WithdrawStateEnum = WithdrawStateEnum.Initial;

  private className = 'tpt-withdraw-funds-dialog';

  private config: MatDialogConfig = {
    width: '520px',
    minHeight: '305px',
  };

  constructor(
    public routeHelper: RouteHelper,
    private spinnerService: SpinnerService,
    private financeService: FinanceServiceV2,
  ) { }

  ngOnDestroy(): void {
  }

  public open(): void {
    this.dialog.config = this.config;
    this.dialog.open(this.className);
    this.withdrawState = WithdrawStateEnum.Initial;
  }

  public close = (): void => {
    this.dialogClose.emit();
    this.dialog.close();
  }

  public withdrawFunds(): void {
    if (this.formControl.invalid) {
      return;
    }

    const amount = Number(this.formControl.value);
    this.withdrawState = WithdrawStateEnum.Loading;

    this.financeService.withdraw(amount)
      .then(() => {
        this.withdrawState = WithdrawStateEnum.Success;
        this.updateBalance.emit();
      })
      .catch(() => {
        this.withdrawState = WithdrawStateEnum.Error;
      });

  }

}
