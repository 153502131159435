import { Injectable } from '@angular/core';
import { ChatApiService } from '../../../services/chat.api.service';
import { BehaviorSubject, forkJoin, Observable, Subject } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { ContractEventModel } from '../models/contract-event.model';
import { ChatItemModel } from '../models/chat-item.model';
import { ChatListItemModel } from '../models/chat-list-item.model';
import { ProjectApiService } from '../../../services/project.api.service';
import { NegotiationResponse } from '../components/parts/contracts-project-card/contracts-project-card.component';
import { SearchService } from '../../../services/search.service';

export interface ChatFilter {
  pageSize?: number;
  pageNumber?: number;
  searchQuery?: string;
  projectId?: number;
  categories?: number[];
  skills?: number[];
  levels?: string[];
  rateMin?: number;
  rateMax?: number;
  budgetMin?: number;
  budgetMax?: number;
  countryCode?: string;
  languageLevels?:
    {
      code: string,
      level: string
    }[];
  fixedProject?: boolean;
  hourlyProject?: boolean;
  ratingLevel?: string;
  provenRecord?: true;
}

@Injectable({
  providedIn: 'root'
})
export class ContractsService {

  public proposalsCount = 0;

  public chatsCount = 0;

  public selectedProject: ChatListItemModel;

  public selectedChat: ChatItemModel;

  public refreshAllChats: Subject<void>;

  public findChats: BehaviorSubject<string> = new BehaviorSubject<string>('');

  public findProposals: BehaviorSubject<ChatFilter> = new BehaviorSubject<ChatFilter>({searchQuery: ''});

  public selectedSidebarTab = new Subject<string>();

  private allChats: ChatListItemModel[];
  private allProposals: ChatListItemModel[];

  constructor(
    private chatApiService: ChatApiService,
    private projectApiService: ProjectApiService,
  ) {
    this.refreshAllChats = new Subject<void>();
  }

  public getAllChats = (search: string = ''): Observable<{chats: ChatListItemModel[]}> => {
    return forkJoin([
      this.chatApiService.getSupportChat(),
      this.chatApiService.getAllChats(search)
    ]).pipe(
      map(([supportChatRes, allChatsRes]) => {
        // некоторые манипуляции, чтобы привести чат саппорта и все остальные чаты к единому виду
        const supportChat = supportChatRes ? [{chats: [supportChatRes]}] : [];
        const allChats = allChatsRes?.chats || [];
        return {
          chats: [...supportChat, ...allChats]
        };
      }),
      tap(this.handleAllChats)
    );
  }

  public getUnreadCount = (): Observable<any> => {
    return this.chatApiService.getUnreadCount();
  }

  public getAllProposals = (query: ChatFilter): Observable<{ proposals: ChatListItemModel[] }> => {
    const params = SearchService.transformParams(query);
    return this.chatApiService.getAllProposals(params).pipe(
      tap(this.handleAllProposals),
    );
  }

  public getChatEvents = (chatId: string): Observable<ContractEventModel[]> => {
    return this.chatApiService.getChatEvents(chatId).pipe(
      tap(() => this.findSelectedProject(chatId)),
      tap(() => this.findSelectedChat(chatId)),
    );
  }

  public getContractById = (id: string): Observable<NegotiationResponse> => {
    return this.projectApiService.getContractById(id);
  }

  public declineFreelancer = (): Observable<void> => {
    return this.chatApiService.declineFreelancer(this.selectedChat.jobConversationId);
  }

  public acceptFreelancer = (): Observable<void> => {
    return this.chatApiService.acceptFreelancer(this.selectedChat.jobConversationId);
  }

  public declineInvite = (): Observable<void> => {
    return this.chatApiService.declineInvite(this.selectedChat.jobConversationId);
  }

  public acceptInvite = (): Observable<void> => {
    return this.chatApiService.acceptInvite(this.selectedChat.jobConversationId);
  }

  public cancel = (): Observable<void> => {
    return this.chatApiService.decline(this.selectedChat.jobConversationId);
  }

  public dispose(): void {
    this.selectedChat = null;
    this.selectedProject = null;
    this.allChats = null;

    // if (this.refreshAllChats) {
    //   this.refreshAllChats.complete();
    //   this.refreshAllChats = null;
    // }
  }

  public findSelectedProject = (chatId: string): void => {
    const chats = this.allChats;
    const proposals = this.allProposals;
    this.selectedProject = proposals.concat(chats)
      .find((chatList: ChatListItemModel) => {
          if (chatList) {
            return chatList.chats?.some((chat: ChatItemModel) => chat.id === chatId);
          }
        }
      );
  }

  private handleAllChats = (response: {chats: ChatListItemModel[]}): void => {
    this.allChats = response.chats;
    this.chatsCount = response.chats.length;
  }

  private handleAllProposals = (response: {proposals: ChatListItemModel[]}): void => {
    this.allProposals = response.proposals;
    this.proposalsCount = response.proposals.length;
  }

  private findSelectedChat = (chatId: string): void => {
    if (!this.selectedProject) { return; }
    const chat = this.selectedProject.chats.find((chat: ChatItemModel) => chat.id === chatId);
    this.selectedChat = chat;
  }

}
