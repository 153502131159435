import { ExpertLevel, Role } from '../classes/enums';
import { Country } from './country';
import { CategoryV2Model } from './category-v2.model';
import { LanguageLevelModel } from './language-level.model';
import { SkillV2Model } from './skill-v2.model';
import { Feedback } from './feedback/rating-variables';
import { EmployerLegalStatusEnum, FreelancerLegalStatusEnum } from './legalStatuses.enum';
import { SpecializationModel } from './specialization.model';

export class ProfileModel {

  public id: string;

  public firstName: string;

  public lastName: string;

  public middleName: string;

  public patronymic: string;

  public birthDate: string;

  public country: Country;

  // Freelancer profile field
  public expertLevel: ExpertLevel[];

  public currentTime: string;

  public description: string;

  public email: string;

  public contactEmail: string;

  public phone: string;

  public skype: string;

  public website: string;

  public links: string[];

  public category: CategoryV2Model;

  public avatar: string;

  public roles: Role[];

  public timeZone: string;

  public hourlyRate: number;

  public languages: Array<LanguageLevelModel>;

  public skills: Array<SkillV2Model>;

  public company: string;

  // Field for the freelancer card in the search page
  public detailsOpened: boolean;

  // Freelancer profile fields
  public activeProjects: number[];

  public proposals: number[];

  public invites: number[];

  public jobParticipating: any[];

  public customSkills: Array<string>;

  public skillIds: Array<any>;

  public gender: string;

  public categoryId: string;

  public subCategoryId: string;

  public interfaceLanguage: string;

  public onBoardingInfo: {
    onBoardingViewed: boolean,
    firstInviteChatId: string,
    firstProposalChatId: string,
    showFirstInviteChatOnBoarding: boolean,
    showFirstProposalChatOnBoarding: boolean
  };

  public profileFullness: number;

  public rating: number;

  public reviews: Feedback[];

  public incomingShow: boolean;

  public outgoingShow: boolean;

  public chatId: string;

  public experience: {
    code: string;
    checkingDate: string;
    duration: number;
    rating: number;
    file: any;
  }[];

  public legalType: EmployerLegalStatusEnum | FreelancerLegalStatusEnum;

  public legalStatus: {
    individualEntrepreneur: {
      inn?: number,
      country?: string,
      region?: string,
      city?: string,
      street?: string,
      house?: string,
      apartment?: string
    },
    entity: {
      requisites: {
        inn?: number,
        ogrn?: number,
        country?: string,
        region?: string,
        city?: string,
        street?: string,
        house?: string,
        apartment?: string
      },
      representative: {
        firstName?: string,
        lastName?: string,
        middleName?: string,
        position?: string,
      },
      representativeAddress: {
        country?: string,
        region?: string,
        city?: string,
        street?: string,
        house?: string,
        apartment?: string
      },
    },
    selfEmployed: {
      inn?: number,
      bankAccount?: number,
      country?: string,
      region?: string,
      city?: string,
      street?: string,
      house?: string,
      apartment?: string
    },
  } | EmployerLegalStatusEnum | FreelancerLegalStatusEnum;

  public emailVerified: boolean;

  public canBeInvitedToRubProject: boolean;

  public newUnverifiedEmail: string;

  public specializations: SpecializationModel[];

  public hasPassword: boolean;

  public phoneVerified: boolean;

  public hideContacts: boolean;

  public selfEmployedStatus: 'NEW' | 'ACTIVE' | 'REMOVED_FROM_REGISTER' | 'DETACHED' | 'NOT_VERIFIED' ;

  public legalInfoValidated: boolean;

  public bankDetailsValidated: boolean;

  public bankDetailsValidatedCo: boolean;

  public personInfoFilled: boolean;

  public creativeObjectsDescription: string;

  // @TODO array for chats between talent and client
  public chats: any[];

  public isEmployer() {
    return this.roles.includes(Role.EMPLOYER);
  }

  public isFreelancer() {
    return this.roles.includes(Role.FREELANCER);
  }
}
