import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Role } from '../classes/enums';
import { ProfileService } from '../services/profile.service';
import { ProfileModel } from '@/models/profile.model';

const USER_KEY = 'auth-user';

@Injectable()
export class RoleGuard implements CanActivate {

  constructor(private profileService: ProfileService, private router: Router) { }

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const currentUser: ProfileModel = JSON.parse(localStorage.getItem(USER_KEY));
    const expectedRoles = route.data.expectedRoles as Role[];

    const validRole = currentUser?.roles?.some(role => expectedRoles.includes(role));

    if (currentUser && validRole) {
      // authorized under the required role so return true
      return true;
    }

    if (currentUser?.roles?.length === 1 && currentUser?.roles?.includes(Role.CREATIVE_OBJECTS_USER) &&
      !expectedRoles.includes(Role.CREATIVE_OBJECTS_USER)) {
      this.router.navigate(['/accept']);
      return false;
    }

    if (expectedRoles.length === 1 && expectedRoles.includes(Role.CREATIVE_OBJECTS_USER) &&
        !currentUser.roles.includes(Role.CREATIVE_OBJECTS_USER)) {
      this.router.navigate(['/creative-objects/app/accept']);
      return false;
    }

    // unauthorized under the required role so redirect to home page with the return url
    this.router.navigate(['/login']);
    return false;
  }
}
