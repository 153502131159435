<tpt-dialog>
  <div class="dialog-wrapper">
    <div class="dialog-title">
      Выберите тип личного кабинета
    </div>

    <div class="dialog-text" *ngIf="role === 'employer'">
      Ваш юридический статус и реквизиты нужны для формирования документов для работы с исполнителями.<br/>
      Если потребуется, вы сможете изменить юридический статус в дальнейшем.
    </div>

    <div class="dialog-text" *ngIf="role === 'freelancer'">
      Ваш юридический статус и реквизиты нужны для формирования документов для работы с заказчиками.<br/>
      Если потребуется, вы сможете изменить свой юридический статус.
    </div>

    <div class="cards-wrap">
      <div *ngFor="let card of cards"
           class="card"
           [class.active]="card.id === entityType"
           matRipple
           (click)="selectType(card.id)"
      >
        <mat-icon [svgIcon]="card.icon" class="mb-12"></mat-icon>

        <div class="card-name">{{('SETTINGS.' + card.name) | translate}}</div>
        <div class="card-text">{{card.description}}</div>

        <div class="currency-wrapper" *ngIf="card.currency">
          <div class="currency">{{'SETTINGS.AVAILABLE_CURRENCIES' | translate}}: </div>
          <div class="currency-list">{{card.currency}}</div>
        </div>
      </div>
    </div>

    <div class="actions-row mt-40">
      <tpt-button type="secondary" size="medium" (click)="close()">{{'BUTTON.CANCEL' | translate}}</tpt-button>
      <tpt-button type="primary" size="medium" (click)="changeType()">{{'SETTINGS.CHANGE_STATUS' | translate}}</tpt-button>
    </div>

  </div>
</tpt-dialog>
