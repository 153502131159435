export class PermissionModel {

  name: string;

  roleId: string;

  roleName: string;

}

export enum PermissionEnum {
  ROLES_EDITING = 'ROLES_EDITING',
  ROLES_ASSIGNING = 'ROLES_ASSIGNING',
  CHAT_CREATING = 'CHAT_CREATING',
  TASKS_READ = 'TASKS_READ',
  TASKS_COMMENTING = 'TASKS_COMMENTING'
}

export enum PermissionsForGA {
  ROLES_EDITING = 'edit',
  ROLES_ASSIGNING = 'assign',
  CHAT_CREATING = 'messages',
}
