<tpt-dialog>
  <div class="dialog-wrapper">
    <div class="heading mb-8">Ошибка регистрации</div>
  </div>

  <div class="regular-16-24 mb-48">
    Данный email уже используется. Вы можете восстановить пароль
    к данной учетной записи или ввести другой email
  </div>

  <div class="actions-row">
    <tpt-button type="primary" size="large" class="full-width"
                (onClick)="useAnotherEmail()">Ввести другой email</tpt-button>
    <tpt-button type="secondary" size="large" class="full-width"
                (onClick)="goToRestorePassword()">Восстановить доступ</tpt-button>
  </div>
</tpt-dialog>
