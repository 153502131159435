import { Component, OnDestroy, ViewChild } from '@angular/core';
import { MatDialogConfig } from '@angular/material/dialog';
import { MatSelectionListChange } from '@angular/material/list';
import { MatStepper } from '@angular/material/stepper';
import { Router } from '@angular/router';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { DialogComponent } from '../../../dialog/components/dialog/dialog.component';
import { SvgIconsEnum} from '../../../../types/svg-icons.enum';
import { ProfileService } from '../../../../services/profile.service';
import { ProjectApiService } from '../../../../services/project.api.service';
import { SpinnerService } from '../../../tpt-ui/services/spinner.service';
import { ChatApiService } from '../../../../services/chat.api.service';
import { RouteHelper } from '../../../../helpers/route.helper';
import { ContractsService } from '../../../contracts/services/contracts.service';
import { DataLayerService } from '../../../../services/data-layer.service';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {RoleModel} from '@/models/project-role/role.model';
import {ProfileModel} from '@/models/profile.model';

@Component({
  selector: 'tpt-new-chat-dialog',
  templateUrl: './new-chat-dialog.component.html',
  styleUrls: ['./new-chat-dialog.component.scss']
})
export class NewChatDialogComponent implements OnDestroy {

  @ViewChild(DialogComponent)
  public dialog: DialogComponent;

  @ViewChild(MatStepper)
  public stepper: MatStepper;

  public svgIconsEnum = SvgIconsEnum;

  form: UntypedFormGroup;

  projects;

  freelancers = [];

  public currentUserId: string;

  public selectedFreelancers = [];

  public count: number;

  private config: MatDialogConfig = {
    width: '520px',
  };

  private className = 'tpt-new-chat-dialog';

  private readonly destroy$ = new Subject<void>();

  constructor(private translate: TranslateService,
              private projectApiService: ProjectApiService,
              private chatApiService: ChatApiService,
              private contractsService: ContractsService,
              private routeHelper: RouteHelper,
              private router: Router,
              private spinner: SpinnerService,
              private dataLayerService: DataLayerService,
              private profileService: ProfileService) {
  }

  public open(): void {
    this.dialog.config = this.config;
    this.dialog.open(this.className);

    this.form = new UntypedFormGroup({
      name: new UntypedFormControl('', [Validators.required]),
      projectId: new UntypedFormControl(null, [Validators.required]),
    });

    if (this.stepper) {
      this.stepper.selectedIndex = 0;
      this.selectedFreelancers = [];
    }

    this.spinner.startSpinner();
    this.projectApiService.getMyProjectsList().pipe(takeUntil(this.destroy$)).subscribe(res => {
      this.projects = res;
      this.count = res.length;
      this.spinner.stopSpinner();
    }, this.spinner.stopSpinner);

    this.currentUserId = this.profileService.currentProfile.id;
  }

  public searchProjects(value: string): void {
    this.projectApiService.getMyProjectsList(value).pipe(takeUntil(this.destroy$)).subscribe(res => {
      this.projects = res;
      this.spinner.stopSpinner();
    }, this.spinner.stopSpinner);
  }

  public close(): void {
    this.dialog.close();
  }

  public getTranslation(item): string {
    if (this.translate.currentLang === 'ru') {
      return item.nameRu;
    }
    return item.name;
  }

  public getFreelancers(): void {
    const projectId = this.form.controls.projectId.value;
    this.projectApiService.getFreelancers(projectId).pipe(takeUntil(this.destroy$))
      .subscribe(res => {
      this.freelancers = res.filter(item => item.id !== this.currentUserId);

      this.projectApiService.getProjectById(projectId).pipe(takeUntil(this.destroy$)).subscribe((project => {
        if (project.employer.id !== this.currentUserId) {
          this.freelancers.push(project.employer);
        }
      }));
    });
  }

  public nextStep(): void {
    if (this.form.controls.name.invalid || this.form.controls.projectId.invalid) {
      this.form.controls.name.markAsTouched();
      this.form.controls.projectId.markAsTouched();
      return;
    }

    this.stepper.next();
  }

  public createChat(): void {
    if (!this.selectedFreelancers.length) { return; }

    const participantIds = this.selectedFreelancers.map(item => item.id);

    const body = this.form.value;
    body.participantIds = participantIds;

    this.chatApiService.createProjectChat(body)
      .then((res) => {
        this.contractsService.refreshAllChats.next(null);
        this.router.navigate(this.routeHelper.contractsChatsUrl(res.id));
        this.close();
    })
      .catch(error => console.log(error));
  }

  public changeSelection(event: boolean, user: ProfileModel) {
    if (event) {
      this.selectedFreelancers.push(user);
    } else {
      this.selectedFreelancers = this.selectedFreelancers.filter(freelancer => freelancer.id !== user.id);
    }
  }

  public isChecked(id): boolean {
    return !!this.selectedFreelancers.filter(freelancer => freelancer.id === id).length;
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
