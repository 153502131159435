import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormControl } from '@angular/forms';
import { lastValueFrom, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { ProjectRolesService } from '../../../../services/project-roles.service';
import { UserRoleModel } from '../../../../models/project-role/user-role.model';
import { SpinnerService } from '../../../tpt-ui/services/spinner.service';
import { RoleModel } from '../../../../models/project-role/role.model';
import { UserRolesDialogComponent } from '../../../common-dialogs/components/user-roles-dialog/user-roles-dialog.component';
import { ProfileService } from '../../../../services/profile.service';
import { PermissionEnum } from '../../../../models/project-role/permission.model';
import { SimpleProjectResponseModel } from '../../../employer/models/simple-project-response.model';
import { ProjectApiService } from '../../../../services/project.api.service';
import { RouteHelper } from '../../../../helpers/route.helper';

@Component({
  selector: 'tpt-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit, OnDestroy {

  public get isEmployer(): boolean {
    return this.profileService.currentProfile.isEmployer();
  }

  @ViewChild(UserRolesDialogComponent) userRolesDialog: UserRolesDialogComponent;

  public users: UserRoleModel[] = [];

  public currentProjectControl = new UntypedFormControl();

  public dropdownItems = [];

  public showRolesTab = false;

  public rolesAssigning = false;

  public currentProfileId: string;

  public project: SimpleProjectResponseModel;

  private destroy$ = new Subject<void>();

  private projectId: string;

  constructor(private rolesService: ProjectRolesService,
              private spinnerService: SpinnerService,
              private profileService: ProfileService,
              private projectApiService: ProjectApiService,
              private router: Router,
              private routeHelper: RouteHelper,
              private activatedRoute: ActivatedRoute) {
    this.projectId = activatedRoute.parent.parent.snapshot.params.id;
  }

  async ngOnInit() {
    this.getProjectUsersRoles();
    this.getMyPermissions();

    this.currentProfileId = this.profileService.currentProfile.id;

    this.project = await lastValueFrom(this.projectApiService.getProjectById(this.projectId));
    const { id, name } = this.project;
    this.currentProjectControl.setValue({ id, name });

    this.activatedRoute.parent.parent.params.pipe(takeUntil(this.destroy$)).subscribe(params => {
      if (this.projectId === params.id) {
        return;
      }
      this.projectId = params.id;
      this.getProject();
    });

    this.currentProjectControl.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((val) => {
      if (val.id !== this.projectId) {
        this.router.navigate(this.routeHelper.employerAccessPageUsers(val.id));
      }
    });

    this.getCurrentTabProjects();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public getProject = async (): Promise<void> => {
    this.project = await lastValueFrom(this.projectApiService.getProjectById(this.projectId));
    this.getProjectUsersRoles();
    this.getMyPermissions();
  }

  public updateUsers() {
    this.getProjectUsersRoles();
  }

  public splitArray(roles: RoleModel[]): {firstPart: RoleModel[], secondPart: RoleModel[], secondPartLength: number} {
    return this.rolesService.splitArray(roles);
  }

  public openUserRolesDialog(user: UserRoleModel) {
    this.userRolesDialog.open(user, this.projectId, this.rolesAssigning);
  }

  public compareFn = (opt, select) => {
    return opt && select && opt?.id === select?.id;
  }

  private getCurrentTabProjects(): void {
    if (!this.project || this.dropdownItems?.length) { return; }
    const state = ['ACTIVE', 'ENDED', 'DRAFT'].includes(this.project.state) ? this.project.state : 'ACTIVE';

    this.projectApiService.getAllJobsList({state}).pipe(takeUntil(this.destroy$)).subscribe(res => {
        this.dropdownItems = res.filter(item => item.type === 'COMPLEX').map(item => {
          return {
            id: item.id,
            name: item.name
          };
        });
     }
    );
  }

  private getMyPermissions() {
    if (this.profileService.currentProfile.isEmployer()) {
      this.showRolesTab = true;
      this.rolesAssigning = true;
      return;
    }

    this.rolesService.getMyRolesInProject(this.projectId).subscribe(res => {
      const permissions = [];
      res.roles.forEach(role => {
        role.permissions.forEach(perm => {
          permissions.push(perm.name);
        });
      });

      this.showRolesTab = permissions.includes(PermissionEnum.ROLES_EDITING);
      this.rolesAssigning = permissions.includes(PermissionEnum.ROLES_ASSIGNING);
    }, () => {});
  }

  private getProjectUsersRoles() {
    this.spinnerService.startSpinner();
    this.rolesService.getProjectUsersRoles(this.projectId).subscribe((res) => {
      this.spinnerService.stopSpinner();
      this.users = res;
    });
  }
}
