import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { KeyValue } from '@angular/common';
import { MatDialogConfig } from '@angular/material/dialog';
import { DialogComponent } from '../../../dialog/components/dialog/dialog.component';
import { ResourcesService } from '../../../../services/resources.service';
import { CancelReceiptDialogComponent } from '../cancel-receipt-dialog/cancel-receipt-dialog.component';
import { FinanceServiceV2 } from '../../services/finance.service';
import { SpinnerService } from '../../../tpt-ui/services/spinner.service';
import { SnackbarNotificationsService } from '../../../../services/snackbar-notifications.service';
import { ProfileService } from '../../../../services/profile.service';
import { FutureExpenseModel } from '../../../finance/models/future-expense.model';

@Component({
  selector: 'tpt-receipt-dialog',
  templateUrl: './receipt-dialog.component.html',
  styleUrls: ['./receipt-dialog.component.scss']
})
export class ReceiptDialogComponent {
  @ViewChild(DialogComponent)
  public dialog: DialogComponent;

  @ViewChild(CancelReceiptDialogComponent, {static: false}) cancelReceiptDialogComponent: CancelReceiptDialogComponent;

  public fileId: string;

  public tempUrl: string;

  public projectId: string;

  public receiptCanceled = false;

  @Output()
  updateHistory: EventEmitter<any> = new EventEmitter<any>();

  private config: MatDialogConfig = {
    width: '450px',
    maxHeight: '100vh',
  };

  private className = 'tpt-receipt-dialog';

  constructor(private resource: ResourcesService,
              private spinner: SpinnerService,
              public profile: ProfileService,
              private snack: SnackbarNotificationsService,
              private financeService: FinanceServiceV2) {
  }

  public open(operation: FutureExpenseModel, doc: KeyValue<any, any>): void {
    this.fileId = doc.value?.id;
    this.tempUrl = doc.value?.tempUrl;
    this.projectId = operation?.jobInfo?.projectId;
    this.receiptCanceled = operation?.docs?.receiptCanceled;
    this.dialog.config = this.config;
    this.dialog.open(this.className);
  }

  public close(): void {
    this.dialog.close();
  }

  public getUrl(link: string): string {
    return this.resource.getTempUrl(link);
  }

  public cancelReceipt(reason: string): void {
    if (!reason) { return; }

    this.spinner.startSpinner();
    this.financeService.cancelReceipt(this.projectId, reason).subscribe(() => {
      this.spinner.stopSpinner();
      this.close();
      this.updateHistory.emit();
    }, () => {
      this.snack.showSmthWentWrong();
      this.spinner.stopSpinner();
    });
  }

  public download() {
    if (!this.fileId) {
      return;
    }

    this.resource.getFileByUUID(this.fileId).subscribe(this.handleFile);
  }

  public openCheckCancelDialog(): void {
    this.spinner.startSpinner();
    this.financeService.cancelReceiptReasons().subscribe(reasons => {
      this.spinner.stopSpinner();
      this.cancelReceiptDialogComponent.open(reasons);
    }, this.spinner.stopSpinner);
  }

  private handleFile = (file: File): void => {
    const objectURL = this.resource.createObjectURL(file);
    const link = document.createElement('a');
    link.download = decodeURIComponent(file.name);
    link.href = objectURL;
    link.click();
    URL.revokeObjectURL(objectURL);
  }
}
