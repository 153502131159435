import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { lastValueFrom, Subject } from 'rxjs';
import { map, takeUntil, tap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

import { SimpleProjectResponseModel } from '../../employer/models/simple-project-response.model';
import { ProjectBoardService } from '../../employer/services/project-board.service';
import { SpinnerService } from '../../tpt-ui/services/spinner.service';
import { CurrencySymbol } from '../../../models/currency.enum';
import { ProfileService } from '../../../services/profile.service';
import { EmployerInfoDialogComponent } from '../../common-dialogs/components/info-dialogs/employer-info-dialog/employer-info-dialog.component';
import { LanguageOption } from '../../../models/language';
import { LanguageService } from '../../../services/language.service';
import { SpecializationModel } from '../../../models/specialization.model';
import { CategoryV2Model } from '@/models/category-v2.model';
import { CategoryService } from '@/services/category.service';

@Component({
  selector: 'tpt-project-info',
  templateUrl: './project-info.component.html',
  styleUrls: ['./project-info.component.scss']
})
export class ProjectInfoComponent implements OnInit, OnDestroy {

  @ViewChild(EmployerInfoDialogComponent)
  public employerInfoDialog: EmployerInfoDialogComponent;

  public project: SimpleProjectResponseModel;

  public currencySymbol = CurrencySymbol;

  public languagesArray: Array<LanguageOption> = [];

  public categories: CategoryV2Model[] = this.categoryService.categories;

  private projectId: string;

  private readonly destroy$ = new Subject<void>();

  get isEmployer(): boolean {
    return this.profile.currentProfile.isEmployer();
  }

  constructor(
    private projectBoardService: ProjectBoardService,
    private activatedRoute: ActivatedRoute,
    private spinner: SpinnerService,
    private languageService: LanguageService,
    public profile: ProfileService,
    public translate: TranslateService,
    private categoryService: CategoryService,
  ) { }

  async ngOnInit(): Promise<void> {
    this.languagesArray = await lastValueFrom(this.languageService.getLanguagesV2());

    if (!this.categories?.length) {
      this.categories = await lastValueFrom(this.categoryService.getCategoriesV2());
    }

    this.activatedRoute.parent.params.pipe(
      map((params: Params) => params.id || null),
      tap((id: string) => this.projectId = id),
      tap((id: string) => this.projectBoardService.currentProjectId = id),
      takeUntil(this.destroy$),
    ).subscribe(this.getProjectInfo);

  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public openEmployerInfo(owner): void {
    this.employerInfoDialog.open(owner, false, false);
  }

  public getLangByCode(lang): string {
    const language = this.languagesArray?.find(item => item.code === lang.code);
    return this.getTranslation(language);
  }

  public getTranslation(item): string {
    if (this.translate.currentLang === 'ru') {
      return item?.nameRu;
    }
    return item?.name;
  }

  public specSkills(specialization: SpecializationModel) {
    const skills = specialization.skills || [];
    const customSkills = specialization.customSkills || [];

    const allSkills = [...skills, ...customSkills];
    const skillsLength = allSkills?.length;

    let showedSkills;
    if (skillsLength > 6 && !specialization.showAllSkills) {
      showedSkills = allSkills.slice(0, 6);
      specialization.moreSkillsCount = skillsLength - 6;
    } else {
      showedSkills = allSkills;
    }

    return showedSkills;
  }

  public showAllSkills(spec: SpecializationModel): void {
    spec.showAllSkills = true;
    spec.moreSkillsCount = 0;
  }

  public getProjectCategory(job) {
    const category = this.categories.find(c => c.id === job.parentCategoryId);
    job.category = category;
    return this.getTranslation(category);
  }

  public getProjectSubCategory(job) {
    const category = this.categories.find(c => c.id === job.parentCategoryId);
    const subCategory = category?.children.find(c => c.id === job.subCategoryId);
    job.subCategory = subCategory;
    return this.getTranslation(subCategory);
  }

  private getProjectInfo = (id: string): void => {
    this.spinner.startSpinner();
    this.projectBoardService.getProjectInfo(id).subscribe((project) => {
      this.project = project;
      this.spinner.stopSpinner();
    }, this.spinner.stopSpinner);
  };

}
