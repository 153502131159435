<div class="page-wrapper">
  <div class="header-row">
    <mat-select class="projects-dropdown"
                *ngIf="dropdownItems?.length && isEmployer; else projectName"
                [formControl]="currentProjectControl"
                [matTooltip]="currentProjectControl.value?.name"
                [matTooltipDisabled]="!currentProjectControl.value?.name"
                matTooltipShowDelay="1500"
                [compareWith]="compareFn"
                [disableOptionCentering]="true">
      <mat-option
        *ngFor="let item of dropdownItems"
        [value]="item">
        {{ item?.name }}
      </mat-option>
    </mat-select>
    <ng-template #projectName>
      <span class="page-title">{{currentProjectControl.value?.name}}</span>
    </ng-template>
  </div>
  <div class="header-page">
    <span class="page-title">{{'ACCESS.ROLES_TITLE' | translate}}</span>
    <div class="tabs">
      <span class="tab regular not-active" routerLink="../users">{{'ACCESS.USERS' | translate}}</span>
      <span class="tab regular active">{{'ACCESS.ROLES' | translate}}</span>
    </div>
  </div>

  <div class="roles-text">{{'ACCESS.ROLES_TEXT' | translate}}</div>

  <div class="roles-wrapper">
    <div class="left-side">
      <ng-container *ngIf="roles?.length">
        <div *ngFor="let role of roles"
             (click)="setActiveRole(role)"
             class="role-item"
             [class.active]="(activeRole?.roleId === role?.roleId) && !addNewRoleState"
        >{{role.name}}</div>
      </ng-container>
      <div class="new-role-button" *ngIf="!addNewRoleState" (click)="addNewRole()">
        <mat-icon [svgIcon]="svgIconsEnum.PlusIcon"></mat-icon>
        {{'ACCESS.NEW_ROLE' | translate}}
      </div>
      <div class="role-item" *ngIf="addNewRoleState" [class.active]=addNewRoleState>
        {{'ACCESS.NEW_ROLE' | translate}}</div>
    </div>
    <div class="right-side">
      <ng-container>
        <div class="role-name-title subhead mb-24">{{'ACCESS.ROLE_NAME' | translate}}</div>
        <div class="role-name mb-32">
          <input matInput #input class="name-input"
                 [placeholder]="'ACCESS.PLACEHOLDER' | translate"
                 [disabled]="rolesWithEditing.includes(activeRoleStats.roleId)"
                 [(ngModel)]="activeRoleStats.name" [class.error]="!input.value.length"/>
        </div>

        <div class="subhead mb-24">{{'ACCESS.MAIN_RIGHTS' | translate}}</div>

        <div class="main-right-wrapper mb-20">
          <div>
            <div class="main-right-title">{{'ACCESS.EDIT_ROLES' | translate}}</div>
            <div class="main-right-info">{{'ACCESS.EDIT_ROLES_INFO' | translate}}</div>
          </div>
          <mat-slide-toggle color="primary" [(ngModel)]="activeRoleStats.rolesEditing"
                            [disabled]="rolesWithEditing.includes(activeRoleStats.roleId)"
          ></mat-slide-toggle>
        </div>

        <div class="main-right-wrapper mb-20">
          <div>
            <div class="main-right-title">{{'ACCESS.ASSIGN_ROLES' | translate}}</div>
            <div class="main-right-info">{{'ACCESS.ASSIGN_ROLES_INFO' | translate}}</div>
          </div>
          <mat-slide-toggle color="primary" [(ngModel)]="activeRoleStats.roleAssigning"
                            [disabled]="rolesWithEditing.includes(activeRoleStats.roleId)"
          ></mat-slide-toggle>
        </div>

        <div class="main-right-wrapper mb-20">
          <div>
            <div class="main-right-title">{{'ACCESS.CHATS' | translate}}</div>
            <div class="main-right-info">{{'ACCESS.CHATS_INFO' | translate}}</div>
          </div>
          <mat-slide-toggle color="primary" [(ngModel)]="activeRoleStats.chatCreating"
                            [disabled]="rolesWithEditing.includes(activeRoleStats.roleId)"
          ></mat-slide-toggle>
        </div>

        <div class="subhead mb-12 comment-title">
          {{'ACCESS.COMMENT' | translate}}
          <tpt-tooltip-button [tooltipText]="'ACCESS.COMMENT_TOOLTIP' | translate"></tpt-tooltip-button>
        </div>
        <div class="main-right-info">{{'ACCESS.COMMENT_INFO' | translate}}</div>
        <div class="comment-row mb-32">
          <div class="pseudo-chips">
            <ng-container *ngIf="activeRoleStats?.taskCommenting?.length; else addRoleComment">
              <tpt-chip class="chip" type="outline" size="small" *ngFor="let role of activeRoleStats.taskCommenting">
                <span class="text">{{role.name}}</span>
              </tpt-chip>
            </ng-container>
            <ng-template #addRoleComment>
              <tpt-chip class="chip add" size="small"
                        [type]="rolesWithEditing.includes(activeRoleStats.roleId) ? 'disabled' : 'outline'"
                        (click)="openCommentDialog()">
                <span class="text">+{{'ACCESS.ADD_ROLE' | translate}}</span>
              </tpt-chip>
            </ng-template>
          </div>
          <div class="action">
            <button mat-icon-button class="edit-roles"
                    *ngIf="activeRoleStats?.taskCommenting?.length"
                    [disabled]="rolesWithEditing.includes(activeRoleStats.roleId)"
                    (click)="openCommentDialog()">
              <mat-icon>more_horiz</mat-icon>
            </button>
          </div>
        </div>

        <div class="subhead mb-12 comment-title">
          {{'ACCESS.VIEW_TASKS' | translate}}
        </div>
        <div class="main-right-info">{{'ACCESS.VIEW_TASKS_INFO' | translate}}</div>
        <div class="comment-row">
          <div class="pseudo-chips">
            <ng-container *ngIf="activeRoleStats?.tasksRead?.length; else addRole">
              <tpt-chip class="chip" type="outline" size="small" *ngFor="let role of activeRoleStats.tasksRead">
                <span class="text">{{role.name}}</span>
              </tpt-chip>
            </ng-container>
            <ng-template #addRole>
              <tpt-chip class="chip add" [type]="rolesWithEditing.includes(activeRoleStats.roleId) ? 'disabled' : 'outline'"
                        size="small"
                        (click)="openReadDialog()">
                <span class="text">+{{'ACCESS.ADD_ROLE' | translate}}</span>
              </tpt-chip>
            </ng-template>
          </div>
          <div class="action">
            <button mat-icon-button class="edit-roles"
                    *ngIf="activeRoleStats?.tasksRead?.length"
                    [disabled]="rolesWithEditing.includes(activeRoleStats.roleId)"
                    (click)="openReadDialog()">
              <mat-icon>more_horiz</mat-icon>
            </button>
          </div>
        </div>
      </ng-container>
    </div>
  </div>

  <div class="actions-row mt-36">
    <tpt-button *ngIf="!addNewRoleState" type="primary"
                [disabled]="rolesWithEditing.includes(activeRoleStats.roleId)"
                (onClick)="saveRole(false)">{{'BUTTON.SAVE' | translate}}
    </tpt-button>
    <tpt-button *ngIf="addNewRoleState" type="primary" (click)="saveRole(true)">
      {{'BUTTON.CREATE' | translate}}
    </tpt-button>
  </div>
</div>

<role-rights-dialog (saveRolesEvent)="updateRoles($event)"></role-rights-dialog>
