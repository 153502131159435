import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, OnDestroy, Output, ViewChild } from '@angular/core';
import { TemplatePortal } from '@angular/cdk/portal';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router } from '@angular/router';

import { CoSvgIconsEnum, SvgIconsEnum } from '@/types/svg-icons.enum';
import { ProfilesApiService } from '@/services/profiles.api.service';
import { RouteHelper } from '@/helpers/route.helper';
import { CurrencySymbol } from '@/models/currency.enum';
import { ProfileService } from '@/services/profile.service';
import { PortalService } from '@/services/portal.service';
import { DataLayerService } from '@/services/data-layer.service';
import { HelpCenterComponent } from '@/modules/common-dialogs/components/help-center/help-center.component';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Role } from '@/classes/enums';
import { CoRouteHelper } from '@/modules/creative-objects/helpers/co-router-helper';
import { AuthService } from '@/services/auth.service';

@Component({
  selector: 'tpt-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements AfterViewInit, OnDestroy {

  @ViewChild(HelpCenterComponent) helpDialog: HelpCenterComponent;

  @Output() toggleMobileSidebar = new EventEmitter<void>();

  public get isEmployer(): boolean {
    return this.profileService.currentProfile.isEmployer();
  }

  public headerPortal: TemplatePortal | null = null;

  public isDark: boolean;

  public svgIconsEnum = SvgIconsEnum;

  public currencySymbol = CurrencySymbol;

  public coSvgIconsEnum = CoSvgIconsEnum;

  public role = Role;

  private readonly destroy$ = new Subject<void>();

  constructor(
    public profileService: ProfileService,
    public profileApiService: ProfilesApiService,
    private portalService: PortalService,
    private dataLayerService: DataLayerService,
    private cdf: ChangeDetectorRef,
    private router: Router,
    public routeHelper: RouteHelper,
    private coRouteHelper: CoRouteHelper,
    private authService: AuthService,
    public activatedRoute: ActivatedRoute,
  ) {
    router.events.subscribe((val) => {
      if (val instanceof NavigationEnd || val instanceof NavigationStart) {
        this.isDark = val.url.startsWith('/subscription') ||
          val.url === '/';
      }
    });
  }

  public ngAfterViewInit(): void {
    this.portalService.headerPortal.pipe(
      takeUntil(this.destroy$),
    ).subscribe((portal: TemplatePortal) => {
      this.headerPortal = portal;
      this.cdf.detectChanges();
    });
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public logout(): void {
    this.profileService.logout();
  }

  public goToCo(): void {
    const roles = this.profileService.currentProfile.roles;
    const client = 'co_client';

    this.authService.switchFlow(client)
      .then(res => this.authService.switchAuth(res.code, client))
      .then(async (response) => {
        this.authService.updateToken(response);
        await this.profileService.refreshCurrentProfile();
      });

    if (roles.length === 1 && !roles?.includes(Role.CREATIVE_OBJECTS_USER)) {
      this.router.navigate(this.coRouteHelper.acceptPage);
      return;
    }

    this.router.navigate(this.routeHelper.creativeObjects);
  }
}
