<div class="header-container">
  <nav class="home-header">
    <mat-icon [svgIcon]="svgIconsEnum.HeaderLogo" class="logo" [routerLink]="['/']"></mat-icon>

    <div class="buttons">
<!--      <button mat-button class="header-lang" (click)="setLang()" >-->
<!--        {{langFE[switchTo] | uppercase}}-->
<!--      </button>-->
      <button mat-button class="login-btn" [queryParams]="activatedRoute.snapshot.queryParams"
              [routerLink]="['/login']">{{'MAIN_PAGE.SIGN_IN' | translate}}</button>
      <button mat-button class="register-btn" [routerLink]="['/register']">{{'MAIN_PAGE.SIGN_UP' | translate}}</button>
    </div>
  </nav>
</div>
