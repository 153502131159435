<tpt-dialog>
  <ng-container *ngIf="step === topUpStateEnum.Initial">
    <div class="title mb-24">Пополнение баланса</div>
    <div class="label">Укажите сумму пополнения</div>
    <div class="input-wrapper">
      <div class="prefix">₽</div>
      <tpt-input [control]="formControl" type="number" class="full-width mb-20"></tpt-input>
    </div>

    <div class="tip mb-32">
      <mat-icon class="info-icon" [svgIcon]="svgIconsEnum.InfoIcon"></mat-icon>
      Размер комиссии устанавливается банком.
      Средства зачисляются на баланс по факту поступления
      на номинальный счет.
    </div>

    <div class="actions-row">
      <tpt-button type="secondary" size="large" (onClick)="close()">Отменить</tpt-button>
      <tpt-button type="primary" size="large" (onClick)="generateInvoice()">Сформировать счет</tpt-button>
    </div>
  </ng-container>

  <ng-container *ngIf="step === topUpStateEnum.Loading">
    <div class="loading-wrapper">
      <mat-spinner [diameter]="32" color="primary"></mat-spinner>
      <div class="title mt-12 mb-8">Идет формирование счета...</div>
      <div class="gray-light">Пожалуйста, подождите</div>
    </div>
  </ng-container>

  <div *ngIf="step === topUpStateEnum.Success" class="success">
    <div class="title mb-24">Счет для оплаты готов</div>
    <div class="label">После оплаты счета средства появятся на вашем балансе</div>

    <div class="invoice" *ngIf="invoice" (click)="downloadInvoice(invoice)">
      <mat-icon [svgIcon]="svgIconsEnum.CheckCircle" class="mr-8"></mat-icon>
      <span>{{invoice?.name}}</span>
    </div>

    <div class="actions-row mt-48">
      <tpt-button type="secondary" size="large" (onClick)="close()">Закрыть</tpt-button>
      <tpt-button type="primary" size="large" (click)="downloadInvoice(invoice)">Скачать счет</tpt-button>
    </div>
  </div>

  <ng-container *ngIf="step === topUpStateEnum.Error">
    <div class="loading-wrapper">
      <svg width="47" height="48" viewBox="0 0 47 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect y="0.0488281" width="47" height="47" rx="7.5" fill="#FBF3F3"/>
        <path
          d="M14.2446 14.8835L14.3537 14.761C14.8629 14.2518 15.6659 14.2154 16.2171 14.6519L16.3396 14.761L23.29 21.7114L30.2404 14.761C30.7496 14.2518 31.5526 14.2154 32.1038 14.6519L32.2262 14.761C32.7354 15.2702 32.7718 16.0732 32.3354 16.6244L32.2262 16.7468L25.2758 23.6973L32.2262 30.6477C32.7355 31.1569 32.7718 31.9599 32.3354 32.511L32.2262 32.6335C31.717 33.1427 30.9141 33.1791 30.3629 32.7426L30.2404 32.6335L23.29 25.6831L16.3396 32.6335C15.8304 33.1427 15.0274 33.1791 14.4762 32.7426L14.3537 32.6335C13.8445 32.1243 13.8082 31.3213 14.2446 30.7702L14.3537 30.6477L21.3042 23.6973L14.3537 16.7468C13.8445 16.2376 13.8082 15.4347 14.2446 14.8835Z"
          fill="#EE1E1E"/>
      </svg>

      <div class="title mb-24 mt-12">Ошибка формирования</div>
      <div class="label text-center">Повторите попытку через 10 минут. Если ошибка повторится, свяжитесь с нами</div>

      <div class="actions-row mt-48">
        <tpt-button type="secondary" size="large" (onClick)="createChatWithSupport()">Поддержка</tpt-button>
        <tpt-button type="primary" size="large" (onClick)="formControl.reset(); step = topUpStateEnum.Initial; ">
          Повторить попытку
        </tpt-button>
      </div>
    </div>

  </ng-container>
</tpt-dialog>
