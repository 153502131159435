<tpt-dialog>
  <div class="dialog-wrapper">
    <div class="headline mb-16">
      Аннулировать чек?
    </div>

    <div class="regular-14-20">
      Аннулирование чека приведете к перерасчету налога.<br/>
      Если вы уверенны, что хотите аннулировать чек, выберите причину:
    </div>

    <div class="mt-16 regular-14-20">
      <mat-radio-group [formControl]="reason">
        <div *ngFor="let reason of reasons">
          <mat-radio-button class="mb-12" color="primary" [value]="reason.code">{{reason?.description}}</mat-radio-button>
        </div>
      </mat-radio-group>
    </div>

    <div class="actions-row mt-40">
      <tpt-button type="secondary" size="medium" (onClick)="close()">
        Отмена
      </tpt-button>
      <tpt-button type="warn" size="medium" class="cancel-receipt ml-12" (onClick)="cancelReceipt()">
        Аннулировать
      </tpt-button>
    </div>
  </div>
</tpt-dialog>
