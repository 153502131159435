import { AbstractControl, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

import { FormContext } from '../../tpt-forms/base/form.context';
import { PassportModel } from '../../../models/userLegalStatus.model';
import { digitLengthValidator } from '../../../validators/exact-length-digit-validator';
import { birthdateValidator } from '../../../validators/birthdate-validator';
import { Role } from '@/classes/enums';

export class PassportForm extends FormContext<PassportModel> {

  static createForm(passportModel?: PassportModel, roles?: Role[]): PassportForm {
    const passportNumber = passportModel?.citizenship === 'KAZAKHSTAN' ?
      passportModel?.passportNumber.replace('N', '') : passportModel?.passportNumber;

    const form = new UntypedFormGroup({
      firstName: new UntypedFormControl({value: passportModel?.firstName || '', disabled: roles.includes(Role.FREELANCER)},
        [Validators.required, Validators.maxLength(255),
          Validators.pattern('^[а-яА-ЯЁё-\\s]+$')]),
      lastName: new UntypedFormControl({value: passportModel?.lastName || '', disabled: roles.includes(Role.FREELANCER)},
        [Validators.required, Validators.maxLength(255),
          Validators.pattern('^[а-яА-ЯЁё-\\s]+$')]),
      patronymic: new UntypedFormControl({value: passportModel?.patronymic || '', disabled: roles.includes(Role.FREELANCER)},
        [Validators.maxLength(255), Validators.pattern('^[а-яА-ЯЁё-\\s]+$')]),
      birthDate: new UntypedFormControl(passportModel?.birthDate, [Validators.required, birthdateValidator]),
      inn: new UntypedFormControl({value: passportModel?.inn || '', disabled: true},
        [Validators.required, digitLengthValidator(12), Validators.maxLength(12)]),
      citizenship: new UntypedFormControl(passportModel?.citizenship || 'RUSSIA', [Validators.required]),
      passportSeries: new UntypedFormControl(passportModel?.passportSeries || ''),
      passportNumber: new UntypedFormControl(passportNumber || ''),
      issued: new UntypedFormControl(passportModel?.issued || ''),
      issuedBy: new UntypedFormControl(passportModel?.issuedBy || ''),
      departmentCode: new UntypedFormControl(passportModel?.departmentCode || '', [Validators.required]),
      birthPlace: new UntypedFormControl(passportModel?.birthPlace || '', [Validators.required])
    });

    return new PassportForm(form);
  }

  public get inn(): AbstractControl {
    return this.getControl('inn');
  }

  public get citizenship(): AbstractControl {
    return this.getControl('citizenship');
  }

  public get firstName(): AbstractControl {
    return this.getControl('firstName');
  }

  public get lastName(): AbstractControl {
    return this.getControl('lastName');
  }

  public get patronymic(): AbstractControl {
    return this.getControl('patronymic');
  }

  public get birthDate(): AbstractControl {
    return this.getControl('birthDate');
  }

  public get birthPlace(): AbstractControl {
    return this.getControl('birthPlace');
  }

  public get series(): AbstractControl {
    return this.getControl('passportSeries');
  }

  public get number(): AbstractControl {
    return this.getControl('passportNumber');
  }

  public get dateOfIssue(): AbstractControl {
    return this.getControl('issued');
  }

  public get issuedBy(): AbstractControl {
    return this.getControl('issuedBy');
  }

  public get departmentCode(): AbstractControl {
    return this.getControl('departmentCode');
  }

  constructor(
    public form: UntypedFormGroup,
  ) {
    super(form);
  }

  public getFormData(): PassportModel {
    return this.form.value;
  }

  public getRawValue(): PassportModel {
    return this.form.getRawValue();
  }

}
