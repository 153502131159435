<div class="search-page-root">
  <aside cdkScrollable>
    <div class="aside-container">
      <div class="filters-header">
        <div class="subhead">Фильтры</div>
        <tpt-button type="ghost" class="refresh" *ngIf="filtersNotEmpty()" (onClick)="refreshFilters()">
          <mat-icon [svgIcon]="svgIconsEnum.ArrowRefresh"></mat-icon>
          Сбросить
        </tpt-button>
      </div>
      <div *ngIf="categories" class="categories-container section">
        <h2 class="filter-title">{{'SEARCH.OPTIONS.AREA_OF_INTERESTS' | translate}}</h2>
        <div class="categories">
          <section *ngFor="let category of categories; let i = index" class="category-section">
            <span class="list-section">
              <mat-checkbox class="list-section main-checkbox"
                            [ngClass]="{'mat-checkbox-indeterminate': someChecked(category)}"
                            [(ngModel)]="category.isSelected"
                            color="primary"
                            (change)="setAll($event.checked, category);
                            getUpdAllSkills($event.checked, category, null)">
              </mat-checkbox>
              <span class="category-name" (click)="toggleSubcats(category)">{{getTranslation(category)}}</span>
            </span>
            <span *ngIf="category.expanded">
              <ul class="sublist-section">
                <li *ngFor="let subCategory of category.children">
                  <mat-checkbox color="primary"
                                [class.selected-sub]="subCategory.isSelected"
                                [checked]="subCategory.isSelected"
                                (change)="subCategoryChanged($event.checked, subCategory, category);
                                getUpdAllSkills($event.checked, category, subCategory)">
                    {{getTranslation(subCategory)}}
                  </mat-checkbox>
                </li>
              </ul>
            </span>
          </section>
        </div>
      </div>
      <form [formGroup]="filter">
        <div class="skills-container section" *ngIf="skills">
          <h2 class="filter-title mb-0">{{'SEARCH.OPTIONS.SKILLS' | translate}}</h2>
          <div class="skills">
            <mat-form-field class="chip-list" appearance="none">
              <mat-chip-list #chipList>
                <input
                  #inputElement
                  [placeholder]="'SEARCH.OPTIONS.ENTER_SKILLS' | translate"
                  [formControl]="skillCtrl"
                  [matAutocomplete]="auto"
                  [matChipInputFor]="chipList"
                  [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                >
              </mat-chip-list>
              <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectedSkill($event); inputElement.blur()">
                <mat-option *ngFor="let skill of filteredItems" [value]="skill">
                  {{getTranslation(skill) || skill}}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
            <div class="pseudo-chips"
                 *ngFor="let skill of filter.get('skills').controls; let i = index">
              {{getTranslation(skill?.value) || skill?.value}}
              <mat-icon [svgIcon]="svgIconsEnum.Close" (click)="removeSkill(i)"></mat-icon>
            </div>
          </div>
        </div>

        <div class="specialist-level section">
          <h2 class="filter-title">{{'SEARCH.OPTIONS.SPECIALIST_LEVEL' | translate}}</h2>
          <div [formGroup]="levelsFormControl" class="checkbox-wrapper">
            <mat-checkbox color="primary" class="mr-12"
                          formControlName="beginner">{{'SEARCH.LEVEL.BEGINNER' | translate}}</mat-checkbox>
            <mat-checkbox color="primary" class="mr-12"
                          formControlName="intermediate">{{'SEARCH.LEVEL.INTERMEDIATE' | translate}}</mat-checkbox>
            <mat-checkbox color="primary" formControlName="master">{{'SEARCH.LEVEL.MASTER' | translate}}</mat-checkbox>
          </div>
        </div>

        <div class="project-budget section">
          <h2 class="filter-title">
            {{'TEAM.JOB_BUDGET' | translate}}, {{currencySymbol.RUB}}
          </h2>
          <nouislider #budgetSlider
                      class="budget-slider"
                      [ngModelOptions]="{standalone: true}"
                      [config]="budgetSliderConfig"
                      [(ngModel)]="filterSlider.budget"
                      (ngModelChange)="budgetChange($event)"
                      name="budget">
          </nouislider>
          <div class="slider-inputs">
            <div class="input-wrapper">
              <div class="prefix">{{currencySymbol.RUB}}, {{'CONTRACTS.FROM' | translate}}</div>
              <input mask="separator" type="text" thousandSeparator=" "
                     [min]="budgetSliderConfig.range.min" [(ngModel)]="filterSlider.budget[0]"
                     [ngModelOptions]="{standalone: true}" (ngModelChange)="setBudgetSliderValue()">
            </div>
            <div class="input-wrapper">
              <div class="prefix">{{currencySymbol.RUB}}, {{'CONTRACTS.TO' | translate}}</div>
              <input mask="separator" type="text" thousandSeparator=" " [max]="budgetSliderConfig.range.max"
                     [(ngModel)]="filterSlider.budget[1]"
                     [ngModelOptions]="{standalone: true}" (ngModelChange)="setBudgetSliderValue()">
            </div>
          </div>
        </div>

        <div class="location section">
          <h2 class="filter-title">{{'SEARCH.OPTIONS.FREELANCERS_LOCATION' | translate}}</h2>
          <tpt-select-search class="custom-select"
                             panelClass="custom-select-search search"
                             [control]="filter.controls.countryCode"
                             valueField="code"
                             [options]="countries"
                             [nameField]="getViewField()"
                             [count]="countriesCount"
                             (searchOptions)="getCountries(false, $event)">
          </tpt-select-search>
        </div>

        <div class="language section">
          <h2 class="filter-title">{{'SEARCH.OPTIONS.LANGUAGE_SKILLS' | translate}}</h2>
          <button mat-button (click)="addLang()"
                  class="add-lang-btn">{{'SEARCH.OPTIONS.ADD_LANGUAGE' | translate}}</button>
          <div class="language-block" *ngFor="let lang of languageArray; index as i">
            <div class="selects">
              <mat-select placeholder="{{'SEARCH.OPTIONS.CHOOSE_LANGUAGE' | translate }}"
                          [(value)]="lang.code"
                          (selectionChange)="updateLanguageForm()"
                          class="language-select">
                <mat-option *ngFor="let language of languages" [value]="language.code" [disabled]="language.disabled">
                  {{getTranslation(language)}}
                </mat-option>
              </mat-select>
              <mat-select placeholder="{{'SEARCH.OPTIONS.LANGUAGE_SKILLS' | translate }}"
                          [(value)]="lang.level"
                          (selectionChange)="updateLanguageForm()">
                <mat-option *ngFor="let level of languageLevels" [value]="level">
                  {{ 'CREATE_PROJECT.STEP3.' + level | translate}}
                </mat-option>
              </mat-select>
            </div>
            <div>
              <mat-icon [svgIcon]="svgIconsEnum.Trash"
                        (click)="removeLang(i)"
                        class="trash-icon"
              ></mat-icon>
            </div>
          </div>

        </div>
      </form>
    </div>

  </aside>
  <div class="search-page-layout" (scroll)="onScroll($event)">
    <div #top></div>
    <tpt-project-card (emitSortChange)="updateSortOrder($event)"
                      (searchEvent)="updateProjects($event)"
                      [showSkeleton]="showSkeleton"
                      [projects]="projects"
                      [skillsFilter]="filter.get('skills')"></tpt-project-card>
    <div class="searching-wrap" *ngIf="loading">
      <mat-icon [svgIcon]="svgIconsEnum.HeaderLogo"></mat-icon>
      <div class="searching">{{'SEARCH.LOADING_MORE' | translate}}</div>
    </div>
  </div>
</div>
