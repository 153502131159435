import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { DialogComponent } from '@/modules/dialog/components/dialog/dialog.component';
import { MatDialogConfig } from '@angular/material/dialog';
import { ImageCropperComponent } from 'ngx-image-cropper';
import { SnackbarNotificationsService } from '@/services/snackbar-notifications.service';
import { SvgIconsEnum } from '@/types/svg-icons.enum';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-photo-crop-dialog',
  templateUrl: './photo-crop-dialog.component.html',
  styleUrls: ['./photo-crop-dialog.component.scss']
})
export class PhotoCropDialogComponent {

  @ViewChild(DialogComponent)
  public dialog: DialogComponent;

  @ViewChild(ImageCropperComponent) imageCropper: ImageCropperComponent;

  @ViewChild('input')input: ElementRef;

  @Input() title = 'Загрузка фото профиля';

  @Input() aspectRatio = 1;

  @Input() cropperMinWidth = 60;

  @Input() cropperMinHeight = 60;

  @Input() showUploadButton = true;

  @Output() cropImageEvent = new EventEmitter();

  public photoInput;

  public imageChangedEvent: any = '';
  public croppedImage: any = '';
  public svgIconsEnum = SvgIconsEnum;
  public flow: 'TC' | 'CO' = 'TC';

  private config: MatDialogConfig = {
    width: '680px',
    minHeight: '470px',
    maxHeight: '100vh',
    panelClass: 'app-photo-crop-dialog',
  };

  private className = 'app-photo-crop-dialog';

  constructor(private snack: SnackbarNotificationsService) {
  }

  fileChangeEvent(event: any): void {
    const photo = event?.target?.files?.[0];
    if (['image/jpeg', 'image/png'].includes(photo?.type)) {
      this.imageChangedEvent = event;
      this.photoInput = event?.target?.files?.[0];
    } else {
      this.loadImageFailed();
    }
  }

  public open(flow: 'TC' | 'CO', file?: any): void {
    this.flow = flow;
    this.dialog.config = this.config;
    this.dialog.open(this.className);

    if (file) {
      this.imageCropper.imageFile = file;
      this.photoInput = file;
    } else {
      this.input.nativeElement.click();
    }
  }

  public close(): void {
    this.dialog.close();
  }

  imageCropped(event) {
    this.croppedImage = event.base64;
    const blob = this.dataURItoBlob(event);
    const file = new File([blob], this.photoInput?.name, { type: blob.type });
    this.cropImageEvent.emit(file);
  }

  dataURItoBlob(data): Blob {
    const dataURI = data.base64;
    const byteString = atob(dataURI.split(',')[1]);
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: mimeString });
  }

  loadImageFailed() {
    this.snack.showNotification('Загрузите изображение в формате PNG или JPEG', 'fail');
  }

  cropImage() {
    if (!this.photoInput) {
      return;
    }
    this.imageCropper.crop();
    this.imageChangedEvent = '';
    this.input.nativeElement.value = null;
    this.close();
  }

  cancel() {
    this.imageChangedEvent = '';
    this.input.nativeElement.value = null;
    this.close();
  }
}
