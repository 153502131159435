import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { SvgIconsEnum } from '../../types/svg-icons.enum';
import { isMobileDevice } from '../../helpers/get-mobile.helper';
import { Meta } from '@angular/platform-browser';

@Component({
  selector: 'confirm-success',
  templateUrl: './confirm-success.component.html',
  styleUrls: ['./confirm-success.component.scss']
})
export class ConfirmSuccessComponent implements OnInit, OnDestroy {

  public isMobile = isMobileDevice();
  public svgIconsEnum = SvgIconsEnum;

  constructor(private readonly meta: Meta) {
  }

  @HostListener('window:resize') onResize() {
    this.isMobile = isMobileDevice();
  }

  ngOnInit(): void {
    const metaValue = this.meta.getTag('name="viewport"');
    if (metaValue.content !== 'width=device-width, initial-scale=1') {
      this.meta.removeTag('name="viewport"');
      this.meta.addTag({ name: 'viewport', content: 'width=device-width, initial-scale=1' });
    }
  }

  ngOnDestroy() {
    const metaValue = this.meta.getTag('name="viewport"');
    if (metaValue.content !== 'width=1200px') {
      this.meta.removeTag('name="viewport"');
      this.meta.addTag({ name: 'viewport', content: 'width=1200px' });
    }
  }
}
