
export enum Role {
  EMPLOYER        = 'EMPLOYER',
  FREELANCER      = 'FREELANCER',
  ADMIN           = 'ADMIN',
  ARBITRAGE_JUDGE = 'ARBITRAGE_JUDGE',
  NOT_SELECTED = 'NOT_SELECTED',
  CREATIVE_OBJECTS_USER = 'CREATIVE_OBJECTS_USER'
}

export enum SignalType {
  JOB           = 'JOB',
  PROJECT       = 'PROJECT',
  TASK          = 'TASK',
  SUGGESTION    = 'SUGGESTION',
  DOCUMENT      = 'DOCUMENT',
  PAYMENT       = 'PAYMENT',
  DISPUTE       = 'DISPUTE',
  MILESTONE     = 'MILESTONE',
  DEPOSIT       = 'DEPOSIT',
  UPCOMING_HOLD = 'UPCOMING_HOLD',
  MESSAGE       = 'MESSAGE',
  PROJECT_STATE = 'PROJECT_STATE'
}

export enum NotificationType {
  EMPLOYER_REGISTERED= 'EMPLOYER.REGISTERED',
  FREELANCER_REGISTERED= 'FREELANCER.REGISTERED',
  EMPLOYER_FREELANCER_INVITED = 'EMPLOYER.FREELANCER_INVITED',
  FREELANCER_FREELANCER_INVITED = 'FREELANCER.FREELANCER_INVITED',
  EMPLOYER_EMPLOYER_CHANGE_PROJECT = 'EMPLOYER.EMPLOYER_CHANGE_PROJECT',
  FREELANCER_EMPLOYER_CHANGE_PROJECT = 'FREELANCER.EMPLOYER_CHANGE_PROJECT',
  EMPLOYER_FREELANCER_PROPOSAL = 'EMPLOYER.FREELANCER_PROPOSAL',
  FREELANCER_FREELANCER_PROPOSAL = 'FREELANCER.FREELANCER_PROPOSAL',
  EMPLOYER_EMPLOYER_ACCEPTED = 'EMPLOYER.EMPLOYER_ACCEPTED',
  FREELANCER_EMPLOYER_ACCEPTED = 'FREELANCER.EMPLOYER_ACCEPTED',
  EMPLOYER_EMPLOYER_ACCEPTED_CHANGES = 'EMPLOYER.EMPLOYER_ACCEPTED_CHANGES',
  FREELANCER_EMPLOYER_ACCEPTED_CHANGES = 'FREELANCER.EMPLOYER_ACCEPTED_CHANGES',
  EMPLOYER_FREELANCER_ACCEPTED = 'EMPLOYER.FREELANCER_ACCEPTED',
  FREELANCER_FREELANCER_ACCEPTED = 'FREELANCER.FREELANCER_ACCEPTED',
  EMPLOYER_FREELANCER_ACCEPTED_CHANGES = 'EMPLOYER.FREELANCER_ACCEPTED_CHANGES',
  FREELANCER_FREELANCER_ACCEPTED_CHANGES = 'FREELANCER.FREELANCER_ACCEPTED_CHANGES',
  EMPLOYER_EMPLOYER_DECLINED = 'EMPLOYER.EMPLOYER_DECLINED',
  FREELANCER_EMPLOYER_DECLINED = 'FREELANCER.EMPLOYER_DECLINED',
  EMPLOYER_EMPLOYER_DECLINED_CHANGES = 'EMPLOYER.EMPLOYER_DECLINED_CHANGES',
  FREELANCER_EMPLOYER_DECLINED_CHANGES = 'FREELANCER.EMPLOYER_DECLINED_CHANGES',
  EMPLOYER_FREELANCER_DECLINED_CHANGES = 'EMPLOYER.FREELANCER_DECLINED_CHANGES',
  FREELANCER_FREELANCER_DECLINED_CHANGES = 'FREELANCER.FREELANCER_DECLINED_CHANGES',
  EMPLOYER_FREELANCER_DECLINED = 'EMPLOYER.FREELANCER_DECLINED',
  FREELANCER_FREELANCER_DECLINED = 'FREELANCER.FREELANCER_DECLINED',
  FREELANCER_FREELANCER_OPEN_DISPUTE = 'FREELANCER.FREELANCER_OPEN_DISPUTE',
  EMPLOYER_FREELANCER_OPEN_DISPUTE = 'EMPLOYER.FREELANCER_OPEN_DISPUTE',
  EMPLOYER_EMPLOYER_OPEN_DISPUTE = 'EMPLOYER.EMPLOYER_OPEN_DISPUTE',
  FREELANCER_EMPLOYER_OPEN_DISPUTE = 'FREELANCER.EMPLOYER_OPEN_DISPUTE',
  FREELANCER_EMPLOYER_CLOSED_DISPUTE = 'FREELANCER.EMPLOYER_CLOSED_DISPUTE',
  EMPLOYER_FREELANCER_CLOSED_DISPUTE = 'EMPLOYER.FREELANCER_CLOSED_DISPUTE',
  EMPLOYER_EMPLOYER_ACCEPTED_ALL_TASKS = 'EMPLOYER.EMPLOYER_ACCEPTED_ALL_TASKS',
  FREELANCER_EMPLOYER_ACCEPTED_ALL_TASKS = 'FREELANCER.EMPLOYER_ACCEPTED_ALL_TASKS',
  EMPLOYER_EMPLOYER_INSUFFICIENT_FUNDS = 'EMPLOYER.EMPLOYER_INSUFFICIENT_FUNDS',
  EMPLOYER_MILESTONE_INSUFFICIENT_FUNDS = 'EMPLOYER.MILESTONE_INSUFFICIENT_FUNDS',
  EMPLOYER_PROJECT_INSUFFICIENT_FUNDS = 'EMPLOYER.PROJECT_INSUFFICIENT_FUNDS',
  FREELANCER_GOT_PAID = 'FREELANCER.GOT_PAID',
  CARD_ATTACHED = 'CARD_ATTACHED',
  CARD_DETACHED = 'CARD_DETACHED',
  PAYONEER_DETACHED = 'PAYONEER_DETACHED',
  FREELANCER_OTHER_FREELANCER_SELECTED = 'FREELANCER.OTHER_FREELANCER_SELECTED',
  EMPLOYER_FREELANCER_DECLINE_SELF = 'EMPLOYER.FREELANCER_DECLINE_SELF',
  FREELANCER_FREELANCER_DECLINE_SELF = 'FREELANCER.FREELANCER_DECLINE_SELF',
  EMPLOYER_EMPLOYER_DECLINE_SELF = 'EMPLOYER.EMPLOYER_DECLINE_SELF',
  FREELANCER_EMPLOYER_DECLINE_SELF = 'FREELANCER.EMPLOYER_DECLINE_SELF',
  EMPLOYER_FREELANCER_LEFT_COMMENT = 'EMPLOYER.FREELANCER_LEFT_COMMENT',
  FREELANCER_EMPLOYER_LEFT_COMMENT = 'FREELANCER.EMPLOYER_LEFT_COMMENT',
  EMPLOYER_FREELANCER_TASK_STATUS_IN_PROGRESS = 'EMPLOYER.FREELANCER_TASK_STATUS_IN_PROGRESS',
  EMPLOYER_FREELANCER_TASK_STATUS_REVIEW = 'EMPLOYER.FREELANCER_TASK_STATUS_REVIEW',
  EMPLOYER_FREELANCER_TASK_STATUS_TO_DO = 'EMPLOYER.FREELANCER_TASK_STATUS_TO_DO',
  FREELANCER_EMPLOYER_ACCEPTED_TASK = 'FREELANCER.EMPLOYER_ACCEPTED_TASK',
  FREELANCER_EMPLOYER_DECLINED_TASK = 'FREELANCER.EMPLOYER_DECLINED_TASK',
  FREELANCER_EMPLOYER_EDITED_TASK = 'FREELANCER.EMPLOYER_EDITED_TASK',
  EMPLOYER_FREELANCER_REWORK_TASK = 'EMPLOYER.FREELANCER_REWORK_TASK',
  FREELANCER_EMPLOYER_CREATED_TASK = 'FREELANCER.EMPLOYER_CREATED_TASK',
  EMPLOYER_FREELANCER_STARTED_TASK = 'EMPLOYER.FREELANCER_STARTED_TASK',
  EMPLOYER_FREELANCER_DECLINED_TASK = 'EMPLOYER.FREELANCER_DECLINED_TASK',
  EMPLOYER_FREELANCER_CHANGE_PROJECT = 'EMPLOYER.FREELANCER_CHANGE_PROJECT',
  FREELANCER_FREELANCER_CHANGE_PROJECT = 'FREELANCER.FREELANCER_CHANGE_PROJECT',
  EMPLOYER_REVIEW_DEADLINE_IS_SOON = 'EMPLOYER.REVIEW_DEADLINE_IS_SOON',
  EMPLOYER_AUTO_ACCEPT = 'EMPLOYER.AUTO_ACCEPT',
  FREELANCER_EMPLOYER_REVOKE_OWN_CHANGES = 'FREELANCER.EMPLOYER_REVOKE_OWN_CHANGES',
  EMPLOYER_FREELANCER_REVOKE_OWN_CHANGES = 'EMPLOYER.FREELANCER_REVOKE_OWN_CHANGES',
  EMPLOYER_FREELANCER_STARTED_ARBITRAGE = 'EMPLOYER.FREELANCER_STARTED_ARBITRAGE',
  FREELANCER_EMPLOYER_STARTED_ARBITRAGE = 'FREELANCER.EMPLOYER_STARTED_ARBITRAGE',
  EMPLOYER_REVIEW_ABOUT_FREELANCER_IS_ALLOWED = 'EMPLOYER.REVIEW_ABOUT_FREELANCER_IS_ALLOWED',
  FREELANCER_REVIEW_ABOUT_EMPLOYER_IS_ALLOWED = 'FREELANCER.REVIEW_ABOUT_EMPLOYER_IS_ALLOWED',
  EMPLOYER_ARBITRAGE_DECISION_IN_FAVOR_OF_EMPLOYER = 'EMPLOYER.ARBITRAGE_DECISION_IN_FAVOR_OF_EMPLOYER',
  FREELANCER_ARBITRAGE_DECISION_IN_FAVOR_OF_EMPLOYER = 'FREELANCER.ARBITRAGE_DECISION_IN_FAVOR_OF_EMPLOYER',
  EMPLOYER_ARBITRAGE_DECISION_IN_FAVOR_OF_FREELANCER = 'EMPLOYER.ARBITRAGE_DECISION_IN_FAVOR_OF_FREELANCER',
  FREELANCER_ARBITRAGE_DECISION_IN_FAVOR_OF_FREELANCER = 'FREELANCER.ARBITRAGE_DECISION_IN_FAVOR_OF_FREELANCER',
  EMPLOYER_ARBITRAGE_DECISION_IN_PROPORTION = 'EMPLOYER.ARBITRAGE_DECISION_IN_PROPORTION',
  FREELANCER_ARBITRAGE_DECISION_IN_PROPORTION = 'FREELANCER.ARBITRAGE_DECISION_IN_PROPORTION',
  EMPLOYER_RUBLE_NEED_CREATE_AN_INVOICE = 'EMPLOYER.RUBLE_NEED_CREATE_AN_INVOICE',
  EMPLOYER_RUBLE_NEED_PAY_AN_INVOICE = 'EMPLOYER.RUBLE_NEED_PAY_AN_INVOICE',
  EMPLOYER_RUBLE_BALANCE_REPLENISHED = 'EMPLOYER.RUBLE_BALANCE_REPLENISHED',
  FNS = 'FNS',
  EMPLOYER_REWARD_PAID = 'EMPLOYER.REWARD_PAID',
  FREELANCER_REWARD_PAID_WITH_TAX = 'FREELANCER.REWARD_PAID_WITH_TAX',
  FREELANCER_REWARD_PAID = 'FREELANCER.REWARD_PAID',
  FREELANCER_JOB_RECEIPT_ERROR_TAXPAYER_UNREGISTERED = 'FREELANCER.JOB_RECEIPT_ERROR_TAXPAYER_UNREGISTERED',
  EMPLOYER_JOB_RECEIPT_ERROR_TAXPAYER_UNREGISTERED = 'EMPLOYER.JOB_RECEIPT_ERROR_TAXPAYER_UNREGISTERED',
  FREELANCER_JOB_RECEIPT_ERROR_TAXPAYER_UNBOUND = 'FREELANCER.JOB_RECEIPT_ERROR_TAXPAYER_UNBOUND',
  EMPLOYER_JOB_RECEIPT_ERROR_TAXPAYER_UNBOUND = 'EMPLOYER.JOB_RECEIPT_ERROR_TAXPAYER_UNBOUND',
  FREELANCER_JOB_PAYMENT_ERROR_TAXPAYER_UNBOUND = 'FREELANCER.JOB_PAYMENT_ERROR_TAXPAYER_UNBOUND',
  FREELANCER_JOB_PAYMENT_ERROR_TAXPAYER_UNREGISTERED = 'FREELANCER.JOB_PAYMENT_ERROR_TAXPAYER_UNREGISTERED',
  EMPLOYER_JOB_PAYMENT_ERROR_TAXPAYER_UNBOUND = 'EMPLOYER.JOB_PAYMENT_ERROR_TAXPAYER_UNBOUND',
  EMPLOYER_JOB_PAYMENT_ERROR_TAXPAYER_UNREGISTERED = 'EMPLOYER.JOB_PAYMENT_ERROR_TAXPAYER_UNREGISTERED',
  EMPLOYER_JOB_REFUND_TO_EMPLOYER = 'EMPLOYER.JOB_REFUND_TO_EMPLOYER',

  EMPLOYER_JOB_CONTRACT_PREPARING = 'EMPLOYER.JOB_CONTRACT_PREPARING',
  FREELANCER_JOB_CONTRACT_PREPARING = 'FREELANCER.JOB_CONTRACT_PREPARING',
  FREELANCER_JOB_CONTRACT_READY = 'FREELANCER.JOB_CONTRACT_READY',
  EMPLOYER_JOB_CONTRACT_READY = 'EMPLOYER.JOB_CONTRACT_READY',
  FREELANCER_JOB_CONTRACT_SIGNED_BY_EMPLOYER = 'FREELANCER.JOB_CONTRACT_SIGNED_BY_EMPLOYER',
  EMPLOYER_JOB_CONTRACT_SIGNED_BY_EMPLOYER = 'EMPLOYER.JOB_CONTRACT_SIGNED_BY_EMPLOYER',
  FREELANCER_JOB_CONTRACT_SIGNING_BY_FREELANCER_EXPIRES = 'FREELANCER.JOB_CONTRACT_SIGNING_BY_FREELANCER_EXPIRES',
  EMPLOYER_JOB_CONTRACT_SIGNING_BY_EMPLOYER_EXPIRES = 'EMPLOYER.JOB_CONTRACT_SIGNING_BY_EMPLOYER_EXPIRES',
  EMPLOYER_JOB_CONTRACT_SIGN_EXPIRED_BY_EMPLOYER = 'EMPLOYER.JOB_CONTRACT_SIGN_EXPIRED_BY_EMPLOYER',
  FREELANCER_JOB_CONTRACT_SIGN_EXPIRED_BY_EMPLOYER = 'FREELANCER.JOB_CONTRACT_SIGN_EXPIRED_BY_EMPLOYER',
  EMPLOYER_JOB_CONTRACT_SIGN_EXPIRED_BY_FREELANCER = 'EMPLOYER.JOB_CONTRACT_SIGN_EXPIRED_BY_FREELANCER',
  FREELANCER_JOB_CONTRACT_SIGN_EXPIRED_BY_FREELANCER = 'FREELANCER.JOB_CONTRACT_SIGN_EXPIRED_BY_FREELANCER',

  FREELANCER_JOB_CONTRACT_SIGNED_BY_FREELANCER = 'FREELANCER.JOB_CONTRACT_SIGNED_BY_FREELANCER',
  EMPLOYER_JOB_CONTRACT_SIGNED_BY_FREELANCER = 'EMPLOYER.JOB_CONTRACT_SIGNED_BY_FREELANCER',
  FREELANCER_JOB_CONTRACT_CHANGED_BY_FREELANCER = 'FREELANCER.JOB_CONTRACT_CHANGED_BY_FREELANCER',
  EMPLOYER_JOB_CONTRACT_CHANGED_BY_FREELANCER = 'EMPLOYER.JOB_CONTRACT_CHANGED_BY_FREELANCER',
  FREELANCER_JOB_CONTRACT_CHANGED_BY_EMPLOYER = 'FREELANCER.JOB_CONTRACT_CHANGED_BY_EMPLOYER',
  EMPLOYER_JOB_CONTRACT_CHANGED_BY_EMPLOYER = 'EMPLOYER.JOB_CONTRACT_CHANGED_BY_EMPLOYER',
  EMPLOYER_JOB_MAKE_ADVANCE_PAYMENT = 'EMPLOYER.JOB_MAKE_ADVANCE_PAYMENT',
  FREELANCER_JOB_AWAIT_PREPAYMENT = 'FREELANCER.JOB_AWAIT_PREPAYMENT',
  EMPLOYER_JOB_ADVANCE_PAYMENT_MADE = 'EMPLOYER.JOB_ADVANCE_PAYMENT_MADE',
  FREELANCER_JOB_ADVANCE_PAYMENT_MADE = 'FREELANCER.JOB_ADVANCE_PAYMENT_MADE',

  EMPLOYER_JOB_INVOICE_IS_BEING_PREPARED = 'EMPLOYER.JOB_INVOICE_IS_BEING_PREPARED',
  EMPLOYER_JOB_PAYMENT_DEADLINE_EXPIRES_7_DAYS = 'EMPLOYER.JOB_PAYMENT_DEADLINE_EXPIRES_7_DAYS',
  EMPLOYER_JOB_PAYMENT_DEADLINE_EXPIRES_24_HOURS = 'EMPLOYER.JOB_PAYMENT_DEADLINE_EXPIRES_24_HOURS',
  EMPLOYER_JOB_PAYMENT_DEADLINE_EXPIRED = 'EMPLOYER.JOB_PAYMENT_DEADLINE_EXPIRED',
  FREELANCER_JOB_PAYMENT_DEADLINE_EXPIRED = 'FREELANCER.JOB_PAYMENT_DEADLINE_EXPIRED',
  EMPLOYER_JOB_INVOICE_SAVED = 'EMPLOYER.JOB_INVOICE_SAVED',
  EMPLOYER_JOB_REFUND_DOCS_READY = 'EMPLOYER.JOB_REFUND_DOCS_READY',
  EMPLOYER_RECEIPT_ERROR_READY_LATE = 'EMPLOYER.RECEIPT_ERROR_READY_LATE',
  FREELANCER_RECEIPT_ERROR_READY_LATE = 'FREELANCER.RECEIPT_ERROR_READY_LATE',
  EMPLOYER_JOB_REFUND_COMMISSION_PAID = 'EMPLOYER.JOB_REFUND_COMMISSION_PAID',
  EMPLOYER_JOB_RECEIPT_READY = 'EMPLOYER.JOB_RECEIPT_READY',
  FREELANCER_JOB_RECEIPT_READY = 'FREELANCER.JOB_RECEIPT_READY',

  FREELANCER_INVALID_PASSPORT_DATA_FNS = 'FREELANCER.INVALID_PASSPORT_DATA_FNS',
  FREELANCER_REQUIRED_FIELDS_ARE_NOT_FILLED_7_DAYS = 'FREELANCER.REQUIRED_FIELDS_ARE_NOT_FILLED_7_DAYS',
  EMPLOYER_REQUIRED_FIELDS_ARE_NOT_FILLED_7_DAYS = 'EMPLOYER.REQUIRED_FIELDS_ARE_NOT_FILLED_7_DAYS',
  FREELANCER_FULL_ACCESS_GRANTED = 'FREELANCER.FULL_ACCESS_GRANTED',
  EMPLOYER_FULL_ACCESS_GRANTED = 'EMPLOYER.FULL_ACCESS_GRANTED',
  EMPLOYER_JOB_FINISHED_AUTOMATICALLY = 'EMPLOYER.JOB_FINISHED_AUTOMATICALLY',
  FREELANCER_JOB_FINISHED_AUTOMATICALLY = 'FREELANCER.JOB_FINISHED_AUTOMATICALLY',
  EMPLOYER_JOB_FINISHED = 'FREELANCER.JOB_FINISHED',
  FREELANCER_JOB_FINISHED = 'FREELANCER.JOB_FINISHED',

  FREELANCER_JOB_FREELANCER_WCC_ONE_DAY_LEFT_TO_SIGN = 'FREELANCER.JOB_FREELANCER_WCC_ONE_DAY_LEFT_TO_SIGN',
  EMPLOYER_JOB_FREELANCER_DID_NOT_SIGN_WCC = 'EMPLOYER.JOB_FREELANCER_DID_NOT_SIGN_WCC',
  FREELANCER_JOB_FREELANCER_DID_NOT_SIGN_WCC = 'FREELANCER.JOB_FREELANCER_DID_NOT_SIGN_WCC',
  EMPLOYER_JOB_FREELANCER_SIGNED_WORK_COMPLETION_CERTIFICATE = 'EMPLOYER.JOB_FREELANCER_SIGNED_WORK_COMPLETION_CERTIFICATE',
  FREELANCER_JOB_FREELANCER_SIGNED_WORK_COMPLETION_CERTIFICATE = 'FREELANCER.JOB_FREELANCER_SIGNED_WORK_COMPLETION_CERTIFICATE',

  FREELANCER_REVOKE_RIGHT = 'FREELANCER.REVOKE_RIGHT',
  FREELANCER_SELF_EMPLOYED_STATUS_LOST = 'FREELANCER.SELF_EMPLOYED_STATUS_LOST',
  FREELANCER_TAXPAYER_UNBOUND = 'FREELANCER.TAXPAYER_UNBOUND',
  FREELANCER_TAX_PAID_FUNDS_LEFT = 'FREELANCER.TAX_PAID_FUNDS_LEFT',
  FREELANCER_TAX_FUNDS_RETURN = 'FREELANCER.TAX_FUNDS_RETURN',
  FREELANCER_TECHNICAL_ERROR_PAY_TAX_YOURSELF = 'FREELANCER.TECHNICAL_ERROR_PAY_TAX_YOURSELF',
  FREELANCER_FUNDS_LEFT_AFTER_PAYMENT = 'FREELANCER.FUNDS_LEFT_AFTER_PAYMENT',
  FREELANCER_TAX_PAID_PAY_REST_YOURSELF = 'FREELANCER.TAX_PAID_PAY_REST_YOURSELF',

  FREELANCER_JOB_ORDER_SIGNED_BY_FREELANCER = 'FREELANCER.JOB_ORDER_SIGNED_BY_FREELANCER',
  FREELANCER_JOB_ORDER_SIGNING_BY_FREELANCER_EXPIRES = 'FREELANCER.JOB_ORDER_SIGNING_BY_FREELANCER_EXPIRES',
  FREELANCER_JOB_ORDER_SIGN_EXPIRED_BY_EMPLOYER = 'FREELANCER.JOB_ORDER_SIGN_EXPIRED_BY_EMPLOYER',
  FREELANCER_JOB_ORDER_SIGN_EXPIRED_BY_FREELANCER = 'FREELANCER.JOB_ORDER_SIGN_EXPIRED_BY_FREELANCER',
  FREELANCER_JOB_ORDER_SIGNED_BY_EMPLOYER = 'FREELANCER.JOB_ORDER_SIGNED_BY_EMPLOYER',
  FREELANCER_JOB_ORDER_READY = 'FREELANCER_.JOB_ORDER_READY',
  FREELANCER_JOB_ORDER_PREPARING = 'FREELANCER.JOB_ORDER_PREPARING',

  EMPLOYER_JOB_ORDER_SIGNED_BY_FREELANCER = 'EMPLOYER.JOB_ORDER_SIGNED_BY_FREELANCER',
  EMPLOYER_JOB_ORDER_SIGNING_BY_EMPLOYER_EXPIRES = 'EMPLOYER.JOB_ORDER_SIGNING_BY_EMPLOYER_EXPIRES',
  EMPLOYER_JOB_ORDER_SIGN_EXPIRED_BY_EMPLOYER = 'EMPLOYER.JOB_ORDER_SIGN_EXPIRED_BY_EMPLOYER',
  EMPLOYER_JOB_ORDER_SIGN_EXPIRED_BY_FREELANCER = 'EMPLOYER.JOB_ORDER_SIGN_EXPIRED_BY_FREELANCER',
  EMPLOYER_JOB_ORDER_SIGNED_BY_EMPLOYER = 'EMPLOYER.JOB_ORDER_SIGNED_BY_EMPLOYER',
  EMPLOYER_JOB_ORDER_READY = 'EMPLOYER.JOB_ORDER_READY',
  EMPLOYER_JOB_ORDER_PREPARING = 'EMPLOYER.JOB_ORDER_PREPARING',

  EMPLOYER_JOB_RECEIPT_CANCELED = 'EMPLOYER.JOB_RECEIPT_CANCELED',
  FREELANCER_JOB_RECEIPT_CANCELED = 'FREELANCER.JOB_RECEIPT_CANCELED',

  FREELANCER_JOB_DELETED = 'FREELANCER.JOB_DELETED',
  FREELANCER_JOB_ANOTHER_FREELANCER_HIRED = 'FREELANCER.JOB_ANOTHER_FREELANCER_HIRED',
  FREELANCER_JOB_WITHDRAWN = 'FREELANCER.JOB_WITHDRAWN',
  EMPLOYER_JOB_INSUFFICIENT_FUNDS = 'EMPLOYER.JOB_INSUFFICIENT_FUNDS',

  EMPLOYER_MVD_NEW_JOB = 'EMPLOYER.MVD_NEW_JOB',
  EMPLOYER_MVD_JOB_END_DATE = 'EMPLOYER.MVD_JOB_END_DATE',
  EMPLOYER_MVD_JOB_FINISHED_EARLIER = 'EMPLOYER.MVD_JOB_FINISHED_EARLIER',

  FREELANCER_SELF_EMPLOYED_TAX_PAYMENT_REVERT = 'FREELANCER.SELF_EMPLOYED_TAX_PAYMENT_REVERT',
  FREELANCER_SELF_EMPLOYED_REMOVED_FROM_REGISTER = 'FREELANCER.SELF_EMPLOYED_REMOVED_FROM_REGISTER',
  FREELANCER_SELF_EMPLOYED_DETACHED = 'FREELANCER.SELF_EMPLOYED_DETACHED',
  FREELANCER_TAX_PAYMENT_ERROR = 'FREELANCER.TAX_PAYMENT_ERROR',
  FREELANCER_ALL_TAX_PAID_BEFORE = 'FREELANCER.ALL_TAX_PAID_BEFORE',
  FREELANCER_TAX_PAID_PARTLY = 'FREELANCER.TAX_PAID_PARTLY',
  FREELANCER_TAX_PAID = 'FREELANCER.TAX_PAID',
  FREELANCER_TAX_PAID_REST_RETURNED = 'FREELANCER.TAX_PAID_REST_RETURNED',
}

export enum EnglishLevel {
  BEGINNER           = 'B',
  ELEMENTARY         = 'E',
  PRE_INTERMEDIATE   = 'P',
  INTERMEDIATE       = 'I',
  UPPER_INTERMEDIATE = 'U',
  ADVANCED           = 'A',
  FLUENT             = 'F',
}

export enum ExpertLevel {
  BEGINNER     = 'BEGINNER',
  INTERMEDIATE = 'INTERMEDIATE',
  MASTER       = 'MASTER',
}

export enum PaymentMethod {
  HOURLY = 'H',
  FIXED  = 'F',
}

export enum ContentType {
  CONVERSATION = 'CV',
  JOB          = 'JB',
  TASK         = 'TS',
  PROJECT      = 'PR',
  AVATAR       = 'AV',
}

export enum JobVisibility {
  PRIVATE    = 'PRI',
  INTERNALLY = 'INT',
  PUBLIC     = 'PUB',
}

export enum LogLevel {
  ERROR = 1,
  WARN  = 2,
  INFO  = 3,
}

export enum ProjectSearchSorting {
  PUBLISH_DATE_ASC = 'PUBLISH_DATE_ASC',
  PUBLISH_DATE_DESC = 'PUBLISH_DATE_DESC',
}

export enum FreelancerSearchSorting {
  RATE_ASC = 'RATE_ASC',
  RATE_DESC = 'RATE_DESC',
}

export enum ForgotPasswordStates {
  INIT             = 0,
  EMAIL_INPUT      = 1,
  EMAIL_SENT       = 2,
  PASSWORD_INPUT   = 4,
  PASSWORD_CHANGED = 8
}

export enum WSViduMessageId {
  REGISTER               = 'register',
  UNREGISTER             = 'unregister',
  CREATE_ROOM            = 'createRoom',
  INVITE_USER            = 'inviteUser',
  WITHDRAW_INVITE_USER   = 'withdrawInviteUser',
  JOIN_ROOM              = 'joinRoom',
  LEAVE_ROOM             = 'leaveRoom',
  INCOMING_CALL_RESPONSE = 'incomingCallResponse',

  // outgoing
  INVITE_RESPONSE        = 'inviteResponse',
  INCOMING_CALL          = 'incomingCall',
  WITHDRAW_RESPONSE      = 'withdrawResponse',
  REGISTER_RESPONSE      = 'registerResponse',
  CREATE_ROOM_RESPONSE   = 'createRoomResponse',
  WITHDRAW_INCOMING_CALL = 'withdrawIncomingCall',
  JOIN_ROOM_RESPONSE     = 'joinRoomResponse',
  USER_LEAVE_ROOM        = 'userLeaveRoom',
  LEAVE_ROOM_RESPONSE    = 'leaveRoomResponse',
  ERROR                  = 'error'
}

export const showErrorIcon: string[] = [
  NotificationType.EMPLOYER_MILESTONE_INSUFFICIENT_FUNDS,
  NotificationType.EMPLOYER_PROJECT_INSUFFICIENT_FUNDS,
  NotificationType.FREELANCER_JOB_RECEIPT_ERROR_TAXPAYER_UNREGISTERED,
  NotificationType.EMPLOYER_JOB_RECEIPT_ERROR_TAXPAYER_UNREGISTERED,
  NotificationType.FREELANCER_JOB_RECEIPT_ERROR_TAXPAYER_UNBOUND,
  NotificationType.EMPLOYER_JOB_RECEIPT_ERROR_TAXPAYER_UNBOUND,
  NotificationType.FREELANCER_JOB_PAYMENT_ERROR_TAXPAYER_UNBOUND,
  NotificationType.FREELANCER_JOB_PAYMENT_ERROR_TAXPAYER_UNREGISTERED,
  NotificationType.EMPLOYER_JOB_PAYMENT_ERROR_TAXPAYER_UNBOUND,
  NotificationType.EMPLOYER_JOB_PAYMENT_ERROR_TAXPAYER_UNREGISTERED,
  NotificationType.EMPLOYER_JOB_CONTRACT_SIGN_EXPIRED_BY_EMPLOYER,
  NotificationType.FREELANCER_JOB_CONTRACT_SIGN_EXPIRED_BY_EMPLOYER,
  NotificationType.EMPLOYER_JOB_CONTRACT_SIGN_EXPIRED_BY_FREELANCER,
  NotificationType.FREELANCER_JOB_CONTRACT_SIGN_EXPIRED_BY_FREELANCER,
  NotificationType.EMPLOYER_RECEIPT_ERROR_READY_LATE,
  NotificationType.FREELANCER_INVALID_PASSPORT_DATA_FNS,
  NotificationType.FREELANCER_RECEIPT_ERROR_READY_LATE,
  NotificationType.FREELANCER_TECHNICAL_ERROR_PAY_TAX_YOURSELF,
  NotificationType.FREELANCER_JOB_ORDER_SIGN_EXPIRED_BY_EMPLOYER,
  NotificationType.FREELANCER_JOB_ORDER_SIGN_EXPIRED_BY_FREELANCER,
  NotificationType.EMPLOYER_JOB_ORDER_SIGN_EXPIRED_BY_EMPLOYER,
  NotificationType.EMPLOYER_JOB_ORDER_SIGN_EXPIRED_BY_FREELANCER,
  NotificationType.EMPLOYER_JOB_RECEIPT_CANCELED,
  NotificationType.FREELANCER_JOB_RECEIPT_CANCELED,
  NotificationType.FREELANCER_TAX_PAYMENT_ERROR,
];

export const showSuccessIcon: string[] = [
  NotificationType.FREELANCER_GOT_PAID,
  NotificationType.CARD_DETACHED,
  NotificationType.CARD_ATTACHED,
  NotificationType.PAYONEER_DETACHED,
  NotificationType.EMPLOYER_REGISTERED,
  NotificationType.FREELANCER_REGISTERED,
  NotificationType.EMPLOYER_RUBLE_BALANCE_REPLENISHED,
  NotificationType.EMPLOYER_REWARD_PAID,
  NotificationType.FREELANCER_REWARD_PAID_WITH_TAX,
  NotificationType.FREELANCER_REWARD_PAID,
  NotificationType.EMPLOYER_EMPLOYER_ACCEPTED_CHANGES,
  NotificationType.FREELANCER_EMPLOYER_ACCEPTED_CHANGES,
  NotificationType.EMPLOYER_FREELANCER_ACCEPTED_CHANGES,
  NotificationType.FREELANCER_FREELANCER_ACCEPTED_CHANGES,
  NotificationType.FREELANCER_JOB_CONTRACT_SIGNED_BY_FREELANCER,
  NotificationType.EMPLOYER_JOB_CONTRACT_SIGNED_BY_FREELANCER,
  NotificationType.FREELANCER_JOB_ADVANCE_PAYMENT_MADE,
  NotificationType.EMPLOYER_JOB_ADVANCE_PAYMENT_MADE,
  NotificationType.EMPLOYER_JOB_RECEIPT_READY,
  NotificationType.FREELANCER_JOB_RECEIPT_READY,
  NotificationType.FREELANCER_FULL_ACCESS_GRANTED,
  NotificationType.EMPLOYER_FULL_ACCESS_GRANTED,
  NotificationType.EMPLOYER_JOB_FREELANCER_SIGNED_WORK_COMPLETION_CERTIFICATE,
  NotificationType.FREELANCER_JOB_FREELANCER_SIGNED_WORK_COMPLETION_CERTIFICATE,
  NotificationType.FREELANCER_FUNDS_LEFT_AFTER_PAYMENT,
  NotificationType.FREELANCER_TAX_PAID_PAY_REST_YOURSELF,
  NotificationType.EMPLOYER_JOB_ORDER_SIGNED_BY_FREELANCER,
  NotificationType.FREELANCER_JOB_ORDER_SIGNED_BY_FREELANCER,
  NotificationType.EMPLOYER_JOB_FINISHED,
  NotificationType.FREELANCER_JOB_FINISHED,
  NotificationType.FREELANCER_TAX_PAID_PARTLY,
  NotificationType.FREELANCER_TAX_PAID,
  NotificationType.FREELANCER_TAX_PAID_REST_RETURNED,
];

export const showInfoIcon: string[] = [
  NotificationType.FREELANCER_OTHER_FREELANCER_SELECTED,
  NotificationType.EMPLOYER_AUTO_ACCEPT,
  NotificationType.EMPLOYER_ARBITRAGE_DECISION_IN_FAVOR_OF_EMPLOYER,
  NotificationType.FREELANCER_ARBITRAGE_DECISION_IN_FAVOR_OF_EMPLOYER,
  NotificationType.EMPLOYER_ARBITRAGE_DECISION_IN_FAVOR_OF_FREELANCER,
  NotificationType.FREELANCER_ARBITRAGE_DECISION_IN_FAVOR_OF_FREELANCER,
  NotificationType.EMPLOYER_ARBITRAGE_DECISION_IN_PROPORTION,
  NotificationType.FREELANCER_ARBITRAGE_DECISION_IN_PROPORTION,
  NotificationType.EMPLOYER_RUBLE_NEED_PAY_AN_INVOICE,
  NotificationType.EMPLOYER_RUBLE_NEED_CREATE_AN_INVOICE,
  NotificationType.EMPLOYER_JOB_REFUND_TO_EMPLOYER,
  NotificationType.EMPLOYER_FREELANCER_ACCEPTED,
  NotificationType.FREELANCER_FREELANCER_ACCEPTED,
  NotificationType.EMPLOYER_FREELANCER_PROPOSAL,
  NotificationType.FREELANCER_FREELANCER_PROPOSAL,
  NotificationType.EMPLOYER_FREELANCER_DECLINE_SELF,
  NotificationType.FREELANCER_FREELANCER_DECLINE_SELF,
  NotificationType.EMPLOYER_FREELANCER_LEFT_COMMENT,
  NotificationType.EMPLOYER_FREELANCER_TASK_STATUS_REVIEW,
  NotificationType.EMPLOYER_FREELANCER_TASK_STATUS_TO_DO,
  NotificationType.EMPLOYER_FREELANCER_TASK_STATUS_IN_PROGRESS,
  NotificationType.EMPLOYER_FREELANCER_REWORK_TASK,
  NotificationType.EMPLOYER_FREELANCER_STARTED_TASK,
  NotificationType.EMPLOYER_FREELANCER_DECLINED_TASK,
  NotificationType.EMPLOYER_FREELANCER_DECLINED,
  NotificationType.FREELANCER_FREELANCER_DECLINED,
  NotificationType.EMPLOYER_FREELANCER_CLOSED_DISPUTE,
  NotificationType.EMPLOYER_FREELANCER_CHANGE_PROJECT,
  NotificationType.EMPLOYER_FREELANCER_DECLINED_CHANGES,
  NotificationType.FREELANCER_FREELANCER_CHANGE_PROJECT,
  NotificationType.FREELANCER_FREELANCER_DECLINED_CHANGES,
  NotificationType.EMPLOYER_FREELANCER_REVOKE_OWN_CHANGES,
  NotificationType.EMPLOYER_FREELANCER_STARTED_ARBITRAGE,
  NotificationType.EMPLOYER_REVIEW_ABOUT_FREELANCER_IS_ALLOWED,
  NotificationType.EMPLOYER_EMPLOYER_ACCEPTED,
  NotificationType.FREELANCER_EMPLOYER_ACCEPTED,
  NotificationType.EMPLOYER_EMPLOYER_CHANGE_PROJECT,
  NotificationType.FREELANCER_EMPLOYER_CHANGE_PROJECT,
  NotificationType.FREELANCER_EMPLOYER_ACCEPTED_ALL_TASKS,
  NotificationType.EMPLOYER_EMPLOYER_ACCEPTED_ALL_TASKS,
  NotificationType.FREELANCER_FREELANCER_INVITED,
  NotificationType.EMPLOYER_FREELANCER_INVITED,
  NotificationType.EMPLOYER_EMPLOYER_DECLINED,
  NotificationType.FREELANCER_EMPLOYER_DECLINED,
  NotificationType.EMPLOYER_EMPLOYER_DECLINE_SELF,
  NotificationType.FREELANCER_EMPLOYER_DECLINE_SELF,
  NotificationType.FREELANCER_EMPLOYER_LEFT_COMMENT,
  NotificationType.FREELANCER_EMPLOYER_ACCEPTED_TASK,
  NotificationType.FREELANCER_EMPLOYER_DECLINED_TASK,
  NotificationType.FREELANCER_EMPLOYER_CREATED_TASK,
  NotificationType.FREELANCER_EMPLOYER_EDITED_TASK,
  NotificationType.FREELANCER_EMPLOYER_CLOSED_DISPUTE,
  NotificationType.FREELANCER_EMPLOYER_DECLINED_CHANGES,
  NotificationType.EMPLOYER_EMPLOYER_DECLINED_CHANGES,
  NotificationType.FREELANCER_EMPLOYER_REVOKE_OWN_CHANGES,
  NotificationType.FREELANCER_REVIEW_ABOUT_EMPLOYER_IS_ALLOWED,
  NotificationType.FNS,
  NotificationType.EMPLOYER_JOB_CONTRACT_PREPARING,
  NotificationType.FREELANCER_JOB_CONTRACT_PREPARING,
  NotificationType.FREELANCER_JOB_CONTRACT_READY,
  NotificationType.EMPLOYER_JOB_CONTRACT_READY,
  NotificationType.FREELANCER_JOB_CONTRACT_SIGNED_BY_EMPLOYER,
  NotificationType.EMPLOYER_JOB_CONTRACT_SIGNED_BY_EMPLOYER,
  NotificationType.FREELANCER_JOB_CONTRACT_CHANGED_BY_FREELANCER,
  NotificationType.EMPLOYER_JOB_CONTRACT_CHANGED_BY_FREELANCER,
  NotificationType.FREELANCER_JOB_CONTRACT_CHANGED_BY_EMPLOYER,
  NotificationType.EMPLOYER_JOB_CONTRACT_CHANGED_BY_EMPLOYER,
  NotificationType.EMPLOYER_JOB_MAKE_ADVANCE_PAYMENT,
  NotificationType.FREELANCER_JOB_AWAIT_PREPAYMENT,
  NotificationType.EMPLOYER_JOB_INVOICE_IS_BEING_PREPARED,
  NotificationType.EMPLOYER_JOB_INVOICE_SAVED,
  NotificationType.EMPLOYER_JOB_REFUND_DOCS_READY,
  NotificationType.EMPLOYER_JOB_REFUND_COMMISSION_PAID,
  NotificationType.EMPLOYER_JOB_FINISHED_AUTOMATICALLY,
  NotificationType.FREELANCER_JOB_FINISHED_AUTOMATICALLY,
  NotificationType.EMPLOYER_JOB_FINISHED,
  NotificationType.FREELANCER_JOB_FINISHED,
  NotificationType.EMPLOYER_JOB_FREELANCER_DID_NOT_SIGN_WCC,
  NotificationType.FREELANCER_JOB_FREELANCER_DID_NOT_SIGN_WCC,
  NotificationType.FREELANCER_TAX_PAID_FUNDS_LEFT,
  NotificationType.FREELANCER_TAX_FUNDS_RETURN,
  NotificationType.EMPLOYER_JOB_ORDER_PREPARING,
  NotificationType.FREELANCER_JOB_ORDER_PREPARING,
  NotificationType.EMPLOYER_JOB_ORDER_READY,
  NotificationType.FREELANCER_JOB_ORDER_READY,
  NotificationType.EMPLOYER_JOB_ORDER_SIGNED_BY_EMPLOYER,
  NotificationType.FREELANCER_JOB_ORDER_SIGNED_BY_EMPLOYER,
  NotificationType.FREELANCER_JOB_DELETED,
  NotificationType.FREELANCER_JOB_ANOTHER_FREELANCER_HIRED,
  NotificationType.FREELANCER_JOB_WITHDRAWN,
  NotificationType.EMPLOYER_MVD_NEW_JOB,
  NotificationType.EMPLOYER_MVD_JOB_END_DATE,
  NotificationType.EMPLOYER_MVD_JOB_FINISHED_EARLIER,
  NotificationType.FREELANCER_ALL_TAX_PAID_BEFORE,
];

export const showWarningIcon = [
  NotificationType.EMPLOYER_REVIEW_DEADLINE_IS_SOON,
  NotificationType.EMPLOYER_FREELANCER_OPEN_DISPUTE,
  NotificationType.FREELANCER_FREELANCER_OPEN_DISPUTE,
  NotificationType.EMPLOYER_EMPLOYER_OPEN_DISPUTE,
  NotificationType.FREELANCER_EMPLOYER_OPEN_DISPUTE,
  NotificationType.FREELANCER_EMPLOYER_STARTED_ARBITRAGE,
  NotificationType.EMPLOYER_JOB_CONTRACT_SIGNING_BY_EMPLOYER_EXPIRES,
  NotificationType.FREELANCER_JOB_CONTRACT_SIGNING_BY_FREELANCER_EXPIRES,
  NotificationType.EMPLOYER_JOB_PAYMENT_DEADLINE_EXPIRES_7_DAYS,
  NotificationType.EMPLOYER_JOB_PAYMENT_DEADLINE_EXPIRES_24_HOURS,
  NotificationType.EMPLOYER_JOB_PAYMENT_DEADLINE_EXPIRED,
  NotificationType.FREELANCER_JOB_PAYMENT_DEADLINE_EXPIRED,
  NotificationType.FREELANCER_REQUIRED_FIELDS_ARE_NOT_FILLED_7_DAYS,
  NotificationType.EMPLOYER_REQUIRED_FIELDS_ARE_NOT_FILLED_7_DAYS,
  NotificationType.FREELANCER_JOB_FREELANCER_WCC_ONE_DAY_LEFT_TO_SIGN,
  NotificationType.FREELANCER_REVOKE_RIGHT,
  NotificationType.FREELANCER_TAX_PAID_PAY_REST_YOURSELF,
  NotificationType.FREELANCER_SELF_EMPLOYED_STATUS_LOST,
  NotificationType.FREELANCER_TAXPAYER_UNBOUND,
  NotificationType.EMPLOYER_JOB_ORDER_SIGNING_BY_EMPLOYER_EXPIRES,
  NotificationType.FREELANCER_JOB_ORDER_SIGNING_BY_FREELANCER_EXPIRES,
  NotificationType.EMPLOYER_JOB_INSUFFICIENT_FUNDS,
  NotificationType.FREELANCER_SELF_EMPLOYED_TAX_PAYMENT_REVERT,
  NotificationType.FREELANCER_SELF_EMPLOYED_REMOVED_FROM_REGISTER,
  NotificationType.FREELANCER_SELF_EMPLOYED_DETACHED,
];
