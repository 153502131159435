<tpt-dialog>
  <div class="dialog-root">
    <div class="feedback-step" *ngIf="commentForm && docs && project">
      <div class="feedback-header">
        Завершение проекта
      </div>

      <div class="title">{{'TRACKER.FREELANCER_RATE_TITLE' | translate}}</div>
      <div class="text regular-14-20">{{'TRACKER.FREELANCER_RATE_TEXT' | translate}}</div>
      <div class="tip regular-14-20" (click)="metricsInfoDialog.open()">{{'TRACKER.FREELANCER_RATE_TIP' | translate}}</div>

      <form [formGroup]="commentForm" >

        <div class="characteristics-container">
          <div>
            <div class="stat-block">
              <div>{{'SETTINGS.FR_accuracy' | translate}}</div>
              <div class="slider-row" [class.-error-state]="errorState(this.commentForm.controls.accuracy)">
                <mat-slider min="0" max="100" step="5" color="primary" formControlName="accuracy"></mat-slider>
                <input matInput disabled min="0" max="100" class="input" type="number"
                       [value]="commentForm.controls.accuracy.value"/>
              </div>
            </div>
            <div class="stat-block">
              <div>{{'SETTINGS.rapidity' | translate}}</div>
              <div class="slider-row" [class.-error-state]="errorState(this.commentForm.controls.rapidity)">
                <mat-slider min="0" max="100" step="5" color="primary" formControlName="rapidity"></mat-slider>
                <input matInput disabled min="0" max="100" class="input" type="number"
                       [value]="commentForm.controls.rapidity.value"/>
              </div>
            </div>
          </div>
          <div>
            <div class="stat-block">
              <div>{{'SETTINGS.FR_quality' | translate}}</div>
              <div class="slider-row" [class.-error-state]="errorState(this.commentForm.controls.quality)">
                <mat-slider min="0" max="100" step="5" color="primary" formControlName="quality"></mat-slider>
                <input matInput disabled min="0" max="100" class="input" type="number"
                       [value]="commentForm.controls.quality.value"/>
              </div>
            </div>
            <div class="stat-block">
              <div>{{'SETTINGS.adequacy' | translate}}</div>

              <div class="slider-row" [class.-error-state]="errorState(this.commentForm.controls.adequacy)">
                <mat-slider min="0" max="100" step="5" color="primary" formControlName="adequacy"></mat-slider>
                <input matInput disabled min="0" max="100" class="input" type="number"
                       [value]="commentForm.controls.adequacy.value"/>
              </div>
            </div>
          </div>
          <div>
            <div class="stat-block">
              <div>{{'SETTINGS.general_impression' | translate}}*</div>

              <div class="slider-row" [class.-error-state]="errorState(this.commentForm.controls.generalImpression)">
                <mat-slider min="0" max="100" step="5" color="primary"
                            formControlName="generalImpression"></mat-slider>
                <input matInput disabled min="0" max="100" class="input" type="number"
                       [value]="commentForm.controls.generalImpression.value"/>
              </div>

              <div class="caption">{{'TRACKER.GENERAL_IMPRESSION_TIP' | translate}}</div>
            </div>
          </div>

        </div>

        <mat-form-field appearance="none" class="comment">
            <textarea matInput class="textarea" maxlength="340" formControlName="text"
                      placeholder="Оставьте комментарий о работе"></textarea>
          <mat-hint align="end" aria-live="polite">
            {{commentForm.controls.text.value.length}} / 340
          </mat-hint>
        </mat-form-field>

      </form>

      <div class="">
        <div class="subhead-1 mb-12">
          Ознакомьтесь с документами и подпишите
        </div>

        <div class="docs" *ngIf="docs.workCompletionCertificate; else PendingAct">
          <div class="contract-doc mb-16 mr-12">
            <mat-icon [svgIcon]="svgIconsEnum.Clipboard" class="mr-4"></mat-icon>
            <span (click)="openDoc(docs.workCompletionCertificate?.id)">{{docs.workCompletionCertificate?.name}}</span>
          </div>
          <div class="contract-doc mb-16" *ngIf="docs.statement">
            <mat-icon [svgIcon]="svgIconsEnum.Clipboard" class="mr-4"></mat-icon>
            <span (click)="openDoc(docs.statement?.id)">{{docs.statement?.name}}</span>
          </div>
        </div>
        <mat-checkbox class="mb-8" color="primary" [(ngModel)]="acceptTerms.confirmResults"
                      [disabled]="!docs.workCompletionCertificate">
          Принимаю результаты работы и согласен завершить Услугу
        </mat-checkbox><br/>

        <mat-checkbox class="mb-8" color="primary" [(ngModel)]="acceptTerms.confirmPayment"
                      [disabled]="!docs.workCompletionCertificate">
          Подтверждаю перевод {{ project?.budget | number}}₽ на счёт исполнителя
        </mat-checkbox><br/>

        <mat-checkbox class="mb-8" color="primary" *ngIf="project.commissionValue"
                      [(ngModel)]="acceptTerms.confirmCommission"
                      [disabled]="!docs.workCompletionCertificate || !docs.statement">
          Подтверждаю перевод {{ project?.commissionValue | number}}₽ на счёт Talent Cloud
        </mat-checkbox><br/>

        <ng-template #PendingAct>
          <div class="info-message act">
            Завершающие документы ещё не сформированы, а без их подписания завершить оказание услуги невозможно.
            Мы уже работаем над формированием документов
          </div>
        </ng-template>
      </div>

      <div class="finish-actions">
        <tpt-button
          type="secondary"
          class="secondary"
          size="large"
          [disabled]="checkBoxesNotValid()"
          (onClick)="finishNoFeedback()"
        >
          Подписать документы и завершить без отзыва
        </tpt-button>
        <tpt-button
          type="primary"
          size="large"
          [disabled]="checkBoxesNotValid()"
          (onClick)="finishLeaveFeedback()"
        >
          Подписать документы
        </tpt-button>
      </div>

    </div>
  </div>
</tpt-dialog>

<tpt-rating-metrics-dialog></tpt-rating-metrics-dialog>
