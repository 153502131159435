import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { DialogComponent } from '../../../dialog/components/dialog/dialog.component';
import { SvgIconsEnum } from '../../../../types/svg-icons.enum';
import { MatDialogConfig } from '@angular/material/dialog';
import { UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'tpt-cancel-receipt-dialog',
  templateUrl: './cancel-receipt-dialog.component.html',
  styleUrls: ['./cancel-receipt-dialog.component.scss']
})
export class CancelReceiptDialogComponent {

  @ViewChild(DialogComponent)
  public dialog: DialogComponent;

  @Output()
  public cancelReceiptEvent: EventEmitter<string> = new EventEmitter();

  public job;

  public reasons: {code: string, description: string}[] = [];

  public svgIconsEnum = SvgIconsEnum;

  public reason = new UntypedFormControl('REFUND');

  private config: MatDialogConfig = {
    width: '520px',
  };

  private className = 'tpt-cancel-receipt-dialog';

  public open(reasons): void {
    this.reasons = reasons;
    this.dialog.config = this.config;
    this.dialog.open(this.className);
  }

  public close(): void {
    this.dialog.close();
  }

  public cancelReceipt(): void {
    this.cancelReceiptEvent.emit(this.reason.value);
    this.close();
  }
}
