
export enum SvgIconsEnum {

  SidebarLogo = 'sidebar-logo',

  ExpandedLogo = 'expanded-logo',

  SidebarHome = 'sidebar-home',

  SidebarHelp = 'sidebar-help',

  SidebarFinances = 'sidebar-finances',

  SidebarMessages = 'sidebar-messages',

  SidebarTeam = 'sidebar-team',

  SidebarSearch = 'sidebar-search',

  SidebarSettings = 'sidebar-settings',

  SidebarFns = 'sidebar-fns',

  SidebarProjects = 'sidebar-projects',

  SidebarExit = 'sidebar-exit',

  Eye = 'eye',

  TriangleDown = 'triangle-down',

  DotsMoreHorizontal = 'dots-more-horizontal',

  Clock = 'clock',

  Trash = 'trash',

  Trash2 = 'trash2',

  AddContactIcon = 'add-contact-icon',

  CircleCheckmark = 'circle-checkmark',

  Info = 'info',

  Notes = 'notes',

  SquareWithPencil = 'square-with-pencil',

  Cross = 'cross',

  Pin = 'pin',

  Unpin = 'unpin',

  Attachment = 'attachment',

  Comment = 'comments',

  AssigneePlaceholder = 'assignee-placeholder',

  AttachedFile = 'attached-file',

  ArrowLeft = 'arrow-left',

  LoginCardIcon = 'login-card-icon',

  RestoreCardIcon = 'restore-card-icon',

  RegisterCardIcon = 'register-card-icon',

  DatepickerCalendar = 'datepicker-calendar',

  StripePurple = 'stripe-purple',

  DialogSuccessIcon = 'dialog-success-icon',

  DialogFailureIcon = 'dialog-failure-icon',

  MastercardGrey = 'mastercard-grey',

  StripeGrey = 'stripe-grey',

  VisaGrey = 'visa-grey',

  PayoneerGrey = 'payoneer-grey',

  PayoneerWithColor = 'payoneer-with-color',

  Location = 'location',

  Check = 'check-icon',

  TaskNotes= 'task-notes',

  Notification = 'notification',

  Language = 'language',

  ArrowDown = 'arrow-down',

  CreateProject = 'create-project',

  FillProfile = 'fill-profile',

  FindProject = 'find-project',

  HireFreel = 'hire-freel',

  Results = 'results',

  Secure = 'secure',

  Track = 'track',

  ArrowRight = 'arrow-right',

  SidebarArrow = 'sidebar-arrow',

  SuccessMessage = 'message-success',

  ErrorMessage = 'message-error',

  SingleProject = 'single-icon',

  MultiProject = 'multi-icon',

  Budget = 'budget',

  Candidate = 'candidate',

  TaskList = 'task-list',

  ArrowBack = 'arrow-back',

  Edit = 'edit',

  Delete = 'delete-outline',

  InfoIcon = 'info-icon',

  Plus = 'plus',

  Assignees = 'assignees',

  PlusIcon = 'plus-icon',

  Details = 'details',

  Calendar = 'calendar',

  Expand = 'expand-icon',

  Feedback = 'feedback',

  Success = 'success',

  Search = 'search',

  HeaderLogo = 'green-badge',

  FooterLogo = 'green-logo',

  WhiteSmallLogo = 'white-badge',

  WhiteBigLogo = 'white-badge-text',

  ArrowForward = 'arrow-forward',

  FlagRU = 'ru',

  FlagEN = 'en',

  Facebook = 'fb',

  Linkedin = 'in',

  Instagram = 'instagram',

  Medium = 'medium',

  Telegram = 'telegram',

  Message = 'message',

  Close = 'close',

  PageUp = 'page-up',

  ConfirmSuccess = 'confirm-success',

  Mail = 'mail',

  HighPrio = 'high-prio',

  MediumPrio = 'medium-prio',

  LowPrio = 'low-prio',

  NoPrio = 'no-prio',

  ExpandMore = 'expand-more',

  Stripe = 'stripe',

  Payoneer = 'payoneer',

  Fee = 'fee',

  Arbitrage = 'arbitrage',

  TransactionFail = 'payment-fail',

  SmallArrowDown = 'small-arrow-down',

  Fivver = 'fivver',

  Freelancer_Ru = 'freelancer_ru',

  Upwork = 'upwork',

  AmazonMT = 'amazon_mechanical_turk',

  Design99 = 'design99',

  Kwork = 'kwork',

  PeoplePH = 'people_per_hour',

  SimplyHired = 'simply_hired',

  Toptal = 'toptal',

  YaToloka = 'ya_toloka',

  Camera = 'camera',

  FilterList = 'filter-list',

  Mustache = 'mustache',

  Bubble = 'bubble',

  Cancel = 'cancel',

  Zzz = 'zzz',

  BubbleMessage = 'bubble-main',

  CreditCard = 'credit-card',

  Flag = 'flag',

  Keyboard = 'keyboard',

  HelpOutline = 'help_outline',

  Bell = 'notifications_none',

  ExpandLess = 'expand_less',

  Individual = 'individual',

  IndividualEntrepreneur = 'individual-entrepreneur',

  LegalEntity = 'legal-entity',

  Hourglass = 'hourglass',

  Download = 'download',

  MoreHoriz = 'more-horiz',

  Link = 'link',

  Sticky = 'sticky',

  Archived = 'archived',

  PurpleCheck = 'purpleCheck',

  DocUpload = 'doc-upload',

  Bank = 'bank',

  Refresh = 'refresh',

  CheckSmall = 'check',

  WarningSmall = 'warning-small',

  LegalInfo = 'legal-info',

  CaptionsBubble = 'captions-bubble',

  LightBulb = 'lightbulb',

  OnBoarding = 'onboarding',

  Contact = 'contact',

  CheckSuccess = 'check-success',

  InfoWarn = 'info-warn',

  CopyLink = 'copy-link',

  Email = 'email',

  Phone = 'phone',

  Skype = 'skype',

  Web = 'web',

  Google = 'google',

  Dollar = 'dollar',

  ThinCheck = 'thin-check',

  Staple = 'staple',

  Replay = 'replay',

  EyeOpen = 'eye-open',

  EyeClose = 'eye-close',

  Deregistered = 'deregistered',

  SupportAvatar = 'support-avatar',

  Processing = 'processing',

  XMark = 'xmark',

  Time = 'time',

  Clipboard = 'clipboard',

  CheckCircle = 'check-circle',

  Sbp = 'sbp',

  WarnIcon = 'warn-icon',

  ErrIcon = 'err-icon',

  ErrorState = 'wavy-warning',

  WarningState = 'warning-state',

  SuccessState = 'success-state',

  CheckThin = 'check-thin',

  ArrowRefresh = 'arrow-refresh',

  Media = 'media',

  BurgerMenu = 'burger-menu',

  Grid = 'grid',

  CoInfo = 'co-info',

  SidebarContracts = 'sidebar-contracts',

  EmptyList = 'empty-list',

  UserIcon = 'user-icon',

  UserIconGray = 'user-icon-gray',
}

export enum CoSvgIconsEnum {

  Image = 'image',

  Books = 'books',

  Quotes = 'quotes',

  Headphones = 'headphones',

  Photo = 'photo',

  Planet = 'planet',

  Puzzle = 'puzzle',

  Crop = 'crop',

  Text = 'text',

  Comics = 'comics',

  Code = 'code',

  Play = 'play',

  Seller = 'seller',

  Buyer = 'buyer',

  SmallLogo = 'co-small-logo',

  FullLogo = 'co-full-logo',

  Wallet = 'wallet',

  Box = 'box',

  Folder = 'folder',

  Study = 'study',

  PO = 'po',

  Design = 'brush',

  Book = 'book',

  Audio = 'audio',

  Music = 'music',

  Buy = 'buy',

  CoSwitcher = 'switcher-co-logo',

  TcSwitcher = 'switcher-tc-logo',

  Cart = 'cart',

  TopUp = 'top-up',

  PriceTag = 'price-tag',

  Withdraw = 'withdraw',

  Exit = 'exit',

}
