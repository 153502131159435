import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { InputMaxValidatorDirective } from './directives/input-max.directive';
import { MinLengthWithTrimValidator } from './directives/min-length-trim.directive';
import { InputMinValidatorDirective } from './directives/input-min-validator.directive';
import { InputMixDirective } from './directives/input-mix.directive';
import { DndDirective } from './directives/dnd.directive';
import { ClickOutsideDirective } from '@/modules/common-directives/directives/click-outside.directive';

@NgModule({
  declarations: [
    InputMaxValidatorDirective,
    MinLengthWithTrimValidator,
    InputMinValidatorDirective,
    InputMixDirective,
    DndDirective,
    ClickOutsideDirective,
  ],
  imports: [
    CommonModule
  ],
  exports: [
    InputMaxValidatorDirective,
    MinLengthWithTrimValidator,
    InputMinValidatorDirective,
    InputMixDirective,
    DndDirective,
    ClickOutsideDirective,
  ]
})
export class CommonDirectivesModule {
}
