<div
  *ngIf="isTextMessage"
  class="message"
>
  <div class="message-header">
    <span class="send-date">
      {{ sendDate }}
    </span>
    <div class="message-menu-button" *ngIf="!message?.deleted">
      <button mat-icon-button [matMenuTriggerFor]="menu">
        <mat-icon [svgIcon]="svgIconsEnum.MoreHoriz"></mat-icon>
      </button>
      <mat-menu #menu="matMenu">
        <button mat-menu-item (click)="deleteMessage()">{{'BUTTON.DELETE' | translate}}</button>
      </mat-menu>
    </div>
  </div>

  <div class="message-body" *ngIf="!message?.deleted; else undoTemplate">
    <div [innerHTML]="message?.content?.body | tptDetectUrl | safeHtml"></div>

    <div
      *ngFor="let file of message.content?.files"
      class="message-body-files"
    >
      <tpt-uploaded-file [file]="file"></tpt-uploaded-file>
    </div>
  </div>

  <ng-template #undoTemplate>
    <div class="message-body">
      {{ 'CONTRACTS.MESSAGE_DELETED' | translate }}
      <span class="undo-btn" (click)="undoDelete()">{{ 'CONTRACTS.CANCEL_DELETE' | translate }}</span>
    </div>
  </ng-template>
</div>

<ng-container *ngIf="isStateMessage && chatType === 'JOB_CHAT'">
  <div class="status-change state-message">
    <div class="status-change-header">
      {{ sendDate }}
    </div>
    <div class="status-change-body" [class.light-green]="greenMessages.includes(message?.type)"
         [class.warning-message]="message?.type === 'state.receipt_canceled'"
    >
      <ng-container *ngIf="message?.type === 'state.freelancer_was_invited'">
        {{ 'CONTRACTS.WAS_INVITED_1' | translate }}
        {{contractsService?.selectedChat?.freelancer?.firstName}} {{contractsService?.selectedChat?.freelancer?.lastName}}
        {{ 'CONTRACTS.WAS_INVITED_2' | translate }}
      </ng-container>

      <ng-container *ngIf="message?.type === 'state.freelancer_was_accepted'">
        <span *ngIf="isEmployer">
          {{contractsService?.selectedChat?.freelancer?.firstName}} {{contractsService?.selectedChat?.freelancer?.lastName}}
          {{ 'CONTRACTS.WAS_ACCEPTED' | translate }}
        </span>
        <span *ngIf="!isEmployer">
          {{ 'CONTRACTS.WAS_ACCEPTED_FR_FLOW' | translate }}
        </span>
      </ng-container>

      <ng-container *ngIf="message?.type === 'state.freelancer_made_proposal'">
        {{ 'CONTRACTS.MADE_PROPOSAL_FR' | translate }}
      </ng-container>

      <ng-container *ngIf="message?.type === 'state.owner_paid_job'">
        {{contractsService?.selectedChat?.employer?.firstName}} {{contractsService?.selectedChat?.employer?.lastName}}
        {{ 'CONTRACTS.PROJECT_PAID_MSG' | translate }}
      </ng-container>

      <ng-container *ngIf="message?.type === 'state.owner_paid_job_milestone'">
        {{contractsService?.selectedChat?.employer?.firstName}} {{contractsService?.selectedChat?.employer?.lastName}}
        {{ 'CONTRACTS.PROJECT_MILESTONE_PAID' | translate: {number: message?.content?.milestone || ''} }}
      </ng-container>

      <ng-container *ngIf="message?.type === 'state.job.freelancer_invited'">
        <div class="subhead-1">{{message?.content?.project?.name}}</div>
        <div class="regular-14-20">
          <ng-container *ngIf="isEmployer">
            Вы пригласили {{message?.content?.freelancer?.firstName}} {{message?.content?.freelancer?.lastName}} на проект
          </ng-container>
          <ng-container *ngIf="!isEmployer">
            {{message?.content?.employer?.firstName}} {{message?.content?.employer?.lastName}} приглашает вас на проект
          </ng-container>
        </div>
      </ng-container>

      <ng-container *ngIf="message?.type === 'state.job.freelancer_applied_for_a_project'">
        <div class="subhead-1">{{message?.content?.project?.name}}</div>
        <div class="regular-14-20">
          <ng-container *ngIf="isEmployer">
            {{message?.content?.freelancer?.firstName}} {{message?.content?.freelancer?.lastName}} подал заявку на участие в проекте
          </ng-container>
          <ng-container *ngIf="!isEmployer">
            Вы подали заявку на участие в проекте
          </ng-container>
        </div>
      </ng-container>

      <ng-container *ngIf="message?.type === 'state.job.freelancer_hired'">
        <div class="subhead-1">Приглашение принято</div>
        <div class="regular-14-20">
          <ng-container *ngIf="isEmployer">
            {{message?.content?.freelancer?.firstName}} {{message?.content?.freelancer?.lastName}} стал исполнителем на проекте
          </ng-container>
          <ng-container *ngIf="!isEmployer">
            Вы стали исполнителем проекта
          </ng-container>
        </div>
      </ng-container>

      <ng-container *ngIf="message?.type === 'state.job.deleted'">
        <div class="regular-14-20">
          <ng-container *ngIf="isEmployer">
            Проект удален
          </ng-container>
          <ng-container *ngIf="!isEmployer">
            {{message?.content?.firstName}} {{message?.content?.lastName}} удалил проект
          </ng-container>
        </div>
      </ng-container>

      <ng-container *ngIf="message?.type === 'state.job.another_freelancer_hired'">
        <div class="regular-14-20">
          <ng-container *ngIf="isEmployer">
            Вы выбрали другого исполнителя на этот проект
          </ng-container>
          <ng-container *ngIf="!isEmployer">
            {{message?.content?.firstName}} {{message?.content?.lastName}} выбрал другого исполнителя на выполнение этого проекта
          </ng-container>
        </div>
      </ng-container>

      <ng-container *ngIf="message?.type === 'state.job.withdrawn'">
        <div class="regular-14-20">
          <ng-container *ngIf="isEmployer">
            Вы сняли проект с публикации
          </ng-container>
          <ng-container *ngIf="!isEmployer">
            {{message?.content?.firstName}} {{message?.content?.lastName}} снял проект с публикации
          </ng-container>
        </div>
      </ng-container>

      <ng-container *ngIf="message?.type === 'state.job.freelancer_joined'">
        <div class="subhead-1">Заявка одобрена</div>
        <div class="regular-14-20">
          <ng-container *ngIf="isEmployer">
            {{message?.content?.freelancer?.firstName}} {{message?.content?.freelancer?.lastName}} стал исполнителем на проекте
          </ng-container>
          <ng-container *ngIf="!isEmployer">
            Вы стали исполнителем проекта
          </ng-container>
        </div>
      </ng-container>

      <ng-container *ngIf="message?.type === 'state.job.contract_preparing'">
        <div class="subhead-1">Ваш договор готовится...</div>
        <div class="regular-14-20">Обычно это занимает не более 30 минут</div>
      </ng-container>

      <ng-container *ngIf="message?.type === 'state.job.order_preparing'">
        <div class="subhead-1">Заказ к договору готовится...</div>
        <div class="regular-14-20">Обычно это занимает не более 30 минут</div>
      </ng-container>

      <ng-container *ngIf="message?.type === 'state.job.contract_ready'">
        <ng-container *ngIf="isEmployer">
          <div class="subhead-1">Договор готов. Подпишите</div>
          <div class="contract">
            <div class="contract-doc">
              <mat-icon [svgIcon]="svgIconsEnum.Clipboard"></mat-icon>
              <span (click)="contractSignDialogComponent.open(message?.content?.fileId, true, 'contract')">
                {{message?.content?.name}}</span>
            </div>
          </div>
          <div class="regular-14-20 mt-12" *ngIf="message?.content?.contractAmount">
            После подписания договора с вашего баланса будут зарезервированы {{message?.content?.contractAmount}} ₽ для оплаты услуг по договору. Исполнитель получит вознаграждение только после выполнения проекта.
          </div>
        </ng-container>
        <ng-container *ngIf="!isEmployer">
          <div class="subhead-1">Договор готов</div>
          <div class="regular-14-20">
            И отправлен на подпись заказчику
          </div>
        </ng-container>
      </ng-container>

      <ng-container *ngIf="message?.type === 'state.job.order_ready'">
        <ng-container *ngIf="isEmployer">
          <div class="subhead-1">Заказ к договору готов. Подпишите</div>
          <div class="contract">
            <div class="contract-doc">
              <mat-icon [svgIcon]="svgIconsEnum.Clipboard"></mat-icon>
              <span (click)="contractSignDialogComponent.open(message?.content?.fileId, true, 'contract')">
                {{message?.content?.name}}</span>
            </div>
          </div>
          <div class="regular-14-20 mt-12" *ngIf="message?.content?.contractAmount">
            После подписания заказа с вашего баланса будут зарезервированы {{message?.content?.contractAmount}} ₽ для оплаты услуг по договору. Исполнитель получит вознаграждение только после выполнения проекта.
          </div>
        </ng-container>
        <ng-container *ngIf="!isEmployer">
          <div class="subhead-1">Заказ к договору готов</div>
          <div class="regular-14-20">
            И отправлен на подпись заказчику
          </div>
        </ng-container>
      </ng-container>

      <ng-container *ngIf="message?.type === 'state.job.contract_signed_by_employer'">
        <ng-container *ngIf="isEmployer">
          <div class="subhead-1">Вы подписали договор</div>
          <div class="contract">
            <div class="mb-8 contract-doc">
              <mat-icon [svgIcon]="svgIconsEnum.CheckCircle" class="green"></mat-icon>
              <span (click)="contractSignDialogComponent.open(message?.content?.fileId, false, 'contract')">
                {{message?.content?.name}}</span>
            </div>
            <div class="mb-8 pl-16 icon-row">
              <mat-icon [svgIcon]="svgIconsEnum.CheckSmall" class="green"></mat-icon>
              Подписант компании
            </div>
            <div class="mb-8 pl-16 icon-row">
              <mat-icon [svgIcon]="svgIconsEnum.Time" class="gray"></mat-icon>
              {{contractsService.selectedChat.freelancer?.firstName}} {{contractsService.selectedChat.freelancer?.lastName}}
            </div>
          </div>
          <div class="regular-14-20 mt-16">
            Договор отправлен на подпись исполнителю
          </div>
        </ng-container>
        <ng-container *ngIf="!isEmployer">
          <div class="subhead-1">Подпишите договор</div>
          <div class="contract">
            <div class="mb-8 contract-doc">
              <mat-icon [svgIcon]="svgIconsEnum.CheckCircle" class="green"></mat-icon>
              <span (click)="contractSignDialogComponent.open(message?.content?.fileId, true, 'contract')">
                {{message?.content?.name}}</span>
            </div>
            <div class="mb-8 pl-16 icon-row">
              <mat-icon [svgIcon]="svgIconsEnum.CheckSmall" class="green"></mat-icon>
              Подписант компании
            </div>
            <div class="mb-8 pl-16 icon-row">
              <mat-icon [svgIcon]="svgIconsEnum.Time" class="gray"></mat-icon>
              {{contractsService.selectedChat.freelancer?.firstName}} {{contractsService.selectedChat.freelancer?.lastName}}
            </div>
          </div>
        </ng-container>
      </ng-container>

      <ng-container *ngIf="message?.type === 'state.job.order_signed_by_employer'">
        <ng-container *ngIf="isEmployer">
          <div class="subhead-1">Вы подписали заказ к договору</div>
          <div class="contract">
            <div class="mb-8 contract-doc">
              <mat-icon [svgIcon]="svgIconsEnum.CheckCircle" class="green"></mat-icon>
              <span (click)="contractSignDialogComponent.open(message?.content?.fileId, false, 'contract')">
                {{message?.content?.name}}</span>
            </div>
            <div class="mb-8 pl-16 icon-row">
              <mat-icon [svgIcon]="svgIconsEnum.CheckSmall" class="green"></mat-icon>
              Подписант компании
            </div>
            <div class="mb-8 pl-16 icon-row">
              <mat-icon [svgIcon]="svgIconsEnum.Time" class="gray"></mat-icon>
              {{contractsService.selectedChat.freelancer?.firstName}} {{contractsService.selectedChat.freelancer?.lastName}}
            </div>
          </div>
          <div class="regular-14-20 mt-16">
            Документ отправлен на подпись исполнителю
          </div>
        </ng-container>
        <ng-container *ngIf="!isEmployer">
          <div class="subhead-1">Подпишите заказ к договору</div>
          <div class="contract">
            <div class="mb-8 contract-doc">
              <mat-icon [svgIcon]="svgIconsEnum.CheckCircle" class="green"></mat-icon>
              <span (click)="contractSignDialogComponent.open(message?.content?.fileId, true, 'contract')">
                {{message?.content?.name}}</span>
            </div>
            <div class="mb-8 pl-16 icon-row">
              <mat-icon [svgIcon]="svgIconsEnum.CheckSmall" class="green"></mat-icon>
              Подписант компании
            </div>
            <div class="mb-8 pl-16 icon-row">
              <mat-icon [svgIcon]="svgIconsEnum.Time" class="gray"></mat-icon>
              {{contractsService.selectedChat.freelancer?.firstName}} {{contractsService.selectedChat.freelancer?.lastName}}
            </div>
          </div>
        </ng-container>
      </ng-container>

      <ng-container *ngIf="message?.type === 'state.job.contract_signed_by_freelancer'">
        <div class="subhead-1">Договор подписан обеими сторонами</div>
        <div class="contract">
          <div class="mb-8 contract-doc">
            <mat-icon [svgIcon]="svgIconsEnum.CheckCircle" class="green"></mat-icon>
            <span (click)="contractSignDialogComponent.open(message?.content?.fileId, false, 'contract')">
                {{message?.content?.name}}</span>
          </div>
          <div class="mb-8 pl-16 icon-row">
            <mat-icon [svgIcon]="svgIconsEnum.CheckSmall" class="green"></mat-icon>
            Подписант компании
          </div>
          <div class="mb-8 pl-16 icon-row">
            <mat-icon [svgIcon]="svgIconsEnum.CheckSmall" class="green"></mat-icon>
            {{contractsService.selectedChat.freelancer?.firstName}} {{contractsService.selectedChat.freelancer?.lastName}}
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="message?.type === 'state.job.order_signed_by_freelancer'">
        <div class="subhead-1">Заказ к договору подписан</div>
        <div class="contract">
          <div class="mb-8 contract-doc">
            <mat-icon [svgIcon]="svgIconsEnum.CheckCircle" class="green"></mat-icon>
            <span (click)="contractSignDialogComponent.open(message?.content?.fileId, false, 'contract')">
                {{message?.content?.name}}</span>
          </div>
          <div class="mb-8 pl-16 icon-row">
            <mat-icon [svgIcon]="svgIconsEnum.CheckSmall" class="green"></mat-icon>
            Подписант компании
          </div>
          <div class="mb-8 pl-16 icon-row">
            <mat-icon [svgIcon]="svgIconsEnum.CheckSmall" class="green"></mat-icon>
            {{contractsService.selectedChat.freelancer?.firstName}} {{contractsService.selectedChat.freelancer?.lastName}}
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="message?.type === 'state.job.contract_changed_by_freelancer'">
        <ng-container *ngIf="isEmployer">
          <div class="subhead-1">Исполнитель внес изменения в договор</div>
          <div class="contract">
            <div class="contract-doc">
              <mat-icon [svgIcon]="svgIconsEnum.Clipboard"></mat-icon>
              <span (click)="contractSignDialogComponent.open(message?.content?.fileId, true, 'contract')">
                {{message?.content?.name}}</span>
            </div>
          </div>
          <div class="regular-14-20 mt-16">
            Ознакомьтесь с новым текстом договора и подпишите
          </div>
        </ng-container>
        <ng-container *ngIf="!isEmployer">
          <div class="subhead-1">Вы внесли изменения в договор</div>
          <div class="contract">
            <div class="contract-doc">
              <mat-icon [svgIcon]="svgIconsEnum.Clipboard"></mat-icon>
              <span (click)="contractSignDialogComponent.open(message?.content?.fileId, false, 'contract')">
                {{message?.content?.name}}</span>
            </div>
          </div>
          <div class="regular-14-20 mt-16">
            Договор отправлен на подпись заказчику
          </div>
        </ng-container>
      </ng-container>

      <ng-container *ngIf="message?.type === 'state.job.contract_changed_by_employer'">
        <ng-container *ngIf="isEmployer">
          <div class="subhead-1">Вы внесли изменения в договор</div>
          <div class="contract">
            <div class="contract-doc">
              <mat-icon [svgIcon]="svgIconsEnum.Clipboard"></mat-icon>
              <span (click)="contractSignDialogComponent.open(message?.content?.fileId, false, 'contract')">
                {{message?.content?.name}}
              </span>
            </div>
          </div>
          <div class="regular-14-20 mt-16">
            Договор отправлен на подпись исполнителю
          </div>
        </ng-container>
        <ng-container *ngIf="!isEmployer">
          <div class="subhead-1">Заказчик внес изменения в договор</div>
          <div class="contract">
            <div class="contract-doc">
              <mat-icon [svgIcon]="svgIconsEnum.Clipboard"></mat-icon>
              <span (click)="contractSignDialogComponent.open(message?.content?.fileId, true, 'contract')">
                {{message?.content?.name}}
              </span>
            </div>
          </div>
          <div class="regular-14-20 mt-16">
            Ознакомьтесь с новым текстом договора и подпишите
          </div>
        </ng-container>
      </ng-container>

      <ng-container *ngIf="message?.type === 'state.job.invoice_is_being_prepared' && isEmployer">
        <div class="subhead-1">Счет готовится...</div>
        <div class="regular-14-20">
          Обычно это занимает не более 30 минут
        </div>
      </ng-container>

      <ng-container *ngIf="message?.type === 'state.job.contract_signing_expires'">
        <div class="subhead-1">Истекает срок подписания договора</div>
        <div class="regular-14-20">
          На подписание договора по проекту осталось 24 часа. Подпишите договор или чат автоматически удалится.
        </div>
      </ng-container>

      <ng-container *ngIf="message?.type === 'state.job.contract_signing_by_freelancer_expires' && !isEmployer ||
                           message?.type === 'state.job.contract_signing_by_employer_expires' && isEmployer">
        <div class="subhead-1">Истекает срок подписания договора</div>
        <div class="regular-14-20">
          На подписание договора по проекту осталось 24 часа. Подпишите договор или чат автоматически удалится.
        </div>
      </ng-container>

      <ng-container *ngIf="message?.type === 'state.job.payment_deadline_expires_7_days' && isEmployer">
        <div class="subhead-1">Истекает срок внесения предоплаты</div>
        <div class="regular-14-20">
          Осталось 7 дней, чтобы внести предоплату по договору
        </div>
      </ng-container>

      <ng-container *ngIf="message?.type === 'state.job.payment_deadline_expires_24_hours' && isEmployer">
        <div class="subhead-1">Истекает срок внесения предоплаты</div>
        <div class="regular-14-20">
          Внесите предоплату по проекту в течение 24 часов или договор с исполнителем будет расторгнут.
        </div>
      </ng-container>

      <ng-container *ngIf="message?.type === 'state.job.reward_paid'">
        <div class="subhead-1">Вознаграждение выплачено</div>
        <div class="regular-14-20">
          {{isEmployer ? 'Вознаграждение отправлено исполнителю' : 'Вам отправлено вознаграждение'}}
        </div>
      </ng-container>

      <ng-container *ngIf="message?.type === 'state.job.waiting_for_bank_response' && isEmployer">
        <div class="subhead-1">Ожидание ответа банка</div>
        <div class="regular-14-20">
          Ждём ответ от банка. Обычно деньги поступают от 30 минут до 3 дней
        </div>
      </ng-container>

      <ng-container *ngIf="message?.type === 'state.job.make_advance_prepayment'">
        <ng-container *ngIf="isEmployer">
          <div class="subhead-1">Внесите предоплату</div>
          <div class="contract">
            <div class="contract-doc">
              <mat-icon [svgIcon]="svgIconsEnum.Clipboard"></mat-icon>
              <span (click)="downloadFile(message?.content?.fileId)">{{message?.content?.name}}</span>
            </div>
          </div>
          <div class="regular-14-20 mt-16">
            Мы холдируем предоплату по проекту до приемки выполненной работы и завершения Услуги
          </div>
        </ng-container>
        <ng-container *ngIf="!isEmployer">
          <div class="subhead-1">Внесение предоплаты</div>
          <div class="regular-14-20">
            Дождитесь внесения предоплаты заказчиком, чтобы приступить к работе над проектом
          </div>
        </ng-container>
      </ng-container>

      <ng-container *ngIf="message?.type === 'state.job.insufficient.funds'">
        <div class="subhead-1">Пополните баланс</div>
        <div class="contract">
          <div class="contract-doc" (click)="topUpBalance(message?.content?.amount)">
            Пополнить баланс - {{message?.content?.amount | number}}₽
            <mat-icon [svgIcon]="svgIconsEnum.ArrowForward" class="ml-8"></mat-icon>
          </div>
        </div>

        <div class="regular-14-20 mt-12">
          На вашем балансе недостаточно средств для резервирования оплаты услуг по договору.
          Пополните баланс, после этого исполнитель сможет начать работу.
        </div>
      </ng-container>

      <ng-container *ngIf="message?.type === 'state.job.advance_payment_made'">
        <ng-container *ngIf="isEmployer">
          <div class="subhead-1">Резервирование средств</div>
          <div class="regular-14-20 mt-12">
            На вашем балансе зарезервированы {{message?.content?.amount | number}}₽ для оплаты услуг по договору. Исполнитель получит вознаграждение только после выполнения проекта.
          </div>
        </ng-container>
        <ng-container *ngIf="!isEmployer">
          <div class="subhead-1">Приступайте к работе!</div>
          <div class="regular-14-20">
            Заказчик внес предоплату за проект. Вы получите вознаграждение после выполнения работы.
          </div>
        </ng-container>
      </ng-container>

      <ng-container *ngIf="message?.type === 'state.job.await_prepayment' && !isEmployer">
        <div class="subhead-1">Внесение предоплаты</div>
        <div class="regular-14-20">
          Дождитесь внесения предоплаты заказчиком, чтобы приступить к работе над проектом
        </div>
      </ng-container>

      <ng-container *ngIf="message?.type === 'state.job.finished'">
        <ng-container *ngIf="isEmployer">
          <div class="subhead-1">Вы завершили Услугу</div>
          <div class="contract">
            <div class="mb-8 contract-doc">
              <mat-icon [svgIcon]="svgIconsEnum.CheckCircle" class="green"></mat-icon>
              <span (click)="contractSignDialogComponent.open(message?.content?.fileId, false, 'wcc')">
                {{message?.content?.name}}</span>
            </div>
            <div class="mb-8 pl-16 icon-row">
              <mat-icon [svgIcon]="svgIconsEnum.CheckSmall" class="green"></mat-icon>
              Подписант компании
            </div>
            <div class="mb-8 pl-16 icon-row">
              <mat-icon [svgIcon]="svgIconsEnum.Time" class="gray"></mat-icon>
              {{contractsService.selectedChat.freelancer?.firstName}} {{contractsService.selectedChat.freelancer?.lastName}}
            </div>
          </div>
          <div class="regular-14-20 mt-16">
            Акт отправлен на подпись исполнителю.<br/>
            Мы сформируем чек самозанятого после перевода вознаграждения исполнителю
          </div>
        </ng-container>
        <ng-container *ngIf="!isEmployer">
          <div class="subhead-1">Ознакомьтесь с актом и подпишите</div>
          <div class="contract">
            <div class="mb-8 contract-doc">
              <mat-icon [svgIcon]="svgIconsEnum.CheckCircle" class="green"></mat-icon>
              <span (click)="contractSignDialogComponent.open(message?.content?.fileId, true, 'wcc')">
                {{message?.content?.name}}</span>
            </div>
            <div class="mb-8 pl-16 icon-row">
              <mat-icon [svgIcon]="svgIconsEnum.CheckSmall" class="green"></mat-icon>
              Подписант компании
            </div>
            <div class="mb-8 pl-16 icon-row">
              <mat-icon [svgIcon]="svgIconsEnum.Time" class="gray"></mat-icon>
              {{contractsService.selectedChat.freelancer?.firstName}} {{contractsService.selectedChat.freelancer?.lastName}}
            </div>
          </div>
          <div class="regular-14-20 mt-16">
            Заказчик завершил Услугу. После подписания акта мы отправим вознаграждение и сформируем чек
          </div>
        </ng-container>
      </ng-container>

      <ng-container *ngIf="message?.type === 'state.job.freelancer_signed_work_completion_certificate'">
        <div class="subhead-1">Акт подписан обеими сторонами</div>
        <div class="contract">
          <div class="mb-8 contract-doc">
            <mat-icon [svgIcon]="svgIconsEnum.CheckCircle" class="green"></mat-icon>
            <span (click)="contractSignDialogComponent.open(message?.content?.fileId, false, 'wcc')">
                {{message?.content?.name}}</span>
          </div>
          <div class="mb-8 pl-16 icon-row">
            <mat-icon [svgIcon]="svgIconsEnum.CheckSmall" class="green"></mat-icon>
            Подписант компании
          </div>
          <div class="mb-8 pl-16 icon-row">
            <mat-icon [svgIcon]="svgIconsEnum.CheckSmall" class="green"></mat-icon>
            {{contractsService.selectedChat.freelancer?.firstName}} {{contractsService.selectedChat.freelancer?.lastName}}
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="message?.type === 'state.job.check_ready'">
        <div class="subhead-1">Чек сформирован</div>
        <div class="contract mb-8" *ngIf="message?.content">
          <div class="contract-doc" (click)="getReceiptLink(message?.content)">
            <mat-icon [svgIcon]="svgIconsEnum.Link" style="width: 20px; height: 20px"></mat-icon>
            Чек
          </div>
        </div>
        <div class="regular-14-20">
          Вы также можете найти чек в разделах “Детали контракта” и “Финансы”
        </div>
      </ng-container>

      <ng-container *ngIf="message?.type === 'state.job.freelancer_wcc_one_day_left_to_sign' && !isEmployer">
        <div class="subhead-1">На подписание акта остался 1 день</div>
        <div class="regular-14-20">
          Подпишите акт или проект будет завершен автоматически.
        </div>
      </ng-container>

      <ng-container *ngIf="message?.type === 'state.job.freelancer_did_not_sign_wcc'">
        <div class="subhead-1">{{isEmployer ? 'Исполнитель не подписал акт' : 'Срок подписания акта истек'}}</div>
        <div class="regular-14-20">
          Проект завершен автоматически
        </div>
      </ng-container>

      <ng-container *ngIf="message?.type === 'state.receipt_error_taxpayer_unregistered'">
        <div class="subhead-1">Чек не сформирован</div>
        <div class="regular-14-20" *ngIf="isEmployer">
          Исполнитель перестал быть самозанятым.<br/>
          Исполнитель должен возместить расходы, возникшие в связи со снятием его с режима НПД.
        </div>
        <div class="regular-14-20" *ngIf="!isEmployer">
          Вы перестали быть самозанятым.<br/>
          По условиям Пользовательского соглашения вы должны возместить заказчику расходы, возникшие в связи со снятием с режима НПД.
        </div>
      </ng-container>

      <ng-container *ngIf="message?.type === 'state.job.receipt_error_taxpayer_unbound'">
        <div class="subhead-1">Чек не сформирован</div>
        <div class="regular-14-20" *ngIf="isEmployer">
          Исполнитель отозвал права у Talent Cloud.<br/>
          Исполнитель должен самостоятельно сформировать чек и направить его вам в чате Talent Cloud или по реквизитам, указанным в договоре.
        </div>
        <div class="regular-14-20" *ngIf="!isEmployer">
          Вы отозвали права у Talent Cloud.<br/>
          Сформируйте чек в приложении "Мой налог" или на сайте
          <a href="https://lknpd.nalog.ru/" target="_blank" class="detected-link">https://lknpd.nalog.ru/</a>,
          и направьте заказчику в чате Talent Cloud или по реквизитам, указанным в договоре.
        </div>
      </ng-container>

      <ng-container *ngIf="message?.type === 'state.payment_error_taxpayer_unregistered'">
        <ng-container *ngIf="isEmployer">
          <div class="subhead-1">Исполнитель потерял статус самозанятого</div>
          <div class="regular-14-20">
            Мы не можем выплатить вознаграждение. Оплатите работу исполнителя самостоятельно.<br/>
            Все документы по сделке доступны в разделах "Финансы" и "Детали контракта".
          </div>
        </ng-container>
        <ng-container *ngIf="!isEmployer">
          <div class="subhead-1">Вы потеряли статус самозанятого</div>
          <div class="regular-14-20">
            Мы не можем выплатить вам вознаграждение по проекту. Обсудите с заказчиком выплату вознаграждения за пределами Talent Cloud.<br/>
            Все документы по сделке доступны в разделах "Финансы" и "Детали контракта".
          </div>
        </ng-container>
      </ng-container>

      <ng-container *ngIf="message?.type === 'state.job.payment_error_taxpayer_unbound'">
        <ng-container *ngIf="isEmployer">
          <div class="subhead-1">Исполнитель отозвал права у Talent Cloud</div>
          <div class="regular-14-20">
            Мы не можем выплатить вознаграждение. Оплатите работу исполнителя самостоятельно.<br/>
            Все документы по сделке доступны в разделах "Финансы" и "Детали контракта".
          </div>
        </ng-container>
        <ng-container *ngIf="!isEmployer">
          <div class="subhead-1">Вы отозвали права у Talent Cloud</div>
          <div class="regular-14-20">
            Мы не можем выплатить вам вознаграждение по проекту. Обсудите с заказчиком выплату вознаграждения за пределами Talent Cloud.<br/>
            Все документы по сделке доступны в разделах "Финансы" и "Детали контракта".
          </div>
        </ng-container>
      </ng-container>


      <ng-container *ngIf="message?.type === 'state.job.refund_to_employer' && isEmployer">
        <div class="subhead-1">Возврат предоплаты</div>
        <div class="regular-14-20">
          Предоплата по проекту перечислена на ваш счет по реквизитам, указанным в личном кабинете.
        </div>
      </ng-container>

      <ng-container *ngIf="message?.type === 'state.job.supplementary_agreement_preparing'">
        <div class="subhead-1">Подготовка дополнительного соглашения...</div>
        <div class="regular-14-20">
          Обычно это занимает не более 30 минут
        </div>
      </ng-container>

      <ng-container *ngIf="message?.type === 'state.job.supplementary_agreement_ready'">
        <ng-container *ngIf="isEmployer">
          <div class="subhead-1">Дополнительное соглашение готово. Подпишите</div>
          <div class="contract">
            <div class="contract-doc">
              <mat-icon [svgIcon]="svgIconsEnum.Clipboard"></mat-icon>
              <span>{{message?.content?.name}}</span>
            </div>
          </div>
        </ng-container>

        <ng-container *ngIf="!isEmployer">
          <div class="subhead-1">Дополнительное соглашение готово</div>
          <div class="regular-14-20">
            И отправлено на подпись заказчику
          </div>
        </ng-container>
      </ng-container>

      <ng-container *ngIf="message?.type === 'state.job.supplementary_agreement_signed_by_employer'">
        <ng-container *ngIf="isEmployer">
          <div class="subhead-1">Вы подписали дополнительное соглашение</div>
          <div class="contract">
            <div class="mb-8 contract-doc">
              <mat-icon [svgIcon]="svgIconsEnum.CheckCircle" class="green"></mat-icon>
              <span (click)="contractSignDialogComponent.open(message?.content?.fileId, false, 'wcc')">
                {{message?.content?.name}}</span>
            </div>
            <div class="mb-8 pl-16 icon-row">
              <mat-icon [svgIcon]="svgIconsEnum.CheckSmall" class="green"></mat-icon>
              Подписант компании
            </div>
            <div class="mb-8 pl-16 icon-row">
              <mat-icon [svgIcon]="svgIconsEnum.Time" class="gray"></mat-icon>
              {{contractsService.selectedChat.freelancer?.firstName}} {{contractsService.selectedChat.freelancer?.lastName}}
            </div>
          </div>
          <div class="regular-14-20">
            Документ отправлен на подпись исполнителю
          </div>
        </ng-container>

        <ng-container *ngIf="!isEmployer">
          <div class="subhead-1">Подпишите дополнительное соглашение</div>
          <div class="contract">
            <div class="mb-8 contract-doc">
              <mat-icon [svgIcon]="svgIconsEnum.CheckCircle" class="green"></mat-icon>
              <span (click)="contractSignDialogComponent.open(message?.content?.fileId, true, 'contract')">
                {{message?.content?.name}}</span>
            </div>
            <div class="mb-8 pl-16 icon-row">
              <mat-icon [svgIcon]="svgIconsEnum.CheckSmall" class="green"></mat-icon>
              Подписант компании
            </div>
            <div class="mb-8 pl-16 icon-row">
              <mat-icon [svgIcon]="svgIconsEnum.Time" class="gray"></mat-icon>
              {{contractsService.selectedChat.freelancer?.firstName}} {{contractsService.selectedChat.freelancer?.lastName}}
            </div>
          </div>
        </ng-container>
      </ng-container>

      <ng-container *ngIf="message?.type === 'state.job.supplementary_agreement_signed_by_freelancer'">
        <div class="subhead-1">Документ подписан обеими сторонами</div>
        <div class="contract">
          <div class="mb-8 contract-doc">
            <mat-icon [svgIcon]="svgIconsEnum.CheckCircle" class="green"></mat-icon>
            <span (click)="contractSignDialogComponent.open(message?.content?.fileId, false, 'contract')">
                {{message?.content?.name}}</span>
          </div>
          <div class="mb-8 pl-16 icon-row">
            <mat-icon [svgIcon]="svgIconsEnum.CheckSmall" class="green"></mat-icon>
            Подписант компании
          </div>
          <div class="mb-8 pl-16 icon-row">
            <mat-icon [svgIcon]="svgIconsEnum.CheckSmall" class="green"></mat-icon>
            {{contractsService.selectedChat.freelancer?.firstName}} {{contractsService.selectedChat.freelancer?.lastName}}
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="message?.type === 'state.job.finished_automatically'">
        <ng-container *ngIf="isEmployer">
          <div class="subhead-1">Услуга завершена автоматически</div>
          <div class="regular-14-20">
            Период проверки последней задачи исполнителя истек, и Услуга была завершена. Акт направлен на подпись исполнителю.<br/>
            Вы получите чек самозанятого после перевода вознаграждения исполнителю.
          </div>
        </ng-container>
        <ng-container *ngIf="!isEmployer">
          <div class="subhead-1">Ознакомьтесь с актом и подпишите</div>
          <div class="contract">
            <div class="contract-doc">
              <mat-icon [svgIcon]="svgIconsEnum.Clipboard"></mat-icon>
              <span (click)="contractSignDialogComponent.open(message?.content?.fileId, true, 'wcc')">
                {{message?.content?.name}}</span>
            </div>
          </div>
          <div class="regular-14-20">
            Услуга завершена автоматически. После подписания акта мы отправим вознаграждение и сформируем чек
          </div>
        </ng-container>
      </ng-container>

      <ng-container *ngIf="message?.type === 'state.job.freelancer_only_signed_work_completion_certificate' && !isEmployer">
        <div class="subhead-1">Вы подписали акт</div>
        <div class="contract">
          <div class="mb-8 contract-doc">
            <mat-icon [svgIcon]="svgIconsEnum.CheckCircle" class="green"></mat-icon>
            <span (click)="contractSignDialogComponent.open(message?.content?.fileId, false, 'wcc')">
                {{message?.content?.name}}</span>
          </div>
          <div class="mb-8 pl-16 icon-row">
            <mat-icon [svgIcon]="svgIconsEnum.CheckSmall" class="green"></mat-icon>
            {{contractsService.selectedChat.freelancer?.firstName}} {{contractsService.selectedChat.freelancer?.lastName}}
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="message?.type === 'state.receipt_canceled'">
        <div class="subhead-1">Аннулирование чека</div>
        <div class="regular-14-20" *ngIf="isEmployer">
          Чек по проекту аннулирован исполнителем, свяжитесь с&nbsp;ним для уточнения причины аннулирования
        </div>
        <div class="regular-14-20" *ngIf="!isEmployer">
          Чек по проекту аннулирован
        </div>
      </ng-container>

      <ng-container *ngIf="message?.type === 'state.job.mvd.new_job' && isEmployer">
        <div class="subhead-1">Отправьте уведомление в МВД</div>
        <div class="regular-14-20">
          Вы заключаете договор с иностранным гражданином. По закону вам нужно отправить уведомление в МВД в течение 3 рабочих дней.
        </div>
      </ng-container>

      <ng-container *ngIf="message?.type === 'state.job.mvd.end_date' && isEmployer">
        <div class="subhead-1">Отправьте уведомление в МВД</div>
        <div class="regular-14-20">
          Срок действия договора с иностранным гражданином истекает сегодня. По закону вам нужно отправить уведомление в МВД в течение 3 рабочих дней.
        </div>
      </ng-container>

      <ng-container *ngIf="message?.type === 'state.job.mvd.finished_earlier' && isEmployer">
        <div class="subhead-1">Отправьте уведомление в МВД</div>
        <div class="regular-14-20">
          Вы завершили договор с иностранным гражданином. По закону вам нужно отправить уведомление в МВД в течение 3 рабочих дней.
        </div>
      </ng-container>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="isGroupChatEventMessage && chatType === 'PROJECT_CHAT'">
  <div class="status-change state-message" *ngIf="message.type === 'm.room.member'">
    <ng-container *ngIf="message?.sender?.id !== message?.content?.userId">
      <div class="status-change-header">
        {{ sendDate }}
      </div>
      <div class="status-change-body">
        <ng-container *ngIf="message.content?.membership === 'join'">
          {{message?.sender?.firstName}} {{message?.sender?.lastName}}
          {{ 'CONTRACTS.INVITED_A_PARTICIPANT' | translate }}
          {{message?.content?.userName}}
        </ng-container>

        <ng-container *ngIf="message.content?.membership === 'kick' || message.content?.membership === 'leave'">
          {{message?.content?.userName}}
          {{ 'CONTRACTS.DELETED_FROM_CHAT' | translate }}
          {{contractsService?.selectedChat?.name}}
        </ng-container>
      </div>
    </ng-container>

    <ng-container *ngIf="message?.sender?.id === message?.content?.userId && message.content?.membership === 'leave'">
      <div class="status-change-header">
        {{ sendDate }}
      </div>
      <div class="status-change-body">
        {{message?.content?.userName}} {{ 'CONTRACTS.LEFT_CHAT' | translate }} {{contractsService?.selectedChat?.name}}
      </div>
    </ng-container>
  </div>

  <div class="status-change" *ngIf="message.type === 'm.room.name'">
    <div class="status-change-header">
      {{ sendDate }}
    </div>
    <div class="status-change-body">
      {{message?.sender?.firstName}} {{message?.sender?.lastName}} {{ 'CONTRACTS.CHAT_NAME_UPDATED' | translate }}
      {{message?.content?.name}}
    </div>
  </div>

  <div class="status-change state-message" *ngIf="message.type === 'm.room.create'">
    <div class="status-change-header">
      {{ sendDate }}
    </div>
    <div class="status-change-body">
      {{message?.content?.employerName}} {{ 'CONTRACTS.CREATED_CHAT' | translate }}
      {{message?.content?.chatName}}
    </div>
  </div>

  <div class="status-change state-message" *ngIf="message.type === 'm.room.avatar'">
    <div class="status-change-header">
      {{ sendDate }}
    </div>
    <div class="status-change-body">
      {{message?.sender?.firstName}} {{message?.sender?.lastName}} {{ 'CONTRACTS.PHOTO_CHANGED' | translate }}
    </div>
  </div>
</ng-container>

<ng-container *ngIf="isProductChatEventMessage && chatType==='CREATIVE_OBJECTS_PRODUCT'">
  <div class="status-change state-message">
    <div class="status-change-header">
      {{ sendDate }}
    </div>
    <div class="status-change-body">
      <ng-container *ngIf="message?.type === 'state.product.purchase'">
        <div class="subhead-1">Выполнение сделки</div>
        <div class="regular-14-20">
          Продукт появится в чате
        </div>
      </ng-container>

      <ng-container *ngIf="message?.type === 'state.product.act'">
        <div class="subhead-1">Акт успешно сформирован</div>
        <div class="contract">
          <div class="contract-doc">
            <mat-icon [svgIcon]="svgIconsEnum.Clipboard"></mat-icon>
            <span (click)="downloadFile(message?.content?.fileId)">{{message?.content?.name}}</span>
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="message?.type === 'state.product.deal_completed'">
        <div class="subhead-1">Сделка прошла успешно</div>
        <div class="regular-14-20">
          Вам отправлена оплата по банковским реквизитам, указанным в вашем личном кабинете
        </div>
      </ng-container>

      <ng-container *ngIf="message?.type === 'state.product' &&
      message?.content?.buyerId === profileService.currentProfile.id">
        <div class="subhead-1">Сделка прошла успешно</div>
        <div class="contract" *ngIf="message?.content.url || message?.content?.documents?.length">
          <div class="contract-doc" *ngIf="message?.content.url">
            <mat-icon [svgIcon]="svgIconsEnum.CopyLink"></mat-icon>
            <a target="_blank" [href]="message?.content?.url">{{message.content?.url}}</a>
          </div>
          <div class="contract-doc" *ngIf="message.content?.documents?.length"
               (click)="downloadFile(message.content.documents[0].fileId)">
            <mat-icon [svgIcon]="svgIconsEnum.Download"></mat-icon>
            Скачать продукт
          </div>
        </div>
        <div class="comment">
          <span class="purple">Комментарий продавца:</span> {{message.content.comment}}
        </div>

        <div class="gray-text">
          Деньги отправлены продавцу.<br/>
          Чек за комиссию вы получите на электронную почту.
        </div>
      </ng-container>
    </div>
  </div>

</ng-container>

<tpt-contract-sign-dialog (signDocumentEvent)="signDocument($event)"></tpt-contract-sign-dialog>

<tpt-pay-sbp-dialog></tpt-pay-sbp-dialog>
