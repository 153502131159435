<tpt-dialog>
  <div class="wrapper">
    <mat-icon [svgIcon]="svgIconsEnum.WarnIcon" class="mb-28"></mat-icon>
    <div class="heading mb-16" [innerHTML]="title"></div>
    <div class="regular-16-24 text" [innerHTML]="text"></div>

    <tpt-button type="secondary" size="large" class="full-width mt-48" (onClick)="close()">
      Понятно
    </tpt-button>
  </div>
</tpt-dialog>
