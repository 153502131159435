<header class="header" [ngClass]="{'dark': isDark}">
  <div class="header__wrapper">
    <mat-toolbar class="header__toolbar">
      <mat-icon [svgIcon]="svgIconsEnum.BurgerMenu" class="burger-menu" (click)="toggleMobileSidebar.emit()"></mat-icon>
      <div class="header__portal"><ng-template [cdkPortalOutlet]="headerPortal"></ng-template></div>
      <ng-container [ngTemplateOutlet]="menuCollapsable"></ng-container>
    </mat-toolbar>
  </div>
</header>

<ng-template #menuCollapsable>
  <ng-container *ngIf="profileService.currentProfile == null">
    <mat-icon [svgIcon]="svgIconsEnum.ExpandedLogo" class="logo" [routerLink]="['/']"></mat-icon>
  </ng-container>

  <div class="header__actions-block" *ngIf="profileService.currentProfile">
    <div *ngIf="profileService.currentProfile.roles.includes(role.FREELANCER)">
      <button mat-icon-button [matMenuTriggerFor]="serviceMenu" class="grid-icon">
        <mat-icon [svgIcon]="svgIconsEnum.Grid"></mat-icon>
      </button>
      <mat-menu #serviceMenu="matMenu" class=" creative-objects">
        <button disableRipple mat-menu-item disabled>
          <span class="button-item">
            <mat-icon [svgIcon]="coSvgIconsEnum.TcSwitcher"></mat-icon>
            <span class="name-state">
              <span class="flow-name">Talent Cloud</span>
              <span class="flow-state">Вы сейчас здесь</span>
            </span>
          </span>
        </button>
        <button disableRipple mat-menu-item (click)="goToCo()">
          <span class="button-item">
            <mat-icon [svgIcon]="coSvgIconsEnum.CoSwitcher"></mat-icon>
            <span class="name-state">
              <span class="flow-name">Creative Objects</span>
              <span class="flow-state">Перейти</span>
            </span>
            <span class="new-badge">Новое!</span>
          </span>
        </button>
      </mat-menu>
    </div>

    <button mat-icon-button class="help-btn" (click)="helpDialog.open()">
      <mat-icon [svgIcon]="svgIconsEnum.HelpOutline" class="icon"></mat-icon>
    </button>

    <tpt-notification *ngIf="profileService.currentProfile"
                      class="icon"></tpt-notification>

    <ng-container *ngIf="profileService.currentProfile == null">
      <a mat-button [routerLink]="['/login']"
         class="header__menu-link link-small white">{{ 'HEADER_COMPONENT.LOGIN' | translate }}</a>
      <a mat-button [routerLink]="['/register']"
         class="header__menu-link link-small white">{{ 'HEADER_COMPONENT.REGISTER' | translate }}</a>
    </ng-container>

    <ng-container *ngIf="(profileService.newProfileObject | async) as profile">
      <div class="header__profile" [matMenuTriggerFor]="profileMenu" (click)="$event.stopPropagation()">
        <div class="avatar-wrapper">
          <tpt-avatar size="24" [textSizeRatio]="2.5" [user]="profile"></tpt-avatar>
        </div>
      </div>
      <mat-menu #profileMenu="matMenu" class="header__menu">
          <div class="profile-info">
            <tpt-avatar class="mr-8" size="32" [textSizeRatio]="2.5" [user]="profile"></tpt-avatar>
            <div>
              <div class="profile-name">
                {{profile?.firstName}} {{profile?.lastName}}
              </div>
            </div>
          </div>
        <button mat-menu-item [routerLink]="['/settings']">{{'HEADER_COMPONENT.EDIT_PROFILE' | translate}}</button>
        <button mat-menu-item (click)="logout()">{{ 'HEADER_COMPONENT.EXIT' | translate }}</button>
      </mat-menu>
    </ng-container>
  </div>
</ng-template>

<tpt-help-center></tpt-help-center>
