import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostListener,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import { Meta } from '@angular/platform-browser';
import { UntypedFormControl, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { GoogleLoginProvider, SocialAuthService, SocialUser } from '@abacritt/angularx-social-login';

import { AlertService } from '@/services/alert.service';
import { ProfileManagementService } from '@/services/profile-management.service';
import { TimezoneService } from '@/services/timezone.service';
import { DataLayerService } from '@/services/data-layer.service';
import { SnackbarNotificationsService } from '@/services/snackbar-notifications.service';
import { ProfilesApiService } from '@/services/profiles.api.service';
import { ProfileService } from '@/services/profile.service';
import { SvgIconsEnum } from '@/types/svg-icons.enum';
import { RegistrationForm } from '@/forms/registration.form';
import { isMobileDevice } from '@/helpers/get-mobile.helper';
import { Role } from '@/classes/enums';
import { RouteHelper } from '@/helpers/route.helper';
import { SpinnerService } from '@/modules/tpt-ui/services/spinner.service';
import {
  SignupErrorDialogComponent
} from '@/modules/common-dialogs/components/signup-error-dialog/signup-error-dialog.component';
import { Constants } from '@/classes/constants';
import {takeUntil} from 'rxjs/operators';
import { lastValueFrom, Subject } from 'rxjs';
import jwtDecode from 'jwt-decode';

const TOKEN_KEY = 'auth-token';

@Component({
  selector: 'tpt-register',
  templateUrl: 'register.component.html',
  styleUrls: ['./register.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RegisterComponent implements OnInit, OnDestroy {

  @ViewChild(SignupErrorDialogComponent) signupErrorDialogComponent: SignupErrorDialogComponent;
  public registerForm: RegistrationForm;
  public Role = Role;

  public registerSuccess = false;
  public svgIconsEnum = SvgIconsEnum;
  public isMobile = isMobileDevice();
  public user: SocialUser;
  public minPasswordLength = Constants.PASSWORD_MIN_LENGTH;

  public roleControl = new UntypedFormControl('', Validators.required);
  public googleSignUpStepTwo = false;
  public basicSignUpStepTwo = false;
  public showTooltip = false;
  public showPass = false;
  private readonly destroy$ = new Subject<void>();

  constructor(
    private router: Router,
    private profileManagementService: ProfileManagementService,
    private profileService: ProfileService,
    private cd: ChangeDetectorRef,
    private alertService: AlertService,
    private timezoneService: TimezoneService,
    public translateService: TranslateService,
    private snack: SnackbarNotificationsService,
    private dataLayerService: DataLayerService,
    private authService: SocialAuthService,
    private spinner: SpinnerService,
    private profileApiService: ProfilesApiService,
    private routeHelper: RouteHelper,
    private readonly meta: Meta,
    public activatedRoute: ActivatedRoute,
  ) { }

  @HostListener('window:resize') onResize() {
    this.isMobile = isMobileDevice();
  }

  public ngOnInit(): void {
    this.registerForm = RegistrationForm.createForm();

    const metaValue = this.meta.getTag('name="viewport"');
    if (metaValue.content !== 'width=device-width, initial-scale=1') {
      this.meta.removeTag('name="viewport"');
      this.meta.addTag({ name: 'viewport', content: 'width=device-width, initial-scale=1' });
    }

    this.authService.authState.pipe(takeUntil(this.destroy$)).subscribe((user: SocialUser) => {
      if (!user) { return; }
      this.registerWithGoogle(user);
    });
  }

  public async registerWithGoogle(socialUser): Promise<void> {
    const token = socialUser.idToken;

    const tptUser = await lastValueFrom(this.profileApiService.getUserAuthProfile(token));

    if (tptUser.roles.includes(Role.NOT_SELECTED)) {
      this.user = socialUser;
      this.googleSignUpStepTwo = true;
      this.cd.detectChanges();
    } else {
      localStorage.setItem(TOKEN_KEY, token);
      localStorage.setItem('at', 'social_g');
      const tokenDecoded: any = jwtDecode(token);
      if (tokenDecoded?.exp) {
        const expires_at = tokenDecoded.exp;
        localStorage.setItem(Constants.EXPIRES_KEY, expires_at);
      }

      await this.profileService.refreshCurrentProfile();

      const url = tptUser.roles.includes(Role.EMPLOYER) ? this.routeHelper.employerProjectsDashboard :
        this.routeHelper.projectSearch;
      await this.router.navigate([url]);
    }
  }

  ngOnDestroy() {
    const metaValue = this.meta.getTag('name="viewport"');
    if (metaValue.content !== 'width=1200px') {
      this.meta.removeTag('name="viewport"');
      this.meta.addTag({name: 'viewport', content: 'width=1200px'});
    }

    this.destroy$.next();
    this.destroy$.complete();
  }

  public createAccountGoogle(): void {
    if (this.roleControl.invalid) {
      this.snack.showNotification('REGISTER_COMPONENT.PLEASE_CHOOSE_THE_ROLE', 'fail');
      return;
    }

    this.spinner.startSpinner();
    this.profileApiService.selectRole(this.roleControl.value, this.user.idToken).subscribe(async () => {
      localStorage.setItem(TOKEN_KEY, this.user.idToken);

      await this.profileService.getUserInfo();
      this.spinner.stopSpinner();

      const url = this.roleControl.value === 'EMPLOYER' ? this.routeHelper.employerProjectsDashboard :
        this.routeHelper.projectSearch;
      await this.router.navigate([url]);
    }, () => {
      this.spinner.stopSpinner();
    });
  }
  public basicSignUp(): void {
    if (this.registerForm.email.invalid) {
      return;
    }

    this.basicSignUpStepTwo = true;
  }

  public register(): void {
    this.registerForm.password.markAsTouched();
    if (this.registerForm.invalid) {
      return;
    }
    const code = this.activatedRoute.snapshot.queryParams?.inviteCode;

    const formData = this.registerForm.getFormData();
    formData.email = formData.email?.trim()?.toLowerCase();
    formData.password = formData.password?.trim();

    const profile = {
      ...formData,
      timeZone: this.timezoneService.currentZone,
      interfaceLanguage: 'ru',
      inviteCode: formData.role === 'FREELANCER' && code ? code : null,
    };

    this.spinner.startSpinner();

    this.profileManagementService.createProfile(profile).then((data) => {
      this.spinner.stopSpinner();
      this.registerSuccess = true;

      if (formData.role === 'FREELANCER' && code) {
        const user = formData.email;
        localStorage.setItem('invitedUser', user);
      }

      this.cd.markForCheck();
    }).catch((error) => {
      if (error.errorCode.message === 'SERVER_ERRORS.PROFILE_WITH_THE_EMAIL_ALREADY_EXISTS') {
        this.signupErrorDialogComponent.open();
      } else if (error.errorCode.message === 'SERVER_ERRORS.INVALID_EMAIL') {
        this.alertService.error(error.errorCode.message);
      } else {
        this.alertService.error('SERVER_ERRORS.SIGNUP_ERROR');
      }
      this.spinner.stopSpinner();
    });
  }

  public goToFirstStep(): void {
    this.googleSignUpStepTwo = false;
    this.basicSignUpStepTwo = false;
  }

  public buttonDisabled(): boolean {
    const agencyContractValid = this.registerForm.role.value === 'FREELANCER' || this.registerForm.agencyContract.value;
    return !agencyContractValid || !this.registerForm.privacyPolicy.value || !this.registerForm.userAgreement.value;
  }
}
