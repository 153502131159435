<tpt-dialog>
  <div class="tpt-withdraw-funds-dialog-root">
    <ng-container *ngIf="withdrawState === withdrawStateEnum.Initial">
      <div class="title">{{'FINANCE.WITHDRAW' | translate}}</div>

      <div class="label mt-24">Укажите сумму, которую хотите вывести</div>
      <div class="input-wrapper">
        <div class="prefix">₽</div>
        <tpt-input [control]="formControl" type="number" class="full-width mb-20"></tpt-input>
      </div>

      <div class="regular-16-24 mb-40">
        Средства будут возвращены на реквизиты, указанные
        в данных отправителя при получении платежа
      </div>

      <div class="actions-row">
        <tpt-button type="secondary" size="large" (onClick)="dialog.close()">
          {{'BUTTON.CANCEL' | translate}}
        </tpt-button>
        <tpt-button type="primary" size="large" class="ml-12" (onClick)="withdrawFunds()">
          Вывести средства
        </tpt-button>
      </div>
    </ng-container>

    <ng-container *ngIf="withdrawState === withdrawStateEnum.Loading">
      <div class="loading-wrapper">
        <mat-spinner [diameter]="32" color="primary"></mat-spinner>
        <div class="title mt-12 mb-8">Обрабатываем ваш запрос</div>
        <div class="gray-light">Пожалуйста, подождите</div>
      </div>
    </ng-container>

    <ng-container *ngIf="withdrawState === withdrawStateEnum.Success">
      <div class="loading-wrapper">
        <svg width="47" height="48" viewBox="0 0 47 48" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect y="0.0488281" width="47" height="47" rx="7.5" fill="#F1FAF5"/>
          <path d="M19.2286 29.0775L14.2436 24.4093C13.7304 23.9287 12.8982 23.9287 12.3849 24.4093C11.8717 24.89 11.8717 25.6692 12.3849 26.1499L18.2992 31.6883C18.8125 32.169 19.6447 32.169 20.1579 31.6883L34.6151 18.1499C35.1283 17.6692 35.1283 16.89 34.6151 16.4093C34.1018 15.9287 33.2696 15.9287 32.7564 16.4093L19.2286 29.0775Z" fill="#57B554"/>
        </svg>
        <div class="title mt-12 mb-8">Средства были отправлены</div>
      </div>
    </ng-container>

    <ng-container *ngIf="withdrawState === withdrawStateEnum.Error">
      <div class="loading-wrapper">

        <svg width="47" height="48" viewBox="0 0 47 48" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect y="0.0488281" width="47" height="47" rx="7.5" fill="#FBF3F3"/>
          <path
            d="M14.2446 14.8835L14.3537 14.761C14.8629 14.2518 15.6659 14.2154 16.2171 14.6519L16.3396 14.761L23.29 21.7114L30.2404 14.761C30.7496 14.2518 31.5526 14.2154 32.1038 14.6519L32.2262 14.761C32.7354 15.2702 32.7718 16.0732 32.3354 16.6244L32.2262 16.7468L25.2758 23.6973L32.2262 30.6477C32.7355 31.1569 32.7718 31.9599 32.3354 32.511L32.2262 32.6335C31.717 33.1427 30.9141 33.1791 30.3629 32.7426L30.2404 32.6335L23.29 25.6831L16.3396 32.6335C15.8304 33.1427 15.0274 33.1791 14.4762 32.7426L14.3537 32.6335C13.8445 32.1243 13.8082 31.3213 14.2446 30.7702L14.3537 30.6477L21.3042 23.6973L14.3537 16.7468C13.8445 16.2376 13.8082 15.4347 14.2446 14.8835Z"
            fill="#EE1E1E"/>
        </svg>
        <div class="title mt-12 mb-8">Ошибка</div>
      </div>
    </ng-container>
  </div>
</tpt-dialog>


