<div class="login-root">
  <div class="login-column">
    <mat-icon [svgIcon]="svgIconsEnum.FooterLogo" class="logo-full"></mat-icon>

    <div class="login">
      <div *ngIf="!googleSignUpStepTwo">
        <h2 class="login__title">{{ 'LOGIN_COMPONENT.LOGIN' | translate }}</h2>

        <form>
          <tpt-input
            [control]="form.username"
            placeholder="{{ 'LOGIN_COMPONENT.ENTER_EMAIL_ADDRESS' | translate }}"
          >
            <ng-container label>Email</ng-container>
          </tpt-input>

          <tpt-input
            [control]="form.password"
            [type]="showPass ? 'text' : 'password'"
            placeholder="{{ 'LOGIN_COMPONENT.ENTER_PASSWORD' | translate }}"
            hintAlign="end"
            class="password-input"
          >
            <ng-container label>{{ 'LOGIN_COMPONENT.PASSWORD' | translate }}</ng-container>
            <ng-container icon>
              <mat-icon class="input-icon"
                        (click)="showPass = !showPass"
                        [svgIcon]="showPass ? svgIconsEnum.EyeOpen : svgIconsEnum.EyeClose"></mat-icon>
            </ng-container>
          </tpt-input>
          <div class="login__login-hint">
            <span routerLink="/forgotPassword">{{ 'LOGIN_COMPONENT.FORGOT_PASSWORD' | translate }}</span>
          </div>

          <tpt-button
            [disabled]="loading"
            type="primary"
            (onClick)="login()"
          >
            {{ 'LOGIN_COMPONENT.LOGIN' | translate }}
          </tpt-button>
        </form>

        <asl-google-signin-button type='standard' size='medium' class="mt-24 d-flex justify-center" shape="square"
                                  logo_alignment="center" locale="ru">
        </asl-google-signin-button>
      </div>

      <div *ngIf="googleSignUpStepTwo" class="register-column">
        <h2 class="login__title">{{ 'REGISTER_COMPONENT.GOOGLE_SIGNUP' | translate}}</h2>

        <div class="mb-24 info-text" [innerHTML]="'REGISTER_COMPONENT.GOOGLE_SIGNUP_TEXT' | translate: {email: user?.email}">
        </div>

        <div class="choose-role mb-8">
          {{ 'REGISTER_COMPONENT.CHOOSE_THE_ROLE' | translate }}
        </div>

        <mat-radio-group [formControl]="roleControl">
          <mat-radio-button value="EMPLOYER" color="primary"
                            labelPosition="before">{{'REGISTER_COMPONENT.I_AM_CLIENT' | translate}}</mat-radio-button>
          <mat-radio-button value="FREELANCER" color="primary"
                            labelPosition="before">{{'REGISTER_COMPONENT.I_AM_TALENT' | translate}}</mat-radio-button>
        </mat-radio-group>

        <tpt-button type="primary" class="mt-32" (onClick)="createAccountGoogle()">
          {{'REGISTER_COMPONENT.CREATE_ACCOUNT' | translate}}
        </tpt-button>
      </div>
    </div>

    <div class="have-acc" *ngIf="!googleSignUpStepTwo">{{'LOGIN_COMPONENT.NO_ACCOUNT' | translate}}
      <span [routerLink]="['/register']" class="link">{{'LOGIN_COMPONENT.SIGN_UP' | translate}}</span>
    </div>
  </div>
  <app-auth-pages-footer></app-auth-pages-footer>
</div>

<tpt-auth-error-dialog></tpt-auth-error-dialog>
